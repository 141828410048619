import React from "react"
// @ts-ignore
import isNil from "crocks/predicates/isNil"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ClearIcon from "@material-ui/icons/Clear"
import { Link } from "react-router-dom"
import { useAnalyticsCount } from "../../../common/store/hooks/useAnalytics"

interface AnalyticsFilterStatsProps {
  className?: string;
  visible?: boolean;
  onClear?: () => void;
}

const useStyles = makeStyles({
  root: {
    marginLeft: -16,
    border: '#ddd 1px solid',
    borderRadius: 6,
    padding: 8,
  },
  message: {
    marginTop: 8,
    color: '#555'
  },
  clearButton: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    color: 'white',
    marginLeft: 8,
    backgroundColor: '#bdbdbd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#999999'
    }
  },
})

const createStateIdParam = (stateId: string) => stateId ? `?_state_id_=${stateId}` : ''

const createLink = (appId: string, stateId: string) =>
  appId ? `/analytics/${appId}${createStateIdParam(stateId)}` : ''

function createAnalyticsMessage(analytics: any, className: string) {
  if (isNil(analytics.eventCount)) {
    return null
  }

  let message: string
  let backLink
  const cnt = analytics.eventCount

  if (cnt > 0) {
    message = `${cnt} case${cnt === 1 ? '' : 's'} selected in ${analytics.appName}.`
    backLink = analytics.appId
      ? <Link to={createLink(analytics.appId, analytics.stateId)}>Return to Analytics Tool Selection</Link>
      : null
  } else {
    message = `No analytics cases selected`
  }

  return (
    <div>
      <Typography className={className} variant={"body2"}>{message}</Typography>
      {backLink}
    </div>
  )
}

const AnalyticsFilterStats: React.FC<AnalyticsFilterStatsProps> = ({ visible = false, onClear }) => {
  const classes = useStyles()
  const analytics = useAnalyticsCount()

  if (!visible) {
    return null
  }

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Analytics Filter</Typography>
        {
          analytics.eventCount > 0 &&
          <div className={classes.clearButton} title="Clear Analytics Filter" onClick={onClear}>
            <ClearIcon style={{ width: 16, height: 16 }}/>
          </div>
        }
      </div>

      {createAnalyticsMessage(analytics, classes.message)}
    </div>
  )
}

export default AnalyticsFilterStats