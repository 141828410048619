import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from "crocks/helpers/compose";
import { hotkeys } from "react-keyboard-shortcuts";
import withStyles from '@material-ui/core/styles/withStyles';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const pagerMessage = (count, current) =>
  count === 0
    ? 'No Scenarios Selected'
    : `${current + 1} of ${count}`;

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(),
    borderTop: '1px solid #AAA',
  },
  tagsContainer: {
    padding: theme.spacing(),
    alignSelf: 'flex-start',
  },
  dlTypesContainer: {
    padding: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dlTypeChecks: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      height: theme.spacing(4),
    }
  },
  pager: {
    height: '100%',
    padding: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    }
  },
});

class ViewerControlBar extends React.PureComponent {
  static propTypes = {
    scenarioCount: PropTypes.number.isRequired,
    currentIndex: PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
  };

  static defaultProps = {
    scenarioCount: 0,
    currentIndex: 0,
  };

  // noinspection JSUnusedGlobalSymbols
  hot_keys = {
    'left': {
      priority: 1,
      handler: () => this.props.scenarioCount > 0 ? this.props.onPrev() : undefined
    },
    'right': {
      priority: 1,
      handler: () => this.props.scenarioCount > 0 ? this.props.onNext() : undefined
    },
  };

  preventFocus = ev => ev.preventDefault();

  render() {
    const { scenarioCount, currentIndex, onPrev, onNext, className, classes } = this.props;
    const pagerDisabled = scenarioCount === 0;

    return (
      <div className={classNames(className, classes.root)}>
        <div className={classes.pager}>
          <Button
            variant="contained"
            disabled={pagerDisabled}
            onClick={onPrev}
            onMouseDown={this.preventFocus}
          >
            Previous
          </Button>

          <Typography>{pagerMessage(scenarioCount, currentIndex)}</Typography>

          <Button
            variant="contained"
            disabled={pagerDisabled}
            onClick={onNext}
            onMouseDown={this.preventFocus}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  hotkeys
)(ViewerControlBar);

/*
<Grid className={classes.root} container>
<Grid item className={classes.tagsContainer} xs={12} md={4}>
<TextField placeholder="Add a Tag" disabled/>
</Grid>
<Grid item xs={12} md={4}>
<div className={classes.dlTypesContainer}>
<div className={classes.dlTypeChecks}>
<FormControlLabel disabled label="Algorithm" control={<Checkbox/>}/>
<FormControlLabel disabled label="Augmented" control={<Checkbox/>}/>
<FormControlLabel disabled label="Simulation" control={<Checkbox/>}/>
</div>
<Button variant="contained" disabled>Add to Download List</Button>
</div>
</Grid>
<Grid item className={classes.pager} xs={12} md={4}>
<Button variant="contained" disabled={pagerDisabled} onClick={onPrev}>Previous</Button>
<Typography>{pagerMessage(scenarioCount, currentIndex)}</Typography>
<Button variant="contained" disabled={pagerDisabled} onClick={onNext}>Next</Button>
</Grid>
</Grid>
*/