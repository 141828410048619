import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from "@material-ui/core/";
import GroupBoxControls from "./GroupBoxControls";
import GroupChildControls from "./GroupChildControls";
import GroupOperation from "./GroupOperation";
import GroupChildren from "./GroupChildren";
import {
  addChildToGroup,
  createGroupOp,
  invertGroupOp,
  removeChildFromGroup,
  toggleCollapse
} from "../../../../common/models";
import { partial } from "../../../../common/util/func";
import VariableSelectionPopup from "./VariableSelectionPopup";
import { replaceChildInGroup } from "../../../../common/models";
import { createAddChildOp } from "./expressionHelpers";

class ExpressionGroup extends React.Component {
  state = {
    openPopup: false,
    popupAnchor: undefined,
  };

  handleToggleOp = () => this.props.onApply(invertGroupOp, this.props.groupOp);
  handleCollapse = () => this.props.onApply(toggleCollapse, this.props.groupOp);

  handleAddGroup = () => this.props.onApply(partial(addChildToGroup, createGroupOp()), this.props.groupOp);
  handleAddExpression = variable => {
    this.handlePopupClose();
    this.props.onApply(createAddChildOp(variable), this.props.groupOp);
  };

  handleDeleteSelf = () => this.props.onDelete(this.props.groupOp);
  handleDeleteChild = child => this.props.onApply(partial(removeChildFromGroup, child), this.props.groupOp);

  handleReplaceChild = (oldChild, newChild) =>
    this.props.onApply(partial(replaceChildInGroup, oldChild, newChild), this.props.groupOp);

  handlePopupOpen = (e) => this.setState({ openPopup: true, popupAnchor: e.target });
  handlePopupClose = () => this.setState({ openPopup: false, popupAnchor: null });

  render() {
    const { groupOp, onApply } = this.props;
    const { openPopup, popupAnchor } = this.state;
    const { isCollapsed, children } = groupOp;

    return (
      <div className="group-container">
        <Paper className="group-op">
          <GroupBoxControls
            isCollapsed={isCollapsed}
            onDelete={this.handleDeleteSelf}
            onToggleCollapse={this.handleCollapse}
          />
          <GroupOperation op={groupOp.op} onToggleOp={this.handleToggleOp} />
          <GroupChildControls
            onAddGroup={this.handleAddGroup}
            onAddExpression={this.handlePopupOpen}
          />
        </Paper>
        {
          !isCollapsed &&
          <GroupChildren
            children={children}
            onApply={onApply}
            onReplace={this.handleReplaceChild}
            onDelete={this.handleDeleteChild}
          />
        }
        <VariableSelectionPopup
          isOpen={openPopup}
          anchor={popupAnchor}
          onSelection={this.handleAddExpression}
          onClose={this.handlePopupClose}
        />
      </div>
    )
  }
}

ExpressionGroup.propTypes = {
  groupOp: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ExpressionGroup;
