import { buildLookupById } from "../util/object";

export const timeSeriesVariables = [
  { id: 1, title: "ABS Activation", name: "abs", type: "state" },
  { id: 2, title: "Acceleration, x-axis", name: "accelX", type: "chart" },
  { id: 3, title: "Acceleration, y-axis", name: "accelY", type: "chart" },
  { id: 4, title: "Acceleration, z-axis", name: "accelZ", type: "chart" },
  { id: 5, title: "Airbag, Driver", name: "airbagDriver", type: "state" },
  { id: 6, title: "Cruise Control", name: "cruiseState", type: "state" },
  { id: 7, title: "Distance in Trip", name: "distance", type: "chart" },
  { id: 8, title: "Electronic Stability Control", name: "esc", type: "state" },
  { id: 9, title: "Elevation, GPS", name: "elevationGps", type: "chart" },
  { id: 10, title: "Heading, GPS", name: "headingGps", type: "chart" },
  { id: 11, title: "Headlight Setting", name: "headlight", type: "state" },
  { id: 12, title: "Pedal, Accelerator", name: "pedalGasPosition", type: "chart" },
  { id: 13, title: "Pedal, Brake", name: "pedalBrakeState", type: "chart" },
  { id: 14, title: "PRNDL", name: "prndl", type: "state" },
  { id: 15, title: "Seatbelt, Driver", name: "seatbeltDriver", type: "state" },
  { id: 16, title: "Pitch Rate, y-axis", name: "gyroY", type: "chart" },
  { id: 17, title: "Roll Rate, x-axis", name: "gyroX", type: "chart" },
  { id: 18, title: "Speed, GPS", name: "speedGps", type: "chart" },
  { id: 19, title: "Speed, Vehicle Network", name: "speedNetwork", type: "chart" },
  { id: 20, title: "Steering Wheel Position", name: "steeringWheelPosition", type: "chart" },
  { id: 22, title: "Traction Control", name: "tractionControlState", type: "state" },
  { id: 23, title: "Turn Signal", name: "turnSignal", type: "state" },
  { id: 25, title: "Wiper Setting", name: "wiper", type: "state" },
  { id: 26, title: "Yaw Rate, z-axis", name: "gyroZ", type: "chart" },
];

export const timeSeriesVariableMap = buildLookupById(timeSeriesVariables);
