import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from "crocks/predicates/isEmpty";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

class OrganizationSelect extends React.PureComponent {
  static propTypes = {
    value: PropTypes.number,
    disabled: PropTypes.bool,
    organizations: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: 0,
    organizations: [],
  };

  render() {
    const { organizations, value, onChange } = this.props;
    const disabled = this.props.disabled || isEmpty(organizations);

    return (
      <FormControl fullWidth required disabled={disabled}>
        <InputLabel htmlFor="org-select">Organization</InputLabel>
        <Select value={value} onChange={onChange} inputProps={{ id: 'org-select' }}>
          {organizations.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
        </Select>
      </FormControl>
    );
  }
}

export default OrganizationSelect;
