import React from 'react';
import Paper from "@material-ui/core/Paper";
import withStyles from '@material-ui/core/styles/withStyles';
import ContentContainer from "../../common/components/ContentContainer";
import DataUseLicense from "./DataUseLicense";

const styles = theme => ({
  paper: {
    maxWidth: 1000,
    margin: '0 auto',
    padding: theme.spacing(4),
    minHeight: 500,
  },
});

class ProfileDulContent extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <ContentContainer>
        <Paper elevation={4} className={classes.paper}>
          <DataUseLicense />
        </Paper>
      </ContentContainer>
    );
  }
}

export default withStyles(styles)(ProfileDulContent);
