import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Popover, withStyles } from "@material-ui/core/";
import CloseButton from "../../../../common/components/CloseButton";

const createCheckBox = (variable, checked, onChange) => (
  <div key={variable.id}>
    <FormControlLabel
      key={variable.id}
      label={variable.name}
      control={
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange(variable.id)}
          value={String(variable.id)}
          style={{ height: 24 }}
        />
      }
    />
  </div>
);

function createCheckBoxes(filterVars, selections, onChange) {
  const selectionSet = new Set(selections);
  return filterVars.sortedByName.map(v => createCheckBox(v, selectionSet.has(v.id), onChange));
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(),
  },
  choices: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    padding: theme.spacing(),
    border: '1px solid #AAA',
    overflow: 'auto',
    width: '100%',
    maxHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    flexGrow: 1,
    minWidth: 100,
  },
});

class AnnotationVariablePopup extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    filterVars: PropTypes.object,
    selections: PropTypes.array,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    filterVars: {},
    selections: [],
  };

  handleAll = () => this.props.onChange(this.props.filterVars.sortedByName.map(v => v.id));
  handleDefault = () => this.props.onChange([]);
  handleNone = () => this.props.onChange(null);

  handleChange = id => ev => {
    const { selections } = this.props;
    const selected = ev.target.checked
      ? [...selections, id]
      : selections.filter(varId => varId !== id);
    this.props.onChange(selected);
  };

  render() {
    const { open, anchorEl, filterVars, selections, onClose, classes } = this.props;

    return (
      <Popover anchorEl={anchorEl} open={open} onClose={onClose} onEnter={this.handleEnter}>
        <div className={classes.root}>
          <CloseButton small onClick={onClose}/>

          <div className={classes.choices}>
            {createCheckBoxes(filterVars, selections, this.handleChange)}
          </div>

          <div className={classes.buttons}>
            <Button size="small" onClick={this.handleAll}>All</Button>
            <Button size="small" onClick={this.handleDefault}>Default</Button>
            <Button size="small" onClick={this.handleNone}>None</Button>
            <div className={classes.spacer}/>
            <Button size="small" onClick={this.props.onClose}>OK</Button>
          </div>
        </div>
      </Popover>
    );
  }
}


export default withStyles(styles)(AnnotationVariablePopup);
