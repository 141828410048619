import { doLoginWith } from "./login";
import { AppRequest } from "../../models";
import { createGetThunk, createPostThunk } from "./utils";

const loginDispatcher = (user, pass) => dispatch => () => dispatch(doLoginWith(user, pass));

export const registerUser = (registration) =>
  createPostThunk({
    url: '/api/register',
    body: registration,
    request: AppRequest.POST_REGISTRATION,
    responseFnFactory: loginDispatcher(registration.username, registration.password),
  });

export const sendRecoveryEmail = email =>
  createGetThunk({
    url: '/api/register/password/forgot',
    params: { email },
    request: AppRequest.GET_FORGOT_PASSWORD
  });

export const resetPassword = (email, password, token) =>
  createPostThunk({
    url: '/api/register/password/reset',
    body: { email, password, token },
    request: AppRequest.POST_PASSWORD_RESET,
    responseFnFactory: loginDispatcher(email, password)
  });
