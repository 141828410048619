import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import safe from "crocks/Maybe/safe";
import map from "crocks/pointfree/map";
import option from "crocks/pointfree/option";
import compose from "crocks/helpers/compose";
import isEmpty from "crocks/predicates/isEmpty";
import isNil from "crocks/predicates/isNil";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import { createNews } from "../../../common/models/news";
import NewsItem from "../../../common/components/NewsItem";
import NewsItemFormFields from "./NewsItemFormFields";
import { isNotNil } from "../../../common/util/predicates";
import getTime from 'date-fns/getTime'

const buttonLabel = id => id === 0 ? 'Create' : 'Save';
const toMillis = compose(option(null), map(getTime), safe(isNotNil));
const isInvalid = (title, body, startTime) => isEmpty(title) || isEmpty(body) || isNil(startTime);

const styles = theme => ({
  root: {
    minWidth: 500,
    display: 'grid'
  },
  preview: {
    minHeight: 100,
    maxWidth: 500,
    border: '1px solid #CCC',
    borderRadius: 6,
    padding: theme.spacing(),
    marginBottom: theme.spacing(4),
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  submitBtn: {
    justifySelf: 'end',
  }
});

class NewsItemForm extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    item: PropTypes.object,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    label: "News Item",
    item: createNews(),
  };

  state = { ...this.props.item };

  handleBodyChange = ev => this.setState({ body: ev.target.value });
  handleTitleChange = ev => this.setState({ title: ev.target.value });

  handleStartChange = date => this.setState({ startTime: toMillis(date) });
  handleEndChange = date => this.setState({ endTime: toMillis(date) });

  handleSubmit = () => this.props.onSubmit(createNews({ ...this.state }));

  render() {
    const { label, item, className, classes } = this.props;
    const { startTime, endTime, title, body } = this.state;

    return (
      <div className={classNames(classes.root, className)}>
        <Typography variant="subtitle1" color="primary">Preview</Typography>
        <NewsItem
          title={title}
          body={body}
          startTime={startTime}
          endTime={endTime}
          className={classes.preview}
        />

        <Typography variant="subtitle1" color="primary">{label}</Typography>
        <NewsItemFormFields
          title={title}
          body={body}
          startTime={startTime}
          endTime={endTime}
          className={classes.form}
          onTitleChange={this.handleTitleChange}
          onBodyChange={this.handleBodyChange}
          onStartChange={this.handleStartChange}
          onEndChange={this.handleEndChange}
        />

        <Button
          color="primary"
          variant="contained"
          disabled={isInvalid(title, body, startTime)}
          onClick={this.handleSubmit}
          className={classes.submitBtn}
        >
          {buttonLabel(item.id)}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(NewsItemForm);
