import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import compose from "crocks/helpers/compose";
import or from "crocks/logic/or";
import and from "crocks/logic/and";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import withStyles from '@material-ui/core/styles/withStyles';

import { UserRole, userIsAdmin } from "../../common/models";
import MemberContactInfo from "./MemberContactInfo";
import IfUser from "../../common/components/logic/IfUser";
import { userHasAnyRoles } from "../../common/models";
import { groupLocalAdminsByOrg } from "./lpaFuncs"

const userIsLpa = userHasAnyRoles([UserRole.ROLE_LPA]);

const isMemberOf = orgId => profile => profile.orgId === orgId;
const isLpaAndMemberOf = orgId => and(userIsLpa, isMemberOf(orgId));
const isAdminOrIsLpaAndMemberOf = orgId => or(userIsAdmin, isLpaAndMemberOf(orgId));

const createMember = (admins, onDetails) => (
  <div key={admins[0].email} style={{ display: 'flex', alignItems: 'flex-start' }}>
    <MemberContactInfo admins={admins} />
    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <IfUser filter={isAdminOrIsLpaAndMemberOf(admins[0].orgId)}>
        <Button variant="contained" color="primary" onClick={onDetails}>View Details</Button>
      </IfUser>
    </div>
  </div>
);

const styles = theme => ({
  summary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.grey[700],
  },
  list: {
    padding: theme.spacing(4),
    flexDirection: 'column',
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(2),
    }
  }
});

class MembershipExpansionPanel extends React.PureComponent {
  static propTypes = {
    membership: PropTypes.object.isRequired,
    localPortalAdmins: PropTypes.array.isRequired,
  };

  handleDetailsClick = id => () => this.props.history.push(`/members/detail/${id}`);

  render() {
    const { membership, localPortalAdmins, classes } = this.props;
    const lpasByOrg = groupLocalAdminsByOrg(localPortalAdmins)

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h4">{membership.rank}</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.list}>
          {lpasByOrg.map(admins => createMember(admins, this.handleDetailsClick(admins[0].orgId)))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(MembershipExpansionPanel);
