import safe from "crocks/Maybe/safe";
import curry from "crocks/helpers/curry";
import compose from "crocks/helpers/compose";
import option from "crocks/pointfree/option";
import isDefined from "crocks/predicates/isDefined";
import constant from "crocks/combinators/constant";
import { wrap } from "../../../common/util/math";
import { safeArray } from "../../../common/util/types";
import { arrayLength } from "../../../common/util/array";
import { arrayProp, functionProp, integerProp } from "../../../common/util/object";
import { isNotNil, isUndefined } from "../../../common/util/predicates";

const callSetScenarioIndex = props => idx =>
  functionProp('setScenarioIndex', props)
    .map(setScenarioIndex => setScenarioIndex(idx));

const getAt = curry(
  (idx, arr) =>
    safeArray(arr)
      .chain(arr => safe(isDefined, arr[idx]))
);

const currentIndex = compose(option(0), integerProp('currentIndex'));

const getScenarioAtCurrentIndex = props => scenarios =>
  getAt(currentIndex(props), scenarios);

/**
 * getCurrentScenario :: Props -> { scenario: Scenario }
 */
const getCurrentScenario = props =>
  arrayProp('scenarios', props)
    .chain(getScenarioAtCurrentIndex(props));

const createStateFromScenario = currentScenario =>
  integerProp('id', currentScenario)
    .map(id => ({ currentScenario, videoUrl: `/api/event/${id}/video/` }));

/**
 * Decrements the currentIndex of the props and returns a new props object with the new currentIndex.
 *
 * decrCurrentIndex :: Props -> Props
 */
export const decrCurrentIndex = (props) =>
  ({ ...props, currentIndex: wrap(currentIndex(props) - 1, 0, arrayLength(props.scenarios) - 1) });

/**
 * Increments the currentIndex of the props and returns a new props object with the new currentIndex.
 *
 * decrCurrentIndex :: Props -> Props
 */
export const incrCurrentIndex = (props) =>
  ({ ...props, currentIndex: wrap(currentIndex(props) + 1, 0, arrayLength(props.scenarios) - 1) });

/**
 * Use the props to perform the side effect of fetching time series data for the current scenario.
 * Returns the provided props unmodified.
 *
 * fetchTimeSeriesSideEffect :: Props -> Props
 */
export const fetchTimeSeriesSideEffect = props => {
  const { fetchTimeSeries, scenarios, currentIndex } = props;

  if (isUndefined(fetchTimeSeries) || isUndefined(currentIndex)) {
    return props;
  }

  getAt(currentIndex, scenarios)
    .chain(integerProp('id'))
    .map(fetchTimeSeries);

  return props;
};

/**
 * Use the props to dispatch the setScenarioIndex action.
 *
 * setNewScenarioIndex :: Props -> Props
 */
export const setNewScenarioIndex = props =>
  integerProp('currentIndex', props)
    .chain(callSetScenarioIndex(props))
    .either(constant(props), constant(props));

/**
 * The videoUrl is the only state to be built at the moment.
 *
 * buildNewState :: Props -> State
 */
export const buildNewState = props =>
  safe(isNotNil, props)
    .chain(getCurrentScenario)
    .chain(createStateFromScenario)
    .option({ currentScenario: {}, videoUrl: '' });

/**
 * Helper composition that does the standard processing when a new scenario (i.e. new currentIndex) is set.
 *
 * processNewScenario :: Props -> State
 */
export const processNewScenario = compose(buildNewState, setNewScenarioIndex, fetchTimeSeriesSideEffect);

