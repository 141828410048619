import { createGetThunk } from "./utils"
import AppRequest from "../../models/AppRequest"

/**
 * Create a thunk action that GETs a document URL from the server.
 *
 * @param id The ID of the document to get the URL for.
 */
export const fetchDocument = id =>
  createGetThunk({
    url: `/api/doc/${id}`,
    request: AppRequest.GET_DOCUMENT,
    responseFnFactory: (dispatch, getState, services) => data => {
      return services.http.downloadFile('', data.url)
    }
  })

/**
 * Create a thunk action that GETs a steering committee document URL from the server.
 *
 * @param id The ID of the document to get the URL for.
 */
export const fetchSteeringCommitteeDocument = id =>
  createGetThunk({
    url: `/api/doc/steering-committee/${id}`,
    request: AppRequest.GET_DOCUMENT,
    responseFnFactory: (dispatch, getState, services) => data => {
      return services.http.downloadFile('', data.url)
    }
  })

