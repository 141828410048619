import isEmpty from "crocks/predicates/isEmpty";
import { isUndefined } from "../../../common/util/predicates";

/**
 * Find the next certificate pending review after the supplied certificate, wrapping to the start
 * of the list if neccessary.
 *
 * findNextPending :: ([IrbCertificate], IrbCertificate) -> IrbCertificate | undefined
 */
export function findNextPending(certs, cert) {
  if (isEmpty(certs) || isUndefined(cert)) {
    return undefined;
  }

  const currIndex = certs.findIndex(c => c.id === cert.id);

  const reorderedCerts = [...certs.slice(currIndex + 1), ...certs.slice(0, currIndex)]
    .filter(c => c.reviewDate === null);

  return isEmpty(reorderedCerts) ? undefined : reorderedCerts[0];
}