import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { userIsAdmin } from "../../models";
import { getUserProfile } from "../../store";

/**
 * IfUserIsAdmin checks to see if the current user has ROLE_ADMIN.  If the check returns true,
 * the children are rendered, otherwise the children are ignored.
 */
const IfUserIsAdmin= ({ profile, children }) => {
  return userIsAdmin(profile) ? children : null;
};

IfUserIsAdmin.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ profile: getUserProfile(state) });

export default connect(mapStateToProps)(IfUserIsAdmin);
