import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isDefined from "crocks/predicates/isDefined";
import isFunction from "crocks/predicates/isFunction";
import identity from "crocks/combinators/identity";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withStyles from '@material-ui/core/styles/withStyles';
import AmpTableHead from "./AmpTableHead";
import formatters from "./formatters";
import { invertSortDirection, SortDirection } from "../../models";

const isSortInfoFromProps = props => isDefined(props.orderBy);

function buildTableCells(columnData, row) {
  return columnData.map(col =>
    isDefined(col.render)
      ? <TableCell key={col.id}>{col.render(row)}</TableCell>
      : <TableCell key={col.id}>{formatters[col.type](row[col.id])}</TableCell>
  )
}

function getSortInfo(state, property) {
  const orderBy = property;
  const order = state.orderBy === property ? invertSortDirection(state.order) : state.order;
  return { orderBy, order };
}

function sortInPlace(data, orderBy, order) {
  order === SortDirection.DESCENDING
    ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
    : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
}

function dispatchSortInfo(state, props, orderBy, order) {
  if (state.order !== order) {
    isFunction(props.onOrderChange) && props.onOrderChange(order);
  }
  if (state.orderBy !== orderBy) {
    isFunction(props.onOrderByChange) && props.onOrderByChange(orderBy);
  }
}

const styles = {
  root: {
    overflowX: 'auto',
  },
};

class AmpTable extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    columnData: PropTypes.array.isRequired,
    className: PropTypes.string,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onOrderChange: PropTypes.func,
    onOrderByChange: PropTypes.func,
    onRowSelected: PropTypes.func,
  };

  static defaultProps = {
    onRowSelected: identity
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const data = [...nextProps.items];
    const order = nextProps.order || prevState.order;
    const orderBy = nextProps.orderBy || prevState.orderBy;

    if (!isSortInfoFromProps(nextProps)) {
      sortInPlace(data, orderBy, order);
    }

    return { data, orderBy, order };
  }

  state = {
    order: SortDirection.ASCENDING,
    orderBy: 'id',
    data: [],
    page: 0,
    rowsPerPage: 50,
    onRowSelected: identity,
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = getSortInfo(this.state, property);

    if (isSortInfoFromProps(this.props)) {
      dispatchSortInfo(this.state, this.props, orderBy, order);
    } else {
      const { data } = this.state;
      sortInPlace(data, orderBy, order);
      this.setState({ data, order, orderBy });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleRowSelect = item => () => this.props.onRowSelected(item);

  render() {
    const { data, order, orderBy } = this.state;
    const { className, classes, columnData } = this.props;

    return (
      <div className={classNames(classes.root, className)}>
        <Table className={classes.table}>
          <AmpTableHead
            columnData={columnData}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {data.map(user => {
              return (
                <TableRow hover tabIndex={-1} key={user.id} onClick={this.handleRowSelect(user)}>
                  {buildTableCells(columnData, user)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(AmpTable);
