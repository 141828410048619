import React from 'react';
import PropTypes from 'prop-types';
import CardStamp from "../DashboardCard/CardStamp";
import CardTitle from "../DashboardCard/CardTitle";
import CardActions from "../DashboardCard/CardActions";
import CardContent from "../DashboardCard/CardContent";
import ActionButton from "../DashboardCard/ActionButton";
import DashboardCard from "../DashboardCard/index";
import NewsIcon from '@material-ui/icons/Language';
import AppRequest from "../../../common/models/AppRequest";
import { isRequestStateSuccess } from "../../../common/util/request";

const { GET_NEWS } = AppRequest;
const isLoading = reqState => !isRequestStateSuccess(reqState, GET_NEWS);

const createSubtitle = (requestState, count) =>
  isLoading(requestState) ? 'Loading...' : `${count} News Item${count === 1 ? '' : 's'}`;

class NewsCard extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    onAddNews: PropTypes.func,
    onAddUpdate: PropTypes.func,
    newsCount: PropTypes.number,
    requestState: PropTypes.object,
  };

  static defaultProps = {
    newsCount: 0,
    requestState: {},
  };

  static getDerivedStateFromProps(nextProps) {
    return { subtitle: createSubtitle(nextProps.requestState, nextProps.newsCount) }
  }

  state = {
    subtitle: ''
  };

  render() {
    const { subtitle } = this.state;
    const { onAddNews, onAddUpdate, requestState, onClick } = this.props;

    return (
      <DashboardCard onClick={onClick}>
        <CardStamp backgroundColor="#D9681F" color="white">
          <NewsIcon />
        </CardStamp>
        <CardContent>
          <CardTitle title="News" subtitle={subtitle}/>
          <CardActions>
            <ActionButton
              label="News"
              onClick={onAddNews}
              disabled={isLoading(requestState)}
            />
            <ActionButton
              label="Tool Update"
              onClick={onAddUpdate}
              disabled={isLoading(requestState)}
            />
          </CardActions>
        </CardContent>
      </DashboardCard>
    );
  }
}

export default NewsCard;