import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton, withStyles } from "@material-ui/core/";
import CollapseIcon from '@material-ui/icons/KeyboardArrowLeft';
import ExpandIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseButton from "../../../../common/components/CloseButton";

const styles = theme => ({
  groupControls: {
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallBtn: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
});

const GroupBoxControls = ({ isCollapsed, onDelete, onToggleCollapse, classes }) => (
  <div className={classNames(classes.groupControls, "group-box-controls")}>
    <IconButton title="Show/Hide Children" className={classes.smallBtn} onClick={onToggleCollapse}>
      { isCollapsed ? <ExpandIcon/> : <CollapseIcon/> }
    </IconButton>
    <CloseButton title="Delete group" small onClick={onDelete} />
  </div>
);

GroupBoxControls.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
};

export default withStyles(styles)(GroupBoxControls);