import React from 'react'
import PropTypes from 'prop-types'
import Typography from "@material-ui/core/Typography"
import withStyles from '@material-ui/core/styles/withStyles'
import { trim } from "../../common/stringUtils"
import Indicators from "./Indicators"
import Features from "./Features"
import Thumbnail from "./Thumbnail"
import AmpDownloadIcons from "../../../../common/components/AmpDownloadIcons"
import compose from "crocks/helpers/compose"
import option from "crocks/pointfree/option"
import prop from "crocks/Maybe/prop"
import ScenarioTagList from "./ScenarioTagList"
import { getAllTagNames } from "../../common/tagUtils"

const trimNarrative = trim(380)
const formatNarrative = str => str && str.length > 0 ? trimNarrative(str) : "No narrative present"

const ampLayer = compose(option(''), prop('ampLayer'))

const styles = {
  root: {
    marginLeft: 80,
    position: 'relative',
    height: 180,
    display: 'grid',
    gridTemplateColumns: '240px auto 1fr',
    gridTemplateRows: 'auto auto 1fr',
    gridGap: '8px 16px',
    cursor: 'pointer',
  },
  thumbnail: {
    gridRow: '1 / 4',
    height: 180,
  },
  scenarioId: {
    gridColumn: '2',
    textAlign: 'left',
  },
  downloads: {
    gridColumn: '2',
    gridRow: '2 / 4'
  },
  caseTypes: {
    gridColumn: '3',
    gridRow: '1',
  },
  features: {
    gridColumn: '3',
  },
  narrative: {
    maxWidth: 1050,
    gridColumn: '3',
    textAlign: 'justify',
    textJustify: 'inter-character',
    overflow: 'hidden',
  },
  tag: {
    position: 'absolute',
    top: 5,
    left: -20,
  }
}

class ListResult extends React.PureComponent {
  static propTypes = {
    scenario: PropTypes.object,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    scenario: {},
  }

  handleClick = () => this.props.onClick(this.props.scenario.id)
  handleLayerClick = layer => this.props.onLayerClick(this.props.scenario.id, layer)

  render() {
    const { scenario, classes } = this.props
    const { id, fileId, caseType, referenceSpeed, featuresPresent, finalNarrative } = scenario
    const narrative = formatNarrative(finalNarrative)

    return (
      <div className={classes.root} onClick={this.handleClick}>
        <Thumbnail className={classes.thumbnail} scenarioId={id}/>
        <ScenarioTagList
          className={classes.tag}
          tagNames={getAllTagNames(scenario)}
          onClick={this.handleTagClick}
        />

        <div className={classes.scenarioId} title={`Case #${fileId}`}>
          <Typography variant="subtitle1">{fileId}</Typography>
        </div>

        <AmpDownloadIcons
          className={classes.downloads}
          ampLayer={ampLayer(scenario)}
          onClick={this.handleLayerClick}
        />

        <Indicators className={classes.caseTypes} caseType={caseType} speed={referenceSpeed}/>
        <Features className={classes.features} featuresPresent={featuresPresent} maxLen={120}/>
        <Typography className={classes.narrative} title={finalNarrative}>{narrative}</Typography>
      </div>
    )
  }
}

export default withStyles(styles)(ListResult)
