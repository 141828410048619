/*
 * This module contains actions having to do with organizations.
 */

import map from "crocks/pointfree/map";
import compose from "crocks/helpers/compose";
import { SET_ORGANIZATIONS, UPDATE_ORGANIZATION } from "./index";
import { createGetThunk, createPostThunk, createPutThunk } from "./utils";
import { AppRequest, createOrganization, createOrgInfo } from "../../models";
import { fetchUsers } from "./user";

export const setOrganizations = organizations => ({ type: SET_ORGANIZATIONS, payload: { organizations } });
export const updateOrganizationAction = organization => ({ type: UPDATE_ORGANIZATION, payload: { organization } });

const refreshUsers = dispatch => a => {
  dispatch(fetchUsers());
  return a;
};

export const fetchOrganizations = () =>
  createGetThunk({
    request: AppRequest.GET_ORGANIZATIONS,
    url: '/api/organization',
    responseFnFactory: dispatch => compose(dispatch, setOrganizations, map(createOrganization)),
  });

export const addOrganization = (name, membershipId) =>
  createPostThunk({
    request: AppRequest.POST_ORGANIZATION,
    url: '/api/organization',
    body: createOrganization({ name, membershipId: membershipId }),
    responseFnFactory: dispatch => () => dispatch(fetchOrganizations()),
  });

export const updateOrganization = org =>
  createPutThunk({
    request: AppRequest.PUT_ORGANIZATION,
    url: `/api/organization/${org.id}`,
    body: org,
    responseFnFactory: dispatch => compose(
      dispatch,
      refreshUsers(dispatch),
      updateOrganizationAction,
      createOrganization
    ),
  });

export const getOrgInfo = (id) =>
  createGetThunk({
    request: AppRequest.GET_ORG_INFO,
    url: `/api/organization/${id}/info`,
    responseFnFactory: () => createOrgInfo,
  });

export const updateOrgInfo = (id, windowsPath, unixPath) =>
  createPutThunk({
    request: AppRequest.PUT_ORG_INFO,
    url: `/api/organization/${id}/info`,
    body: { windowsPath, unixPath },
    responseFnFactory: () => createOrgInfo,
  });
