import React from 'react';
import PropTypes from 'prop-types';
import CircleIndicator from "./CircleIndicator";
import { isNotNull } from "../../../common/util/predicates";

const CaseTypeIndicator = ({ caseType, size = 36 }) => {
  // Case type was NEVER supposed to be null, but here we are.
  const caseStr = isNotNull(caseType) ? caseType : 'Unknown';

  switch (caseStr[0]) {
    case "B":
      return <CircleIndicator value="B" fill="#98D9F6" stroke="#979797" size={size} tooltip={caseStr}/>;
    case "C":
      return <CircleIndicator value="C" fill="#F9959E" stroke="#979797" size={size} tooltip={caseStr}/>;
    case "N":
      return <CircleIndicator value="NC" fill="#FCFC83" stroke="#979797" size={size} tooltip={caseStr}/>;
    case "E":
      return <CircleIndicator value="E" fill="#C0C0C0" stroke="#979797" size={size} tooltip={caseStr}/>;
    default:
      return <CircleIndicator value="?" fill="#F8F8F8" stroke="#FF9797" size={size} tooltip={caseStr}/>;
  }
};

CaseTypeIndicator.propTypes = {
  caseType: PropTypes.string,
};

CaseTypeIndicator.defaultProps = {
  caseType: "Unknown"
};

export default CaseTypeIndicator;