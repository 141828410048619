import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(),
  },
  title: {
    fontSize: '75%',
    textAlign: 'center',
    color: theme.palette.amp.viewerDim,
  },
  value: {
    fontSize: '105%',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '-5px',
    color: theme.palette.amp.viewerHighlight,
  },
});

const HandsOnWheel = ({ title, value, className, classes }) => (
  <div className={classNames(className, classes.root)}>
    <Typography className={classes.title}>{title}</Typography>
    <Typography className={classes.value}>{value}</Typography>
  </div>
);

HandsOnWheel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

HandsOnWheel.defaultProps = {
  value: '--'
};

export default withStyles(styles)(HandsOnWheel);


// import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
// import when from "crocks/logic/when";
// import prop from "crocks/Maybe/prop";
// import compose from "crocks/helpers/compose";
// import constant from "crocks/combinators/constant";
// import alt from "crocks/pointfree/alt";
// import option from "crocks/pointfree/option";
// import TimeSeriesValue from "./TimeSeriesValue";
// import Typography from "@material-ui/core/Typography";
// import withStyles from "@material-ui/core/styles/withStyles";
// import { isNull } from "../../../../../common/util/predicates";
//
// const provideNullProp = constant("NULL");
// const getProp = map => name => prop(name, map);
// const getElseVal = map => prop("ELSE", map);
//
// const createLookup = map => compose(
//   option(''),
//   alt(getElseVal(map)),
//   getProp(map),
//   when(isNull, provideNullProp)
// );
//
//
// class ValueMappedStatus extends React.PureComponent {
//   static propTypes = {
//     data: PropTypes.array,
//     time: PropTypes.number,
//     map: PropTypes.object,
//     title: PropTypes.string,
//     className: PropTypes.string,
//   };
//
//   static defaultProps = {
//     data: [],
//     time: 0,
//     map: { ELSE: '--', NULL: '--' },
//   };
//
//   render() {
//     const { data, time, title, map, className, classes } = this.props;
//     const lookup = createLookup(map);
//
//     return (
//     );
//   }
// }
//
// export default withStyles(styles)(ValueMappedStatus);
