import { createFactoryFunc } from "./factory";
import { provideEmptyString } from "../util/providers";

const orgInfoProps = [
  { name: 'windowsPath', defaultProvider: provideEmptyString },
  { name: 'unixPath', defaultProvider: provideEmptyString },
];

/**
 * Create a canonical OrgInfo object.
 *
 * createOrganization :: Object -> OrgInfo
 */
export const createOrgInfo = createFactoryFunc(orgInfoProps);
