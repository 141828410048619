import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import classNames from 'classnames'
import compose from "crocks/helpers/compose"
import prop from "crocks/Maybe/prop"
import safe from "crocks/Maybe/safe"
import isEmpty from "crocks/predicates/isEmpty"
import isString from "crocks/predicates/isString"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import withStyles from '@material-ui/core/styles/withStyles'
import SettingsIcon from '@material-ui/icons/Settings'
import SpeedIndicator from "../../../../common/components/indicators/SpeedIndicator"
import AmpDownloadIcons from "../../../../common/components/AmpDownloadIcons"
import CaseTypeIndicator from "../../../../common/components/indicators/CaseTypeIndicator"
import { isNotNil, isNotNull } from "../../../../common/util/predicates"
import { getCaseDescription } from "../../common/scenario"
import AnnotationVariablePopup from "./AnnotationVariablePopup"
import { listVariableIds } from "../../common/constants"
import { stringCompare } from "../../../../common/util/string"
import EmptyAnnotationMessage from "./EmptyAnnotationMessage"
import { getFilterVars, getSelectedAnnotationTabVariableIds } from "../../../../common/store"
import {
  fetchFilterVars,
  fetchLayerDownloadUrl,
  fetchScenario,
  setSelectedAnnotationTabVariableIds
} from "../../../../common/store/actions"
import ScenarioViewerTagList from "./ScenarioViewerTagList"
import { getAllTagNames } from "../../common/tagUtils"
import AmpDialog from "../../../../common/components/AmpDialog"
import ConnectedTagEditor from "../../common/components/ConnectedTagEditor"

const scenarioProp = (name, scenario) =>
  prop(name, scenario)
    .chain(safe(isNotNull))
    .map(val => isString(val) ? val.replace(/(\S);(\S)/g, "$1; $2") : val)
    .option('Unspecified')

const buildHeaderString = scenario =>
  prop('fileId', scenario)
    .map(id => `Case #${id}`)
    .option('')

const referenceSpeed = scenario =>
  prop('referenceSpeed', scenario)
    .option(0)

const caseType = scenario =>
  prop('caseType', scenario)
    .option('?')

const ampLayer = scenario =>
  prop('ampLayer', scenario)
    .option('')


const createGridItem = (scenario, variable) => (
  <div key={variable.id} title={variable.name}>
    <Typography style={{ marginBottom: 8 }}>
      <span style={{ fontWeight: 'bold' }}>{variable.name}</span>: {scenarioProp(variable.columnName, scenario)}
    </Typography>
  </div>
)

const styles = theme => ({
  root: {
    marginTop: theme.spacing(),
  },
  header: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: 'auto auto 16px 50px auto',
    gridTemplateColumns: '1fr 1fr',
  },
  configButton: {
    position: 'absolute',
    top: -10,
    right: 0,
  },
  text: {
    gridColumn: '1 / 3'
  },
  downloads: {
    gridRow: '4',
    gridColumn: '2',
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(),
  },
  indicators: {
    gridRow: '4',
    gridColumn: '1',
    display: 'flex'
  },
  attributes: {
    display: 'grid',
    margin: '16px 8px',
  },
  tags: {
    marginLeft: 8,
    gridRow: '5',
    gridColumn: '1 / -1',
  },
})

class AnalysisPane extends React.PureComponent {
  static propTypes = {
    scenario: PropTypes.object,
    filterVars: PropTypes.object,
    onLayerClick: PropTypes.func,
    onAnnotationChange: PropTypes.func,
    selectedVariableIds: PropTypes.array,
    className: PropTypes.string,
  }

  static defaultProps = {
    scenario: null,
    filterVars: {},
    selectedVariableIds: [],
  }

  static getDerivedStateFromProps(nextProps) {
    const { selectedVariableIds: propIds } = nextProps
    let selectedVariableIds = []

    if (isNotNull(propIds)) {
      selectedVariableIds = isEmpty(propIds) ? listVariableIds : propIds
    }

    return { selectedVariableIds }
  }

  state = {
    selectedVariableIds: [],
    popupOpen: false,
    popupAnchor: null,
    showTagEditor: false,
  }

  handlePopupOpen = e => this.setState({ popupOpen: true, popupAnchor: e.target })
  handlePopupClose = () => this.setState({ popupOpen: false, popupAnchor: null })

  handleLayerClick = layer => this.props.fetchLayerDownloadUrl(this.props.scenario.id, layer)
  handleAnnotationChange = this.props.setSelectedAnnotationTabVariableIds

  handleShowTagEditor = () => this.setState({ showTagEditor: true })
  handleTagDialogClose = () => {
    this.setState({ showTagEditor: false })
    this.props.fetchScenario(this.props.scenario.id)
  }

  render() {
    const { scenario, filterVars, className, classes } = this.props
    const { selectedVariableIds, popupOpen, popupAnchor, showTagEditor } = this.state

    const header = buildHeaderString(scenario)
    const description = getCaseDescription(scenario)
    const selectedVariables = selectedVariableIds
      .map(v => filterVars.lookupById[v])
      .filter(isNotNil)
      .sort((a, b) => stringCompare(a.name, b.name))

    return (
      <div className={classNames(className, classes.root)}>
        <div className={classes.header}>
          <IconButton className={classes.configButton} onClick={this.handlePopupOpen}>
            <SettingsIcon/>
          </IconButton>

          <Typography className={classes.text} variant="h5">{header}</Typography>
          <Typography className={classes.text}>{description}</Typography>

          <div className={classes.indicators}>
            <SpeedIndicator value={referenceSpeed(scenario)}/>
            <CaseTypeIndicator caseType={caseType(scenario)}/>
          </div>

          <div className={classes.downloads}>
            <AmpDownloadIcons ampLayer={ampLayer(scenario)} onClick={this.handleLayerClick} horizontal/>
          </div>

          <ScenarioViewerTagList
            className={classes.tags}
            onClick={this.handleShowTagEditor}
            tagNames={getAllTagNames(scenario)}
          />
        </div>

        <div className={classes.attributes}>
          {selectedVariables.map(filterVar => createGridItem(scenario, filterVar))}
        </div>

        <EmptyAnnotationMessage selectedVariables={selectedVariables} onConfigClick={this.handlePopupOpen}/>

        <AnnotationVariablePopup
          open={popupOpen}
          anchorEl={popupAnchor}
          filterVars={filterVars}
          selections={selectedVariableIds}
          onChange={this.handleAnnotationChange}
          onClose={this.handlePopupClose}
        />

        <AmpDialog
          open={showTagEditor}
          title={`Tag Case #${scenario.fileId}`}
          onClose={this.handleTagDialogClose}
        >
          <ConnectedTagEditor scenario={scenario}/>
        </AmpDialog>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  filterVars: getFilterVars(state),
  selectedVariableIds: getSelectedAnnotationTabVariableIds(state),
})

const mapDispatchToProps = {
  fetchScenario,
  fetchFilterVars,
  fetchLayerDownloadUrl,
  setSelectedAnnotationTabVariableIds
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AnalysisPane)
