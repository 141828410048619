import React from 'react'
import PropTypes from 'prop-types'
import Popover from "@material-ui/core/Popover"
import Button from "@material-ui/core/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import CloseButton from "../../../../common/components/CloseButton"
import withStyles from "@material-ui/core/styles/withStyles"

const createCheckBox = (choice, checked, onChange) => (
  <FormControlLabel
    key={choice.id}
    label={choice.choice}
    control={
      <Checkbox
        color="primary"
        checked={checked}
        onChange={onChange(choice.id)}
        value={String(choice.id)}
      />
    }
  />
)

function createCheckBoxes(choices, selected, onChange) {
  return choices.map(ch => createCheckBox(ch, selected.has(ch.id), onChange))
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(),
  },
  choices: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    overflow: 'auto',
    width: '100%',
    minHeight: '20vh',
    maxHeight: '50vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between',
    flexWrap: 'wrap',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    flexGrow: 1,
    minWidth: 100,
  },
  opRow: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  selectedText: {
    color: '#000'
  },
  deselectedText: {
    color: '#AAA'
  }
})

class ListSelectionPopup extends React.Component {
  state = {
    selected: new Set(this.props.expression.selected),
  }

  handleAll = () => this.setState({ selected: new Set(this.props.expression.variable.choices.map(ch => ch.id)) })
  handleNone = () => this.setState({ selected: new Set() })

  handleChange = id => ev => {
    const selected = new Set(this.state.selected)
    ev.target.checked ? selected.add(id) : selected.delete(id)
    this.setState({ selected })
  }

  handleSubmit = () => this.props.onChange(Array.from(this.state.selected.values()))

  render() {
    const { selected } = this.state
    const { open, anchorEl, expression, onClose, classes } = this.props
    const disableSubmit = selected.size === 0

    return (
      <Popover anchorEl={anchorEl} open={open} onClose={onClose} onEnter={this.handleEnter}>
        <div className={classes.root}>
          <CloseButton small onClick={onClose}/>

          <div className={classes.choices}>
            {createCheckBoxes(expression.variable.choices, selected, this.handleChange)}
          </div>

          <div className={classes.buttons}>
            <Button size="small" onClick={this.handleAll}>All</Button>
            <Button size="small" onClick={this.handleNone}>None</Button>
            <div className={classes.spacer}/>
            <Button size="small" disabled={disableSubmit} onClick={this.handleSubmit}>OK</Button>
          </div>
        </div>
      </Popover>
    )
  }
}

ListSelectionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  expression: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(ListSelectionPopup)
