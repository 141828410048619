import React from 'react';
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  actions: {
    marginTop: theme.spacing(),
  }
});

class CardActions extends React.PureComponent {
  swallowClick = ev => ev.stopPropagation();

  render() {
    const { classes, children } = this.props;

    return (
      <div onClick={this.swallowClick}>
        <Divider/>
        <div className={classes.actions}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(CardActions);
