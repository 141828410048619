import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideZero } from "../util/providers";

const filterProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'description', defaultProvider: provideEmptyString },
  { name: 'conditions', defaultProvider: provideEmptyString },
  { name: 'lastModifiedDate', defaultProvider: provideZero },
];

/**
 * Create a canonical Filter object.
 */
export const createFilter = createFactoryFunc(filterProps);
