import React from 'react';
import PropTypes from 'prop-types';
import ProfileInputFields, { errorCount } from "../common/components/ProfileInputFields";
import PasswordConfirmPair from "../common/components/PasswordConfirmPair";
import SubmitButton from "../common/components/SubmitButton";
import { connect } from "react-redux";
import { registerUser, resetRequestState } from "../common/store/actions";
import AppRequestState from "../common/models/AppRequestState";
import AppRequest from "../common/models/AppRequest";
import { stringProp } from "../common/util/object";
import { getRequestState } from "../common/store";

const request = AppRequest.POST_REGISTRATION;
const isNameValid = profile => profile.firstName && profile.lastName;

const isCreateDisabled = (profile, password, errors) =>
  errorCount(errors) > 0 || !isNameValid(profile) || !password;

const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: "Creating profile...",
  [AppRequestState.FAILURE]: "An error occurred, please try again later.",
  [AppRequestState.SUCCESS]: "Profile Created!",
};

class ProfileCreationForm extends React.Component {
  state = {
    step: 0,
    errors: {},
    password: '',
    profile: { firstName: '', lastName: '', phoneNumber: '' },
    createDisabled: true,
  };

  componentDidMount() {
    this.props.resetRequestState(request);
  }

  handleProfileChange = (fieldName, value, errorMessage) => {
    const profile = { ...this.state.profile, [fieldName]: value };
    const errors = { ...this.state.errors, [fieldName]: errorMessage };
    const createDisabled = isCreateDisabled(errors, profile, this.state.password);
    this.setState({ profile, errors, createDisabled });
  };

  handlePasswordChange = password => {
    const { profile, errors } = this.state;
    const createDisabled = isCreateDisabled(profile, password, errors);
    this.setState({ password, createDisabled });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { username, token } = this.props;
    const { profile, password } = this.state;
    this.props.registerUser({ username, inviteToken: token, ...profile, password });
  };

  render() {
    const { requestState } = this.props;
    const { createDisabled } = this.state;
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    return (
      <form onSubmit={this.handleSubmit}>
        <ProfileInputFields {...this.state} onProfileChange={this.handleProfileChange} />
        <PasswordConfirmPair onChange={this.handlePasswordChange} />
        <SubmitButton
          label="Create Profile"
          disabled={createDisabled}
          stateMsg={stateMsg}
          requestState={requestState}
        />
      </form>
    );
  }
}

ProfileCreationForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default connect(
  state => ({ requestState: getRequestState(state, request) }),
  { registerUser, resetRequestState }
)(ProfileCreationForm);