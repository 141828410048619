import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import compose from "crocks/helpers/compose"
import reducers from './reducers/index'
import initializeStore from "./initializeStore"

const { withExtraArgument } = thunk
const composeMiddleware = compose(composeWithDevTools, applyMiddleware, withExtraArgument)

/**
 * Configure and return a Redux store.
 * @param extra An extra argument to be passed to all thunks (see Redux Thunk)
 * @param storeCreator The store creation func, defaults to Redux's createStore.
 * @returns {Store<any>}
 */
function configureStore(extra = {}, storeCreator = createStore) {
  const store = storeCreator(
    combineReducers(reducers),
    composeMiddleware(extra),
  )

  return initializeStore(store)
}

export default configureStore

/*
 * State accessors decouple clients from the structure of the store's state.
 * Re-export the state accessors here to provide access to them all in one
 * place for the outside world.
 */
export {
  getAppStatus,
  getUserProfile,
  getRequestState,
  getSelectedChartVariables,
  getSelectedAnnotationTabVariableIds,
  getScenarioBrowserViewState,
  getScenarioBrowserExpandedQuickFilters,
} from './reducers/app'

export {
  getScenarios,
  getScenarioIndex,
  getScenarioCounts,
  getScenarioSortColumn,
  getScenarioSortDirection,
  getScenarioPageMax,
  getScenarioPageIndex,
  getScenarioPageSize,
  getTimeSeriesCache,
  getTimeSeriesCacheEntry,
  getExpressionTree,
  getFilterVars,
  getFilterVarsSorted,
  getFilterVarsLookup,
  getChartVars,
  isSaveNeeded,
  getAnalyticsCount,
  // getFilterVars,
  // getTimeSeriesVars,
  // getTimeSeriesVarsLookup,
} from './reducers/userData'

export {
  getNews,
  getFaqs,
  getUsers,
  getMemberships,
  getOrganizations,
  getIrbCertificates,
  getLocalPortalAdmins,
} from './reducers/admin'
