import React from 'react';
import { Route, Switch } from "react-router-dom";
import ProfileIrbContent from "./ProfileIrbContent";
import ProfileDulContent from "./ProfileDulContent";
import ProfileUpdateContent from "./ProfileUpdateContent";
import ProfilePasswordContent from "./ProfilePasswordContent";

const ProfileContent = () => (
  <Switch>
    <Route path="/profile/changePassword" component={ProfilePasswordContent}/>
    <Route path="/profile/irb" component={ProfileIrbContent}/>
    <Route path="/profile/dul" component={ProfileDulContent}/>
    <Route path="/profile" component={ProfileUpdateContent}/>
  </Switch>
);

export default ProfileContent;

