import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useLocation } from "../../common/util/reactRouterHooks"

const useStyles = makeStyles(theme => ({
  contentContainer: {
    padding: theme.spacing(),
  },
  content: {
    cursor: 'pointer',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '80px minmax(auto, 350px)',
    gridColumnGap: theme.spacing(),
  },
  icon: {
    gridColumn: '1',
    gridRow: '1 / -1',
    marginTop: theme.spacing(0.5),
    borderRadius: 6,
    border: `1px solid #630B0D`,
    boxShadow: '3px 3px 4px #777',
  },
}))

export default function AnalyticsAppCard({ id, name, description, iconUrl }) {
  const classes = useStyles()
  const { navigate } = useLocation()
  const handleLaunch = useCallback(() => navigate(`analytics/${id}`), [id, navigate])

  return (
    <div>
      <div className={classes.contentContainer}>
        <div className={classes.content} onClick={handleLaunch}>
          <img className={classes.icon} src={iconUrl} alt={name} width={80} height={80}/>
          <Typography variant="h6" style={{ color: 'black', fontWeight: 500, marginTop: 4 }}>{name}</Typography>
          <Typography variant="body1" style={{ color: 'black' }}>{description}</Typography>
        </div>
      </div>
    </div>
  )
}

AnalyticsAppCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  iconUrl: PropTypes.string,
}
