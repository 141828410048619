import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import compose from "crocks/helpers/compose";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import CloudIcon from '@material-ui/icons/CloudDownloadOutlined';
import UserTable from "./UserTable";
import UserTableHeader from "./UserTableHeader";
import AmpDialog from "../../../common/components/AmpDialog";
import InviteUserForm, { inviteUserDialogInfo } from "../../../common/components/forms/InviteUserForm";
import ContentContainer from "../../../common/components/ContentContainer";
import FileDownloadCard from "../../../common/components/FileDownloadCard";
import { downloadFileFromUrl, updateUserInfo } from "../../../common/store/actions";

const styles = theme => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '100px 1fr',
  },
  reports: {
    padding: theme.spacing(),
    display: 'flex',
  }
});

class UserManager extends React.PureComponent {
  static propTypes = {
    users: PropTypes.array,
    organizations: PropTypes.array,
  };

  static defaultProps = {
    users: [],
    organizations: [],
  };

  state = {
    open: false,
  };

  handleClose = () => this.setState({ open: false });
  handleAddUser = () => this.setState({ open: true });

  handleDownloadReportClick = () => this.props.downloadFileFromUrl('/api/event/report');

  render() {
    const { open } = this.state;
    const { users, organizations, classes } = this.props;

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <UserTableHeader onAddUser={this.handleAddUser} />
          <UserTable users={users} organizations={organizations}/>

          <div className={classes.reports}>
            <FileDownloadCard
              icon={<CloudIcon/>}
              title="User Downloads"
              description="All the downloads initiated by users of the site"
              fileType=".CSV"
              onClick={this.handleDownloadReportClick}
            />
          </div>
        </Paper>

        <AmpDialog
          open={open}
          text={inviteUserDialogInfo.dialogText}
          title={inviteUserDialogInfo.dialogTitle}
          onClose={this.handleClose}
        >
          <InviteUserForm organizations={organizations}/>
        </AmpDialog>
      </ContentContainer>
    );
  }
}

const mapDispatchToProps = { downloadFileFromUrl, updateUserInfo };

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(UserManager);
