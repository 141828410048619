import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import VideoOverlay from "./VideoOverlay";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#222',
    padding: theme.spacing(6),
    borderRadius: '50%',
  },
  text: {
    color: '#888',
    marginTop: theme.spacing(2),
  },
});

let LoadingOverlay = ({ show, classes }) => {
  return (
    <VideoOverlay show={show}>
      <div className={classes.root}>
        <CircularProgress color="secondary" variant="indeterminate" thickness={5} />
        <Typography className={classes.text} variant="subtitle1">Loading...</Typography>
      </div>
    </VideoOverlay>
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  show: false,
};

export default LoadingOverlay = withStyles(styles)(LoadingOverlay);

