import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteAnalyticsIds, fetchAnalyticsAppsFromServer, fetchFunctionalScenariosFromServer, putAnalyticsIds } from "../actions"
import { getAnalyticsCount } from ".."

function useAnalyticsApps() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(fetchAnalyticsAppsFromServer()), [dispatch])
}

function useFunctionalScenarios() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(fetchFunctionalScenariosFromServer()), [dispatch])
}

function useAnalyticsTag() {
  const dispatch = useDispatch()

  const setAnalyticsIds = useCallback(
    (appId, appName, stateId, eventIds) => dispatch(putAnalyticsIds(appId, appName, stateId, eventIds)),
    [dispatch]
  )

  const clearAnalyticsIds = useCallback(() => dispatch(deleteAnalyticsIds()), [dispatch])

  return { setAnalyticsIds, clearAnalyticsIds }
}


function useAnalyticsCount() {
  return useSelector(getAnalyticsCount)
}

export { useAnalyticsApps, useFunctionalScenarios, useAnalyticsTag, useAnalyticsCount }