import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import UserManager from "./UserManager";
import NewsManager from "./NewsManager";
import FaqManager from "./FaqManager";
import IrbReview from "./IrbReview";
import OrganizationManager from "./OrganizationManager";

import {
  getFaqs,
  getIrbCertificates,
  getMemberships,
  getNews,
  getOrganizations,
  getRequestState,
  getUserProfile,
  getUsers,
} from "../../common/store";

import {
  amendUserToIrb,
  deleteFaqFromServer,
  deleteNewsItemFromServer,
  fetchAllIrbCertificates,
  fetchFaqsFromServer,
  fetchMemberships,
  fetchNewsFromServer,
  fetchOrganizations,
  fetchUsers,
  postFaqToServer,
  postNewsItemToServer,
  putFaqToServer,
  putNewsItemToServer,
  sendEmailToUsers,
  submitIrbReview
} from "../../common/store/actions";

/**
 * This component serves as the main router and main point of contact with the Redux
 * store for the admin pages.
 */
class AdminContent extends React.Component {
  static propTypes = {
    news: PropTypes.array,
    users: PropTypes.array,
    organizations: PropTypes.array,
    irbCertificates: PropTypes.array,
    requests: PropTypes.object,
  };

  static defaultProps = {
    news: [],
    faqs: [],
    users: [],
    organizations: [],
    irbCertificates: [],
    requests: {},
  };

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchMemberships();
    this.props.fetchOrganizations();
    this.props.fetchNewsFromServer();
    this.props.fetchFaqsFromServer();
    this.props.fetchAllIrbCertificates();
  }

  render() {
    const { submitIrbReview, amendUserToIrb, sendEmailToUsers } = this.props;
    const { postFaqToServer, putFaqToServer, deleteFaqFromServer } = this.props;
    const { postNewsItemToServer, putNewsItemToServer, deleteNewsItemFromServer } = this.props;
    const { news, faqs, users, memberships, organizations, irbCertificates, profile, requests } = this.props;

    return (
      <Switch>
        <Route path="/admin/users">
          <UserManager users={users} organizations={organizations}/>
        </Route>

        <Route path="/admin/news">
          <NewsManager
            news={news}
            onCreateNews={postNewsItemToServer}
            onUpdateNews={putNewsItemToServer}
            onDeleteNews={deleteNewsItemFromServer}
          />
        </Route>

        <Route path="/admin/faq">
          <FaqManager
            faqs={faqs}
            onCreateFaq={postFaqToServer}
            onUpdateFaq={putFaqToServer}
            onDeleteFaq={deleteFaqFromServer}
          />
        </Route>

        <Route path="/admin/organizations">
          <OrganizationManager organizations={organizations} memberships={memberships}/>
        </Route>

        <Route path="/admin/reviews">
          <IrbReview
            users={users}
            profile={profile}
            requests={requests}
            irbCertificates={irbCertificates}
            onSubmitIrbReview={submitIrbReview}
            onAmendUserToIrb={amendUserToIrb}
          />
        </Route>

        <Route
          path="/admin"
          render={routeProps =>
            <AdminDashboard
              news={news}
              faqs={faqs}
              users={users}
              requests={requests}
              memberships={memberships}
              organizations={organizations}
              irbCertificates={irbCertificates}
              onCreateNewsItem={postNewsItemToServer}
              onCreateFaqItem={postFaqToServer}
              onSendEmail={sendEmailToUsers}
              onSubmitIrbReview={submitIrbReview}
              {...routeProps}
            />
          }
        />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  news: getNews(state),
  faqs: getFaqs(state),
  users: getUsers(state),
  memberships: getMemberships(state),
  organizations: getOrganizations(state),
  irbCertificates: getIrbCertificates(state),
  profile: getUserProfile(state),
  requests: getRequestState(state),
});

const mapDispatchToProps = {
  fetchUsers,
  fetchMemberships,
  fetchOrganizations,
  fetchNewsFromServer,
  fetchFaqsFromServer,
  fetchAllIrbCertificates,
  postNewsItemToServer,
  putNewsItemToServer,
  deleteNewsItemFromServer,
  postFaqToServer,
  putFaqToServer,
  deleteFaqFromServer,
  submitIrbReview,
  amendUserToIrb,
  sendEmailToUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContent);
