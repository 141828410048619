import { fromNullable, tryCatch } from "../util/func";

const KEYS = Object.freeze({
  uiState: 'uiState',
  variables: 'variables',
  currentFilter: 'currentFilter',
  expressionTree: 'expressionTree',
});

const read = key =>
  fromNullable(() => localStorage.getItem(key))
    .chain(json => tryCatch(() => JSON.parse(json)));

const write = key => item => {
  tryCatch(() => localStorage.setItem(key, JSON.stringify(item)));
  return item;
};

/**
 * Writes the serialized form of the expression tree to local storage.
 *
 * @param expressionTreeJson The JSON of the tree to be serialized and stored.
 * @returns The original expressionTree that was passed in (for chaining).
 */
export function writeExpressionTree(expressionTreeJson) {
  tryCatch(() => localStorage.setItem(KEYS.expressionTree, expressionTreeJson));
  return expressionTreeJson;
}

/**
 * Reads the serialized form of the expression tree from local storage.
 *
 * @returns A Maybe containing the expression tree from storage or Nothing if there was a problem.
 */
export const readExpressionTree = () =>
  fromNullable(() => localStorage.getItem(KEYS.expressionTree));

/**
 * Writes the variables list to local storage.
 *
 * @param variables The variables array.
 * @returns {*} The variables array that was passed in (for chaining).
 */
export function writeVariables(variables) {
  tryCatch(() => localStorage.setItem(KEYS.variables, JSON.stringify(variables)));
  return variables;
}

/**
 * Reads the variables from local storage.
 *
 * @returns A Maybe containing the variables read from storage or Nothing if there was a problem.
 */
export const readVariables = () =>
  fromNullable(() => localStorage.getItem(KEYS.variables))
    .chain(json => tryCatch(() => JSON.parse(json)));

/**
 * Writes the current filter to local storage.
 *
 * @param filter The current filter array.
 * @returns {*} The current filter that was passed in (for chaining).
 */
export function writeCurrentFilter(filter) {
  tryCatch(() => localStorage.setItem(KEYS.currentFilter, JSON.stringify(filter)));
  return filter;
}

/**
 * Reads the current filter from local storage.
 *
 * @returns A Maybe containing the filter read from storage or Nothing if there was a problem.
 */
export const readCurrentFilter = () =>
  fromNullable(() => localStorage.getItem(KEYS.currentFilter))
    .chain(json => tryCatch(() => JSON.parse(json)));

/**
 * readUiState :: () -> Maybe [Int]
 */
export const readUiState = () => read(KEYS.uiState);

/**
 * writeUiState :: [Int] -> [Int]
 */
export const writeUiState = write(KEYS.uiState);

