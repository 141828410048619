import React from 'react';
import documents from './documents';
import AmpBannerPage from "../common/components/AmpBannerPage";
import DocContent from "./DocContent";

const Index = (props) => {
  const { id } = props.match ? props.match.params : 'contact';
  const doc = documents.find(d => d.id === id);

  return (
    <AmpBannerPage>
      <DocContent {...doc}/>
    </AmpBannerPage>
  );
};

export default Index;