import React from 'react'
import { Route, Switch } from "react-router-dom"
import FunctionalScenarioApp from './FunctionalScenarioApp'
import FunctionalScenarioAppGrid from './FunctionalScenarioAppGrid'
import { FunctionalScenarioStateProvider } from './FunctionalScenarioStateProvider'

export default function FunctionalScenarioContent() {
  return (
    <FunctionalScenarioStateProvider>
      <Switch>
        <Route path="/functionalscenario/:appId" component={FunctionalScenarioApp} />
        <Route path="/functionalscenario" component={FunctionalScenarioAppGrid} />
      </Switch>
    </FunctionalScenarioStateProvider>
  )
}
