import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  root: {},
};

class DataUseLicense extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <h1>AUTOMATED MOBILITY PARTNERSHIP (AMP) PROGRAM DATA USE LICENSE SUMMARY</h1>

        <h3>By using AMP data portal you are agreeing to the terms in the following license summary document.</h3>

        <p>
        Licensing Agency: Virginia Polytechnic Institute and State University by and through its Virginia Tech
        Transportation Institute (“VTTI”).
        </p>

        <h3>OVERVIEW</h3>
        <h4>Description of the Automated Mobility Partnership (AMP) Program</h4>
        <h4>VTTI Resources and Experience</h4>
        <p>
        Since its inception, VTTI has supported public- and private-sector customers in research and
        development related to the latest vehicle technologies, including connected and automated vehicles. In
        2013, VTTI unveiled the Center for Automated Vehicle Systems, which was created specifically to
        respond to the rapid development of Automated Driving Systems (ADSs). In December 2017, VTTI
        unveiled its Urban Expansion to the Virginia Smart Road and announced the development of the Rural
        Expansion. In combination with the Smart Road, which VTTI has operated for 17 years, these expansions
        provide a complete set of environments to support automation research and development.
        </p>

        <p>
        VTTI currently maintains and oversees databases containing enormous amounts of naturalistic driving
        data (~70 million miles of data, representing ~90% of the naturalistic driving data in the world), including
        data from the Second Strategic Highway Research Program 2 (SHRP 2). Over a three-year period, the
        SHRP 2 program collected almost 32.5 million miles and nearly 1 million hours of continuous driving data
        and video. Additional VTTI databases contain a wide range of driving data including teenage drivers,
        motorcyclists, and commercial truck drivers.
        </p>

        <h4>Automated Mobility Partnership (AMP) Program</h4>
        <p>
        The Automated Mobility Partnership (“AMP”) program will join leading industry companies, VTTI
        Primary Investigators (PIs), and industry collaborators to promote the development of tools, techniques,
        and data resources that support the rapid advancement of automated-vehicle deployment for its
        members.  The AMP program is a precompetitive research activity.  It has a highly evolved data and
        analytics plan to give order to the complex and chaotic scenarios inherent to real-world driving.
        Through the creation of a significant scenario library (AMP Scenario Library) based on a multitude of
        naturalistic driving studies, AMP will enable scenario reconstruction that supports the development and
        evaluation of Automated Driving Systems (ADSs).  The rates and descriptive analytics work will
        characterize the frequency of events and epochs contained in the augmented layer.  There will also be
        work completed to characterize human driver kinematics in similar conditions.
        </p>

        <p>
        Steering Committee Members will receive access to the AMP Scenario Library Portal and Component
        Layers and Descriptive and Rate Analytic Work Products. Each individual company has the ability to
        perform independent work using the AMP downloadable data, work products, and toolsets for the
        development and evaluation of their own technologies (e.g., ADAS, ADSs, mobility, connected, and/or
        other).
        </p>

        <h4>Data Access</h4>
        <p>
        Requests by specific licensee’s qualified researchers for access to the AMP Scenario Library Portal shall
        be made to Virginia Tech, following processes approved by the VT Institutional Review Board (IRB),
        which is responsible for ensuring compliance with the participant informed consent forms under which
        the Naturalistic Driving Study (NDS) were obtained, and for compliance with the HHS Common Rule
        governing human subject research data.  These processes are described in this Data Use License (DUL).
        </p>

        <h4>Licensee and Licensing Agency</h4>
        <p>
        The Company Steering Committee Member signing this DUL is the licensee and the Virginia Tech
        Transportation Institute is the licensing agency for the purposes of this DUL to allow for access to the
        AMP Scenario Library Portal.
        </p>

        <h4>PII</h4>
        <p>
        Personally Identifiable Information (PII) is defined as any information that could reasonably identify a
        human subject. Some examples of PII are demographic information, face video or comparable images,
        voiceprints, and employer’s names. Any elements considered identifying or potentially identifying (PII)
        will be removed from the AMP Scenario Library NDS data, to the best of the licensing agency's ability
        and utilizing state-of-the-art tools.  The licensee and its qualified researchers agree to report back to the
        licensing agency any instances where they believe that PII is inadvertently present within the AMP
        Scenario Library NDS data.  The licensing agency will review any such reports and take appropriate
        corrective action.
        </p>

        <h4>Original vs Transformed Data</h4>
        <p>
        The AMP Scenario Library NDS data will include original data (i.e., collected directly from the participant
        at enrollment, about the vehicle at installation, or from each trip as the driver conducted normal daily
        driving). Original data must generally be destroyed by the licensing agency at a certain number of years
        after data collection ended (depending on the type of data) and thus must be tracked. For this reason,
        this DUL has a default licensee data retention length of two years past project completion. De-identified
        summary data (which cannot be reverted to original data by transformation) produced by the licensee
        as part of their work processes on this DUL may be retained indefinitely and used by the licensee
        without licensing agency constraints.
        </p>

        <h4>Local Portal Administrator</h4>
        <p>
        The licensee shall appoint a Local Portal Administrator (LPA).  The LPA shall be responsible for ensuring
        that the provisions of this license are adhered to by the licensee and the licensee’s qualified researchers.
        The LPA shall also serve as a primary point of contact for the licensee’s qualified researchers related to
        obtaining, maintaining, or removing access to the AMP Scenario Library Portal.
        </p>

        <h4>Certificate of Confidentiality</h4>
        <p>
        Research subjects in the NDSs that form the AMP Scenario Library data are generally protected by
        Certificates of Confidentiality issued by the Department of Health and Human Services (DHHS) to
        Virginia Polytechnic Institute in accordance with the provisions of section 301(d) of the Public Health
        Service Act (42 U.S. C., 241(d)). Certificates of Confidentiality are issued by the National Institutes of
        Health (NIH) and other DHHS agencies to protect identifiable research information from forced or
        compelled disclosure. Certificates of Confidentiality allow the investigator and others who have access
        to research records to refuse to disclose identifying information on research participants in civil,
        criminal, administrative, legislative, or other proceedings, whether federal, state, or local. The licensee is
        an authorized user of the NDS data under the terms of the Certificate of Confidentiality. As such, the
        licensee and the licensee’s qualified researchers, , are authorized to protect the privacy of the
        individuals who are the subjects of the NDS by withholding their names and other identifying
        characteristics from all persons not connected with the conduct of the study. “Identifying
        characteristics” include data such as: name, address, social security or other identifying number,
        fingerprints, voiceprints, photographs, genetic information or tissue samples, or any other item or
        combination of data about a research participant which could reasonably lead, directly or indirectly by
        reference to other information, to identification of that research subject.
        </p>

        <h4>Security Requirements</h4>
        <p>In order to maintain the AMP Scenario Library data securely, the licensee affirms that:</p>
        <ul>
          <li>
            Only those persons identified on the AMP Portal as qualified researchers associated with the
          licensee will have access to the original data, and that appropriate role-based access controls
          will be used to enforce this restriction.
          </li>
          <li>
            The AMP Scenario Library data will be encrypted when at rest.  Data will either be encrypted as
          part of a full-disk encryption scheme (using BitLocker, FileVault, or other) or within encrypted
          containers (using VeraCrypt, Bitlocker, or other).
          </li>
          <li>
            Systems interacting with and/or storing the AMP Scenario Library data will be monitored, and
          appropriate action will be taken when alerts are received.
          </li>
          <li>
            Appropriate controls are in place to perform and confirm expungement when this DUL expires
          (two years past project completion).
          </li>
          <li>
            Appropriate information system baseline hardening procedures will be used (including, but not
          limited to, system updating, local firewall implementation, and antivirus scanning) for systems
          interacting with and/or storing these research data.  Industry standards from organizations such
          as NIST and the Center for Internet Security will be used to inform these baseline procedures.
          </li>
        </ul>

        <h4>AMP Data Use Project Information</h4>
        <p>
        The AMP Scenario Library Portal will be released in June 2018 for a beta testing phase with the full initial
        1st release scheduled for July 2018, with its initial lifespan lasting through July 2020.  The data may be
        retained for 24 months following the project completion (currently, July 2022).  If Virginia Tech confirms
        the following: that the licensee is still actively using the data at the end of the data retention period,
        that the licensee contact information (including all users accessing the data) is updated and that the
        licensee is within the DUL requirements, this DUL can be extended upon mutual written agreement of
        the licensee and the licensing agency.
        </p>

        <p>
        The AMP Scenario Library will be created based on naturalistic driving data to enable ADS development
        tasks, particularly event and epoch virtual reconstruction.  The AMP Scenario Library will include a mix
        of safety critical events (e.g., crashes and near-crashes) and epochs of interest (e.g., low-sun-angle
        driving, traffic circle traversals) from light, middle, and heavy-duty vehicles.
        </p>

        <p>
        The licensee will have access to the AMP Scenario Library Portal and associated data per the terms of
        this DUL.  It is expected that the work performed with the data will mainly support the advancement of
        ADS, with an emphasis on safety (though this is not expected to be the only application).  The uses of
        the data shall be conformant to the provisions of the IRB application that will cover the data under this
        DUL.  No research or development activities will be undertaken with the data without approval of an
        IRB.  VTTI will not provide data prior to obtaining all necessary consents and approvals.
        </p>

        <p>
        The licensee cannot make the data available to personnel who are not employees, employees of
        controlled subsidiaries, or individuals under the management of the licensee, nor can the data be stored
        within or accessed by IT infrastructure that is not under the administrative control of the licensee and at
        all times subject to the security requirements set forth herein.
        </p>

        <h4>Data Access Process</h4>
        <p>
        The licensee will appoint a LPA that will be the primary point of contact within the licensee’s
        organization for inquiries related to the AMP Scenario Library Portal.
        </p>

        <p>
        The LPA will be provided with elevated credentials within the AMP Scenario Library Portal, which will
        allow for management of the registered licensee Qualified Researchers (QRs).
        </p>

        <p>Upon receiving a request for access from an interested party within the licensee, the LPA shall:</p>
        <ul>
          <li>Ensure that the requestor has received training to become a qualified researcher (per IRB
            training requirements, which will be outlined and tracked  in the AMP Scenario Library Portal)</li>
          <li>Provide the requestor with a link for self-registration (if this step has not already been
            completed)</li>
          <li>Approve the requestor within the AMP Scenario Library Portal, signifying that the requestor is
          employed by the licensee and has interest in the data in concert with the data uses described in
          this DUL and outlined in the IRB application.
            <ul>
              <li>If the desired data use is misaligned with the data uses described in this DUL and
              outlined in the IRB application, the LPA is responsible for initiating a request with the
              licensing agency so that the IRB application is amended as appropriate.</li>
            </ul>
          </li>
        </ul>

        <p>
        The LPA shall also disable users from the licensee’s list of qualified researchers promptly as the
        individuals cease to be employed by the licensee or no longer have a need for access to the AMP
        Scenario Library Portal.  In these cases, the LPA is also responsible for confirmation that any portions of
        the dataset that were used locally by the disabled user have been deleted.
        </p>

        <h3>LICENSEE RESPONSIBILITIES</h3>
        <p>The licensee agrees to the following terms and conditions of this license:</p>
        <h4>Control Access to the Data</h4>
        <ul>
          <li>Access to the AMP Scenario Library Portal data will be limited solely to the qualified researchers
          within the licensee’s organization who have gone through the proper training and followed the
          process described in the previous section. The data are not to be “loaned” or otherwise
          conveyed to anyone other than these QRs.</li>
          <li>Comply with procedures described in the Security Requirements section of this DUL.</li>
          <li>Certify via email response that all copies of any data provided under the terms of this DUL
          (including backup copies) have been destroyed at the end of the data retention term.</li>
        </ul>

        <h4>Ensure Continuing IRB Approval</h4>
        <ul>
          <li>Maintain continuous IRB coverage of the project.  The licensing agency will assist with this task
          by maintaining IRB approval of the AMP effort through the Virginia Tech IRB, but the licensee
          must be responsive to associated communications and cognizant of the need for amendments
          to the IRB application.</li>
          <li>Ensure that all study staff registered as qualified researchers have had IRB/ethics/human
          subjects training, maintain records of this training, and provide these records upon request.  The
          licensing agency will provide tools to assist with this record-keeping task in the AMP Scenario
          Library Portal.</li>
        </ul>

        <h4>Maintain Ongoing Communication with Licensing Agency</h4>
        <ul>
          <li>Notify licensing agency of changes in the employment status of the LPA, and provide an
          alternate LPA designee in these situations.</li>
          <li>Inform the licensing agency about any changes to the following:
            <ul>
              <li>Personnel: personnel changes (adding or removing)</li>
              <li>Scope: requests for additional research outside the approved scope</li>
              <li>Term of data retention: requests to hold the data beyond the original term of retention</li>
            </ul>
          </li>
          <li>Notify licensing agency immediately if you receive any legal, investigatory, or other demand for
          disclosure of data, including any request or requirement to provide data to any external entity
          under conditions that are inconsistent with any requirement of this license, afford the licensing
          agency an opportunity to object to any such demand prior to disclosing any data or information
          to the extent permitted by applicable law, and reasonably cooperate with the licensing agency
          in any objection.</li>
          <li>When qualified researchers leave the project, the LPA will promptly notify the licensing agency
          in writing that these individuals are no longer authorized to access the data.</li>
        </ul>

        <h4>Ensure Adherence to Data Protection Requirements by Qualified Researchers</h4>
        <ul>
          <li>No qualified researcher shall attempt to learn the identity of any participant.</li>
          <li>If the licensee discovers PII in any data, licensee shall immediately provide that information to
          the licensing agency so it can be properly de-identified for future use.  The licensee shall also
          immediately remove the PII information from any existing datasets.</li>
        </ul>

        <h4>Adhere to Terms and Conditions</h4>
        <ul>
          <li>The licensee is hereby authorized to use the data for the time period and purpose indicated in
          this data use license. All other uses are prohibited.</li>
          <li>The licensee may not share the data with others not included on the AMP Scenario Library
          Portal as qualified researchers.</li>
          <li>The licensing agency does not provide any warranty or guarantee as to the accuracy, timeliness,
          performance, completeness, or suitability of the data, information, and/or materials, provided
          under the terms of this data use license. The licensee acknowledges that such information and
          materials may contain inaccuracies or errors and we expressly exclude liability for any such
          inaccuracies or errors to the fullest extent permitted by law.</li>
        </ul>

        <h3>VIOLATIONS OF THIS LICENSE</h3>
        <p>
        If the licensing agency determines that any aspect of this DUL has been violated using objective and
        reasonable information, the licensing agency, in consultation with the appropriate IRB, may invoke one
        or more of the following sanctions to individual Members of the licensee team, the entire team, or the
        licensee's institution, as it deems appropriate:
        </p>
        <ul>
          <li>Denial of all future access to AMP Scenario Library Portal data.</li>
          <li>Withholding of any data not yet made available to the licensee.</li>
          <li>Report of the violation to the Researcher's Institution's office responsible for scientific integrity
            and misconduct, with a request that the institution's sanctions for misconduct be imposed.</li>
          <li>Such other remedies that may be available to the licensing agency under law or equity, including
            injunctive relief.</li>
        </ul>
      </div>
    );
  }
}

export default withStyles(styles)(DataUseLicense);
