const contactUs = `
  <h1 style="text-align: center;">Amp Portal</h1>
  <h2 style="text-align: center;">CONTACTING US</h2>
 
  <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
  
  <h4 style="margin-bottom: 0">Mike Mollenhauer</h4>
  <a href="mailto:mmollenhauer@vtti.vt.edu">mmollenhauer@vtti.vt.edu</a>  

  <h4 style="margin-bottom: 0">Virginia Tech Transportation Institute</h4>
  <p style="margin-top: 0">
    3500 Transportation Research Plaza<br>
    Blacksburg, VA 24060<br>
    Phone: (540) 231-1500<br>
  </p>
`;

export default contactUs;