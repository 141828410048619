import { stringSplitter } from "../../../common/util/string";
import compose from "crocks/helpers/compose";
import option from "crocks/pointfree/option";
import map from "crocks/pointfree/map";
import propPath from "crocks/Maybe/propPath";
import assoc from "crocks/helpers/assoc";
import ifElse from "crocks/logic/ifElse";
import { getTagDisplayName } from "../../../common/models/tag"

const errorMessages = {
  allTags: 'Could not load tags from server. Check your network connection or try again later.',
  createTag: 'Tag could not be created',
  updateTag: 'Tag could not be updated',
  deleteTag: 'Tag could not be deleted',
  assignTag: 'Tag assignment failed',
  removeTag: 'Tag removal failed',
};

const updateTagList = (tags, tag) => {
  const index = tags.findIndex(t => t.id === tag.id);
  return [...tags.slice(0, index), tag, ...tags.slice(index + 1)];
};

const replaceTagName = (names, oldName, newName) => {
  const idx = names.indexOf(oldName)
  if (idx < 0) {
    return names;
  }

  return [...names.slice(0, idx), newName, ...names.slice(idx + 1)];
}

const isFailureState = state => state === 'FAILURE';
const getRequestState = propPath(['payload', 'requestState']);

const isError = compose(option(false), map(isFailureState), getRequestState);

const emptyState = () => ({});

const setBusy = value => assoc('busy', value);
const setAllTags = allTags => assoc('allTags', allTags, {});
const clearBusyMsg = assoc('busyMsg', '');
const safeTagDisplayName = tag => getTagDisplayName(tag).option('')

const clearErrors = assoc('errors', {});
const setError = key => state => ({ ...state, errors: { [key]: errorMessages[key] } });

const setErrorState = errorKey => compose(setError(errorKey), clearBusyMsg, setBusy(false));
const setSuccessState = compose(clearErrors, clearBusyMsg, setBusy(false));

const addTagToState = state => tag => ({ allTags: [...state.allTags, tag] });

const removeTagFromState = (state, id) => () =>
  ({ ...state, allTags: state.allTags.filter(t => t.id !== id) });

const replaceTagInState = (state, oldName) => tag => {
  console.log('replaceTagInState', oldName, tag)
  return {
    ...state,
    allTags: updateTagList(state.allTags, tag),
    assignedTagNames: replaceTagName(state.assignedTagNames, oldName, safeTagDisplayName(tag))
  };
};

const addAssignedTagToState = state => tag => ({
    ...state,
    allTags: updateTagList(state.allTags, tag),
    assignedTagNames: [...state.assignedTagNames, (safeTagDisplayName(tag))]
  });

const removeAssignedTagFromState = state => tag => ({
  ...state,
  allTags: updateTagList(state.allTags, tag),
  assignedTagNames: state.assignedTagNames.filter(name => name !== safeTagDisplayName(tag))
});

const processFetchTagsError = compose(setErrorState('allTags'), emptyState);
const processCreateTagError = compose(setErrorState('createTag'), emptyState);
const processUpdateTagError = compose(setErrorState('updateTag'), emptyState);
const processDeleteTagError = compose(setErrorState('deleteTag'), emptyState);
const processAssignTagError = compose(setErrorState('assignTag'), emptyState);
const processRemoveTagError = compose(setErrorState('removeTag'), emptyState);

const processFetchTagsSuccess = compose(setSuccessState, setAllTags);
const processCreateTagSuccess = state => compose(setSuccessState, addTagToState(state));
const processDeleteTagSuccess = (state, id) => compose(setSuccessState, removeTagFromState(state, id));
const processUpdateTagSuccess = (state, oldName) => compose(setSuccessState, replaceTagInState(state, oldName));
const processAssignTagSuccess = state => compose(setSuccessState, addAssignedTagToState(state));
const processRemoveTagSuccess = state => compose(setSuccessState, removeAssignedTagFromState(state));

export const splitTagNames = stringSplitter(';');
export const findTagByName = (tags, name) => tags.find(t => t.name === name);

export const getAllTagNames = scenario => [
  ...splitTagNames(scenario.tags),
  ...splitTagNames(scenario.sharedTags)
];

/**
 * processFetchTagsResponse :: Response -> State
 */
export const processFetchTagsResponse = ifElse(isError, processFetchTagsError, processFetchTagsSuccess);

/**
 * processCreateTagResponse :: State -> Response -> State
 */
export const processCreateTagResponse = state =>
  ifElse(isError, processCreateTagError, processCreateTagSuccess(state));

/**
 * processUpdateTagResponse :: State -> Response -> State
 */
export const processUpdateTagResponse = (state, oldDisplayName) =>
  ifElse(isError, processUpdateTagError, processUpdateTagSuccess(state, oldDisplayName));

/**
 * processDeleteTagResponse :: (State, Int) -> Response -> State
 */
export const processDeleteTagResponse = (state, id) =>
  ifElse(isError, processDeleteTagError, processDeleteTagSuccess(state, id));

/**
 * processCreateTagResponse :: State -> Response -> State
 */
export const processAssignTagResponse = state =>
  ifElse(isError, processAssignTagError, processAssignTagSuccess(state));

/**
 * processCreateTagResponse :: State -> Response -> State
 */
export const processRemoveTagResponse = state =>
  ifElse(isError, processRemoveTagError, processRemoveTagSuccess(state));
