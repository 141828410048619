import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import FilterBuilder from './FilterBuilder';
import { createAmpExpressionTree } from "../../../common/models";

const styles = {
  root: {
    height: 0,
    // Animation is too choppy once it was tied to the filter update at the same time - DRI
    // transition: theme.transitions.create('height', { duration: theme.transitions.duration.shortest }),
    '&$expanded': {
      height: 600,
    },
  },
  expanded: {}
};

class FilterBuilderPanel extends React.PureComponent {
  static propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
    expressionTree: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    expressionTree: createAmpExpressionTree(),
  };

  render() {
    const { visible, expressionTree, onChange, className, classes } = this.props;
    const rootClassName = classNames(classes.root, className, { [classes.expanded]: visible });

    return (
      <div className={rootClassName}>
        {visible && <FilterBuilder expressionTree={expressionTree} onChange={onChange}/>}
      </div>
    );
  }
}

export default withStyles(styles)(FilterBuilderPanel);
