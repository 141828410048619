import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextField from "@material-ui/core/TextField";
import withStyles from '@material-ui/core/styles/withStyles';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { DatePicker } from '@material-ui/pickers';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '8px',
  },
  datePicker: {},
  textField: {
    gridColumn: '1 / 3',
  }
};

class NewsItemFormFields extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    className: PropTypes.string,
    onTitleChange: PropTypes.func,
    onBodyChange: PropTypes.func,
    onStartChange: PropTypes.func,
    onEndChange: PropTypes.func,
  };

  render() {
    const { title, body, startTime, endTime, className, classes } = this.props;
    const { onTitleChange, onBodyChange, onStartChange, onEndChange } = this.props;

    return (
      <div className={classNames(className, classes.root)}>
        <DatePicker
          autoOk
          clearable
          required
          value={startTime}
          maxDate={endTime}
          maxDateMessage="Date must be less than the end date"
          format="MMM d, yyyy"
          className={classes.datePicker}
          emptyLabel="Start Date"
          onChange={onStartChange}
          leftArrowIcon={<LeftIcon/>}
          rightArrowIcon={<RightIcon/>}
        />

        <DatePicker
          autoOk
          clearable
          value={endTime}
          minDate={startTime}
          minDateMessage="Date must be greater than the start date"
          format="MMM d, yyyy"
          className={classes.datePicker}
          emptyLabel="End Date"
          onChange={onEndChange}
          leftArrowIcon={<LeftIcon/>}
          rightArrowIcon={<RightIcon/>}
        />

        <TextField
          fullWidth
          required
          label="Title"
          value={title}
          onChange={onTitleChange}
          className={classes.textField}
        />

        <TextField
          fullWidth
          multiline
          label="Body"
          value={body}
          onChange={onBodyChange}
          className={classes.textField}
        />
      </div>
    );
  }
}

export default withStyles(styles)(NewsItemFormFields);
