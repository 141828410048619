import React from 'react';
import PropTypes from 'prop-types';
import isNil from "crocks/predicates/isNil";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import ViewIcon from "@material-ui/icons/Image";
import { formatDayMonthYear } from "../../../common/util/date";

const DateWithViewIcon = ({ date, className, onClick }) => (
  <div className={className} onClick={onClick}>
    <Typography>{formatDayMonthYear(date)}</Typography>
    <IconButton onClick={onClick}><ViewIcon/></IconButton>
  </div>
);

DateWithViewIcon.propTypes = {
  date: PropTypes.number,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const styles = {
  date: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

class ReviewDateTableCell extends React.PureComponent {
  static propTypes = {
    row: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  };

  handleClick = () => this.props.onClick(this.props.row);

  render() {
    const { row, classes } = this.props;

    return isNil(row.reviewDate)
      ? <Button variant="contained" color="primary" onClick={this.handleClick}>Review</Button>
      : <DateWithViewIcon className={classes.date} date={row.reviewDate} onClick={this.handleClick}/>;
  }
}

export default withStyles(styles)(ReviewDateTableCell);
