/*
 * This module contains actions having to do with membership levels.
 */

import map from "crocks/pointfree/map";
import compose from "crocks/helpers/compose";
import { createGetThunk } from "./utils";
import { getMemberships } from "../index";
import { AppRequest, createMembership } from "../../models";
import { SET_MEMBERSHIPS } from "./index";

export const setMemberships = memberships => ({ type: SET_MEMBERSHIPS, payload: { memberships } });

const membershipsRefreshPredicate = state => getMemberships(state).length === 0;
const processMemberships = dispatch => compose(dispatch, setMemberships, map(createMembership));

export const fetchMemberships = () =>
  createGetThunk({
    request: AppRequest.GET_MEMBERSHIPS,
    url: '/api/membership',
    responseFnFactory: processMemberships,
    refreshPredicate: membershipsRefreshPredicate
  });

