import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { toTitle } from "../../../common/util/string";

const directionClassString = direction => `iconDirection${toTitle(direction)}`;

const styles = theme => ({
  icon: {
    width: 28,
    height: 28,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
  },
  iconDirectionDesc: {
    transform: 'rotate(0deg)',
  },
  iconDirectionAsc: {
    transform: 'rotate(180deg)',
  },
});

class SortDirectionButton extends React.PureComponent {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { direction, onClick, classes } = this.props;
    const dirClass = directionClassString(direction);
    const className = classNames(classes.icon, classes[dirClass]);

    return (
      <IconButton color="primary" onClick={onClick}>
        <ArrowDownwardIcon className={className}/>
      </IconButton>
    );
  }
}

export default withStyles(styles)(SortDirectionButton);
