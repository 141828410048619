import PropTypes from 'prop-types';
import { when } from "../../util/func";

/**
 * This component returns one set of children nodes from a list of options based on the choice param.
 * NOTE: See the when function in /common/util/func.js for more details.
 */
const When = ({ choice, options }) => when(choice, options);

When.propTypes = {
  choice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  options: PropTypes.object.isRequired,
};

export default When;