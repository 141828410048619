import React from 'react';
import PropTypes from 'prop-types';
import prop from "crocks/Maybe/prop";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import AmpTable from "../../../common/components/AmpTable";
import ColumnType from "../../../common/components/AmpTable/ColumnType";
import ContentContainer from "../../../common/components/ContentContainer";
import { isArrayEmpty } from "../../../common/util/predicates";
import { buildLookupById } from "../../../common/util/object";
import BackWithHeading from "../BackWithHeading";
import { createOrganization } from "../../../common/models";
import AmpDialog from "../../../common/components/AmpDialog";
import EditOrganizationForm from "./EditOrganizationForm";

const { STRING, INTEGER, BOOLEAN } = ColumnType;

const columnData = [
  { id: 'id', label: 'ID', type: INTEGER, width: '20px' },
  { id: 'name', label: 'Name', type: STRING },
  { id: 'membership', label: 'Membership Level', type: STRING },
  { id: 'enabled', label: 'Enabled', type: BOOLEAN },
];

const isLoading = (orgs, mems) => isArrayEmpty(orgs) || isArrayEmpty(mems);

const lookupRank = lookup => id =>
  prop(id, lookup)
    .chain(prop('rank'))
    .option('');

const orgMembershipMapper = lookupRank => org => ({ ...org, membership: lookupRank(org.membershipId) });

function createTableData(organizations, memberships) {
  const membershipsLookup = buildLookupById(memberships);
  const mapper = orgMembershipMapper(lookupRank(membershipsLookup));
  return organizations.map(mapper);
}

const LoadingMessage = ({ message }) => (
  <div style={{ display: 'grid', height: '100%', justifyItems: 'center' }}>
    <Typography variant="h4">{message}</Typography>
  </div>
);

const styles = theme => ({
  root: {
    height: '100%',
    maxWidth: 1000,
    margin: '0 auto',
    padding: theme.spacing(),
  },
});

class OrganizationManager extends React.PureComponent {
  static propTypes = {
    memberships: PropTypes.array,
    organizations: PropTypes.array,
  };

  static defaultProps = {
    memberships: [],
    organizations: [],
  };

  state = {
    open: false,
    editOrg: createOrganization()
  };

  handleEditOrg = editOrg => this.setState({ open: true, editOrg });
  handleClose = () => this.setState({ open: false, editOrg: createOrganization() });

  render() {
    const { open, editOrg } = this.state;
    const { memberships, organizations, classes } = this.props;
    const items = createTableData(organizations, memberships);

    const componentToRender = isLoading(memberships, organizations)
      ? <LoadingMessage message="Loading Organizations..." />
      : <AmpTable columnData={columnData} items={items} onRowSelected={this.handleEditOrg}/>;

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <BackWithHeading heading="Organizations"/>
          {componentToRender}
        </Paper>

        <AmpDialog title="Edit Organization" open={open} onClose={this.handleClose}>
          <EditOrganizationForm org={editOrg} memberships={memberships} />
        </AmpDialog>
      </ContentContainer>
    );
  }
}

export default withStyles(styles)(OrganizationManager);