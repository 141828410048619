/*
 * As an experiment this component will be written in TypeScript.  If we do decide to move
 * to TS in the future, we need to know that introducing it into a code base won't just
 * break everything or force us into a big-bang scenario of porting the whole project to
 * TS at once.
 */
import React from 'react'
// @ts-ignore
import classNames from 'classnames'
import ToggleButton from './ToggleButton'
import AndOrSelector from './AndOrSelector'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { invertGroupOp, toggleGroupEnabled } from '../../../common/models/expressionGroupOp'
import { Theme } from "@material-ui/core"

interface GroupNode {
  op: string;
  isEnabled: boolean;
}

interface TreeLookup {
  root: GroupNode;
  portalBranch: GroupNode;
  quickFilterBranch: GroupNode;
  advancedFilterBranch: GroupNode;
  analyticsFilterBranch: GroupNode;
}

interface FilterControlsProps {
  className: string;
  treeLookup: TreeLookup;
  onFilterChange: (op: any, node: GroupNode) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '1fr 50px 1fr 50px 1fr'
  }
}))

const FilterControls: React.FC<FilterControlsProps> = ({
  className,
  treeLookup,
  onFilterChange,
}) => {
  const classes = useStyles()
  const { root, quickFilterBranch, advancedFilterBranch, portalBranch, analyticsFilterBranch } = treeLookup

  const handleTogglePortalGroupOp = () => onFilterChange(invertGroupOp, portalBranch)
  const handleToggleAnalyticsGroupOp = () => onFilterChange(invertGroupOp, root)

  const handleQuickFilterBranchToggle =  () => onFilterChange(toggleGroupEnabled, quickFilterBranch)
  const handleAdvancedFilterBranchToggle =  () => onFilterChange(toggleGroupEnabled, advancedFilterBranch)
  const handleAnalyticsBranchToggle = () => onFilterChange(toggleGroupEnabled, analyticsFilterBranch)

  return (
    <div className={classNames(className, classes.root)}>
      <ToggleButton onToggle={handleQuickFilterBranchToggle} selected={quickFilterBranch.isEnabled}>
        Quick Filter
      </ToggleButton>

      <AndOrSelector value={portalBranch.op} onChange={handleTogglePortalGroupOp}/>

      <ToggleButton onToggle={handleAdvancedFilterBranchToggle} selected={advancedFilterBranch.isEnabled}>
        Advanced Filter
      </ToggleButton>

      <AndOrSelector value={root.op} onChange={handleToggleAnalyticsGroupOp}/>

      <ToggleButton onToggle={handleAnalyticsBranchToggle} selected={analyticsFilterBranch.isEnabled}>
        Analytics Filter
      </ToggleButton>
    </div>
  )
}

export default FilterControls