import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Hidden from "@material-ui/core/Hidden"
import { withStyles } from "@material-ui/core/styles"
import AccountButton from "./AccountButton"

const styles = ({
  smallToolbar: {
    margin: 0,
    padding: 0,
  },
  navBarTabs: {
    flexGrow: 1,
  }
})

class NavBar extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    pathname: PropTypes.string,
  }

  static defaultProps = {
    profile: {},
    pathname: '',
  }

  static getDerivedStateFromProps(nextProps) {
    const { pathname } = nextProps
    let tabIndex = 0

    if (pathname.match(/scenarios/)) {
      tabIndex = 1
    } else if (pathname.match(/analytics/)) {
      tabIndex = 2
    } else if (pathname.match(/functionalscenario/)) {
      tabIndex = 3
    } else if (pathname.match(/members/)) {
      tabIndex = 4
    } else if (pathname.match(/support/)) {
      tabIndex = 5
    }

    return { tabIndex }
  }

  state = {
    tabIndex: 0,
  }

  handleChange = (event, tabIndex) => this.setState({ tabIndex })

  render() {
    const { tabIndex } = this.state
    const { classes, profile } = this.props

    return (
      <AppBar position="static" color="secondary">
        <Hidden mdUp>
          <Toolbar className={classes.smallToolbar}>
            <IconButton color="inherit" aria-label="Menu"><MenuIcon /></IconButton>
            <Typography variant="h6">AMP Data Portal</Typography>
          </Toolbar>
        </Hidden>
        <Hidden smDown>
          <Toolbar>
            <Tabs
              value={tabIndex}
              className={classes.navBarTabs}
              indicatorColor="primary"
              onChange={this.handleChange}
            >
              <Tab label={<Typography variant="h6">AMP</Typography>} component={Link} to="/" />
              <Tab label="Cases" component={Link} to="/scenarios" />
              <Tab label="Analytics" component={Link} to="/analytics" />
              <Tab label="Functional Scenario Discovery" component={Link} to="/functionalscenario" />
              <Tab label="Members" component={Link} to="/members" />
              <Tab label="Support" component={Link} to="/support" />
            </Tabs>
            <AccountButton name={`${profile.firstName} ${profile.lastName}`} />
          </Toolbar>
        </Hidden>
      </AppBar>
    )
  }
}

export default withStyles(styles)(NavBar)