/**
 *  @type {
 *    Object.<{
 *      GRID: string,
 *      LIST: string,
 *      TABLE: string,
 *      FILTER: string,
 *    }>
 *  }
 */
const ResultsViewTypes = Object.freeze({
  GRID: 'GRID',
  LIST: 'LIST',
  TABLE: 'TABLE',
  FILTER: 'FILTER',
});

export default ResultsViewTypes;