import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { isNotEmptyString } from "../../../common/util/predicates";

const createSearchIcon = (last, current, onClear) =>
  isNotEmptyString(last) && current === last
    ? <IconButton onClick={onClear}><ClearIcon/></IconButton>
    : <IconButton type="submit"><SearchIcon/></IconButton>;

const styles = {
  searchBox: {
    height: '2.5em',
    border: '1px solid #888',
    borderRadius: '6px',
    paddingLeft: 8,
  },
};

class SearchBar extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
  };

  state = {
    search: this.props.value,
  };

  handleChange = ev => this.setState({ search: ev.target.value });

  handleClear = () => {
    this.setState({ search: '' });
    this.props.onSearch('');
  };

  handleSubmit = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onSearch(this.state.search);
  };

  render() {
    const { search } = this.state;
    const { value, classes, className } = this.props;
    const rootClassName = classNames(className, classes.root);

    return (
      <form className={rootClassName} onSubmit={this.handleSubmit}>
        <FormControl fullWidth>
          <Input
            id="search-box"
            type="text"
            disableUnderline
            value={search}
            className={classes.searchBox}
            placeholder="Case ID or Text Search"
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                {createSearchIcon(value, search, this.handleClear)}
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(SearchBar);
