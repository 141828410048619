import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from "@material-ui/core/";

const styles = theme => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.light,
    '&:hover': {
      fontWeight: 500,
      textDecoration: 'underline',
    }
  }
});

const ClickableText = ({ classes, children, ...rest }) =>
  <Typography className={classes.link} {...rest}>{children}</Typography>;

ClickableText.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ClickableText);