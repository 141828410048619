import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  text: {
    textAlign: 'center',
  }
});

class CircleIndicator extends React.PureComponent {
  static propTypes = {
    stroke: PropTypes.string,
    fill: PropTypes.string,
    size: PropTypes.number,
    value: PropTypes.string,
    tooltip: PropTypes.string,
    cornerRadius: PropTypes.string,
  };

  static defaultProps = {
    size: 36,
    fill: "#C0C0C0",
    stroke: "#979797",
    cornerRadius: '50%',
  };

  render() {
    const { stroke, fill, size, cornerRadius, value, tooltip, classes } = this.props;

    return (
      <div
        title={tooltip}
        className={classes.root}
        style={{
          width: size,
          height: size,
          backgroundColor: fill,
          borderRadius: cornerRadius,
          border: `2px solid ${stroke}`
        }}
      >
        <Typography
          className={classes.text}
          variant='subtitle1'
          style={{ fontSize: `${size * .55}px`}}
        >
          {value}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(CircleIndicator);

//