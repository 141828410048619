import React from 'react';
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const MembershipSelect = ({ memberships, value, onChange }) => (
  <FormControl required fullWidth>
    <InputLabel htmlFor="mem-select">Membership</InputLabel>
    <Select value={value} onChange={onChange} inputProps={{ id: 'org-select' }}>
      { memberships.map(mem => <MenuItem key={mem.id} value={mem.id} button={true}>{mem.rank}</MenuItem>) }
    </Select>
  </FormControl>
);

MembershipSelect.propTypes = {
  value: PropTypes.number,
  memberships: PropTypes.array,
  onChange: PropTypes.func,
};

export default MembershipSelect;
