import React from 'react';

function PassengerIcon() {
  return (
    <svg width="81px" height="75px" viewBox="0 0 602 554" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PassengerIcon" transform="translate(40.000000, 1.000000)">
          <circle id="Oval" stroke="#FFFFFF" fill="#FFFFFF" cx="112" cy="58" r="58"/>
          <path d="M0,121 C38.5450651,356.471742 75.5167511,482.130697 110.915058,497.976865 C144.170229,513.438286 227.296725,516.264463 360.294545,506.455397" id="Line" stroke="#CCCCCC" strokeWidth="80" strokeLinecap="round"/>
          <rect id="Rectangle" stroke="#FFFFFF" fill="#FFFFFF" transform="translate(157.102387, 287.546508) rotate(-12.000000) translate(-157.102387, -287.546508) " x="99.6023872" y="127.546508" width="115" height="320" rx="55"/>
          <polyline id="Path-2" stroke="#FFFFFF" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round" points="132.628058 162.313442 262.372869 304.997914 375.215675 340.675036"/>
          <polyline id="Path-3" stroke="#FFFFFF" strokeWidth="80" strokeLinecap="round" points="180 408 393.290375 408 521.566317 512.172566"/>
        </g>
      </g>
    </svg>
  );
}

export default PassengerIcon;
