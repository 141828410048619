import React from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import ContentContainer from "../../../common/components/ContentContainer";
import BackWithHeading from "../BackWithHeading";
import { createNews, NewsType } from "../../../common/models/news";
import NewsTable from "./NewsTable";
import AmpDialog from "../../../common/components/AmpDialog";
import NewsItemForm from "./NewsItemForm";

const styles = theme => ({
  root: {
    height: '100%',
    margin: '0 auto',
    padding: theme.spacing(),
  },
  table: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
});

class NewsManager extends React.PureComponent {
  static propTypes = {
    news: PropTypes.array,
    onCreateNews: PropTypes.func,
    onUpdateNews: PropTypes.func,
    onDeleteNews: PropTypes.func,
  };

  static defaultProps = {
    news: [],
  };

  static getDerivedStateFromProps(nextProps) {
    const memberNews = nextProps.news.filter(n => n.type === NewsType.MEMBER_NEWS);
    const toolUpdates = nextProps.news.filter(n => n.type === NewsType.TOOL_UPDATE);
    return { memberNews, toolUpdates };
  }

  state = {
    open: false,
    dlgTitle: '',
    dlgItem: {},
    memberNews: [],
    toolUpdates: [],
  };

  handleClose = () => this.setState({ open: false });

  handleAddNewsItem = () =>
    this.setState({
      open: true,
      dlgTitle: 'Create Member News',
      dlgItem: createNews()
    });

  handleAddToolUpdate = () =>
    this.setState({
      open: true,
      dlgTitle: 'Create Tool Update',
      dlgItem: createNews({ type: NewsType.TOOL_UPDATE })
    });

  handleEditNewsItem = dlgItem => this.setState({ open: true, dlgTitle: 'Edit Member News', dlgItem });
  handleEditToolUpdate = dlgItem => this.setState({ open: true, dlgTitle: 'Edit Tool Update', dlgItem });

  handleCreateNews = item => {
    this.handleClose();
    if (item.id === 0) {
      this.props.onCreateNews(item);
    } else {
      this.props.onUpdateNews(item);
    }
  };

  handleDeleteNews = item => this.props.onDeleteNews(item.id);

  render() {
    const { classes } = this.props;
    const { open, dlgTitle, dlgItem, memberNews, toolUpdates } = this.state;

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <BackWithHeading heading="News Items"/>
          <NewsTable
            className={classes.table}
            items={memberNews}
            itemName="Member News"
            onAddNewsItem={this.handleAddNewsItem}
            onEditNewsItem={this.handleEditNewsItem}
            onDeleteNewsItem={this.handleDeleteNews}
          />
          <NewsTable
            className={classes.table}
            items={toolUpdates}
            itemName="Tool Update"
            onAddNewsItem={this.handleAddToolUpdate}
            onEditNewsItem={this.handleEditToolUpdate}
            onDeleteNewsItem={this.handleDeleteNews}
          />
        </Paper>

        <AmpDialog
          open={open}
          title={dlgTitle}
          onClose={this.handleClose}
        >
          <NewsItemForm key={dlgItem} onSubmit={this.handleCreateNews} item={dlgItem}/>
        </AmpDialog>
      </ContentContainer>
    );
  }
}

export default withStyles(styles)(NewsManager);