import React from 'react';
import PropTypes from 'prop-types';
import prop from "crocks/Maybe/prop";
import withStyles from '@material-ui/core/styles/withStyles';
import PrndlStatus from "./status/PrndlStatus";
import SpeedStatus from "./status/SpeedStatus";
import TextStatus from "./status/TextStatus";
import BlinkerStatus from "./status/BlinkerStatus";
import ValueMappedStatus from "./status/ValueMappedStatus";
import { isEnhancedL2Dataset } from '../../../../common/models/scenario';

const wiperMap = { 0: "OFF", 1: "INT", 2: "LOW", 3: "HIGH", ELSE: '--', NULL: '--' };
const seatBeltMap = { 0: "ON", 1: "OFF", ELSE: '--', NULL: '--' };
const airBagMap = { 0: "READY", 1: "DEPLOYED", ELSE: '--', NULL: '--' };

const createVarLookup = timeSeries => name => prop(name, timeSeries).option([]);

const latCtrlActiveVals = [3, 4];
const longCtrlActiveVals = [2, 3];

const styles = theme => ({
  root: {
    display: 'grid',
    backgroundColor: '#222',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '30px auto 1fr',
    gridRowGap: `${theme.spacing(2)}px`,
    gridColumnGap: `${theme.spacing()}px`,
    marginTop: theme.spacing(2),
    justifyItems: 'center',
  },
  leftStatus: {
    gridColumn: '1',
    gridRow: '2',
    display: 'flex',
    flexDirection: 'column',
  },
  leftBlinker: {
    gridColumn: '1',
    gridRow: '1',
    justifySelf: 'end',
  },
  prndl: {
    gridColumn: '2',
    alignSelf: 'center',
  },
  rightBlinker: {
    gridColumn: '3',
    gridRow: '1',
    justifySelf: 'start',
  },
  speed: {
    gridColumn: '2',
    gridRow: '2',
  },
  rightStatus: {
    gridColumn: '3',
    gridRow: '2',
    display: 'flex',
    flexDirection: 'column',
  },
});

class StatusPane extends React.PureComponent {
  static propTypes = {
    scenario: PropTypes.object,
    timeSeries: PropTypes.object,
    currentTime: PropTypes.number,
  };

  static defaultProps = {
    currentTime: 0,
    timeSeries: {},
  };

  render() {
    const { scenario, timeSeries, currentTime, classes } = this.props;
    const tsVar = createVarLookup(timeSeries);
    const isL2 = isEnhancedL2Dataset(scenario);

    return (
      <div className={classes.root}>
        <BlinkerStatus left className={classes.leftBlinker} data={tsVar('turnSignal')} time={currentTime} />
        <PrndlStatus className={classes.prndl} data={tsVar('prndl')} time={currentTime} />
        <BlinkerStatus className={classes.rightBlinker} data={tsVar('turnSignal')} time={currentTime} />

        <div className={classes.leftStatus}>
          <TextStatus data={tsVar('abs')} time={currentTime}>ABS</TextStatus>
          <TextStatus data={tsVar('esc')} time={currentTime}>ESC</TextStatus>
          <TextStatus data={tsVar('tractionControlState')} time={currentTime}>TRAC</TextStatus>
          <TextStatus data={tsVar('headlight')} time={currentTime}>LIGHTS</TextStatus>
          <TextStatus data={tsVar('cruiseState')} time={currentTime}>CRUISE</TextStatus>
          {isL2 ? <TextStatus data={tsVar('longCtrl')} activeValues={longCtrlActiveVals} time={currentTime}>LONG CTRL</TextStatus> : null}
          {isL2 ? <TextStatus data={tsVar('latCtrl')} activeValues={latCtrlActiveVals} time={currentTime}>LAT CTRL</TextStatus> : null}
        </div>

        <SpeedStatus className={classes.speed} data={timeSeries['speedNetwork']} time={currentTime} />

        <div className={classes.rightStatus}>
          <ValueMappedStatus
            title="Wiper"
            map={wiperMap}
            time={currentTime}
            data={tsVar('wiper')}
          />
          <ValueMappedStatus
            title="Seat Belt"
            map={seatBeltMap}
            time={currentTime}
            data={tsVar('seatbeltDriver')}
          />
          <ValueMappedStatus
            title="Air Bag"
            map={airBagMap}
            time={currentTime}
            data={tsVar('airbagDriver')}
          />
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(StatusPane);
