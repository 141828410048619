import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import VideoOverlay from "./VideoOverlay";

const styles = theme => ({
  unavailable: {
    color: '#888',
    backgroundColor: '#222',
    padding: theme.spacing(4),
    borderRadius: 8,
  },
});

let ErrorOverlay = ({ show, classes }) => {
  return (
    <VideoOverlay show={show}>
      <Typography className={classes.unavailable} variant="h4">Video Unavailable</Typography>
    </VideoOverlay>
  );
};

ErrorOverlay.propTypes = {
  show: PropTypes.bool,
};

ErrorOverlay.defaultProps = {
  show: false,
};

export default withStyles(styles)(ErrorOverlay);

