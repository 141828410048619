import safe from "crocks/Maybe/safe"
import compose from "crocks/helpers/compose"
import { readExpressionTree, readUiState, readVariables } from "../services/localStorage"
import { pushExpressionTree, setFilterVariables, setUiState } from "./actions"
import { deserializeExpressionTree, EXPRESSION_TREE_VERSION } from "../models"

const checkTreeVersion = tree => tree && tree.version === EXPRESSION_TREE_VERSION
const createDispatcher = ({ dispatch }, actionCreator) => compose(dispatch, actionCreator)

const loadVariables = store =>
  readVariables()
    .map(createDispatcher(store, setFilterVariables))

const loadExpressionTree = store =>
  readExpressionTree()
    .map(deserializeExpressionTree)
    .chain(safe(checkTreeVersion))
    .map(createDispatcher(store, pushExpressionTree))

const loadUiState = store =>
  readUiState()
    .map(ui => {
      console.log('read UI from localstorage', ui)
      return ui
    })
    .map(createDispatcher(store, setUiState))

const initializeStore = store => {
  // Order matters
  loadVariables(store)
  loadExpressionTree(store)
  loadUiState(store)
  return store
}

export default initializeStore