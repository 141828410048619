import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import UserIcon from '@material-ui/icons/SupervisorAccount';
import AddIcon from '@material-ui/icons/Add';
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(),
  },
  usersIcon: {
    width: 48,
    height: 48,
    // color: '#888',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  addButton: {
    marginLeft: theme.spacing(),
  },
  addIcon: {
    width: 36,
    height: 36,
  },
});

class UserTableHeader extends React.PureComponent {
  static propTypes = {
    showDisabledUsers: PropTypes.bool,
    onInviteUser: PropTypes.func,
    onToggleEnabledUsers: PropTypes.func,
  };

  static defaultProps = {
    showDisabledUsers: false,
  };

  render() {
    const { showDisabledUsers, onInviteUser, onToggleEnabledUsers, classes } = this.props;

    return (
      <div className={classes.root}>
        <UserIcon color="primary" className={classes.usersIcon}/>
        <Typography color="primary" variant="h4">Users</Typography>

        <div style={{ flexGrow: 1 }} />

        <Typography>Show Disabled Users</Typography>
        <Switch
          color="primary"
          checked={showDisabledUsers}
          onChange={onToggleEnabledUsers}
        />

        <div style={{ width: 48 }} />

        <Button variant="contained" color="primary" onClick={onInviteUser}>
          <AddIcon /> Invite User
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(UserTableHeader);
