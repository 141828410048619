import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from "@material-ui/core/CircularProgress";
import TagContainer from "../common/components/TagContainer";
import { isUndefined } from "../../../common/util/predicates";
import identity from "crocks/combinators/identity";

const formatResultString = counts =>
  counts.filtered > 0
    ? `${counts.filtered} case${counts.filtered > 1 ? 's' : ''} found`
    : `No cases found`;

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '1.5em',
  },
  tag: {
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '1.25em',
  },
  filtering: {
    marginLeft: 16,
  }
};

class ResultsSummary extends React.PureComponent {
  static propTypes = {
    filterBusy: PropTypes.bool,
    scenarioCounts: PropTypes.object,
    onAddTagClick: PropTypes.func,
  };

  static defaultProps = {
    filterBusy: false,
    scenarioCounts: {},
    onAddTagClick: identity,
  };

  handleTagClick = () => this.props.onAddTagClick(null);

  render() {
    const { scenarioCounts, classes, className } = this.props;
    const cls = classNames(className, classes.root);

    if (isUndefined(scenarioCounts.filtered)) {
      return (
        <div className={classes.root}>
          <CircularProgress variant="indeterminate" size={24}/>
        </div>
      );
    }

    return (
      <div className={cls}>

        <Typography>{formatResultString(scenarioCounts)}</Typography>

        {
          scenarioCounts.total !== scenarioCounts.filtered &&
          <TagContainer className={classes.tag} onClick={this.handleTagClick}>
            <Typography color="inherit">+ Add Tags</Typography>
          </TagContainer>
        }
      </div>
    );
  }
}

export default withStyles(styles)(ResultsSummary);
