import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import GridResult from "./GridResult";

const gridResultMapper = (onClick, onLayerClick, onTagClick) => s =>
  <GridResult
    key={s.id}
    scenario={s}
    onClick={onClick}
    onLayerClick={onLayerClick}
    onTagClick={onTagClick}
  />;

const styles = {
  root: {
    display: 'grid',
    justifyItems: 'center',
    gridGap: '8px',
    gridTemplateRows: 'repeat(auto-fill, 320px)',
    gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
  },
};

class GridResultsView extends React.PureComponent {
  static propTypes = {
    scenarios: PropTypes.array,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onTagClick: PropTypes.func,
    onLayerClick: PropTypes.func,
  };

  static defaultProps = {
    scenarios: [],
  };

  render() {
    const { scenarios, onClick, onLayerClick, onTagClick, className, classes } = this.props;

    return (
      <div className={classNames(classes.root, className)}>
        {scenarios.map(gridResultMapper(onClick, onLayerClick, onTagClick))}
      </div>
    );
  }
}

export default withStyles(styles)(GridResultsView);
