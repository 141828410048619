import React from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import identity from 'crocks/combinators/identity'
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { arraysAreEqualIgnoreOrder, findArrayAdditions, findArrayRemovals } from "../../../../common/util/array"
import useUserProfile from "../../../../common/store/hooks/useUserProfile"
import OrganizationCheckList from "./OrganizationCheckList"
import IfUser from "../../../../common/components/logic/IfUser"
import { userIsTagger } from "../../../../common/models/user"

const updateSharedIds = (orgIds, targetId, shouldAdd) =>
  shouldAdd ? [...orgIds, targetId] : orgIds.filter(id => targetId !== id)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    minWidth: 400,
    padding: theme.spacing(2),
  },
  buttonRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}))

function useTagEditFormState(tag, onSubmit) {
  const [values, setValues] = React.useState({
    name: tag.name || '',
    description: tag.description || '',
    sharedOrgIds: tag.sharedOrgIds || [],
  })

  const user = useUserProfile()

  const hasChanged = tag.name !== values.name ||
    tag.description !== values.description ||
    !arraysAreEqualIgnoreOrder(tag.sharedOrgIds, values.sharedOrgIds)

  const isShared = values.sharedOrgIds.indexOf(user.orgId) >= 0
  const isNotOwner = user.email !== tag.ownerEmail

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleSharedToggle = ev =>
    setValues({
      ...values,
      sharedOrgIds: updateSharedIds(values.sharedOrgIds, user.orgId, ev.target.checked)
    })

  const handleMultiSharedToggle = (org, checked) =>
    setValues({
      ...values,
      sharedOrgIds: updateSharedIds(values.sharedOrgIds, org.id, checked)
    })

  const handleSubmit = ev => {
    ev.preventDefault()
    ev.stopPropagation()

    const additions = findArrayAdditions(tag.sharedOrgIds, values.sharedOrgIds)
    const removals = findArrayRemovals(tag.sharedOrgIds, values.sharedOrgIds)

    onSubmit(tag.id, values.name, values.description, tag.displayName, additions, removals)
  }

  return {
    ...values,
    hasChanged,
    isShared,
    isNotOwner,
    handleSharedToggle,
    handleMultiSharedToggle,
    handleChange,
    handleSubmit
  }
}

export default function TagEditForm({ tag, onSubmit, onCancel }) {
  const classes = useStyles()
  const {
    name,
    description,
    sharedOrgIds,
    hasChanged,
    isShared,
    isNotOwner,
    handleSharedToggle,
    handleMultiSharedToggle,
    handleChange,
    handleSubmit
  } = useTagEditFormState(tag, onSubmit)

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        id="tag-name"
        label="Name"
        className={classes.textField}
        value={name}
        onChange={handleChange('name')}
        margin="normal"
        disabled={isNotOwner}
        required
        autoFocus
      />

      <TextField
        id="tag-desc"
        label="Description"
        className={classes.textField}
        value={description}
        onChange={handleChange('description')}
        margin="normal"
        disabled={isNotOwner}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isShared}
            onClick={handleSharedToggle}
            value="shared"
            color="primary"
          />
        }
        label="Shared"
      />

      <IfUser filter={userIsTagger}>
        <OrganizationCheckList selectedIds={new Set(sharedOrgIds)} onChange={handleMultiSharedToggle}/>
      </IfUser>

      <div className={classes.buttonRow}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="submit" onClick={onCancel} disabled={!hasChanged}>
          Submit
        </Button>
      </div>
    </form>
  )
}

TagEditForm.propTypes = {
  tag: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

TagEditForm.defaultProps = {
  tag: {},
  onSubmit: identity,
  onCancel: identity
}