import React from 'react';
import PropTypes from 'prop-types';
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';
import withStyles from "@material-ui/core/styles/withStyles";
import TimeSeriesVariableSelect from "../ChartVariableSelect";

import { clamp } from "../../../../common/util/math";
import { provideZero } from "../../../../common/util/providers";
import { setSelectedChartVariables } from "../../../../common/store/actions/index";
import { getChartVars, getSelectedChartVariables } from "../../../../common/store/index";

import { createNextState, } from "./timeSeriesViewerFuncs";
import { buildChartOptions, calcTimeAnimDuration, createAnnotationPlugin } from "./timeSeriesChartFuncs";
import { getEpochTimeStampDuration } from "../../common/timeSeries";

const chartNotAnimating = lineChart => lineChart.chartInstance.animating === false;
const timeIsUpdated = (nextProps, props) => nextProps.playbackSeconds !== props.playbackSeconds;

const styles = theme => ({
  root: {
    height: "100%",
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridRowGap: `${theme.spacing()}px`,
    margin: theme.spacing(),
  },
  chartAnchor: {
    position: 'relative',
  },
  chartContainer: {
    position: 'absolute',
    left: 10,
    right: 10,
    height: '100%',
  },
});

class TimeSeriesViewer extends React.Component {
  static propTypes = {
    scenario: PropTypes.object,
    timeSeries: PropTypes.object,
    playbackSeconds: PropTypes.number,
    durationSeconds: PropTypes.number,
    onSeekChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    scenario: {},
    timeSeries: {},
    playbackSeconds: 0,
    durationSeconds: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.scenario.id === 0 ? null : createNextState(nextProps, prevState);
  }

  state = {
    lastScenario: {},
    lastTimeSeries: {},
    refTimeSecs: 0,
    cueTimeSecs: 0,
    timeOfInterestSecs: 0,
    annotationStartSecs: 0,
    annotationEndSecs: 0,
    data: {},
    options: buildChartOptions(0),
    secondsOffsetFn: provideZero,
    lastSelections: [],
  };

  constructor(props) {
    super(props);
    this.plugins = [createAnnotationPlugin(this)];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (timeIsUpdated(nextProps, this.props) && chartNotAnimating(this.lineChart)) {
      const duration = calcTimeAnimDuration(nextProps, this.props);
      this.lineChart.chartInstance.render({ duration, easing: 'linear' });
    }

    return nextState.lastScenarioId !== this.state.lastScenarioId ||
      nextState.lastTimeSeriesId !== this.state.lastTimeSeriesId ||
      nextProps.selectedChartVars !== this.props.selectedChartVars;
  }

  componentDidUpdate() {
    // Make sure the chart is using the latest options
    const chart = this.lineChart.chartInstance;
    chart.options = { ...this.state.options, onClick: this.handleClick };
    chart.data = this.state.data;
    chart.update({ duration: 250 });
  }

  handleLegendChange = selections => this.props.setSelectedChartVariables(selections);

  handleClick = ev => {
    const axis = this.lineChart.chartInstance.scales["x-axis-0"];
    const percent = clamp((ev.offsetX - axis.left) / (axis.right - axis.left), 0, 1);
    const duration = getEpochTimeStampDuration(this.props.timeSeries).option(0);
    const calculatedDuration = percent * duration;
    const seekTime = Math.min(this.props.durationSeconds, calculatedDuration);
    this.props.onSeekChange(seekTime.toFixed(1));
  };

  chartRef = node => this.lineChart = node;

  render() {
    const { data, options } = this.state;
    const { chartVars, selectedChartVars, className, classes } = this.props;
    const rootClass = classNames(className, classes.root);

    return (
      <div className={rootClass}>
        <TimeSeriesVariableSelect
          chartVariables={chartVars}
          selectedChartVariables={selectedChartVars}
          onChange={this.handleLegendChange}
        />
        <div className={classes.chartAnchor}>
          <div className={classes.chartContainer}>
            <Line
              data={data}
              options={options}
              plugins={this.plugins}
              ref={this.chartRef}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  chartVars: getChartVars(state),
  selectedChartVars: getSelectedChartVariables(state),
});

const mapDispatchToProps = { setSelectedChartVariables };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TimeSeriesViewer);
