/**
 * The main application states.
 *
 * @type {Object.<{APP_LOADING: string, APP_LOGGED_IN: string, APP_LOGGED_OUT: string}>}
 */
const AppStatus = Object.freeze({
  APP_LOADING: "APP_LOADING",
  APP_LOGGED_IN: "APP_LOGGED_IN",
  APP_LOGGED_OUT: "APP_LOGGED_OUT",
});

export default AppStatus;