import { createGroupOp, isGroupNode } from './expressionGroupOp';
import { isArrayEmpty } from "../util/predicates";
import { tryCatch } from "../util/func";
import constant from "crocks/combinators/constant";
import compose from "crocks/helpers/compose";
import prop from "crocks/Maybe/prop";
import option from "crocks/pointfree/option";
import isNil from "crocks/predicates/isNil";
import isDefined from "crocks/predicates/isDefined";
import { createFactoryFunc } from "./factory";

export const EXPRESSION_TREE_VERSION = 3;

const expressionTreeProps = [
  { name: 'root', defaultProvider: () => createGroupOp() },
  { name: 'version', defaultProvider: constant(EXPRESSION_TREE_VERSION) },
];

/**
 * getRoot :: ExpressionTree -> GroupOp | undefined
 */
export const getRoot = compose(option(undefined), prop("root"));

/**
 * Creates a new expression tree containing the passed in groupOp as the root node.
 *
 * @param root A GroupOp to use as the root of the tree.
 * @returns {{root: {op: string, children: Array, isCollapsed: boolean, parent: *}}}
 */
export const createExpressionTree = createFactoryFunc(expressionTreeProps);

/**
 * Applies the given operation to the specified node in the tree.  Returns a new tree with the
 * operation applied to the given node, or if the node was not found, the same tree.  Either
 * way, no mutations are done to the original tree.
 *
 * @param tree The tree containing the node to be operated upon.
 * @param node The node to which the operation will be applied if it is found in the tree.
 * @param op The operation to apply. This is a function that is invoked once the node is located.
 *    This function is invoked with the node as the only parameter and should return a new node
 *    with the op applied.
 * @returns {{root: *}} A new tree if the node was found and the operation applied, otherwise
 *    just returns the original tree unmodified.
 */
export const applyToTree = (tree, node, op) =>
  node.uuid === tree.root.uuid
    ? createExpressionTree({ root: applyToNode(tree.root, op) })
    : createExpressionTree({ root: applyToChildren(tree.root, node, op) });

/**
 * If node is one of the group's descendents, op will be applied to node and a new group will be
 * returned that contains the modified descendent.  If the group provided has no children, then the
 * group will be returned unmodified.
 *
 * @param group The group that is currently being processed in the search for node.
 * @param node The node to which the operation will be applied if it is found in the tree.
 * @param op The operation to apply. This is a function that is invoked once the node is located.
 *    This function is invoked with the node as the only parameter and should return a new node
 *    with the op applied.
 *
 * @returns {*} The original group or a new group containing the modified node as a descendent.
 */
export function applyToChildren(group, node, op) {
  if (isArrayEmpty(group.children)) {
    return group;
  }

  const children = group.children
    .map(c => c === node ? applyToNode(c, op) : applyToChildren(c, node, op));

  return { ...group, children };
}

/**
 * Applies the given operation to the given node.
 *
 * @param node The node to which the operation is applied.
 * @param op A function that receives the node as its only parameter and returns a new node
 *    that is the result of applying the operation to the original node.  The op function
 *    should NEVER mutate the original node, but it is legal for the op to return the
 *    original node if no mutation has occurred (i.e. a no-op).
 *
 * @returns {*} A new node that is the result of applying op to node.
 */
export const applyToNode = (node, op) => op(node);

/**
 * Finds and returns a node or sub-node with the given label, or undefined if it doesn't exist.
 */
export function findNodeByLabel(node, label) {
  if (isNil(node) || isNil(label)) {
    return undefined;
  }

  if (node.label === label) {
    return node;
  }

  if (isGroupNode(node)) {
    return node.children
      .map(c => findNodeByLabel(c, label))
      .find(isDefined);
  }

  return  undefined;
}

/**
 * A simple way to reduce a tree to a given value.  For example count nodes matching some
 * criteria as set forth in the supplied function.
 *
 * @param group
 * @param fn
 * @param initialValue
 *
 * @returns {*}
 */
export function treeReduce(group, fn, initialValue) {
  const value = fn(initialValue, group);

  if (isArrayEmpty(group.children)) {
    return value;
  }

  return group.children.reduce((val, node) => treeReduce(node, fn, val), value);
}

export const isExpressionTree = tree => tree && isGroupNode(tree.root);

// export const createExpressionTreeFromMemento = (memento, variableLookup, version = EXPRESSION_TREE_VERSION) =>
//   Object.keys(variableLookup).length === 0
//     ? createExpressionTree()
//     : createExpressionTree({ version, root: createGroupFromMemento(memento, variableLookup) });

/**
 * Serialize the provided tree to JSON using memento representations of the nodes
 *
 * @param tree The tree to serialize, must have the shape { version: Integer, root: GroupOp }
 * @returns {string} The JSON serialization string or an empty string if tree is not an expression tree.
 */
export const serializeExpressionTree = tree => isExpressionTree(tree) ? JSON.stringify(tree) : '';

/**
 * Deserialize an expression tree from a string.
 *
 * @param treeStr A stringified version of a tree memento.
 * @returns The deserialized expression tree or a default expression tree if something went wrong.
 */
export const deserializeExpressionTree = treeStr =>
  tryCatch(() => JSON.parse(treeStr))
    .option(createExpressionTree());
