import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const TagDeleteConfirmation = ({ onDelete, onCancel, className, classes }) => (
  <div className={classNames(classes.root, className)}>
    <Typography>{`Delete this tag from the server and remove the tag from all scenarios?`}</Typography>
    <div className={classes.buttons}>
      <Button variant="contained" onClick={onCancel}>Cancel</Button>
      <Button variant="contained" color="primary" onClick={onDelete}>Delete</Button>
    </div>
  </div>
);

TagDeleteConfirmation.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default withStyles(styles)(TagDeleteConfirmation);
