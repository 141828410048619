import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/";

const styles = {
  docRoot: {
    overflow: 'auto',
    backgroundColor: '#eee',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  docTitle: {
    padding: '1em 0',
  },
  docContent: {
    margin: '0 auto',
    maxWidth: '600px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    '& > p': {
      margin: '1em 0',
    }
  }
};

const DocContent = ({ content, classes }) => (
  <div className={classes.docRoot}>
    {content && <div className={classes.docContent} dangerouslySetInnerHTML={{ __html: content }}/>}
  </div>
);

DocContent.propTypes = {
  content: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(styles)(DocContent);

