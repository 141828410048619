import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideZero } from "../util/providers";

const membershipProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'rank', defaultProvider: provideEmptyString },
];

/**
 * Create a canonical Membership object.
 *
 * createMembership :: Object -> Membership
 */
export const createMembership = createFactoryFunc(membershipProps);

