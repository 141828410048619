import React from 'react'
import PropTypes from 'prop-types/prop-types'
import classNames from 'classnames'
import isNil from "crocks/predicates/isNil"
import isEmpty from "crocks/predicates/isEmpty"
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"
import { makeStyles } from "@material-ui/core/styles"
import { buildGridForApps } from "./gridBuilder"
import { useShinyAppState } from "./shinyAppContext"
import AnalyticsAppEntry from "./AnalyticsAppEntry"
import ContentContainer from "../../common/components/ContentContainer"
import Paper from "@material-ui/core/Paper"
import ampLogo from "../../common/components/img/amp-logo.png"

const createLoading = () => <LinearProgress/>

const createEmptyAppList = (classes) => (
  <div className={classes.emptyRoot}>
    <Typography>Sorry, there are no analytics applications available at this time.</Typography>
  </div>
)

const appGridMapper = cell => {
  if (isEmpty(cell.applications)) {
    return null
  }

  const key = cell.applications.map(app => app.name).join()
  return (
    <div
      key={key}
      style={{
        width: '100%',
        borderRadius: 4,
        border: `2px solid #630B0D`,
        backgroundColor: 'rgba(225, 225, 225, 0.85)',
        gridRow: cell.rowStart,
        gridColumn: `${cell.colStart} / ${cell.colEnd}`,
        color: '#ccc',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {cell.applications.map(app => <AnalyticsAppEntry key={app.id} {...app} />)}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    padding: 8,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '75%',
    },
  },
  appGrid: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateRows: '5px [title] auto 5px [header] auto 5px [summary] minmax(100px, auto) 5px [freq] minmax(100px, auto) 5px [dist] minmax(100px, auto) 5px [end] 1fr',
    gridTemplateColumns: '[start] 5px [desc] 2fr 5px [cnc] 1fr 5px [epochs] 1fr 5px [baselines] 1fr 5px [end]',
    justifyItems: 'center',
    margin: theme.spacing(3),
  },
  emptyRoot: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(4),
    }
  },
  logo: {
    gridRow: 'title / summary',
    gridColumn: 'desc',
    width: "100%",
    margin: "0 8px 8px 8px",
  },
  columnTitle: {
    gridRow: 'title',
    gridColumn: 'cnc / end',
    textAlign: 'center',
    alignSelf: 'center',
  },
  cncHeader: {
    gridRow: 'header',
    gridColumn: 'cnc',
    textAlign: 'center',
    alignSelf: 'center'
  },
  epochsHeader: {
    gridRow: 'header',
    gridColumn: 'epochs',
    textAlign: 'center',
    alignSelf: 'center'
  },
  baselinesHeader: {
    gridRow: 'header',
    gridColumn: 'baselines',
    alignSelf: 'center'
  },
  descriptionColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
    width: '100%',
    height: '100%',
    padding: theme.spacing(),
    backgroundColor: 'rgba(225, 225, 225, 0.85)',
  },
  collectionSummaryDesc: {
    gridRow: 'summary',
    gridColumn: 'desc',
  },
  freqRateDesc: {
    gridRow: 'freq',
    gridColumn: 'desc',
  },
  distributionsDesc: {
    gridRow: 'dist',
    gridColumn: 'desc',
  },
  divider: {
    backgroundColor: '#ccc',
    borderRadius: 2.5,
    width: '100%',
    height: '100%',
  }
}))

function AnalyticsAppGrid() {
  const classes = useStyles()
  const { apps } = useShinyAppState()

  console.log('shiny apps', apps)

  if (isNil(apps)) {
    return createLoading()
  }

  if (isEmpty(apps)) {
    return createEmptyAppList(classes)
  }

  const appCells = buildGridForApps(apps)

  return (
    <ContentContainer className={classes.contentContainer}>
      <Paper className={classes.root}>
        <div className={classes.appGrid}>
          <img className={classes.logo} src={ampLogo} alt="AMP Logo"/>
          <div className={classes.columnTitle}>
            <Typography variant="h5" color="primary" style={{ fontWeight: 500 }}>Case Types</Typography>
          </div>
          <div className={classes.cncHeader}>
            <Typography variant="h6" color="primary">Crashes</Typography>
            <Typography variant="h6" color="primary">Near-Crashes</Typography>
          </div>

          <div className={classes.epochsHeader}>
            <Typography variant="h6" color="primary">Epochs of Interest</Typography>
          </div>

          <div className={classes.baselinesHeader}>
            <Typography variant="h6" color="primary">Baselines</Typography>
          </div>

          <div className={classNames(classes.descriptionColumn, classes.collectionSummaryDesc)}>
            <Typography variant="h6" color="primary">
              What is in the AMP collection and how can it be used?
            </Typography>
          </div>

          <div className={classNames(classes.descriptionColumn, classes.freqRateDesc)}>
            <Typography variant="h6" color="primary">
              How frequently do cases occur per distance or relative to other cases?
            </Typography>
          </div>

          <div className={classNames(classes.descriptionColumn, classes.distributionsDesc)}>
            <Typography variant="h6" color="primary">
              What are the distributions of things measured within the cases?
            </Typography>
          </div>

          <div className={classes.divider} style={{ gridColumn: 1, gridRow: '5 / end' }}/>
          <div className={classes.divider} style={{ gridColumn: 3, gridRow: '1 / end' }}/>
          <div className={classes.divider} style={{ gridColumn: 5, gridRow: '3 / end' }}/>
          <div className={classes.divider} style={{ gridColumn: 7, gridRow: '3 / end' }}/>
          <div className={classes.divider} style={{ gridColumn: 9, gridRow: '1 / end' }}/>

          <div className={classes.divider} style={{ gridRow: 1, gridColumn: '3 / end' }}/>
          <div className={classes.divider} style={{ gridRow: 3, gridColumn: '3 / end' }}/>
          <div className={classes.divider} style={{ gridRow: 5, gridColumn: 'start / end' }}/>
          <div className={classes.divider} style={{ gridRow: 7, gridColumn: 'start / end' }}/>
          <div className={classes.divider} style={{ gridRow: 9, gridColumn: 'start / end' }}/>
          <div className={classes.divider} style={{ gridRow: 11, gridColumn: 'start / end' }}/>

          {appCells.map(appGridMapper)}

        </div>
      </Paper>
    </ContentContainer>
  )
}

AnalyticsAppGrid.propTypes = {
  classes: PropTypes.object,
}

export default AnalyticsAppGrid

/*
  collectionSummaryHeader: {
    gridRow: 'summary',
    gridColumn: 'header',
    justifySelf: 'start',
    alignSelf: 'center'
  },
  freqRateHeader: {
    gridRow: 'freq',
    gridColumn: 'header',
    justifySelf: 'start',
    alignSelf: 'center'
  },
  distributionsHeader: {
    gridRow: 'dist',
    gridColumn: 'header',
    justifySelf: 'start',
    alignSelf: 'center'
  },

  const [appList, setAppList] = useState(null)
  const handleCellClick = cell => setAppList(cell.applications)
  const handleClose = () => setAppList(null)


<div className={classes.collectionSummaryHeader}>
  <Typography variant="h6" color="primary">Collection Summary</Typography>
</div>
<div className={classes.freqRateHeader}>
  <Typography variant="h6" color="primary">Frequencies and Rates</Typography>
</div>

<div className={classes.distributionsHeader}>
  <Typography variant="h6" color="primary">Distributions</Typography>
</div>

  <img
    key={app.id}
    src={app.iconUrl}
    alt={app.name}
    width={80}
    height={80}
    title={app.name}
    style={{ borderRadius: 6, marginLeft: 4, marginRight: 4 }}
  />

<AmpDialog
  open={isNotEmpty(appList)}
  title="Select a Tool"
  onClose={handleClose}
>
  <AnalyticsAppList apps={appList}/>
</AmpDialog>

  const apps = [
    {
      id: "overview-app",
      name: "Overview Tool",
      description: "This tool gives an overview of what can be found within the AMP data and how it was created.",
      url: "https://gallery.shinyapps.io/goog-trend-index/",
      iconUrl: "/api/shiny/icon/metrics-app",
      rowCategory: "Collection Summary",
      caseTypes: "Crashes and Near-Crashes,Epochs,Baselines",
    },
    {
      id: "metrics-app",
      name: "Metrics Tool",
      description: "This tool assists in exploring the relationships between measures collected within cases.",
      url: "https://gallery.shinyapps.io/goog-trend-index/",
      iconUrl: "/api/shiny/icon/metrics-app",
      rowCategory: "Distributions",
      caseTypes: "Crashes and Near-Crashes,Epochs,Baselines",
    },
    {
      id: "db-app",
      name: "Database Comparison Tool",
      description: "This tool assists in comparing the composition of different databases.",
      url: "https://gallery.shinyapps.io/goog-trend-index/",
      iconUrl: "/api/shiny/icon/metrics-app",
      rowCategory: "Frequencies and Rates",
      caseTypes: "Crashes and Near-Crashes,Epochs",
    },
    {
      id: "db-app2",
      name: "Database Comparison Tool",
      description: "This tool assists in comparing the composition of different databases.",
      url: "https://gallery.shinyapps.io/goog-trend-index/",
      iconUrl: "/api/shiny/icon/metrics-app",
      rowCategory: "Frequencies and Rates",
      caseTypes: "Crashes and Near-Crashes,Epochs,Baselines",
    },
    {
      id: "dist-app",
      name: "Distributions Tool",
      description: "This tool assists in investigating distributions measures within case types.",
      url: "https://gallery.shinyapps.io/goog-trend-index/",
      iconUrl: "/api/shiny/icon/metrics-app",
      rowCategory: "Distributions",
      caseTypes: "Crashes and Near-Crashes,Epochs,Baselines",
    },
    {
      id: "rates-app",
      name: "Rates Tool",
      description: "This tool describes teh rates of occurrence of cases.",
      url: "https://shiny.rstudio.com/gallery/kmeans-example.html",
      iconUrl: "/api/shiny/icon/rates-app",
      rowCategory: "Frequencies and Rates",
      caseTypes: "Crashes and Near-Crashes,Epochs",
    },
    {
      id: "rates-app2",
      name: "Rates Tool",
      description: "This tool describes teh rates of occurrence of cases.",
      url: "https://shiny.rstudio.com/gallery/kmeans-example.html",
      iconUrl: "/api/shiny/icon/rates-app",
      rowCategory: "Frequencies and Rates",
      caseTypes: "Crashes and Near-Crashes,Epochs",
    }
  ]

*/