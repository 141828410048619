/*
 * This module contains functions that apply generally to most if not all models.
 */
import compose from "crocks/helpers/compose";
import { integerProp } from "../util/object";
import { safeArray, safeInteger } from "../util/types";
import { ofNilable } from "../util/func";
import uuidv4 from "uuid/v4";

const findInArray = array => pred => array.find(pred);
const hasIntegerID = id => obj => getIntegerId(obj).option() === id;

/**
 * Returns the value of the "id" property on the provided object as long as it exists and is an
 * integer.
 *
 * getId :: Object -> Maybe Integer | Maybe Nothing
 */
export const getIntegerId = integerProp("id");

/**
 * findByIntegerId :: Integer -> [a] -> Maybe a | Maybe Nothing
 */
export const findByIntegerId = id => array =>
  safeArray(array)
    .chain(array =>
      safeInteger(id)
        .chain(compose(ofNilable, findInArray(array), hasIntegerID))
    );

/**
 * Creates a new object with all the props of the source object plus a uuid prop containing
 * a v4 UUID string.
 *
 * withUuid :: Object -> Object
 */
export const withUuid = obj => ({ ...obj, uuid: uuidv4() });
