import React from 'react';

const MediaDeviceIcon = () => (
  <svg width="46px" height="75px" viewBox="0 0 106 173" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Special-Cases" transform="translate(-669.000000, -363.000000)">
        <g id="Portable-Media" transform="translate(520.000000, 325.000000)">
          <g id="Cell-Icon" transform="translate(149.000000, 38.000000)">
            <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="2" x="1" y="1" width="104" height="171" rx="8"/>
            <rect id="Rectangle-Copy" stroke="#FFFFFF" strokeWidth="2" x="8" y="6" width="91" height="153" rx="8"/>
            <rect id="Rectangle" stroke="#FFFFFF" x="38.5" y="161.5" width="30" height="7" rx="3.5"/>
            <g id="Group-2" transform="translate(19.000000, 48.000000)">
              <ellipse id="Oval" fill="#FFFFFF" cx="13.5" cy="58.5" rx="13.5" ry="14.5"/>
              <ellipse id="Oval-Copy" fill="#FFFFFF" cx="48.5" cy="50.5" rx="13.5" ry="14.5"/>
              <polyline id="Path" stroke="#FFFFFF" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round" points="22 59 22 17.5693643 56 0 56 50.8813047"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MediaDeviceIcon;
