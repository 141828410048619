import { createFactoryFunc } from "./factory";
import and from "crocks/logic/and";
import compose from "crocks/helpers/compose";
import curry from "crocks/helpers/curry";
import isInteger from "crocks/predicates/isInteger";
import map from "crocks/pointfree/map";
import option from "crocks/pointfree/option";
import prop from "crocks/Maybe/prop";
import { isArrayNotEmpty, isGreaterThan } from "../util/predicates";
import { provideEmptyArray, provideEmptyString, provideZero } from "../util/providers";

const tagProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'displayName', defaultProvider: provideEmptyString },
  { name: 'description', defaultProvider: provideEmptyString },
  { name: 'eventCount', defaultProvider: provideZero },
  { name: 'ownerEmail', defaultProvider: provideEmptyString },
  { name: 'ownerOrgId', defaultProvider: provideZero },
  { name: 'sharedOrgIds', defaultProvider: provideEmptyArray },
];

/**
 * Maximum length for the tag's name string.
 *
 * @type {number}
 */
export const maxTagLength = 20;

/**
 * Create a canonical Tag object.  A tag is a user-defined string that can
 * be assigned to scenarios.
 *
 * createTag :: Object -> Tag
 */
export const createTag = createFactoryFunc(tagProps);

export const getTagId = prop('id');
export const getTagName = prop('name');
export const getTagDisplayName = prop('displayName');
export const getTagDescription = prop('description');
export const getTagEventCount = prop('eventCount');
export const getTagOwnerOrgId = prop('ownerOrgId');
export const getTagOwnerEmail = prop('ownerEmail');
export const getTagSharedOrgIds = prop('sharedOrgIds');

const isValidId = and(isInteger, isGreaterThan(0));

export const isTagValid = compose(option(false), map(isValidId), getTagId);
export const isTagShared = compose(option(false), map(isArrayNotEmpty), getTagSharedOrgIds);
export const isTagOwnedBy = curry((userEmail, tag) =>
  getTagOwnerEmail(tag)
    .map(email => email === userEmail)
    .option(false)
);

/**
 * A tag belongs in the organization if the tag is owned by or shared with that
 * organization.
 *
 * isTagInOrg :: Int -> Tag -> Boolean
 */
export const isTagInOrg = curry((orgId, tag) => {
  const tagOwnerOrgId = getTagOwnerOrgId(tag).option(0)
  const sharedOrgIds = getTagSharedOrgIds(tag).option([])
  return tagOwnerOrgId === orgId || sharedOrgIds.indexOf(orgId) >= 0
})