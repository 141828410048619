import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { leadVehicleActorColor, primaryOtherActorColor } from "../radarRenderer"

const useRlrStyles = makeStyles(() => ({
  root: {
    display: "flex",
    fontSize: "90%",
    marginBottom: 8,
  },
  block: {
    width: 16,
    height: 16,
    marginRight: 8,
  }
}))

function RadarLegendRow({ text = '', color = leadVehicleActorColor }) {
  const classes = useRlrStyles()

  return (
    <div className={classes.root}>
      <div className={classes.block} style={{ backgroundColor: color }}/>
      <div style={{ maxWidth: "30%" }}>{text}</div>
    </div>
  )
}

const useRelStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    color: '#ccc',
    display: "flex",
    flexDirection: 'column',
  },
  legend: {
    fontSize: "110%",
    marginBottom: 8,
  }
}))

function RadarEnhancedLegend({ visible = false }) {
  const classes = useRelStyles()

  if (!visible) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.legend}>Legend</div>
      <RadarLegendRow text="Scenario Lead" color={leadVehicleActorColor}/>
      <RadarLegendRow text="Principal Other Vehicle" color={primaryOtherActorColor}/>
    </div>
  )
}

export default RadarEnhancedLegend
