import * as React from 'react'
import { AnalyticsApp } from '../../common/models/analyticsApp';
import { useFunctionalScenarios } from "../../common/store/hooks/useAnalytics"
import { ensureArrayResponse } from "../../common/store/actions/utils"

// interface FunctionalScenarioAppData {
//     id: string;
//     scenario: string;
//     url: string;
//     name: string;
//     description: string;
// }
// interface FunctionalScenarioState {
//     apps: FunctionalScenarioAppData[] | undefined;
//     setApps: (apps: FunctionalScenarioAppData[]) => void;
// }

interface FunctionalScenarioState {
    scenarios: AnalyticsApp[] | undefined;
    setScenarios: (apps: AnalyticsApp[]) => void;
}

type FunctionalScenarioProviderProps = { children: React.ReactNode }

const FunctionalScenarioStateContext = React.createContext<FunctionalScenarioState | undefined>(undefined)

function FunctionalScenarioStateProvider({ children }: FunctionalScenarioProviderProps) {
    const [apps, setApps] = React.useState<AnalyticsApp[] | undefined>(undefined)

    // React.useEffect(() => {
    //     setApps([{
    //         id: 'lane-change-draft-1',
    //         scenario: 'lane-change',
    //         name: 'Draft 1',
    //         description: 'The first draft of the Lane Change Cut-In scenario',
    //         // url: 'https://gallery.shinyapps.io/goog-trend-index/',
    //         url: '/shiny/functional-scenario-cutins/',
    //     }])
    // }, [])

    const fetchFunctionalScenarios = useFunctionalScenarios()

    React.useEffect(() => {
        fetchFunctionalScenarios()
            // @ts-ignore
            .then(ensureArrayResponse)
            .then(setApps)
    }, [setApps, fetchFunctionalScenarios])

    return (
        <FunctionalScenarioStateContext.Provider value={{ scenarios: apps, setScenarios: setApps }}>
            {children}
        </FunctionalScenarioStateContext.Provider>
    )
}

function useFunctionalScenarioState() {
    const context = React.useContext(FunctionalScenarioStateContext)

    if (context === undefined) {
        throw new Error('useFunctionalScenarioState must be used within a FunctionalScenarioStateProvider')
    }

    return context
}

export { FunctionalScenarioStateProvider, useFunctionalScenarioState }