/**
 * Clamp a value between the minimum and maximum specified.
 *
 * @param val The value to clamp.
 * @param min The minimum value
 * @param max The maximum value
 * @returns {number} min if val < min, max if val > max, otherwise just val.
 */
export const clamp = (val, min, max) => Math.min(Math.max(min, val), max);

/**
 * Wrap a value when it goes outside of the min/max range.
 *
 * @param val The value to potentially wrap
 * @param min The minimum value
 * @param max The maximum value
 * @returns {number} min if val > max, max if val < min, otherwise just val.
 */
export const wrap = (val, min, max) => val < min ? max : (val > max ? min : val);