import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import SpeedIndicator from "../../../../common/components/indicators/SpeedIndicator";
import CaseTypeIndicator from "../../../../common/components/indicators/CaseTypeIndicator";

const styles = {
  root: {
    display: 'flex',
  },
};

class Indicators extends React.PureComponent {
  static propTypes = {
    caseType: PropTypes.string,
    speed: PropTypes.number,
    vertical: PropTypes.bool,
  };

  static defaultProps = {
    caseType: 'Unknown',
    vertical: false,
    speed: null,
  };

  render() {
    const { caseType, speed, vertical, className, classes } = this.props;
    const style = vertical ? { flexDirection: 'column' } : undefined;

    return (
      <div className={classNames(className, classes.root)} style={style}>
        <SpeedIndicator value={speed} />
        <CaseTypeIndicator caseType={caseType} />
      </div>
    );
  }
}

export default withStyles(styles)(Indicators);
