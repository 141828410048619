import React from 'react';
import PropTypes from 'prop-types';
import { pipe } from "../../common/util/func";
import { withStyles } from "@material-ui/core/styles/index";
import { connect } from "react-redux";
import { changeProfilePassword, resetRequestState } from "../../common/store/actions";
import AppRequestState from "../../common/models/AppRequestState";
import { Grid, TextField } from "@material-ui/core/";
import SubmitButton from "../../common/components/SubmitButton";
import PasswordConfirmPair from "../../common/components/PasswordConfirmPair";
import ProfileUiContainer from "../../common/components/TitledFormContainer";
import { stringProp } from "../../common/util/object";
import AppRequest from "../../common/models/AppRequest";

const requestName = AppRequest.PUT_PROFILE_PASSWORD;

const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: "Changing Password...",
  [AppRequestState.FAILURE]: "Your current password was incorrect, no changes were made.",
  [AppRequestState.SUCCESS]: "Password Changed!",
};

const styles = {
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

class ProfilePasswordContent extends React.Component {
  state = {
    oldPassword: '',
    newPassword: '',
  };

  handleNewPasswordChange = newPassword => this.setState({ newPassword });
  handleCurrentPasswordChange = ev => this.setState({ oldPassword: ev.currentTarget.value });

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.changeProfilePassword(this.state);
  };

  render() {
    const { oldPassword, newPassword } = this.state;
    const { classes, profile, requestState } = this.props;

    const disableSubmit = oldPassword.length === 0 || newPassword.length === 0;
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    return (
      <ProfileUiContainer title='Change Password' email={profile.email}>
        <form className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField type="password" label="Current Password" value={oldPassword} fullWidth autoFocus
                         onChange={this.handleCurrentPasswordChange} />
            </Grid>
            <Grid item xs={12}>
              <PasswordConfirmPair onChange={this.handleNewPasswordChange} />
            </Grid>
            <Grid item xs={12}>
              <SubmitButton label="Change Password" requestState={requestState} stateMsg={stateMsg}
                            disabled={disableSubmit} />
            </Grid>
          </Grid>
        </form>
      </ProfileUiContainer>
    )
  }
}

ProfilePasswordContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({
  profile: app.profile,
  requestState: app.requests[requestName]
});

export default pipe(
  connect(mapStateToProps, { changeProfilePassword, resetRequestState }),
  withStyles(styles)
)(ProfilePasswordContent);