import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from "crocks/predicates/isEmpty";
import TextField from "@material-ui/core/TextField";
import { maxTagLength } from "../../../../common/models/tag";

const TagNameTextField = ({ label, value, onChange, className }) => {
  const charsLeft = isEmpty(value) ? ' ' : `${maxTagLength - value.length} characters left`;

  return (
    <TextField
      autoFocus
      fullWidth
      label={label}
      value={value}
      helperText={charsLeft}
      className={className}
      onChange={onChange}
    />
  );
};

TagNameTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

TagNameTextField.defaultProps = {
  value: '',
};

export default TagNameTextField;
