import isEmpty from "crocks/predicates/isEmpty"
import { CaseType, RowCategory } from "../../common/models/analyticsApp"
import { rhead, rtail } from "../../common/util/array"

const colBase = 4
const rowBase = 6

/**
 * Calculate the cell index based on the base offset and accounting for the spacers between rows and columns
 */
const sourceToCellIndex = (base, srcIdx) => base + 2 * srcIdx

const createGridCell = (row, col) => {
  const rowStart = sourceToCellIndex(rowBase, row)
  const colStart = sourceToCellIndex(colBase, col)

  return {
    rowStart,
    colStart,
    colEnd: colStart + 1,
    applications: []
  }
}

function createDefaultSpans() {
  return RowCategory.reduce((spans, row, rowIdx) => {
    spans[row] = CaseType.reduce((types, type, colIdx) => {
      types[type] = createGridCell(rowIdx, colIdx)
      return types
    }, {})
    return spans
  }, {})
}

function forEachPropIn(obj, fn) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      fn(obj[prop])
    }
  }
}

/**
 * Return An array that is the intersection of arrays a and b
 */
function intersection(a, b) {
  const bSet = new Set(b)
  return a.filter(el => bSet.has(el))
}

/**
 * Return An array that is the union of arrays a and b
 */
function union(a, b) {
  const union = new Set([...a, ...b])
  return [...union]
}

function combineGridCells(a, b) {
  return {
    rowStart: a.rowStart,
    colStart: Math.min(a.colStart, b.colStart),
    colEnd: Math.max(a.colEnd, b.colEnd),
    applications: union(a.applications, b.applications)
  }
}

const shouldCombineCells = (a, b) => intersection(a.applications, b.applications).length > 0

function combinerReducer(cells, cell) {
  if (cells.length === 0) {
    return [cell]
  }

  const src = rhead(cells)
  return shouldCombineCells(src, cell)
    ? [...rtail(cells), combineGridCells(src, cell)]
    : [...cells, cell]
}

function combineCellsInRow(row) {
  const cnc = row[CaseType[0]]
  const epochs = row[CaseType[1]]
  const baselines = row[CaseType[2]]

  return [cnc, epochs, baselines].reduce(combinerReducer, [])
}

function gridMapToCellList(grid) {
  const gridCells = []
  forEachPropIn(grid, row => gridCells.push(...combineCellsInRow(row)))
  return gridCells
}

export function buildGridForApps(apps) {
  const grid = createDefaultSpans()

  if (isEmpty(apps)) {
    return gridMapToCellList(grid)
  }

  apps.forEach(app => {
    const row = grid[app.rowCategory]
    app.caseTypes
      .split(',')
      .forEach(caseType => row[caseType].applications.push(app))
  })

  return gridMapToCellList(grid)
}