import isNil from "crocks/predicates/isNil"
import isEmpty from "crocks/predicates/isEmpty"
import ampTheme from "../../../../ampTheme"

const leadVehicleActorType = 1
// const primaryOtherActorType = 2

const carWidthMeters = 1.85
const carLengthMeters = 4.694
const laneWidthMeters = 3.7

const maxRangeMeters = 100
const rangeArcs = [25, 50, 75]
const azimuthLimitDegrees = 18

const azimuthLineRads = (90 - azimuthLimitDegrees) * Math.PI / 180
const arcStartRads = (270 - azimuthLimitDegrees) * Math.PI / 180
const arcEndRads = (270 + azimuthLimitDegrees) * Math.PI / 180

const targetFillColor = '#FFF'
const targetStrokeColor = '#222'
const instrumentLineColor = '#6F6F6F'
const leadTargetColor = ampTheme.palette.amp.viewerHighlight

export const leadVehicleActorColor = "#C0304F"
// export const leadVehicleActorColor = ampTheme.palette.primary.main
export const primaryOtherActorColor = "#FF6824"

const secondsToIndex = secs => Math.round(secs * 10)

function drawBackground(ctx, width, height) {
  const gradient = ctx.createLinearGradient(width / 2, height, width / 2, 0)
  gradient.addColorStop(0.0, '#444')
  gradient.addColorStop(0.5, '#222')

  ctx.fillStyle = '#222'
  ctx.fillRect(0, 0, width, height)

  const pixelsPerMeter = height / maxRangeMeters
  const laneWidthInPixels = laneWidthMeters * pixelsPerMeter

  ctx.fillStyle = gradient
  ctx.fillRect(width / 2 - laneWidthInPixels / 2, 0, laneWidthInPixels, height)
}

function drawAzimuthLines(ctx, width, height) {
  const halfWidth = width / 2
  const endY = height - halfWidth * Math.tan(azimuthLineRads)

  ctx.strokeStyle = instrumentLineColor

  ctx.beginPath()
  ctx.moveTo(0, endY)
  ctx.lineTo(halfWidth, height)
  ctx.lineTo(width, endY)
  ctx.stroke()
}

function drawRangeArcs(ctx, width, height) {
  const pixelsPerMeter = height / maxRangeMeters
  const startX = width / 2

  ctx.strokeStyle = instrumentLineColor

  rangeArcs
    .map(range => range * pixelsPerMeter)
    .forEach(radius => {
      ctx.beginPath()
      ctx.arc(startX, height, radius, arcStartRads, arcEndRads)
      ctx.stroke()
    })

  ctx.fillStyle = instrumentLineColor
  ctx.textAlign = 'center'
  ctx.textBaseline = 'bottom'

  rangeArcs
    .map(range => [range, height - range * pixelsPerMeter])
    .forEach(([range, startY]) => {
      ctx.fillText(range + 'm', startX, startY)
      ctx.fillText(range + 'm', startX, startY)
      ctx.fillText(range + 'm', startX, startY)
    })
}

const createTransformedCoordinateString = (rangeX, rangeY) => `(${rangeY.toFixed(1)}m, ${(-rangeX).toFixed(1)}m)`

function drawNormalTargetVehicle(ctx, x, y, width, height, isLead, rangeX, rangeY) {
  const radius = width / 2

  ctx.strokeStyle = targetStrokeColor

  ctx.beginPath()
  ctx.fillStyle = isLead ? leadTargetColor : targetFillColor
  ctx.arc(x, y, radius, 0, 360)
  ctx.fill()

  if (isLead) {
    const coordinateStr = createTransformedCoordinateString(rangeX, rangeY)
    ctx.textAlign = 'left'
    ctx.textBaseline = 'bottom'
    ctx.font = 'normal 18px Roboto'
    ctx.fillText(coordinateStr, x + radius, y - radius)
  }
}

function drawEnhancedTargetVehicle(ctx, x, y, width, height, actorType, rangeX, rangeY) {
  const radius = width / 2

  ctx.strokeStyle = targetStrokeColor
  ctx.fillStyle = actorType === 0
    ? targetFillColor
    : actorType === leadVehicleActorType ? leadVehicleActorColor : primaryOtherActorColor

  ctx.beginPath()
  if (actorType === 0) {
    ctx.arc(x, y, radius, 0, 360)
  } else {
    ctx.rect(x - radius, y - radius, width, width)
  }
  ctx.fill()

  if (actorType !== 0) {
    const coordinateStr = createTransformedCoordinateString(rangeX, rangeY)
    ctx.textAlign = 'left'
    ctx.textBaseline = 'bottom'
    ctx.font = 'normal 18px Roboto'
    ctx.fillText(coordinateStr, x + radius, y - radius)
  }
}

function drawTargetArrow(ctx, x, targetLength, isLead, rangeX, rangeY, isEnhanced, actorType) {
  const shaftLen = targetLength * .75
  const arrowLen = targetLength * .25

  if (isEnhanced) {
    ctx.strokeStyle = actorType === 0
      ? targetFillColor
      : actorType === leadVehicleActorType ? leadVehicleActorColor : primaryOtherActorColor
  } else {
    ctx.strokeStyle = isLead ? leadTargetColor : targetFillColor
  }

  ctx.beginPath()
  ctx.moveTo(x, shaftLen)
  ctx.lineTo(x, 0)
  ctx.lineTo(x - arrowLen, arrowLen)
  ctx.moveTo(x, 0)
  ctx.lineTo(x + arrowLen, arrowLen)
  ctx.stroke()

  ctx.fillStyle = isLead ? leadTargetColor : targetFillColor
  ctx.textAlign = 'center'
  ctx.textBaseline = 'top'
  ctx.fillText(`${Math.round(rangeY)}m`, x, targetLength)
}

function createTargetDrawer(ctx, center, height, pixelsPerMeter) {
  return (id, rangeX, rangeY, targetWidth, targetLength, isLead = false, isEnhanced, actorType) => {
    const x = center + rangeX * pixelsPerMeter
    const y = height - rangeY * pixelsPerMeter

    if (rangeY < maxRangeMeters) {
      isEnhanced
        ? drawEnhancedTargetVehicle(ctx, x, y, targetWidth, targetLength, actorType, rangeX, rangeY)
        : drawNormalTargetVehicle(ctx, x, y, targetWidth, targetLength, isLead, rangeX, rangeY)
    } else {
      drawTargetArrow(ctx, x, targetLength, isLead, rangeX, rangeY, isEnhanced, actorType)
    }
  }
}

function drawTargets(ctx, width, height, currentTime, radarData, isEnhanced) {
  const index = secondsToIndex(currentTime)

  if (isNil(index) || isEmpty(radarData)) {
    return
  }

  const targets = radarData[index]
  if (isNil(targets)) {
    return
  }

  const pixelsPerMeter = height / maxRangeMeters
  const targetWidthPixels = carWidthMeters * pixelsPerMeter
  const targetLengthPixels = carLengthMeters * pixelsPerMeter

  const drawTarget = createTargetDrawer(ctx, width / 2, height, pixelsPerMeter)

  for (let i = 0; i < targets.length; ++i) {
    const [id, rangeX, rangeY, isLead, actorType] = targets[i]
    drawTarget(id, -rangeY, rangeX, targetWidthPixels, targetLengthPixels, isLead === 1, isEnhanced, actorType)
  }
}

export function drawRadar(canvas, ctx, currentTime, timeSeries, isEnhanced) {
  if (isNil(ctx)) {
    return
  }

  const { width, height } = canvas

  drawBackground(ctx, width, height)
  drawAzimuthLines(ctx, width, height)
  drawRangeArcs(ctx, width, height)
  drawTargets(ctx, width, height, currentTime, timeSeries, isEnhanced)
}