import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import AmpTable from "../../../common/components/AmpTable";
import ColumnType from "../../../common/components/AmpTable/ColumnType";
import { newsTypeToString } from "../../../common/models/news";
import ActionButton from "../DashboardCard/ActionButton";
import { pluralOf } from "../../../common/util/string";
import ActionsColumn from "../components/ActionsColumn";

const { STRING, INTEGER, DATE } = ColumnType;

const buildColumnData = (onEdit, onDelete) => [
  { id: 'id', label: 'ID', type: INTEGER, width: '20px' },
  { id: 'startTime', label: 'Start', type: DATE },
  { id: 'endTime', label: 'End', type: DATE },
  { id: 'type', label: 'Type', type: STRING, render: item => newsTypeToString(item.type) },
  { id: 'title', label: 'Title', type: STRING },
  {
    id: 'actions',
    label: 'Actions',
    render: item => <ActionsColumn item={item} onEdit={onEdit} onDelete={onDelete} /> },

];

const styles = theme => ({
  root: {
    display: 'grid',
    marginBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    overflowX: 'auto',
    '& > *': {
      minWidth: 800,
    },
  }
});

class NewsTable extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    itemName: PropTypes.string,
    className: PropTypes.string,
    onAddNewsItem: PropTypes.func,
    onEditNewsItem: PropTypes.func,
    onDeleteNewsItem: PropTypes.func,
  };

  static defaultProps = {
    items: [],
  };

  static getDerivedStateFromProps(nextProps) {
    const { onEditNewsItem, onDeleteNewsItem } = nextProps;
    return { columnData: buildColumnData(onEditNewsItem, onDeleteNewsItem) }
  }

  state = {
    columnData: {}
  };

  render() {
    const { columnData } = this.state;
    const { itemName, items, onAddNewsItem,  classes, className } = this.props;

    return (
      <div className={classNames(className, classes.root)}>
        <div className={classes.header}>
          <Typography variant="subtitle1" color="primary">{pluralOf(itemName)}</Typography>
          <ActionButton label={itemName} onClick={onAddNewsItem}/>
        </div>

        <AmpTable className={classes.table} columnData={columnData} items={items}/>
      </div>
    );
  }
}

export default withStyles(styles)(NewsTable);
