import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from "crocks/predicates/isEmpty"
import TagEditorEntry from "./TagEditorEntry"
import Typography from "@material-ui/core/Typography"
import TagDeleteConfirmation from "./TagDeleteConfirmation"
import { getTagDisplayName, getTagName, isTagInOrg, isTagShared } from "../../../../common/models/tag"
import { stringCompare } from "../../../../common/util/string"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useUserProfile from "../../../../common/store/hooks/useUserProfile"

const useStyles = makeStyles({
  root: {},
  confirm: {},
  noTags: {
    color: '#888',
    textAlign: 'center',
  }
})

const createConfirmMessage = (onConfirmCancel, onConfirmDelete, classes) => (
  <TagDeleteConfirmation
    className={classes.confirm}
    onCancel={onConfirmCancel}
    onDelete={onConfirmDelete}
  />
)

const orderByTagName = (fst, snd) =>
  stringCompare(getTagName(fst).option(''), getTagName(snd).option(''))

function sortTagsByTypeAndName(tags) {
  const splitTags = tags.reduce((tags, tag) => {
    const idx = isTagShared(tag) ? 0 : 1
    tags[idx].push(tag)
    return tags
  }, [[], []])

  splitTags.forEach(list => list.sort(orderByTagName))
  return splitTags[1].concat(splitTags[0])
}

const createNoTagsMessage = (msg, className) => (
  <Typography className={className} variant="subtitle1">
    {msg}
  </Typography>
)

export default function TagEditorEntryList({
  tags,
  className,
  assignedNameSet,
  emptyMessage,
  confirmId,
  onUpdate,
  onShowDeleteConfirmation,
  onConfirmCancel,
  onConfirmDelete,
  onToggleAssignment,
}) {
  const classes = useStyles()

  const user = useUserProfile()
  const isTagInUserOrg = isTagInOrg(user.orgId)
  const filteredAndSortedTags = sortTagsByTypeAndName(tags.filter(isTagInUserOrg))

  if (isEmpty(tags)) {
    return createNoTagsMessage(emptyMessage, classes.noTags)
  }

  return (
    <div className={classNames(className, classes.root)}>
      {
        filteredAndSortedTags
          .map(tag => (
            <div key={tag.id}>
              <TagEditorEntry
                tag={tag}
                assigned={assignedNameSet.has(getTagDisplayName(tag).option(''))}
                onUpdateTag={onUpdate}
                onToggleAssignment={onToggleAssignment}
                onShowDeleteConfirmation={onShowDeleteConfirmation}
              />
              {tag.id === confirmId && createConfirmMessage(onConfirmCancel, onConfirmDelete, classes)}
            </div>
          ))
      }
    </div>
  )
};


TagEditorEntryList.propTypes = {
  tags: PropTypes.array,
  assignedNameSet: PropTypes.object,
  confirmId: PropTypes.number,
  emptyMessage: PropTypes.string,
  className: PropTypes.string,
  onUpdate: PropTypes.func,
  onShowDeleteConfirmation: PropTypes.func,
  onToggleAssignment: PropTypes.func,
  onConfirmCancel: PropTypes.func,
  onConfirmDelete: PropTypes.func,
}

TagEditorEntryList.defaultProps = {
  tags: [],
  assignedNameSet: new Set(),
  confirmId: 0,
}

