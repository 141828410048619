import React from 'react';
import PropTypes from 'prop-types';
import identity from "crocks/combinators/identity";
import withStyles from '@material-ui/core/styles/withStyles';
import RoleChips from "../../../common/components/RoleChips";

const styles = {
  root: {
    cursor: 'pointer',
  },
};

class RoleColumn extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onEdit: PropTypes.func,
  };

  static defaultProps = {
    user: {},
    onEdit: identity,
  };

  handleClick = () => this.props.onEdit(this.props.user);

  render() {
    const { user, classes } = this.props;
    return (
      <div className={classes.root} onClick={this.handleClick}>
        <RoleChips roles={user.roles} />
      </div>
    );
  }
}

export default withStyles(styles)(RoleColumn);
