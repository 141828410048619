import React from 'react';
import PropTypes from 'prop-types';
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import DefaultIcon from '@material-ui/icons/CloudDownload';

const styles = theme => ({
  root: {
    maxWidth: 500,
    cursor: 'pointer',
    padding: theme.spacing(),
    border: '1px solid #AAA',
    boxShadow: '0px 2px 4px #CCC',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    display: 'grid',
    gridTemplateColumns: '50px auto 4em',
    gridTemplateRows: '40px auto',
    gridColumnGap: '8px',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: '#CCC',
      position: 'relative',
      top: '2px',
      left: '2px',
    }
  },
  title: {
    lineHeight: '1em',
    color: theme.palette.primary.dark,
  },
  icon: {
    '& > *': {
      color: theme.palette.secondary.dark,
      width: 48,
      height: 48,
    },
  },
  fileType: {
    color: '#EEE',
    backgroundColor: theme.palette.secondary.dark,
    // gridRow: '2 / 3',
  },
  description: {
    gridColumn: '2 / 4',
    lineHeight: '1em',
    color: theme.palette.secondary.contrastText,
  }
});

class FileDownloadCard extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    fileType: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    icon: <DefaultIcon />,
  };

  handleClick = () => this.props.onClick(this.props.orgId);

  render() {
    const { icon, title, fileType, description, onClick, classes } = this.props;
    return (
      <div className={classes.root} onClick={onClick}>
        <div className={classes.icon}>{icon}</div>

        <Typography className={classes.title} variant="subtitle1">{title}</Typography>
        <Chip className={classes.fileType} label={fileType}/>

        <Typography className={classes.description} variant="caption">
          {description}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(FileDownloadCard);
