import {
  ADD_FILTER,
  FILTER_SAVED,
  PUSH_EXPRESSION_TREE,
  SET_ANALYTICS_COUNT,
  SET_CURRENT_FILTER,
  SET_FILTER_VARS,
  SET_FILTERS,
  SET_SCENARIO,
  SET_SCENARIO_COUNTS,
  SET_SCENARIO_INDEX,
  SET_SCENARIO_PAGE_INDEX,
  SET_SCENARIO_PAGES,
  SET_SCENARIO_SORT_COLUMN,
  SET_SCENARIO_SORT_DIRECTION,
  SET_SCENARIOS,
  SET_TIME_SERIES,
  SET_TIME_SERIES_VARS
} from "../actions"

import { createAmpExpressionTree, createChartVariables, createFilter, deserializeExpressionTree } from "../../models"

import { stringCompare } from "../../util/string"
import { buildLookupById } from "../../util/object"

const defaultTree = createAmpExpressionTree()

/**
 * This is the shape of the state contained in the userData branch of the Redux store.
 * It is here to provide documentation on the state and to provide initial defaults
 * for the reducers.
 */
export const userDataInitialState = {
  scenarios: {
    all: [],
    currentIndex: -1,
    analytics: {},
    counts: {},
    pages: {
      index: 1,
      max: 1,
      size: 100,
    },
    timeSeriesCache: {},
    sortColumn: 'fileId',
    sortDirection: 'asc',
  },
  filters: {
    all: [],
    current: createFilter(),
  },
  expressionTree: {
    stack: [defaultTree],
    stackIndex: 0,
    savedIndex: -1,
  },
  filterVars: {
    all: [],
    sortedByName: [],
    lookupById: {},
  },
  timeSeriesVars: {
    all: [],
    lookupById: {},
    chartVariables: [],
  },
}

function createVariableState(variables) {
  if (!variables) {
    return undefined
  }
  const sortedByName = variables.sort((a, b) => stringCompare(a.name, b.name))
  const lookupById = buildLookupById(variables)
  return { all: variables, sortedByName, lookupById }
}

const filterVarsReducer = (state = userDataInitialState.userData.filterVars, action) => {
  if (action.type === SET_FILTER_VARS) {
    return createVariableState(action.payload.filterVars) || state
  }
  return state
}

const timeSeriesVarsReducer = (state = userDataInitialState.userData.timeSeriesVars, action) => {
  if (action.type === SET_TIME_SERIES_VARS) {
    const all = action.payload.timeSeriesVars
    const lookupById = buildLookupById(all)
    const chartVariables = createChartVariables(all)
    return { all, lookupById, chartVariables }
  }
  return state
}

const byMostRecentlyUsed = (a, b) => b.lastModifiedDate - a.lastModifiedDate

const addOrReplaceInList = (filters, filter) => {
  const index = filters.findIndex(f => f.id === filter.id)
  return [...filters.slice(0, index), filter, ...filters.slice(index + 1)]
    .sort(byMostRecentlyUsed)
}

const doSave = (state, action) => {
  const { filter } = action.payload
  return { all: addOrReplaceInList(state.all, filter), current: filter }
}

/**
 * Whenever we receive a new list of filters for the current user, verify that the
 * user's current filter is among them.  If it's not, rewrite it with an ID of 0
 * to create a new filter based on the expression tree of the old invalid one.  This
 * gives the user a chance to save it as a new filter.
 */
function validateCurrentFilter(current, filters) {
  if (filters.findIndex(f => f.id === current.id) >= 0) {
    return current
  }
  return { ...current, id: 0 }
}

const filtersReducer = (state = userDataInitialState.userData.filters, action) => {
  switch (action.type) {
    case SET_FILTERS:
      const allFilters = [...action.payload.filters].sort(byMostRecentlyUsed)
      return { ...state, all: allFilters, current: validateCurrentFilter(state.current, allFilters) }

    case ADD_FILTER:
      return { ...state, all: [...state.all, action.payload.filter].sort(byMostRecentlyUsed) }

    case SET_CURRENT_FILTER:
      return { ...state, current: action.payload.filter }

    case FILTER_SAVED:
      return doSave(state, action)

    default:
      return state
  }
}

const initStackFromFilter = ({ conditions }, variableLookup) => ({
  stack: [deserializeExpressionTree(conditions, variableLookup)],
  stackIndex: 0,
  savedIndex: 0,
})

const expressionTreeReducer = (state = userDataInitialState.userData.expressionTree, action, filterVars) => {
  switch (action.type) {
    case PUSH_EXPRESSION_TREE:
      const { expressionTree } = action.payload
      const { stack, stackIndex, savedIndex } = state
      return {
        stack: [...stack.slice(0, stackIndex + 1), expressionTree],
        stackIndex: stackIndex + 1,
        savedIndex,
      }

    case SET_CURRENT_FILTER:
      return initStackFromFilter(action.payload.filter, filterVars.lookupById)

    case FILTER_SAVED:
      return { ...state, savedIndex: state.stackIndex }

    default:
      return state
  }
}

const scenariosReducer = (state = userDataInitialState.userData.scenarios, action) => {
  switch (action.type) {
    case SET_SCENARIO:
      const index = state.all.findIndex(s => s.id === action.payload.scenario.id)
      const all = [...state.all.slice(0, index), action.payload.scenario, ...state.all.slice(index + 1)]
      return { ...state, all }

    case SET_SCENARIOS:
      return { ...state, ...action.payload, currentIndex: 0 }

    case SET_SCENARIO_INDEX:
    case SET_SCENARIO_COUNTS:
    case SET_ANALYTICS_COUNT:
    case SET_SCENARIO_PAGES:
    case SET_SCENARIO_SORT_COLUMN:
    case SET_SCENARIO_SORT_DIRECTION:
      return { ...state, ...action.payload }

    case SET_SCENARIO_PAGE_INDEX:
      return { ...state, pages: { ...state.pages, index: action.payload.index } }

    case SET_TIME_SERIES:
      const { entry } = action.payload
      return { ...state, timeSeriesCache: { ...state.timeSeriesCache, [entry.scenarioId]: entry } }
    default:
      return state
  }
}

/**
 * Root reducer for userData.
 */
export const userData = (state = userDataInitialState, action) => {
  switch (action.type) {
    case SET_TIME_SERIES:
    case SET_SCENARIO:
    case SET_SCENARIOS:
    case SET_SCENARIO_INDEX:
    case SET_SCENARIO_COUNTS:
    case SET_ANALYTICS_COUNT:
    case SET_SCENARIO_PAGES:
    case SET_SCENARIO_PAGE_INDEX:
    case SET_SCENARIO_SORT_COLUMN:
    case SET_SCENARIO_SORT_DIRECTION:
      return { ...state, scenarios: scenariosReducer(state.scenarios, action) }

    case ADD_FILTER:
    case SET_FILTER_VARS:
      return { ...state, filterVars: filterVarsReducer(state.filterVars, action) }

    case SET_TIME_SERIES_VARS:
      return { ...state, timeSeriesVars: timeSeriesVarsReducer(state.filterVars, action) }

    case SET_FILTERS:
      return { ...state, filters: filtersReducer(state.filters, action) }

    case FILTER_SAVED:
    case SET_CURRENT_FILTER:
      return {
        ...state,
        filters: filtersReducer(state.filters, action),
        expressionTree: expressionTreeReducer(state.expressionTree, action, state.filterVars),
      }

    case PUSH_EXPRESSION_TREE:
      return { ...state, expressionTree: expressionTreeReducer(state.expressionTree, action) }

    default:
      return state
  }
}

/*
 * State accessors decouple clients from the structure of the store's state. These
 * are all re-exported from store/index.js for use by the outside world.
 */
export const getScenarios = state => state.userData.scenarios.all
export const getScenarioIndex = state => state.userData.scenarios.currentIndex
export const getScenarioCounts = state => state.userData.scenarios.counts
export const getScenarioSortColumn = state => state.userData.scenarios.sortColumn
export const getScenarioSortDirection = state => state.userData.scenarios.sortDirection
export const getScenarioPageSize = state => state.userData.scenarios.pages.size
export const getScenarioPageIndex = state => state.userData.scenarios.pages.index
export const getScenarioPageMax = state => state.userData.scenarios.pages.max
export const getAnalyticsCount = state => state.userData.scenarios.analytics

export const getTimeSeriesCache = state => state.userData.scenarios.timeSeriesCache
export const getTimeSeriesCacheEntry = (state, id) => state.userData.scenarios.timeSeriesCache[id]

export const getExpressionTree = state => {
  const { expressionTree } = state.userData
  return expressionTree.stack[expressionTree.stackIndex]
}
const getStackIndex = state => state.userData.expressionTree.stackIndex
const getSavedIndex = state => state.userData.expressionTree.savedIndex

export const isSaveNeeded = state => getStackIndex(state) !== getSavedIndex(state)

export const getFilterVars = state => state.userData.filterVars
export const getFilterVarsSorted = state => state.userData.filterVars.sortedByName
export const getFilterVarsLookup = state => state.userData.filterVars.lookupById

export const getChartVars = state => state.userData.timeSeriesVars.chartVariables

// Note: These are not used... yet.
// export const getTimeSeriesVars = state => state.userData.timeSeriesVars.all;
// export const getTimeSeriesVarsLookup = state => state.userData.timeSeriesVars.lookupById;
// export const getFilters = state => state.userData.filters.all;
// export const getFilterVars = state => state.userData.filterVars.all;
// export const getCurrentFilter = state => state.userData.filters.current;

