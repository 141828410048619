import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { formatFeatures, trim } from "../../common/stringUtils";

class Features extends React.PureComponent {
  static propTypes = {
    featuresPresent: PropTypes.string,
    maxLen: PropTypes.number,
  };

  static defaultProps = {
    featuresPresent: '',
    maxLen: 500,
  };

  render() {
    const { featuresPresent, maxLen, small, className } = this.props;
    const featuresStr = formatFeatures(featuresPresent);
    const featuresStrTrimmed = trim(maxLen, featuresStr);
    const textStyle = small ? { fontSize: '75%' } : undefined;

    return (
      <div className={className} title={featuresStr}>
        <Typography style={textStyle}>
          {featuresStrTrimmed}
        </Typography>
      </div>
    );
  }
}

export default Features;
