import { addChildToGroup, createExpression, isFloatVariable } from "../../../../common/models";
import { isIntVariable, isListVariable } from "../../../../common/models";
import { partial } from "../../../../common/util/func";

export const createDefaultExpression = variable => {
  const data = isIntVariable(variable) || isFloatVariable(variable) ? '0' : '';
  const selected = isListVariable(variable) ? variable.choices.map(ch => ch.id) : undefined;
  const opts = { variable, data, selected };
  return createExpression(opts);
};

export const createAddChildOp = variable => partial(addChildToGroup, createDefaultExpression(variable));

export const displayStringForData = ({ variable, data }) =>
  isIntVariable(variable)
    ? data
    : data.length === 0 ? "ANY VALUE" : data;
