import React from 'react';
import chain from "crocks/pointfree/chain";
import compose from "crocks/helpers/compose";
import prop from "crocks/Maybe/prop";
import propPath from "crocks/Maybe/propPath";
import option from "crocks/pointfree/option";
import AppRequest from "../../../common/models/AppRequest";
import { getUserFullName } from "../../../common/models";
import { allRequestsInFlight } from "../../../common/util/request";
import ColumnType from "../../../common/components/AmpTable/ColumnType";
import { buildLookupById, createCombiner, createJoin, createJoiner } from "../../../common/util/object";
import ReviewDateTableCell from "./ReviewDateTableCell";
import AmendedDateTableCell from "./AmendedDateTableCell";

const { GET_USERS, GET_IRB_CERTIFICATES } = AppRequest;
const { STRING, INTEGER, DATE, BOOLEAN } = ColumnType;

const reviewDateRenderer = onClick => row => <ReviewDateTableCell row={row} onClick={onClick} />;
const buildReviewDateColumn = (userIsIrb, onReviewClick) => ({
  id: 'reviewDate',
  label: 'Review Date',
  type: DATE,
  render: userIsIrb ? reviewDateRenderer(onReviewClick) : undefined,
});

const amendedDateRenderer = onClick => row => <AmendedDateTableCell row={row} onClick={onClick} />;
const buildAmendedToIrbColumn = (userIsIrb, onAmendClick) => ({
  id: 'amendedToIrbDate',
  label: 'Amended To IRB',
  type: DATE,
  render: userIsIrb ? amendedDateRenderer(onAmendClick) : undefined,
});

const itemProps = [
  { name: 'id', provider: compose(option(0), propPath([0, 'id'])) },
  { name: 'userId', provider: compose(option(0), propPath([1, 'id'])) },
  { name: 'name', provider: compose(option('Unknown'), chain(getUserFullName), prop(1)) },
  { name: 'email', provider: compose(option('Unknown'), propPath([1, 'email'])) },
  { name: 'uploadDate', provider: compose(option(null), propPath([0, 'uploadDate'])) },
  { name: 'reviewDate', provider: compose(option(null), propPath([0, 'reviewDate'])) },
  { name: 'issueDate', provider: compose(option(null), propPath([0, 'issueDate'])) },
  { name: 'approved', provider: compose(option(''), propPath([0, 'approved'])) },
  { name: 'reviewer', provider: compose(option('None'), propPath([0, 'reviewerEmail'])) },
  { name: 'contentType', provider: compose(option(''), propPath([0, 'contentType'])) },
  { name: 'amendedToIrbDate', provider: compose(option(null), propPath([1, 'amendedToIrbDate'])) },
];

export const isLoading = states => allRequestsInFlight(states, GET_USERS, GET_IRB_CERTIFICATES);

export function joinIrbCertificatesToUsers(certificates, users) {
  const userJoin = createJoin("userId", buildLookupById(users));
  const joiner = createJoiner([userJoin]);
  const combiner = createCombiner(itemProps);

  return certificates
    .map(joiner)
    .map(combiner);
}

export const getColumnData = (userIsIrb, onReviewClick, onAmendClick) => [
  { id: 'id', label: 'ID', type: INTEGER, width: '20px' },
  { id: 'name', label: 'User Name', type: STRING },
  { id: 'email', label: 'User Email', type: STRING },
  { id: 'uploadDate', label: 'Upload Date', type: DATE },
  { id: 'issueDate', label: 'Issue Date', type: DATE },
  buildReviewDateColumn(userIsIrb, onReviewClick),
  { id: 'approved', label: 'Approved', type: BOOLEAN },
  { id: 'reviewer', label: 'Reviewer', type: STRING },
  buildAmendedToIrbColumn(userIsIrb, onAmendClick),
];

