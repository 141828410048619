import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '0 0 auto',
  },
};

const CardContent = ({ classes, children }) =>
  <div className={classes.content}>{children}</div>;

export default withStyles(styles)(CardContent);