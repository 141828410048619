import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/";
import NewsItem from "../../common/components/NewsItem";

const styles = theme => ({
  root: {
    width: '100%',
    padding: '1em 4em',
    overflowY: 'auto',
    // maxWidth: '40em',
  },
  list: {
    padding: 0,
    listStyle: 'none',
    '& > li': {
      marginBottom: theme.spacing(2),
    }
  },
});

const NewsList = ({ title, items, justify, classes }) => {
  return (
    <div className={classes.root} style={{ justifySelf: justify }}>
      <Typography variant="h5" color="primary">{title}</Typography>
      <ul className={classes.list}>
        { items.map(item => <li key={item.id}><NewsItem {...item} /></li>) }
      </ul>
    </div>
  );
};

NewsList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  justify: PropTypes.string,
};

NewsList.defaultProps = {
  justify: 'start',
};

export default withStyles(styles)(NewsList);