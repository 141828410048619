import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { parse } from "query-string";
import { resetPassword, resetRequestState } from "../common/store/actions";
import AppRequestState from "../common/models/AppRequestState";
import AmpBannerPage from "../common/components/AmpBannerPage";
import ChangePasswordForm from "./ChangePasswordForm";
import AppStatus from "../common/models/AppStatus";
import AppRequest from "../common/models/AppRequest";

const isSuccess = (request) => request === AppRequestState.SUCCESS;

const passwordChangedAndSignedIn = (app) => {
  const { requests, status } = app;
  const changePasswordReq = requests[AppRequest.POST_PASSWORD_RESET];
  return isSuccess(changePasswordReq) && status === AppStatus.APP_LOGGED_IN;
};

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    const { email, token } = parse(props.location.search);
    this.state = { email, token, password: '' };
  }

  componentDidMount() {
    resetRequestState(AppRequest.POST_PASSWORD_RESET);
  }

  componentDidUpdate() {
    if (passwordChangedAndSignedIn(this.props.app)) {
      this.props.history.push('/');
    }
  }

  handlePasswordChange = password => this.setState({ password });

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { email, password, token } = this.state;
    this.props.doChangePassword(email, password, token);
  };

  render() {
    const { requests } = this.props.app;

    return (
      <AmpBannerPage>
        <ChangePasswordForm
          { ...this.state } requestState={requests[AppRequest.POST_PASSWORD_RESET]}
          onPasswordChange={this.handlePasswordChange}
          onSubmit={this.handleSubmit} />
      </AmpBannerPage>
    );
  }
}

ResetPasswordPage.propTypes = {
  app: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  ({ app }) => ({ app }),
  { doChangePassword: resetPassword, resetRequestState }
)(ResetPasswordPage);