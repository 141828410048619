import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, TextField, withStyles } from "@material-ui/core/";

import CaptionLink from "../../common/components/CaptionLink";
import SubmitButton from "../../common/components/SubmitButton";
import { doLogin, resetRequestState } from "../../common/store/actions";
import { isEmptyString } from "../../common/util/string";
import AppRequestState from "../../common/models/AppRequestState";
import { pipe } from "../../common/util/func";
import { stringProp } from "../../common/util/object";
import AppRequest from "../../common/models/AppRequest";
import { getRequestState } from "../../common/store";

const request = AppRequest.POST_LOGIN;
const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: 'Signing In...',
  [AppRequestState.SUCCESS]: 'Signed In',
  [AppRequestState.FAILURE]: 'Unknown email or password',
};

const styles = theme => ({
  root: {
    width: '100%'
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class LoginForm extends React.Component
{
  state = {
    email: '',
    password: '',
  };

  componentDidMount() {
    this.props.resetRequestState(request);
  }

  handleEmailChange = ev => this.setState({ email: ev.target.value });
  handlePasswordChange = ev => this.setState({ password: ev.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.doLogin(new FormData(event.target));
  };

  render() {
    const { email, password} = this.state;
    const { classes, requestState, onForgotPassword } = this.props;

    const stateMsg = stringProp(requestState, stateMsgs).option('');
    const disableSubmit = isEmptyString(email) || isEmptyString(password);

    return (
      <form method="POST" onSubmit={this.handleSubmit}>
        <Grid className={classes.root} container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              type="email"
              name="username"
              label="Email Address"
              onChange={this.handleEmailChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              name="password"
              label="Password"
              onChange={this.handlePasswordChange}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <CaptionLink onClick={onForgotPassword}>Forgot Password?</CaptionLink>
          </Grid>
          <Grid className={classes.submit} item xs={12} >
            <SubmitButton
              label="Sign In"
              stateMsg={stateMsg}
              requestState={requestState}
              disabled={disableSubmit}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

LoginForm.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ requestState: getRequestState(state, request) });
const mapDispatchToProps = { doLogin, resetRequestState };

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LoginForm);