/**
 * @type {Object.<{STRING: string, NUMBER: string, INTEGER: string, BOOLEAN: string, DATE: string}>}
 */
const ColumnType = Object.freeze({
  STRING: "STRING",
  NUMBER: "NUMBER",
  INTEGER: "INTEGER",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
});

export default ColumnType;