import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem } from "@material-ui/core/";

const UserMenuItem = ({ text, icon, onClick, divider }) => (
  <MenuItem key={text} divider={divider} onClick={() => onClick(text)}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text}/>
  </MenuItem>
);

UserMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  divider: PropTypes.bool,
};

export default UserMenuItem;