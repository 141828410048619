import React from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import ContentContainer from "../../../common/components/ContentContainer";
import BackWithHeading from "../BackWithHeading";
import AmpDialog from "../../../common/components/AmpDialog";
import FaqTable from "./FaqTable";
import FaqForm from "./FaqForm";
import { createFaq } from "../../../common/models/faq";

const styles = theme => ({
  root: {
    height: '100%',
    margin: '0 auto',
    padding: theme.spacing(),
  },
  table: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
});

class FaqManager extends React.PureComponent {
  static propTypes = {
    faqs: PropTypes.array,
    onCreateFaq: PropTypes.func,
    onUpdateFaq: PropTypes.func,
    onDeleteFaq: PropTypes.func,
  };

  static defaultProps = {
    faqs: [],
  };

  state = {
    open: false,
    dlgTitle: '',
    dlgItem: createFaq(),
  };

  handleClose = () => this.setState({ open: false });
  handleAddFaq = () => this.setState({ open: true, dlgTitle: 'Create FAQ Item', dlgItem: createFaq() });
  handleEditFaq = dlgItem => this.setState({ open: true, dlgTitle: 'Edit FAQ Item', dlgItem });

  handleCreateFaq = item => {
    this.handleClose();
    if (item.id === 0) {
      this.props.onCreateFaq(item);
    } else {
      this.props.onUpdateFaq(item);
    }
  };

  handleDeleteFaq = item => this.props.onDeleteFaq(item.id);

  render() {
    const { faqs, classes } = this.props;
    const { open, dlgTitle, dlgItem } = this.state;

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <BackWithHeading heading="Frequently Asked Questions"/>
          <FaqTable
            className={classes.table}
            items={faqs}
            onAddFaq={this.handleAddFaq}
            onEditFaq={this.handleEditFaq}
            onDeleteFaq={this.handleDeleteFaq}
          />
        </Paper>

        <AmpDialog
          open={open}
          title={dlgTitle}
          onClose={this.handleClose}
        >
          <FaqForm key={dlgItem.id} onSubmit={this.handleCreateFaq} faq={dlgItem}/>
        </AmpDialog>
      </ContentContainer>
    );
  }
}

export default withStyles(styles)(FaqManager);