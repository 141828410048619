import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import CssBaseline from "@material-ui/core/CssBaseline"
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import ampTheme from "./ampTheme"
import DocPage from "./DocPage"
import HomePage from "./HomePage/HomePage"
import RegisterPage from "./RegisterPage"
import { fetchUserProfile } from "./common/store/actions"

const theme = createMuiTheme(ampTheme)

const UI = () => (
  <div>
    <CssBaseline/>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Switch>
          <Route path="/doc/:id" component={DocPage}/>
          <Route path="/register" component={RegisterPage}/>
          <Route path="/" component={HomePage}/>
        </Switch>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </div>
)

class App extends React.Component {
  componentDidMount() {
    this.props.store.dispatch(fetchUserProfile())
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <Router>
          <UI/>
        </Router>
      </Provider>
    )
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
}

export default App
