import React from 'react'
import PropTypes from 'prop-types'
import Typography from "@material-ui/core/Typography/Typography"
import FileDownloadCard from "../../common/components/FileDownloadCard"
import identity from "crocks/combinators/identity"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import withStyles from "@material-ui/core/styles/withStyles"
import IfUser from "../../common/components/logic/IfUser"
import { userIsSteeringCommitteeMember } from "../../common/models/user"

const STEERING_IPG_SCRIPT = 1
const STEERING_ANALYTICS_SCRIPT = 2

const styles = theme => ({
  root: {},
  subheading: {
    marginTop: theme.spacing(),
  },
  downloads: {
    '& > *': {
      margin: `${theme.spacing(2)}px 0`,
    }
  },
  list: {
    flexDirection: 'column',
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(2),
    }
  }
})

class UtilitiesSection extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onDownload: PropTypes.func,
    onSteeringDownload: PropTypes.func,
  }

  static defaultProps = {
    user: {},
    onDownload: identity,
    onSteeringDownload: identity,
  }

  handleIpgScriptClick = () => this.props.onSteeringDownload(STEERING_IPG_SCRIPT)
  handleAnalyticsScriptClick = () => this.props.onSteeringDownload(STEERING_ANALYTICS_SCRIPT)

  render() {
    const { classes } = this.props

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Utilities</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.list}>
          <IfUser filter={userIsSteeringCommitteeMember}>
            <div className={classes.downloads}>
              <FileDownloadCard
                fileType=".ZIP"
                title="CarMaker Import Bundle"
                description="Zip file containing AMP CarMaker import files"
                onClick={this.handleIpgScriptClick}
              />

              <FileDownloadCard
                fileType=".ZIP"
                title="AMP Analytics Scripts"
                description="This .zip file contains example scripts in MATLAB, Python, and R to read data downloaded for the AMP Analytics tools."
                onClick={this.handleAnalyticsScriptClick}
              />
            </div>
          </IfUser>

          <ul style={{ listStyle: 'none', padding: 0, paddingTop: 16, paddingRight: 16 }}>
            <li>
              <Typography variant="subtitle1">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  title="AMP Utility Scripts Repository"
                  href="https://gitlab.vtti.vt.edu/public-projects/amp-scripts"
                >
                  Utility Scripts
                </a>
              </Typography>

              <Typography style={{ paddingLeft: 8 }}>
                This GitLab repository contains command line scripts for <a
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download Windows Script"
                  href="https://gitlab.vtti.vt.edu/public-projects/amp-scripts/raw/master/AMP_Unzip.batch">
                  Windows
              </a> and <a
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download Unix Script"
                  href="https://gitlab.vtti.vt.edu/public-projects/amp-scripts/raw/master/AMP_Unzip.sh">
                  Mac OS X/Linux
              </a> that help unzip and manage the files downloaded from the portal.
              </Typography>
            </li>
          </ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(UtilitiesSection)
