import React from 'react';
import { connect } from "react-redux";
import compose from "crocks/helpers/compose";
import { withStyles } from "@material-ui/core/";
import NewsList from './NewsList';
import LandingBanner from "./LandingBanner";
import { getNews } from "../../common/store";
import { fetchNewsFromServer } from "../../common/store/actions";
import { NewsType } from "../../common/models/news";

const filterAndSortByDate = type => news =>
  [...news]
    .filter(n => n.type === type)
    .sort((a, b) => b.startTime - a.startTime)
    .slice(0, 4);

const createMemberNewsList = filterAndSortByDate(NewsType.MEMBER_NEWS);
const createToolUpdateList = filterAndSortByDate(NewsType.TOOL_UPDATE);

const styles = {
  root: {
    minHeight: '0',
    display: 'grid',
    gridTemplateRows: '30vh 1fr',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem',
    justifyContent: 'space-between'
  },
};

class LandingContent extends React.PureComponent {
  static getDerivedStateFromProps(nextProps) {
    const memberNews = createMemberNewsList(nextProps.news);
    const toolUpdates = createToolUpdateList(nextProps.news);
    return { memberNews, toolUpdates };
  }

  state = {
    memberNews: [],
    toolUpdates: [],
  };

  componentDidMount() {
    this.props.fetchNewsFromServer();
  }

  render() {
    const { classes } = this.props;
    const { memberNews, toolUpdates } = this.state;

    return (
      <div className={classes.root}>
        <LandingBanner />
        <NewsList title="Member News" items={memberNews}/>
        <NewsList title="Data and Tool Updates" items={toolUpdates}/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  news: getNews(state),
});

const mapDispatchToProps = { fetchNewsFromServer };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(LandingContent);
