import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    zIndex: 1,
    position: 'absolute',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
  },
});

class Track extends React.PureComponent {
  static propTypes = {
    railSize: PropTypes.number,
    source: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }).isRequired,
    target: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
    getTrackProps: PropTypes.func.isRequired,
  };

  static defaultProps = {
    railSize: 14,
  };

  render() {
    const { railSize, source, target, getTrackProps, classes } = this.props;
    return (
      <div
        className={classes.root}
        style={{
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
          height: railSize,
          borderRadius: railSize / 2
        }}
        {...getTrackProps()}
      />
    );
  }
}

export default withStyles(styles)(Track);
