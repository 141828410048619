import { pipe } from "../util/func";

const isValid = passwordVal => passwordVal.length >= 12;
const needsPasswordHelperText = passwordVal => passwordVal.length > 0 && !isValid(passwordVal);
const passwordValidAndConfirmed = (passwordVal, confirmVal) => isValid(passwordVal) && passwordVal === confirmVal;

const passwordState = (value = "", helperText = "", error = false) => ({ value, helperText, error });

const createStateFromValues = (passwordVal, confirmVal) => constructState(
  passwordState(passwordVal),
  passwordState(confirmVal)
);

const addHelperText = state => {
  const { password } = state;
  const helperText = needsPasswordHelperText(password.value)
    ? 'Passwords must be at least 12 characters in length'
    : '';
  return constructState({ ...password, helperText }, state.confirm);
};

const validateConfirmation = state => {
  if (state.confirm.value.length === 0) {
    return state;
  }
  if (state.password.value.startsWith(state.confirm.value)) {
    return state;
  }
  const { value } = state.confirm;
  return constructState(state.password, passwordState(value, 'Passwords do not match', true));
};

const fireChange = (onChange, passwordVal, confirmVal) => {
  if (passwordValidAndConfirmed(passwordVal, confirmVal)) {
    onChange(passwordVal);
  } else {
    onChange('');
  }
};

const calcPasswordState = pipe(
  createStateFromValues,
  addHelperText,
  validateConfirmation
);

export const processPasswordChange = (onChange, passwordVal, confirmVal) => {
  const newState = calcPasswordState(passwordVal, confirmVal);
  fireChange(onChange, newState.password.value, newState.confirm.value);
  return newState;
};

export const constructState = (password = passwordState(), confirm = passwordState()) => ({ password, confirm });