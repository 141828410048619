import compose from "crocks/helpers/compose";
import { fetchUserProfile } from "./profile";
import { AppRequest } from "../../models";
import { createPostThunk } from "./utils";

export const doLoginWith = (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  return doLogin(formData);
};

export const doLogin = formData =>
  createPostThunk({
    url: '/login',
    body: formData,
    request: AppRequest.POST_LOGIN,
    responseFnFactory: dispatch => compose(dispatch, fetchUserProfile)
  });
