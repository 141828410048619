import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import safe from "crocks/Maybe/safe";
import { isNotEmpty } from "../../../../common/util/predicates";
import TagContainer from "../../common/components/TagContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const byLengthDescending = (a, b) => b[0].length - a[0].length;
const splitOnColon = tagName => tagName.split(':');

const createTagReducer = (maxCount, onClick, className, textClass) =>
  (els, tag, idx, src) => {
    if (els.length < maxCount - 1) {
      els.push(
        <TagContainer
          assigned
          key={tag}
          className={className}
          onClick={onClick}
          variant={tag.length > 1 ? 'shared' : 'private'}
        >
          <Typography color='inherit' variant="caption" className={textClass}>
            {tag[0]}
          </Typography>
        </TagContainer>
      );
    } else if (els.length === maxCount - 1) {
      els.push(
        <TagContainer
          assigned
          key={tag}
          className={className}
          onClick={onClick}
        >
          <Typography color='inherit' variant="caption">
            {`+${src.length - maxCount + 1} more`}
          </Typography>
        </TagContainer>
      );
    }
    return els;
  };

const createTags = (maxCount, onClick, className, textClass) => tags =>
  [...tags]
    .map(splitOnColon)
    .sort(byLengthDescending)
    .reduce(createTagReducer(maxCount, onClick, className, textClass), []);

const createAddTag = (onClick, className) => [
  <TagContainer key="add" className={className} onClick={onClick} assigned>
    <Typography color="inherit">+</Typography>
  </TagContainer>
];

const tagsToEls = (tagNames, maxCount, onClick, className, textClass) =>
  safe(isNotEmpty, tagNames)
    .map(createTags(maxCount, onClick, className, textClass))
    .option(createAddTag(onClick, className));

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      marginBottom: 8,
    }
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
  tagName: {
    maxWidth: '90px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
};

const ScenarioTagList = ({ tagNames, maxCount, onClick, className, classes }) => {
  return (
    <div className={classNames(classes.root, className)}>
      {tagsToEls(tagNames, maxCount, onClick, classes.tag, classes.tagName)}
    </div>
  );
};

ScenarioTagList.propTypes = {
  className: PropTypes.string,
  maxCount: PropTypes.number,
  tagNames: PropTypes.array,
  onClick: PropTypes.func,
};

ScenarioTagList.defaultProps = {
  maxCount: 4,
  tagNames: [],
};

export default withStyles(styles)(ScenarioTagList);
