import isArray from "crocks/predicates/isArray";
import { provideEmptyString, provideFalse, provideNull, provideZero } from "../util/providers";
import { createFactoryFunc } from "./factory";
import { isNull } from "../util/predicates";
import compose from "crocks/helpers/compose";
import { integerProp, stringProp } from "../util/object";
import option from "crocks/pointfree/option";

const hasReviewPending = cert => isNull(cert.reviewDate);

const certProps = [
  { name: "id", defaultProvider: provideZero },
  { name: "userId", defaultProvider: provideZero },
  { name: "reviewerEmail", defaultProvider: provideEmptyString },
  { name: "approved", defaultProvider: provideFalse },
  { name: "uploadDate", defaultProvider: provideNull },
  { name: "reviewDate", defaultProvider: provideNull },
  { name: "issueDate", defaultProvider: provideNull },
  { name: "contentType", defaultProvider: provideEmptyString },
];

/**
 * Create and return the canonical representation of an IRB certificate upload.
 *
 * createIrbCertificate :: Object -> IrbCertificate
 */
export const createIrbCertificate = createFactoryFunc(certProps);

/**
 * Returns those certificates that haven't been reviewed yet.
 *
 * filterByReviewPending :: [IrbCertificate] -> [IrbCertificate]
 */
export const filterByReviewPending = certs => isArray(certs) ? certs.filter(hasReviewPending) : [];

/**
 * certId :: IrbCertificate -> Number
 */
export const certId = compose(option(0), integerProp('id'));

/**
 * certContentType :: IrbCertificate -> String
 */
export const certContentType = compose(option(''), stringProp('contentType'));
