import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MembershipSelect from "./MembershipSelect";
import SubmitButton from "../../../common/components/SubmitButton";
import { Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppRequestState from "../../../common/models/AppRequestState";
import { useDispatch } from "react-redux";
import { SET_REQUEST_STATE, updateOrganization } from "../../../common/store/actions";
import { createOrganization } from "../../../common/models";

const bindValueTo = fn => ev => fn(ev.target.value);
const bindCheckedTo = fn => (ev, checked) => fn(checked);

const stateMsgInFlight = 'Updating...';
const stateMsgSuccess = 'Organization updated.';
const stateMsgFailure = 'Failed to update the organization.';

const useStyles = makeStyles(theme => ({
  root: {
    width: 600,
    maxWidth: '80vw',
    padding: theme.spacing(2),
    display: 'grid',
    gridRowGap: theme.spacing(1),
    justifyItems: 'start',
  },
  switch: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 0
  }
}));

const hasChanged = (org, orgName, orgMembershipId, orgEnabled) =>
  orgName === org.name && orgMembershipId === org.membershipId && org.enabled === orgEnabled;

function EditOrganizationForm({ org, memberships }) {
  const [orgName, setOrgName] = useState(org.name);
  const [orgEnabled, setOrgEnabled] = useState(org.enabled);
  const [orgMembershipId, setOrgMembershipId] = useState(org.membershipId);

  const [stateMsg, setStateMsg] = useState('');
  const [requestState, setRequestState] = useState(AppRequestState.NONE);

  const classes = useStyles();
  const dispatch = useDispatch();
  const disabled = hasChanged(org, orgName, orgMembershipId, orgEnabled);

  const handleSubmit = ev => {
    ev.preventDefault();
    setStateMsg(stateMsgInFlight);
    setRequestState(AppRequestState.IN_FLIGHT);

    const newOrg = createOrganization({
      ...org,
      name: orgName,
      enabled: orgEnabled,
      membershipId: orgMembershipId
    });

    dispatch(updateOrganization(newOrg))
      .then(result => {
        if (result.type === SET_REQUEST_STATE) {
          setRequestState(result.payload.requestState);
          setStateMsg(stateMsgFailure);
        } else {
          setRequestState(AppRequestState.SUCCESS);
          setStateMsg(stateMsgSuccess);
        }
      });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        fullWidth
        autoFocus
        required
        type="text"
        margin="normal"
        label="Organization Name"
        value={orgName}
        onChange={bindValueTo(setOrgName)}
      />
      <MembershipSelect
        value={orgMembershipId}
        memberships={memberships}
        onChange={bindValueTo(setOrgMembershipId)}
      />
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            color="primary"
            checked ={orgEnabled}
            onChange={bindCheckedTo(setOrgEnabled)}
          />
        }
        label="Enabled"
        labelPlacement="start"
      />
      <SubmitButton
        label="Update"
        stateMsg={stateMsg}
        requestState={requestState}
        disabled={disabled}/>
    </form>
  );
}

EditOrganizationForm.propTypes = {
  org: PropTypes.object,
  memberships: PropTypes.array,
};

export default EditOrganizationForm;
