import Color from 'color';
import constant from "crocks/combinators/constant";
import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideFalse, provideZero } from "../util/providers";

const chartColors = [
  "#00B2B5",
  "#D76A6A",
  "#FD2272",
  "#FF9500",
  "#AC81FF",
  "#2F7E17",
  "#A5DB00",
  "#A29200",
  "#3F7FFA",
  "#555555",
  "#A80AEA"
];

const lookupChartColor = index => chartColors[index % chartColors.length];
const chartVariableMapper = (variable, index) => ({ ...variable, baseColor: lookupChartColor(index) });
const provideInactiveColor = obj => Color(obj.baseColor).alpha(0.2).string();

const selectedChartVariableProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'title', defaultProvider: provideEmptyString },
  { name: 'type', defaultProvider: constant('chart') },
  { name: 'baseColor', defaultProvider: constant('#000') },
  { name: 'inactiveColor', defaultProvider: provideInactiveColor },
  { name: 'isActive', defaultProvider: provideFalse },
];

/**
 * Create a canonical entry for a chart variable that has been selected for display on the
 * scenario viewer chart.
 *
 * Object -> SelectedChartVariable
 */
export const createSelectedChartVariable = createFactoryFunc(selectedChartVariableProps);

export const createChartVariables = timeSeriesVariables =>
  timeSeriesVariables
    .filter(v => v.type === "chart")
    .map(chartVariableMapper);
