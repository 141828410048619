/**
 * Enumeration of the different types of video player layout modes:
 *
 * @type {{WIDE: string, NORMAL: string}}
 */
const PlayerMode = {
  NORMAL: 'NORMAL',
  WIDE_SCREEN: 'WIDE_SCREEN',
};

export const isNormal= mode => mode === PlayerMode.NORMAL;
export const isWideScreen = mode => mode === PlayerMode.WIDE_SCREEN;

export const togglePlayerMode = mode => isWideScreen(mode) ? PlayerMode.NORMAL : PlayerMode.WIDE_SCREEN;

export default PlayerMode;