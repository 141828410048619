import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import isNil from "crocks/predicates/isNil"
import isEmpty from "crocks/predicates/isEmpty"
import TagEditor from "./TagEditor"
import { createTag, getTagDisplayName } from "../../../../common/models/tag"
import {
  assignTagToScenarios,
  createTagOnServer,
  deleteTagFromServer,
  fetchTagsFromServer,
  removeTagFromScenarios,
  updateTagOnServer
} from "../../../../common/store/actions"
import {
  findTagByName,
  getAllTagNames,
  processAssignTagResponse,
  processCreateTagResponse,
  processDeleteTagResponse,
  processFetchTagsResponse,
  processRemoveTagResponse,
  processUpdateTagResponse,
} from "../tagUtils"
import { isNull } from "../../../../common/util/predicates"
import {
  assignTagToFilter,
  deleteSharedOrgsForTag,
  removeTagFromFilter,
  setSharedOrgsForTag
} from "../../../../common/store/actions/tag"

const SLOW_THRESHOLD = 1000

const isAssigned = (names, name) => names.indexOf(name) >= 0
const isPotentiallySlow = ({ filterCount, scenario }) => isNil(scenario) && filterCount > SLOW_THRESHOLD

const addSharedOrgsToTag = (sendAdditions, tagId, oldName, additions) => newState =>
isEmpty(additions)
  ? Promise.resolve(newState)
  : sendAdditions(tagId, additions)
    .then(processUpdateTagResponse(newState, oldName))

const removeSharedOrgsFromTag = (sendRemovals, tagId, oldName, removals) => newState =>
isEmpty(removals)
  ? Promise.resolve(newState)
  : sendRemovals(tagId, removals)
    .then(processUpdateTagResponse(newState, oldName))

class ConnectedTagEditor extends React.PureComponent {
  static propTypes = {
    // If scenario is null, this is assumed to be for all scenarios in the
    // current filter rather than an individual scenario.
    scenario: PropTypes.object,

    // The count of scenarios that will be tagged if we're tagging a filter (i.e. scenario is null).
    filterCount: PropTypes.number,

    // Dispatched actions from Redux connect fn
    fetchTagsFromServer: PropTypes.func.isRequired,
    createTagOnServer: PropTypes.func.isRequired,
    updateTagOnServer: PropTypes.func.isRequired,
    deleteTagFromServer: PropTypes.func.isRequired,
    assignTagToScenarios: PropTypes.func.isRequired,
    removeTagFromScenarios: PropTypes.func.isRequired,
    setSharedOrgsForTag: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.currentScenario !== nextProps.scenario) {
      return {
        currentScenario: nextProps.scenario,
        assignedTagNames: nextProps.scenario ? getAllTagNames(nextProps.scenario) : [],
      }
    }
    return null
  }

  state = {
    currentScenario: null,
    allTags: [],
    assignedTagNames: [],
    busy: true,
    busyMsg: '',
    errors: {},
  }

  componentDidMount() {
    this.setBusy("Loading...")
    this.props.fetchTagsFromServer()
      .then(processFetchTagsResponse)
      .then(newState => this.setState(newState))
  }

  setBusy = busyMsg => this.setState({ busy: true, busyMsg })

  handleCreate = name => {
    this.setBusy()
    this.props.createTagOnServer(createTag({ name }))
      .then(processCreateTagResponse(this.state))
      .then(newState => {
        this.setState(newState)
        this.handleToggleAssignment(findTagByName(newState.allTags, name))
      })
  }

  handleUpdate = (tagUpdate, oldDisplayName, sharedOrgAdditions, sharedOrgRemovals) => {
    this.setBusy()

    const { updateTagOnServer, deleteSharedOrgsForTag, setSharedOrgsForTag } = this.props
    const tagUpdateFn = tagUpdate.name === undefined
      ? () => Promise.resolve(this.state.allTags.find(t => t.id === tagUpdate.id))
      : updateTagOnServer

    console.log('updating', tagUpdate, oldDisplayName, this.state)
    tagUpdateFn(tagUpdate)
      .then(processUpdateTagResponse(this.state, oldDisplayName))
      .then(addSharedOrgsToTag(setSharedOrgsForTag, tagUpdate.id, oldDisplayName, sharedOrgAdditions))
      .then(removeSharedOrgsFromTag(deleteSharedOrgsForTag, tagUpdate.id, oldDisplayName, sharedOrgRemovals))
      .then(newState => console.log('newState', newState) || this.setState(newState))
  }

  handleDelete = id => {
    this.setBusy()
    this.props.deleteTagFromServer(id)
      .then(processDeleteTagResponse(this.state, id))
      .then(newState => this.setState(newState))
  }

  assignTag = tag => {
    this.props.assignTagToScenarios(tag.id, [this.props.scenario.id])
      .then(processAssignTagResponse(this.state))
      .then(newState => this.setState(newState))
  }

  removeTag = tag => {
    this.props.removeTagFromScenarios(tag.id, [this.props.scenario.id])
      .then(processRemoveTagResponse(this.state))
      .then(newState => this.setState(newState))
  }

  assignTagToFilter = tag => {
    this.props.assignTagToFilter(tag.id)
      .then(processAssignTagResponse(this.state))
      .then(newState => this.setState(newState))
  }

  removeTagFromFilter = tag => {
    this.props.removeTagFromFilter(tag.id)
      .then(processRemoveTagResponse(this.state))
      .then(newState => this.setState(newState))
  }

  handleToggleAssignment = tag => {
    const msg = isPotentiallySlow(this.props) ? 'This may take a few seconds...' : ''
    this.setBusy(msg)

    if (isNull(this.props.scenario)) {
      isAssigned(this.state.assignedTagNames, getTagDisplayName(tag).option(''))
        ? this.removeTagFromFilter(tag)
        : this.assignTagToFilter(tag)
    } else {
      isAssigned(this.state.assignedTagNames, getTagDisplayName(tag).option(''))
        ? this.removeTag(tag)
        : this.assignTag(tag)
    }
  }

  render() {
    const { allTags, assignedTagNames, errors, busy, busyMsg } = this.state
    return (
      <TagEditor
        busy={busy}
        busyMsg={busyMsg}
        allTags={allTags}
        assignedTagNames={assignedTagNames}
        errors={errors}
        onCreate={this.handleCreate}
        onUpdate={this.handleUpdate}
        onDelete={this.handleDelete}
        onToggleAssignment={this.handleToggleAssignment}
      />
    )
  }
}

const mapDispatchToProps = {
  fetchTagsFromServer,
  createTagOnServer,
  updateTagOnServer,
  deleteTagFromServer,
  assignTagToScenarios,
  removeTagFromScenarios,
  assignTagToFilter,
  removeTagFromFilter,
  setSharedOrgsForTag,
  deleteSharedOrgsForTag,
}

export default connect(null, mapDispatchToProps)(ConnectedTagEditor)
