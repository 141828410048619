import React from 'react'
import PropTypes from 'prop-types'
import Typography from "@material-ui/core/Typography/Typography"
import FileDownloadCard from "../../common/components/FileDownloadCard"
import identity from "crocks/combinators/identity"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import withStyles from "@material-ui/core/styles/withStyles"

const PORTAL_MODULE_VIDEO = 12
const SIM_READY_DATASET_MODULE_VIDEO = 13
const USER_APPLICATION_MODULE_1_VIDEO = 14
const USER_APPLICATION_MODULE_2_VIDEO = 15
const AMP_OVERVIEW_VIDEO = 16
const AMP_CUT_IN_VIDEO = 17

const styles = theme => ({
  root: {},
  subheading: {
    marginTop: theme.spacing(),
  },
  downloads: {
    '& > *': {
      margin: `${theme.spacing(2)}px 0`,
    }
  },
  list: {
    flexDirection: 'column',
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(2),
    }
  }
})

class VideosSection extends React.PureComponent {
  static propTypes = {
    onDownload: PropTypes.func,
  }

  static defaultProps = {
    user: {},
    onDownload: identity,
    onSteeringDownload: identity,
  }

  handleAmpOverviewVideoClick = () => this.props.onDownload(AMP_OVERVIEW_VIDEO)
  handlePortalModuleVideoClick = () => this.props.onDownload(PORTAL_MODULE_VIDEO)
  handleSimDataReadyModuleVideoClick = () => this.props.onDownload(SIM_READY_DATASET_MODULE_VIDEO)
  handleUserAppModule1VideoClick = () => this.props.onDownload(USER_APPLICATION_MODULE_1_VIDEO)
  handleUserAppModule2VideoClick = () => this.props.onDownload(USER_APPLICATION_MODULE_2_VIDEO)
  handleCutInRadarVideoClick = () => this.props.onDownload(AMP_CUT_IN_VIDEO)

  render() {
    const { classes } = this.props

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h5">Videos</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.list}>
          <Typography className={classes.subheading}>
            The downloadable videos in this section give overviews of the AMP website as well as
            tutorials on its many features.
          </Typography>

          <div className={classes.downloads}>
            <FileDownloadCard
              fileType=".MP4"
              title="AMP Overview Video"
              description="Provides an overview of the AMP program, including Period 3 enhancements, to support members' leadership discussions and to share internally with individuals or groups who may benefit from AMP and its portal."
              onClick={this.handleAmpOverviewVideoClick}
            />
            <FileDownloadCard
              fileType=".MP4"
              title="AMP Portal Module"
              description="Introduction to the AMP Portal Viewer and Browser includes information on the case types, conflict begin/point of interest, and urgency score"
              onClick={this.handlePortalModuleVideoClick}
            />
            <FileDownloadCard
              fileType=".MP4"
              title="AMP Simulation Ready Dataset Module"
              description="Introduction to the simulation-ready datasets including algorithm layer data, augmented layer, and an example demonstration of the IPG CarMaker import script"
              onClick={this.handleSimDataReadyModuleVideoClick}
            />
            <FileDownloadCard
              fileType=".MP4"
              title="AMP User Application Module 1"
              description="Provides an example of how streamed sensor data, from the AMP Portal, can be used to inform engineering and development work"
              onClick={this.handleUserAppModule1VideoClick}
            />
            <FileDownloadCard
              fileType=".MP4"
              title="AMP User Application Module 2"
              description="Provides an example of utilizing the AMP data within simulation for algorithm bench testing.  The video includes applying a built-in ACC algorithm, parameterizing the actor data using data from AMP Analytics tools, collecting data from the simulation environment, running the simulations, and analyzing the responses"
              onClick={this.handleUserAppModule2VideoClick}
            />
            <FileDownloadCard
              fileType=".MP4"
              title="AMP Cut-in and Radar Data Workshop"
              description="WP1 aimed to simplify the radar data to support further analysis and use (e.g., identified LV and POV and where one car had multiple Object IDs, joined them under most common Object ID).  This workshop covers topics such as: details on radar sensors used for naturalistic driving datasets within AMP, background and scope of WP1 cut-in work, cut-in radar curation and annotation work, as well as portal enhancements related to WP1."
              onClick={this.handleCutInRadarVideoClick}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(VideosSection)
