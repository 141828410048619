import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import Faq from "../../common/components/Faq";
import {isNotEmpty} from "../../common/util/predicates"

const dividerStyle = {
  height: 1,
  margin: '16px auto',
  background: `linear-gradient(0.25turn, #FFF, #DDD, #DDD, #FFF)`,
};

const createDivider = () => <div style={dividerStyle} />;

const createFaqList = faqs =>
  faqs
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(f => (
      <div key={f.id}>
        { createDivider() }
        <Faq question={f.question} answer={f.answer}/>
      </div>
    ));

const createSubHeading = (lpaEmail, className) => (
  <Typography className={className}>
    If you have additional questions that are not covered here, please contact
    your <a href={`mailto:${lpaEmail}?subject=AMP+Web+Site+Question`}>local portal administrator</a>.
  </Typography>
);

const styles = theme => ({
  root: {
    padding: theme.spacing(),
  },
  subheading: {
    textAlign: 'center',
    maxWidth: 500,
    margin: `${theme.spacing(2)}px auto`,
  }
});

class FaqSection extends React.PureComponent {
  static propTypes = {
    faqs: PropTypes.array,
    lpaEmail: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    faqs: [],
  };

  render() {
    const { faqs, lpaEmail, className, classes } = this.props;
    return (
      <div className={classNames(classes.root, className)}>
        <Typography align="center" variant="h5">Frequently Asked Questions</Typography>

        {isNotEmpty(lpaEmail) ? createSubHeading(lpaEmail, classes.subheading) : null}

        {createFaqList(faqs)}
        {createDivider()}
      </div>
    );
  }
}

export default withStyles(styles)(FaqSection);
