import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CloudDownload from '@material-ui/icons/CloudDownload';

const buildTooltip = layer => `Download the ${layer} data`;

const styles = {
  icon: {
    width: 36,
    height: 36,
    color: '#F7CBB0',
    cursor: 'pointer',
  },
};

class DownloadIcon extends React.PureComponent {
  static propTypes = {
    layer: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    layer: 'Unknown',
    color: '#888',
  };

  handleClick = ev => {
    ev.stopPropagation();
    this.props.onClick(this.props.layer);
  };

  render() {
    const { layer, color, classes } = this.props;
    return (
      <div title={buildTooltip(layer)} onClick={this.handleClick}>
        <CloudDownload className={classes.icon} style={{ color }}/>
      </div>
    );
  }
}

export default withStyles(styles)(DownloadIcon);
