import React, { useEffect } from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import identity from "crocks/combinators/identity"
import isEmpty from "crocks/predicates/isEmpty";
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import useOrganizations from "../../../../common/store/hooks/useOrganizations"
import makeStyles from "@material-ui/core/styles/makeStyles"

const createCheckBox = (org, checked, onChange) => (
  <FormControlLabel
    key={org.id}
    label={org.name}
    control={
      <Checkbox
        checked={checked}
        onChange={onChange}
        value={String(org.id)}
        color="primary"
      />
    }
  />
)

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: "column",
  }
})

export default function OrganizationCheckList({ selectedIds, onChange }) {
  const classes = useStyles()
  const { organizations, fetchOrganizations } = useOrganizations()

  useEffect(() => {
    if (isEmpty(organizations)) fetchOrganizations()
  })

  const handleChange = org => ev => onChange(org, ev.target.checked)

  return (
    <div className={classes.root}>
      { organizations.map(o => createCheckBox(o, selectedIds.has(o.id), handleChange(o)))}
    </div>
  )
}

OrganizationCheckList.propTypes = {
  organizations: PropTypes.array,
  selectedIds: PropTypes.object,
  onChange: PropTypes.func,
}

OrganizationCheckList.defaultProps = {
  organizations: [],
  selectedIds: new Set(),
  onChange: identity
}