import React from 'react';
import { connect } from "react-redux";
import { pipe } from "../../common/util/func";
import { withStyles } from "@material-ui/core/styles";
import AppRequestState from "../../common/models/AppRequestState";
import { resetRequestState, updateUserProfile } from "../../common/store/actions";
import ProfileUiContainer from "../../common/components/TitledFormContainer";
import ProfileInputFields, { errorCount } from "../../common/components/ProfileInputFields";
import SubmitButton from "../../common/components/SubmitButton";
import { stringProp } from "../../common/util/object";
import AppRequest from "../../common/models/AppRequest";

const hasChanged = (fields, profile) =>
  profile.firstName !== fields.firstName ||
  profile.lastName !== fields.lastName ||
  profile.phoneNumber !== fields.phoneNumber ||
  profile.optOutOfEmail !== fields.optOutOfEmail;

const canSubmit = (errors, user, profile) => errorCount(errors) === 0 && hasChanged(user, profile);

const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: "Updating...",
  [AppRequestState.FAILURE]: "An error occurred during the update, no changes were made.",
  [AppRequestState.SUCCESS]: "Profile updated!",
};

const styles = ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class ProfileUpdateContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      profile: { ...props.profile },
    };
  }

  handleProfileChange = (fieldName, value, error) => {
    const errors = { ...this.state.errors, [fieldName]: error };
    const profile = { ...this.state.profile, [fieldName]: value };
    this.setState({ errors, profile });

    const { profile: orgProfile, requestState, resetRequestState } = this.props;
    if (requestState !== AppRequestState.NONE && hasChanged(profile, orgProfile)) {
      resetRequestState(AppRequest.PUT_PROFILE);
    }
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.updateUserProfile(this.state.profile);
  };

  render() {
    const { errors, profile } = this.state;
    const { profile: orgProfile, classes, requestState } = this.props;

    const stateMsg = stringProp(requestState, stateMsgs).option('');
    const disableSubmit = !canSubmit(errors, profile, orgProfile);

    return (
      <ProfileUiContainer title='Profile Information' email={profile.email}>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <ProfileInputFields profile={profile} errors={errors} onProfileChange={this.handleProfileChange}/>
          <SubmitButton label="Update" requestState={requestState} stateMsg={stateMsg} disabled={disableSubmit}/>
        </form>
      </ProfileUiContainer>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  profile: app.profile,
  requestState: app.requests[AppRequest.PUT_PROFILE]
});

export default pipe(
  connect(mapStateToProps, { updateUserProfile, resetRequestState }),
  withStyles(styles)
)(ProfileUpdateContent);