import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Input, InputLabel, withStyles } from "@material-ui/core/";
import { constructState, processPasswordChange } from "./passwordLogic";
import { pipe } from "../util/func";

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: theme.spacing()
  },
});

/**
 * This component presents a pair of text inputs for entering a new password and confirming it
 * by entering it again.
 */
class PasswordConfirmPair extends React.Component {
  constructor(props) {
    super(props);
    this.state = constructState();
    this.processChange = pipe(
      processPasswordChange.bind(null, this.props.onChange),
      this.setState.bind(this)
    )
  }

  handlePasswordChange = (ev) => this.processChange(ev.target.value, this.state.confirm.value);
  handleConfirmChange = (ev) => this.processChange(this.state.password.value, ev.target.value);

  render() {
    const { classes, autoFocus, label } = this.props;
    const { password, confirm } = this.state;

    return (
      <div className={classes.container}>
        <FormControl required className={classes.formControl} error={password.error} aria-describedby="password-helper-text">
          <InputLabel htmlFor="password">{label}</InputLabel>
          <Input id="password" type="password" value={password.value} onChange={this.handlePasswordChange}
                 autoFocus={autoFocus} />
          <FormHelperText id="password-helper-text">{password.helperText}</FormHelperText>
        </FormControl>

        <FormControl required className={classes.formControl} error={confirm.error} aria-describedby="confirm-helper-text">
          <InputLabel htmlFor="confirm-password">Confirm {label}</InputLabel>
          <Input id="confirm-password" type="password" value={confirm.value} onChange={this.handleConfirmChange} />
          <FormHelperText id="confirm-helper-text">{confirm.helperText}</FormHelperText>
        </FormControl>
      </div>
    )
  }
}

PasswordConfirmPair.defaultProps = {
  label: 'Password'
};

PasswordConfirmPair.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(PasswordConfirmPair);
