import { createFactoryFunc } from "./factory"
import { provideEmptyString, provideZero } from "../util/providers"
import { isNotEmptyString, isNotNil } from "../util/predicates";

export const RowCategory = [
  'Collection Summary',
  'Frequencies and Rates',
  'Distributions'
]

export const CaseType = [
  'Crashes and Near-Crashes',
  'Epochs',
  'Baselines'
]

export interface AnalyticsApp {
  id: string;
  name: string;
  description: string;
  url: string;
  iconUrl: string;
  rowCategory: string;
  caseTypes: string;  // comma separated string of case types
  labels: string;
  sortOrder: number;
}

export type AnalyticsAppDto = Partial<AnalyticsApp>

const analyticsAppProps = [
  { name: 'id', defaultProvider: provideEmptyString },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'description', defaultProvider: provideEmptyString },
  { name: 'url', defaultProvider: provideEmptyString },
  { name: 'iconUrl', defaultProvider: provideEmptyString },
  { name: 'rowCategory', defaultProvider: provideEmptyString },
  { name: 'caseTypes', defaultProvider: provideEmptyString },
  { name: 'labels', defaultProvider: provideEmptyString },
  { name: 'sortOrder', defaultProvider: provideZero },
];

type AnalyticsFactoryFunc = (dto?: AnalyticsAppDto) => AnalyticsApp

const factoryFunc: (dto?: AnalyticsAppDto) => AnalyticsApp = createFactoryFunc(analyticsAppProps)

/**
 * Create a canonical AnalyticsApp object.  This object contains a description of a single
 * analytics app, including its name, description, and URL.
 */
export const createAnalyticsApp: AnalyticsFactoryFunc = (dto?: AnalyticsAppDto) => {
  const aa = factoryFunc(dto)

  if (isNotNil(aa.labels) && isNotEmptyString(aa.labels)) {
    const labelsObj = JSON.parse(aa.labels);
    aa.sortOrder = parseInt(labelsObj['sortorder'], 10)
  }

  return aa
}
