import React from 'react';
import PropTypes from 'prop-types';
import ExpressionGroup from "./ExpressionGroup";
import Expression from "./Expression";
import { isGroupNode } from "../../../../common/models";

const GroupChildren = ({ children, onApply, onDelete, onReplace }) => {
  return (
    <div className="condition-children">
      {
        children.map(c => isGroupNode(c)
          ? <ExpressionGroup key={c.uuid} groupOp={c} onApply={onApply} onDelete={onDelete} />
          : <Expression key={c.uuid} expression={c} onApply={onApply} onReplace={onReplace} onDelete={onDelete} />
        )
      }
    </div>
  );
};


GroupChildren.propTypes = {
  onApply: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

export default GroupChildren;