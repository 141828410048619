import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from 'react-compound-slider';
import Rail from './Rail';
import Track from './Track';
import Handle from './Handle';

const styles = {
  root: {
    width: '100%',
  },
  slider: {
    position: "relative",
    width: "100%",
  },
};

class AmpSlider extends React.PureComponent {
  static propTypes = {
    values: PropTypes.array,
    domain: PropTypes.array,
    step: PropTypes.number,
    mode: PropTypes.number,
    railSize: PropTypes.number,
    handleSize: PropTypes.number,
    onChange: PropTypes.func,
    onUpdate: PropTypes.func,
    onSlideStart: PropTypes.func,
    onSlideEnd: PropTypes.func,
  };

  static defaultProps = {
    values: [0],
    domain: [0, 100],
    step: 1,
    mode: 1,
    railSize: 4,
    handleSize: 21,
  };

  render() {
    const { railSize, handleSize, domain, classes } = this.props;

    return (
      <div className={classes.root} style={{ height: railSize }}>
        <Slider className={classes.slider} {...this.props}>
          <Slider.Rail>
            {({ getRailProps }) => <Rail railSize={railSize} getRailProps={getRailProps}/>}
          </Slider.Rail>
          <Slider.Handles>
            {({ handles, getHandleProps }) => (
              <div>
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    railSize={railSize}
                    handleSize={handleSize}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Slider.Handles>
          <Slider.Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    railSize={railSize}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Slider.Tracks>
        </Slider>
      </div>
    );
  }
}

export default withStyles(styles)(AmpSlider);
