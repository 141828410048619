/*
 * The functions in this file use expression builders to create expression tree nodes that will be
 * sent to the server in order to filter the scenarios according to the user's current UI selections.
 * Expression builders consume the `filterParams` object (see filterParams.js) and use the settings
 * it contains to create their expression tree nodes. The filterParams.js module provides helper
 * funcs to retrieve the filter settings from a filterParams object.
 *
 * Expression builders can be simple, as in the case of list variables, where there is a simple
 * one-to-one correspondence of one expression node for each list variable entry in the filterParams
 * object.
 *
 * A builder can also be more complicated as in the case of the text search where the resulting
 * expression tree nodes depends on the type of data contained in the `filterParams` -- it could
 * be a integer expression if the user types in a case ID or it could be a string search of the
 * finalNarrative variable (or both, using an OR group op as a root node).
 *
 * The point is that the `filterParams` and expression builder system provides a very flexible
 * means to turn user selections into expression tree nodes that can be processed by the server
 * in order to filter the scenarios in the database.
 */

import Maybe from "crocks/Maybe";
import prop from "crocks/Maybe/prop";
import safe from "crocks/Maybe/safe";
import { getTextSearch } from "./filterParams";
import { zip } from "../../../common/util/array";
import { tryCatch } from "../../../common/util/func";
import { GROUP_OPS } from "../../../common/models/expressionGroupOp";
import { listVariableIds, TEXT_SEARCH_LABEL, textSearchVariableIds } from "./constants";
import { isNotEmptyString, isNotNil, isNotNull } from "../../../common/util/predicates";
import {
  createQuickFilterBranch,
  createExpression,
  createGroupOp,
  createValueExpression,
  findNodeByLabel,
} from "../../../common/models";

const setNotEmpty = set => set.size > 0;

const listVarExpressionBuilder = variable => filterParams =>
  prop(variable.name, filterParams)
    .chain(safe(setNotEmpty))
    .map(selected => createExpression({ variable, label: variable.name, selected: Array.from(selected) }))
    .option(null);

const mapListIdsToBuilders = variableLookup =>
  listVariableIds.map(id => listVarExpressionBuilder(variableLookup[id]));

const apply = ([fn, ...args]) => fn.apply(null, args);
const removeNulls = vars => vars.filter(isNotNull);
const lookupVariableById = lookup => id => prop(id, lookup).option(null);
const idsToVariables = lookup => ids => ids.map(lookupVariableById(lookup));
const textSearchGroupOptions = { op: GROUP_OPS.OR, label: TEXT_SEARCH_LABEL };

const createNarrativeExpression = data => variable =>
  createValueExpression({ variable, op: 'cn', data });

const createCaseIdExpression = data => variable =>
  safe(isNotNil, parseInt(data, 10))
    .map(data => createValueExpression({ variable, op: 'eq', data }))
    .option(null);

const expressionNodeCreators = data => [
  createCaseIdExpression(data),
  createNarrativeExpression(data),
];

const createTextSearchChildNodes = (vars, textSearchStr) =>
  zip(expressionNodeCreators(textSearchStr), vars)
    .map(apply)
    .filter(isNotNull);

/**
 * buildExpressionNodesForTextSearch :: [FilterVariable] -> String -> GroupOp
 */
const buildExpressionNodesForTextSearch = vars => textSearchStr =>
  createGroupOp({
    ...textSearchGroupOptions,
    children: createTextSearchChildNodes(vars, textSearchStr)
  });

/**
 * Given the variableLookup object, creates and returns a builder function that can take the
 * current filterParams and create expression tree nodes that represent a text search of the
 * final narrative and case ID fields.
 */
export const createTextSearchExpressionBuilder = variableLookup => filterParams =>
  Maybe.Just(textSearchVariableIds)
    .map(idsToVariables(variableLookup))
    .map(removeNulls)
    .chain(vars =>
      safe(isNotEmptyString, getTextSearch(filterParams))
        .map(buildExpressionNodesForTextSearch(vars))
    )
    .option(null);

/**
 * textSearchExpressionExtractor :: GroupOp -> String
 */
export const textSearchExpressionExtractor = galleryBranch =>
  safe(isNotNil, findNodeByLabel(galleryBranch, TEXT_SEARCH_LABEL))
    .chain(node => tryCatch(() => node.children[0].data))
    .option('');

/**
 * Create an array of builders, each of which is capable of taking a filterParams object and
 * producing one or more expression tree nodes.
 */
export const createSearchExpressionBuilders = variableLookup =>
  mapListIdsToBuilders(variableLookup)
    .concat(createTextSearchExpressionBuilder(variableLookup));

/**
 * Rebuild and return a new gallery branch given the current filterParams and the list of
 * expression builders.
 */
export const buildSearchExpressions = (filterParams, builders) =>
  createQuickFilterBranch({
    children: builders
      .map(b => b(filterParams))
      .filter(isNotNull)
  });
