import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from "@material-ui/core/Snackbar";
import identity from "crocks/combinators/identity";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    paddingTop: theme.spacing()
  }
});

function DownloadInProgressSnackbar({ open, onClose, classes }) {
  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={3000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">Your download will begin shortly...</span>}
    />
  );
}

DownloadInProgressSnackbar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DownloadInProgressSnackbar.defaultProps = {
  open: false,
  onClose: identity,
};

export default withStyles(styles)(DownloadInProgressSnackbar);
