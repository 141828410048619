import React from 'react';
import { Route, Switch } from "react-router-dom";
import MembersList from "./MembersList";
import MemberDetails from "./MemberDetails";

const MembersContent = () => (
  <Switch>
    <Route path="/members/detail/:orgId" component={MemberDetails}/>
    <Route path="/members" component={MembersList}/>
  </Switch>
);

export default MembersContent;
