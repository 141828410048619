import React from 'react';

function InteriorObjectIcon() {
  return (
    <svg width="110px" height="75px" viewBox="0 0 664 449" version="1.1">
      <defs>
        <radialGradient cx="22.358544%" cy="29.45339%" fx="22.358544%" fy="29.45339%" r="78.3516934%" id="radialGradient-1">
          <stop stopColor="#DDDDDD" offset="0%"/>
          <stop stopColor="#464646" offset="100%"/>
        </radialGradient>
      </defs>
      <g id="Triangle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-4" transform="translate(1.000000, 0.000000)">
          <g id="Group-3" stroke="#040404">
            <polygon id="Triangle" fill="#CCCCCC" points="170.949306 0.89895583 235.945844 430.992315 -2.84217094e-14 373"/>
            <polygon id="Triangle" fill="#696969" points="173.801386 0.89895583 390.578888 313.450511 239.007199 430.992315"/>
          </g>
          <circle id="Oval" fill="url(#radialGradient-1)" cx="465" cy="251" r="198"/>
        </g>
      </g>
    </svg>
  );
}

export default InteriorObjectIcon;
