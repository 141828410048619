import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import ListResult from "./ListResult";

const listResultMapper = (onClick, onLayerClick) => s =>
  <ListResult key={s.id} scenario={s} onClick={onClick} onLayerClick={onLayerClick}/>;

const styles = {
  root: {
    display: 'grid',
    justifyItems: 'start',
    gridRowGap: '32px',
  },
};

class ListResultsView extends React.PureComponent {
  static propTypes = {
    scenarios: PropTypes.array,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onLayerClick: PropTypes.func,
  };

  static defaultProps = {
    scenarios: [],
  };

  render() {
    const { scenarios, onClick, onLayerClick, className, classes } = this.props;
    return (
      <div className={classNames(classes.root, className)}>
        {scenarios.map(listResultMapper(onClick, onLayerClick))}
      </div>
    );
  }
}

export default withStyles(styles)(ListResultsView);
