import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideZero } from "../util/providers";

const lpaProps = [
  { name: 'email', defaultProvider: provideEmptyString },
  { name: 'firstName', defaultProvider: provideEmptyString },
  { name: 'lastName', defaultProvider: provideEmptyString },
  { name: 'orgName', defaultProvider: provideEmptyString },
  { name: 'orgId', srcName: 'orgID', defaultProvider: provideZero },
  { name: 'phoneNumber', defaultProvider: provideEmptyString },
  { name: 'rank', defaultProvider: provideEmptyString },
];

/**
 * Create a canonical representation of an Local Portal Admin user.
 *
 * createLocalPortalAdmin :: Object -> LocalPortalAdmin
 */
export const createLocalPortalAdmin = createFactoryFunc(lpaProps);
