import React from 'react';
import PropTypes from 'prop-types';
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    maxWidth: 500,
    padding: theme.spacing(),
    border: '2px solid #AAA',
    boxShadow: '0px 2px 4px #CCC',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    display: 'grid',
    gridTemplateColumns: '50px auto 4em',
    gridTemplateRows: '40px auto',
    gridColumnGap: '8px',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: '#CCC',
      position: 'relative',
      top: '2px',
      left: '2px',
    }
  },
  icon: {
    '& > *': {
      color: theme.palette.secondary.dark,
      width: 48,
      height: 48,
    },
  },
  fileType: {
    color: '#EEE',
    backgroundColor: theme.palette.secondary.dark,
    // gridRow: '2 / 3',
  },
  description: {
    gridColumn: '2 / 4',
  }
});

class Report extends React.PureComponent {
  static propTypes = {
    url: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
  };

  handleClick = () => this.props.onClick(this.props.orgId);

  render() {
    const { icon, url, title, description, classes } = this.props;
    return (
      <a className={classes.root} href={url} download>
        <div className={classes.icon}>{icon}</div>

        <Typography variant="h5" color="textSecondary">{title}</Typography>
        <Chip className={classes.fileType} label=".CSV"/>

        <Typography className={classes.description} variant="caption" color="textSecondary">
          {description}
        </Typography>
      </a>
    );
  }
}

export default withStyles(styles)(Report);
