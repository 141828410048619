import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import identity from "crocks/combinators/identity"
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from "@material-ui/core/Typography/Typography"
import FileDownloadCard from "../../common/components/FileDownloadCard"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"

const VIDEO_DD = 1
const SUPP_DD = 2
const TIME_DD = 3
const TIMESTAMPS_ILLUSTRATION = 6
const DATA_LOG = 8
const DATA_USE = 10
const ANALYTICS_LOG = 18

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  subheading: {
    marginTop: theme.spacing(),
  },
  downloads: {
    '& > *': {
      margin: `${theme.spacing(2)}px 0`,
    }
  },
  list: {
    flexDirection: 'column',
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(2),
    }
  }
})

class DocsSection extends React.PureComponent {
  static propTypes = {
    onDownload: PropTypes.func,
  }

  static defaultProps = {
    onDownload: identity,
  }

  handleDataUseClick = () => this.props.onDownload(DATA_USE)
  handleVideoClick = () => this.props.onDownload(VIDEO_DD)
  handleSupplementalClick = () => this.props.onDownload(SUPP_DD)
  handleTimeSeriesClick = () => this.props.onDownload(TIME_DD)
  handleTimestampIllustrationClick = () => this.props.onDownload(TIMESTAMPS_ILLUSTRATION)
  handleDataLogClick = () => this.props.onDownload(DATA_LOG)
  handleAnalyticsLogClick = () => this.props.onDownload(ANALYTICS_LOG)

  render() {
    const { classes } = this.props
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Documentation</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.list}>
          <Typography>
            All AMP data are defined in the following data dictionaries. Use of these
            data and the data dictionaries are governed by the <Link to='/profile/dul'>Data Use License</Link>.
          </Typography>

          <Typography className={classes.subheading}>
            Frequently asked data use questions are explained in the below AMP Data Use in Brief document.
          </Typography>

          <div className={classes.downloads}>
            <FileDownloadCard
              fileType=".PDF"
              title="AMP Data Release and Update Log"
              description="History of data released on the AMP Portal"
              onClick={this.handleDataLogClick}
            />

            <FileDownloadCard
              fileType=".PDF"
              title="AMP Analytics Log"
              description="History of data released in the Analytics section of the AMP Portal"
              onClick={this.handleAnalyticsLogClick}
            />

            <FileDownloadCard
              fileType=".PDF"
              title="AMP Data Use in Brief"
              description="Frequently asked questions about AMP data usage"
              onClick={this.handleDataUseClick}
            />

            <FileDownloadCard
              fileType=".PDF"
              title="Supplemental Dictionary"
              description="The supplemental data reduction dictionary"
              onClick={this.handleSupplementalClick}
            />

            <FileDownloadCard
              fileType=".PDF"
              title="Time Series Dictionary"
              description="Algorithm and Augmented Layer Time Series Dictionary"
              onClick={this.handleTimeSeriesClick}
            />

            <FileDownloadCard
              fileType=".PDF"
              title="Timestamps Illustration"
              description="Illustrates the various timestamp markers used in AMP data"
              onClick={this.handleTimestampIllustrationClick}
            />

            <FileDownloadCard
              fileType=".PDF"
              title="Video Dictionary"
              description="The video reduction data dictionary"
              onClick={this.handleVideoClick}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(DocsSection)

/*
      <div className={classes.root}>

        <Typography variant="h5">Documentation</Typography>

        <Typography className={classes.subheading}>
          All AMP data are defined in the following data dictionaries. Use of these
          data and the data dictionaries are governed by the <Link to='/profile/dul'>Data Use License</Link>.
        </Typography>

        <Typography className={classes.subheading}>
          Frequently asked data use questions are explained in the below AMP Data Use in Brief document.
        </Typography>

        <div className={classes.downloads}>
          <FileDownloadCard
            fileType=".PDF"
            title="AMP Data Use in Brief"
            description="Frequently asked questions about AMP data usage"
            onClick={this.handleDataUseClick}
          />

          <FileDownloadCard
            fileType=".PDF"
            title="Video Dictionary"
            description="The video reduction data dictionary"
            onClick={this.handleVideoClick}
          />

          <FileDownloadCard
            fileType=".PDF"
            title="Supplemental Dictionary"
            description="The supplemental data reduction dictionary"
            onClick={this.handleSupplementalClick}
          />

          <FileDownloadCard
            fileType=".PDF"
            title="Time Series Dictionary"
            description="Algorithm and Augmented Layer Time Series Dictionary"
            onClick={this.handleTimeSeriesClick}
          />

          <FileDownloadCard
            fileType=".PDF"
            title="Timestamps Illustration"
            description="Illustrates the various timestamp markers used in AMP data"
            onClick={this.handleTimestampIllustrationClick}
          />

          <FileDownloadCard
            fileType=".PDF"
            title="AMP Data Release and Update Log"
            description="History of data released on the AMP Portal"
            onClick={this.handleDataLogClick}
          />
        </div>
      </div>

 */