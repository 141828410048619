import './ExpressionBuilder.css';

import React from 'react';
import PropTypes from 'prop-types';
import ExpressionGroup from "./ExpressionGroup";
import { withStyles } from "@material-ui/core/";
import { partial } from "../../../../common/util/func";
import { createAdvancedFilterBranch } from "../../../../common/models/ampExpressionTree";
import { createAmpExpressionTree, getAdvancedFilterBranch, getRoot, replaceChildInGroup } from "../../../../common/models";

const styles = {
  root: {
    width: '100%',
    border: '#ddd 1px solid',
    borderRadius: 6,
    overflow: 'scroll',
    position: 'relative',
    paddingLeft: '10px',
  },
  scroller: {
    userSelect: 'none',
  }
};

class ExpressionBuilder extends React.PureComponent {
  static propTypes = {
    expressionTree: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    expressionTree: createAmpExpressionTree(),
    onChange: () => undefined,
  };

  // We need special handling to delete the root of the filter branch from the root of expression tree
  handleDelete = () => {
    const expressionTree = this.props.expressionTree;
    const child = getAdvancedFilterBranch(expressionTree);
    const newChild = createAdvancedFilterBranch();
    this.props.onChange(partial(replaceChildInGroup, child, newChild), getRoot(expressionTree));
  };

  render() {
    const { expressionTree, onChange, classes } = this.props;
    const filterBranch = getAdvancedFilterBranch(expressionTree);

    return (
      <div className={classes.root}>
        <div className={classes.scroller}>
          {filterBranch &&
            <ExpressionGroup
              groupOp={filterBranch}
              onApply={onChange}
              onDelete={this.handleDelete}
            />
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ExpressionBuilder);
