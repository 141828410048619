import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { pipe } from "../../common/util/func";

import { Paper, withStyles } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import LoginForm from "./LoginForm";
import ForgottenPasswordDialog from "./ForgottenPasswordDialog";
import loginBackground from '../../common/assets/grey-background.png';
import { sendRecoveryEmail } from "../../common/store/actions";

const sideColor = `rgba(255, 255, 255, .2)`;
const midColor = `rgba(0, 0, 0, 0)`;
const overlayGrad = `linear-gradient(to right, ${sideColor}, ${midColor} 20%, ${midColor} 80%, ${sideColor})`;

const styles = theme => ({
  root: {
    height: '100%',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `${overlayGrad}, url(${loginBackground})`,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '5%',
  },
  container: {
    maxWidth: '600px',
    margin: '2%',
    padding: '2%',
    borderRadius: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.90)'
  },
  welcome: {
    textAlign: 'center',
    marginBottom: theme.spacing(),
  }
});

class LoginDialog extends React.PureComponent {
  state = { dlgOpen: false };

  handleDialogOpen = () => this.setState({ dlgOpen: true });
  handleDialogClosed = () => this.setState({ dlgOpen: false });

  handleForgottenPasswordSubmit = email => {
    this.handleDialogClosed();
    this.props.sendRecoveryEmail(email);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.container} elevation={8}>
          <Typography className={classes.welcome} variant="h4">Sign In</Typography>
          <LoginForm onForgotPassword={this.handleDialogOpen} />
        </Paper>
        <ForgottenPasswordDialog
          open={this.state.dlgOpen}
          onClose={this.handleDialogClosed}
          onSubmit={this.handleForgottenPasswordSubmit}/>
      </div>
    );
  }
}

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default pipe(
  connect(null, { sendRecoveryEmail }),
  withStyles(styles)
)(LoginDialog);