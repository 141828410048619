import React from 'react'
import { connect } from "react-redux"
import AppStatus from "../common/models/AppStatus"

import LoginPage from "./LoginPage"
import HomePageContainer from "./HomePageContainer"
import { getAppStatus, getUserProfile } from "../common/store"

const HomePage = props => {
  switch (props.status) {
    case AppStatus.APP_LOADING:
      return <div>Loading...</div>
    case AppStatus.APP_LOGGED_IN:
      return <HomePageContainer profile={props.profile} pathname={props.location.pathname}/>
    default:
      return <LoginPage/>
  }
}

const mapStateToProps = state => ({
  status: getAppStatus(state),
  profile: getUserProfile(state),
})

export default connect(mapStateToProps)(HomePage)