import React from 'react';

const CellularIcon = () => (
  <svg width="46px" height="75px" viewBox="0 0 106 173" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Special-Cases" transform="translate(-669.000000, -72.000000)">
        <g id="Cellular-Phone" transform="translate(520.000000, 34.000000)">
          <g id="Cell-Icon" transform="translate(149.000000, 38.000000)">
            <rect id="Rectangle" stroke="#FFFFFF" strokeWidth="2" x="1" y="1" width="104" height="171" rx="8"/>
            <rect id="Rectangle-Copy" stroke="#FFFFFF" strokeWidth="2" x="8" y="6" width="91" height="153" rx="8"/>
            <rect id="Rectangle" stroke="#FFFFFF" x="38.5" y="161.5" width="30" height="7" rx="3.5"/>
            <g id="Group" transform="translate(21.000000, 32.000000)" fill="#FFFFFF">
              <rect id="Rectangle" x="0" y="80" width="9.92248062" height="20.028169"/>
              <rect id="Rectangle-Copy-2" x="13" y="60" width="9.92248062" height="39.9014085"/>
              <rect id="Rectangle-Copy-3" x="27" y="43" width="9.92248062" height="56.7887324"/>
              <rect id="Rectangle-Copy-4" x="41" y="21" width="9.92248062" height="79.1549296"/>
              <rect id="Rectangle-Copy-5" x="55" y="0" width="9.92248062" height="100"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CellularIcon;
