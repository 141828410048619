import isNil from 'crocks/predicates/isNil';
import { getEpochTimeStamps, offsetSeconds } from "../../common/timeSeries";
import { isNotNil } from "../../../../common/util/predicates";

export const buildChartOptions = (maxTicks, selections = []) => ({
  responsive: true,
  maintainAspectRatio: false,
  responsiveAnimationDuration: 0,
  scales: {
    xAxes: [{
      ticks: {
        maxTicksLimit: maxTicks,
      }
    }],
    yAxes: selections.map(v => ({
      display: v.isActive,
      id: v.name,
    }))
  },
  legend: {
    display: false,
  },
});

/**
 * createXAxisLabels :: TimeSeriesCacheEntry -> [Number]
 */
export const createXAxisLabels = timeSeries =>
  getEpochTimeStamps(timeSeries)
    .map(timeStamps => timeStamps.map(offsetSeconds(timeStamps[0])))
    .option([0]);

/**
 * Build a data set object with the standard settings and the provided params.
 */
export const buildDataSet = (variable, data = []) => ({
  label: variable.title,
  yAxisID: variable.name,
  data: data.map(x => x === null ? 0 : x),
  fill: false,
  lineTension: 0.1,
  borderColor: variable.isActive ? variable.baseColor : variable.inactiveColor,
  pointBorderWidth: 0,
  pointHoverRadius: 5,
  pointRadius: 0,
  pointHitRadius: 10,
});

/**
 * Construct chart data with one data set for each variable.
 */
export function buildChartData(selections = [], timeSeries, labels) {
  const datasets =
    selections.map(v => {
      const data = timeSeries[v.name];
      return buildDataSet(v, data);
    });

  return { labels, datasets };
}

/**
 * Calculate the time of the animation that interpolates between successive time values from the
 * video player.  This interpolated animation keeps the chart marker animating more smoothly.
 *
 * We return a value for the duration of the animation that is 90% of the actual time delta
 * (in milliseconds).  This ensures that we will be within a few tens of milliseconds of
 * the current time when we get our next time.  However, if the delta is larger than 200ms,
 * we simply return a duration of -1 allowing the animation to catch up to the current time
 * in one step when the user seeks forward or backward in the video.
 */
export const calcTimeAnimDuration = (nextProps, props) => {
  const deltaT = nextProps.playbackSeconds - props.playbackSeconds;

  if (isNil(deltaT)) {
    return -1;
  }

  return deltaT < 0.2 ? deltaT * 900 : -1;
};

const pickYAxis = scales => Object.values(scales).find(s => s.id !== 'x-axis-0') || { top: 0, bottom: 0 };

function calcXVals(xAxis, currX, lastPercentX, easing) {
  const { left, right, min, max } = xAxis;

  if (min === undefined || max === undefined) {
    return { x: 0, percentX: 0 };
  }

  const percentX = (currX - min) / (max - min);

  if (isNaN(percentX)) {
    return { x: 0, percentX: 0 };
  }

  const x = left + (right - left) * (percentX + (lastPercentX - percentX) * (1 - easing));
  return { x, percentX };
}

function drawLabel(ctx, x, y, text, color) {
  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(-Math.PI / 2);
  ctx.textAlign = "start";
  ctx.textBaseline = "top";
  ctx.fillStyle = color;
  ctx.fillText(text, 0, 0);
  ctx.restore();
}

function createRectDrawer(ctx, xAxis, yAxis, easing) {
  return (currX1, currX2, lastPercentX1, lastPercentX2, color) => {
    const { top, bottom } = yAxis;
    const xVals1 = calcXVals(xAxis, currX1, lastPercentX1, easing);
    const xVals2 = calcXVals(xAxis, currX2, lastPercentX2, easing);

    ctx.fillStyle = color;
    ctx.fillRect(xVals1.x, top, xVals2.x - xVals1.x, bottom - top);

    return easing === 1
      ? { start: xVals1.percentX, end: xVals2.percentX }
      : { start: lastPercentX1, end: lastPercentX2 };
  }
}

function createVerticalLineDrawer(ctx, xAxis, yAxis, easing) {
  return (currX, lastPercentX, color, label) => {
    if (isNil(currX) || currX < 0) {
      return 1;
    }

    const { top, bottom } = yAxis;
    const { x, percentX } = calcXVals(xAxis, currX, lastPercentX, easing);

    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(x, top);
    ctx.lineTo(x, bottom);
    ctx.stroke();

    if (isNotNil(label) && currX > 0) {
      drawLabel(ctx, x, bottom, label, color);
    }

    return easing === 1 ? percentX : lastPercentX;
  }
}

export const createAnnotationPlugin = component => ({
  id: 'AmpAnnotations',
  lastCuePercent: 1,
  lastRefPercent: 1,
  lastCurrPercent: 1,
  lastToiPercent: 1,
  lastAnnotationPercent: { start: 1, end: 1 },

  beforeDraw: function (chart, easing) {
    const ctx = chart.ctx;
    const xAxis = chart.scales['x-axis-0'];
    const yAxis = pickYAxis(chart.scales);

    const { start, end } = this.lastAnnotationPercent;
    const drawRectOnChart = createRectDrawer(ctx, xAxis, yAxis, easing);
    const { annotationStartSecs, annotationEndSecs } = component.state;

    this.lastAnnotationPercent = drawRectOnChart(annotationStartSecs, annotationEndSecs, start, end, "#EEE");
  },

  afterDraw: function (chart, easing) {
    const ctx = chart.ctx;
    const xAxis = chart.scales['x-axis-0'];
    const yAxis = pickYAxis(chart.scales);

    const drawVerticalLineOnChart = createVerticalLineDrawer(ctx, xAxis, yAxis, easing);

    const { playbackSeconds } = component.props;
    const { cueTimeSecs, refTimeSecs, timeOfInterestSecs } = component.state;

    this.lastCurrPercent = drawVerticalLineOnChart(playbackSeconds, this.lastCurrPercent, 'blue');

    this.lastCuePercent = drawVerticalLineOnChart(cueTimeSecs, this.lastCuePercent, 'green', 'Cue');
    this.lastToiPercent = drawVerticalLineOnChart(timeOfInterestSecs, this.lastToiPercent, 'red', 'POI');
    this.lastRefPercent = drawVerticalLineOnChart(refTimeSecs, this.lastRefPercent, 'magenta', 'ConBeg');
  }
});