import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const styles = {
  titleContainer: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    flexGrow: 1,
  },
  title: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  subtitle: {
    color: '#888',
  },
};

class CardTitle extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  render() {
    const { title, subtitle, classes } = this.props;
    return (
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h4">{title}</Typography>
        <Typography className={classes.subtitle} variant="subtitle1">{subtitle}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(CardTitle);
