import PropTypes from 'prop-types';

/**
 * Component that can determine the visibility of its children based on the expression given.
 */
const If = ({ expression, children }) => Boolean(expression) ? children : null;

If.propTypes = {
  expression: PropTypes.any.isRequired,
  children: PropTypes.node,
};

export default If;