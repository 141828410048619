import React from 'react';
import PropTypes from 'prop-types';
import compose from "crocks/helpers/compose";
import isEmpty from "crocks/predicates/isEmpty";
import isDefined from "crocks/predicates/isDefined";
import identity from "crocks/combinators/identity";
import Button from "@material-ui/core/Button";
import withStyles from '@material-ui/core/styles/withStyles';
import TagEditorEntryList from "./TagEditorEntryList";
import { isBlank } from "../../../../common/util/predicates";
import CircularProgress from "@material-ui/core/CircularProgress";
import TagNameTextField from "./TagNameTextField";
import { maxTagLength } from "../../../../common/models/tag";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

const arrayToSet = arr => new Set(arr);
const tagNamesToSet = compose(arrayToSet, tags => tags.map(t => t.name));

const createEmptyMessage = (errors, busy, filterValue) => {
  if (isDefined(errors.allTags)) {
    return errors.allTags;
  }

  if (busy) {
    return '';
  }

  if (filterValue.length === 0) {
    return 'No matching tags. Click the Create button to add a new tag.';
  }

  return '';
};

const filterTags = (allTags, filterText) => {
  if (isEmpty(filterText)) {
    return allTags;
  }

  const regexp = new RegExp(`^${filterText}`);
  return allTags.filter(t => t.name.match(regexp) !== null);
};

const styles = {
  root: {
    width: '20em',
    height: '50vh',
    display: 'grid',
    gridRowGap: '16px',
    gridTemplateRows: 'auto 1fr',
  },
  entries: {
    gridColumn: '1 / -1',
    minHeight: 300,
    maxHeight: 600,
    fontSize: 30,
  },
  form: {
    display: 'grid',
    gridColumnGap: '8px',
    gridTemplateColumns: '1fr auto',
  },
  createButton: {
    justifySelf: 'center',
    alignSelf: 'center',
  },
  confirm: {},
  busyOverlay: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  busyMsg: {
    marginTop: 16,
  }
};

class TagEditor extends React.PureComponent {
  static propTypes = {
    allTags: PropTypes.array,
    assignedTagNames: PropTypes.array,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onToggleAssignment: PropTypes.func,
    busy: PropTypes.bool,
    busyMsg: PropTypes.string,
    errors: PropTypes.object,
  };

  static defaultProps = {
    busy: false,
    busyMsg: '',
    allTags: [],
    assignedTagNames: [],
    errors: {},
    onCreate: identity,
    onUpdate: identity,
    onDelete: identity,
  };

  static getDerivedStateFromProps(nextProps, state) {
    return {
      filteredTags: filterTags(nextProps.allTags, state.filterValue),
      allTagNamesSet: tagNamesToSet(nextProps.allTags),
      assignedNameSet: arrayToSet(nextProps.assignedTagNames),
    };
  }

  state = {
    allTagNamesSet: null,
    assignedNameSet: null,
    filterValue: '',
    confirmDeleteId: 0,
    filteredTags: [],
  };

  stopPropagationIfBusy = ev => this.props.busy ? ev.stopPropagation() : undefined;

  handleSubmit = ev => ev.preventDefault();

  handleCreate = () => {
    this.props.onCreate(this.state.filterValue);
    this.setState({ filterValue: '', filteredTags: this.props.allTags })
  };

  handleFilterValueChange = ev => {
    const filterValue = ev.target.value;
    if (isDefined(filterValue) && filterValue.length <= maxTagLength) {
      const filteredTags = filterTags(this.props.allTags, filterValue);
      this.setState({ filterValue, filteredTags });
    }
  };

  handleShowConfirmDelete = confirmDeleteId => this.setState({ confirmDeleteId });

  handleConfirmCancel = () => this.setState({ confirmDeleteId: 0 });
  handleConfirmDelete = () => {
    this.props.onDelete(this.state.confirmDeleteId);
    this.setState({ confirmDeleteId: 0 });
  };

  render() {
    const { onUpdate, onToggleAssignment, busy, busyMsg, errors, classes } = this.props;
    const { filteredTags, allTagNamesSet, assignedNameSet, filterValue, confirmDeleteId } = this.state;

    const buttonDisabled = isBlank(filterValue) || allTagNamesSet.has(filterValue);

    return (
      <div className={classes.root} aria-disabled={true}>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <TagNameTextField
            value={filterValue}
            label="Tag Name"
            onChange={this.handleFilterValueChange}
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.createButton}
            onClick={this.handleCreate}
            disabled={buttonDisabled}
          >
            Create
          </Button>
        </form>

        <TagEditorEntryList
          tags={filteredTags}
          className={classes.entries}
          confirmId={confirmDeleteId}
          assignedNameSet={assignedNameSet}
          emptyMessage={createEmptyMessage(errors, busy, filterValue)}
          onUpdate={onUpdate}
          onToggleAssignment={onToggleAssignment}
          onConfirmCancel={this.handleConfirmCancel}
          onConfirmDelete={this.handleConfirmDelete}
          onShowDeleteConfirmation={this.handleShowConfirmDelete}
        />

        {busy &&
        <Fade in={busy} timeout={{ enter: 500, exit: 500 }} style={{ transitionDelay: '200ms' }}>
          <div className={classes.busyOverlay}>
            <CircularProgress variant="indeterminate"/>
            {busyMsg && <Typography className={classes.busyMsg}>{busyMsg}</Typography>}
          </div>
        </Fade>
        }
      </div>
    );
  }
}

export default withStyles(styles)(TagEditor);
