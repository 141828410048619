import React from 'react';
import PropTypes from 'prop-types';
import compose from "crocks/helpers/compose";
import isDefined from "crocks/predicates/isDefined";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import SubmitButton from "../../../common/components/SubmitButton";
import { inviteUser } from "../../../common/store/actions";
import { connect } from "react-redux";
import AppRequestState from "../../models/AppRequestState";
import { isEmptyString } from "../../../common/util/string";
import { stringProp } from "../../util/object";
import AppRequest from "../../models/AppRequest";
import { getRequestState } from "../../store";
import OrganizationSelect from "../OrganizationSelect";

const request = AppRequest.POST_USER_INVITE;
const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: 'Sending...',
  [AppRequestState.SUCCESS]: 'Invitation sent.',
  [AppRequestState.FAILURE]: 'Could not invite this user.',
};

const styles = theme => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing(2),
    }
  }
});

class InviteUserForm extends React.PureComponent {
  static propTypes = {
    orgId: PropTypes.number,
    organizations: PropTypes.array.isRequired,
  };

  static getDerivedStateFromProps(nextProps) {
    return isDefined(nextProps.orgId) ? { orgId: nextProps.orgId } : null;
  }

  state = {
    email: "",
    orgId: 0,
  };

  handleOrgChange = ev => this.setState({ orgId: ev.target.value });
  handleEmailChange = ev => this.setState({ email: ev.target.value });

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.inviteUser(this.state.email, this.state.orgId);
  };

  render() {
    const { orgId, email } = this.state;
    const { orgId: propsOrgId, organizations, classes, requestState } = this.props;

    const disabled = isEmptyString(email) || orgId === 0;
    const orgSelectDisabled = isDefined(propsOrgId);
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          required
          type="email"
          margin="normal"
          label="Email Address"
          onChange={this.handleEmailChange}
        />
        <OrganizationSelect
          value={orgId}
          disabled={orgSelectDisabled}
          organizations={organizations}
          onChange={this.handleOrgChange}
        />
        <SubmitButton
          label="Send Invitation"
          stateMsg={stateMsg}
          requestState={requestState}
          disabled={disabled}/>
      </form>
    )
  }
}

const mapStateToProps = state => ({ requestState: getRequestState(state, request) });
const mapDispatchToProps = { inviteUser };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(InviteUserForm);

export const inviteUserDialogInfo = {
  dialogTitle: 'Invite a New User',
  dialogText: 'An email with a registration link will be emailed to the address you provide.',
};

