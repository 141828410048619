import React from 'react';
import PropTypes from 'prop-types';
import unless from "crocks/logic/unless";
import compose from "crocks/helpers/compose";
import Typography from "@material-ui/core/Typography";
import { formatDateRange } from "../util/date";
import { isEmptyString, stringAppender } from "../util/string";

const buildRangeAndTitle = (start, end, title) => compose(
  stringAppender(title),
  unless(isEmptyString, stringAppender(' - ')),
  formatDateRange
)(start, end);

class NewsItem extends React.PureComponent {
  static propTypes = {
    body: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const { title, body, startTime, endTime, className } = this.props;
    return (
      <div className={className}>
        <Typography variant="subtitle1" color="primary">
          {buildRangeAndTitle(startTime, endTime, title)}
        </Typography>
        <Typography variant="body2">{body}</Typography>
      </div>
    );
  }
}

export default NewsItem;
