import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, withStyles } from "@material-ui/core/";
import PasswordConfirmPair from "../common/components/PasswordConfirmPair";
import AppRequestState from "../common/models/AppRequestState";
import SubmitButton from "../common/components/SubmitButton";
import { stringProp } from "../common/util/object";

const isButtonDisabled = (pw, state) => pw.length === 0 || state === AppRequestState.IN_FLIGHT;

const styles = theme => ({
  form: {
    width: '40%',
    minWidth: 400,
    margin: '2em auto',
    display: 'flex',
    flexDirection: 'column'
  },
  email: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(),
  }
});

const messageMap = {
  [AppRequestState.IN_FLIGHT]: "Changing password....",
  [AppRequestState.FAILURE]: 'An error prevented your password from being changed.',
  [AppRequestState.SUCCESS]: 'Password updated, signing in...'
};

const ChangePasswordForm = ({ classes, email, password, requestState, onSubmit, onPasswordChange }) => {
  const stateMsg = stringProp(requestState, messageMap).option('');
  const disableButton = isButtonDisabled(password, requestState);

  return (
    <div>
      <form className={classes.form} onSubmit={onSubmit}>
        <Typography variant="subtitle1" gutterBottom>
          Type your new password below and then confirm it.
        </Typography>
        <TextField className={classes.email} value={email} label="Email" fullWidth disabled/>
        <PasswordConfirmPair label='New Password' onChange={onPasswordChange} autoFocus/>
        <SubmitButton
          label="Change Password"
          className={classes.button}
          stateMsg={stateMsg}
          disabled={disableButton}
          requestState={requestState}
        />
      </form>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChangePasswordForm);