import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  displayStringForOp,
  isFloatVariable,
  isIntVariable,
  setValueData,
  setValueOperation
} from "../../../../common/models";
import { identity, partial } from "../../../../common/util/func";
import ValueDataPopup from "./ValueDataPopup";
import { displayStringForData } from "./expressionHelpers";

const createOpsMenuItems = (ops, onClick) =>
  ops.map(op => <MenuItem key={op} button={false} onClick={() => onClick(op)}>{displayStringForOp(op)}</MenuItem>);

const intValidator = value => value.replace(/\D/g, '');
const floatValidator = value => value.replace(/[^0-9.]/g, '');

const lookupValidator = variable =>
  isIntVariable(variable)
    ? intValidator
    : isFloatVariable(variable) ? floatValidator : identity;

const renderPopup = (expression, state, onChange, onClose) => (
  <ValueDataPopup
    key={expression.uuid}
    value={expression.data}
    open={state.dataPopupOpen}
    anchorEl={state.popupAnchor}
    validate={lookupValidator(expression.variable)}
    onChange={onChange}
    onClose={onClose}
  />
);

const styles = theme => ({
  expControls: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  narrow: {
    padding: theme.spacing(),
    minWidth: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  }
});

class ValueExpression extends React.Component {
  state = {
    opPopupOpen: false,
    dataPopupOpen: false,
    popupAnchor: undefined,
  };

  handleOpsChange = op => {
    this.handleOpPopupClose();
    this.props.onApply(partial(setValueOperation, op), this.props.expression);
  };

  handleOpPopupOpen = e => this.setState({ opPopupOpen: true, popupAnchor: e.target });
  handleOpPopupClose = () => this.setState({ opPopupOpen: false, popupAnchor: null });

  handleDataChange = data => {
    this.handleDataPopupClose();
    this.props.onApply(partial(setValueData, data), this.props.expression);
  };

  handleDataPopupOpen = e => this.setState({ dataPopupOpen: true, popupAnchor: e.target });
  handleDataPopupClose = () => this.setState({ dataPopupOpen: false, popupAnchor: null });

  render() {
    const { opPopupOpen, popupAnchor } = this.state;
    const { expression, onVariableClick, classes } = this.props;

    const opStr = displayStringForOp(expression.op);
    const dataStr = displayStringForData(expression);

    return (
      <div>
        <div className={classes.expControls}>
          <Button className={classes.narrow} onClick={onVariableClick}>{expression.variable.name}</Button>
          <Button className={classes.narrow} onClick={this.handleOpPopupOpen}>{opStr}</Button>
          <Button className={classes.narrow} onClick={this.handleDataPopupOpen}>{dataStr}</Button>
        </div>

        <Menu open={opPopupOpen} anchorEl={popupAnchor} onClose={this.handleOpPopupClose}>
          {createOpsMenuItems(expression.variable.cmp, this.handleOpsChange)}
        </Menu>

        {renderPopup(expression, this.state, this.handleDataChange, this.handleDataPopupClose)}
      </div>
    );
  }
}

ValueExpression.propTypes = {
  expression: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVariableClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ValueExpression);
