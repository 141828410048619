import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const VideoOverlay = ({ show, children, classes }) => {
  if (show === false) {
    return null;
  }

  const swallowEvent = ev => ev.stopPropagation();

  return (
    <div className={classes.root} onClick={swallowEvent} onMouseEnter={swallowEvent} onMouseLeave={swallowEvent}>
      {children}
    </div>
  );
};

VideoOverlay.propTypes = {
  show: PropTypes.bool,
};

VideoOverlay.defaultProps = {
  show: false,
};

export default withStyles(styles)(VideoOverlay);
