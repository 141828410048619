import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Popover, withStyles } from "@material-ui/core/";
import CloseButton from "../../../common/components/CloseButton";
import { createSelectedChartVariable } from "../../../common/models";

const createCheckBox = (variable, checked, onChange) => (
  <FormControlLabel
    key={variable.id}
    label={variable.title}
    control={
      <Checkbox
        checked={checked}
        onChange={onChange(variable.id)}
        value={String(variable.id)}
        style={{ color: variable.baseColor }}
      />
    }
  />
);

function createCheckBoxes(variables, selections, onChange) {
  const selectionSet = new Set(selections.map(v => v.id));
  return variables.map(v => createCheckBox(v, selectionSet.has(v.id), onChange))
}

const mergeSelected = (a, b) => a.concat(b).sort((a, b) => a.id - b.id);

function ensureOneIsActive(selections) {
  const activeCount = selections.reduce((sum, v) => sum + (v.isActive ? 1 : 0), 0);
  if (activeCount === 1) {
    return selections;
  }
  return selections.map((v, i) => createSelectedChartVariable({ ...v, isActive: i === 0 }));
}

function removeFromSelected(selections, id) {
  const newSelections = selections.filter(v => v.id !== id);
  return ensureOneIsActive(newSelections);
}

function addToSelected(variables, selections, id) {
  const varsToAdd = variables
    .filter(v => v.id === id)
    .map(createSelectedChartVariable);

  return ensureOneIsActive(mergeSelected(selections, varsToAdd));
}

function buildAllSelected(vars, selections) {
  const selectedIds = new Set(selections.map(v => v.id));
  const newSelections = vars
    .filter(v => !selectedIds.has(v.id))
    .map(createSelectedChartVariable);

  return ensureOneIsActive(mergeSelected(selections, newSelections));
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(),
  },
  choices: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    overflow: 'auto',
    width: '100%',
    maxHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    flexGrow: 1,
    minWidth: 100,
  },
});

class ChartVariablePopup extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    variables: PropTypes.array.isRequired,
    selections: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleAll = () => this.props.onChange(buildAllSelected(this.props.variables, this.props.selections));
  handleNone = () => this.props.onChange([]);

  handleChange = id => ev => {
    const { variables, selections } = this.props;
    const selected = ev.target.checked
      ? addToSelected(variables, selections, id)
      : removeFromSelected(selections, id);
    this.props.onChange(selected);
  };

  render() {
    const { open, anchorEl, variables, selections, onClose, classes } = this.props;

    return (
      <Popover anchorEl={anchorEl} open={open} onClose={onClose} onEnter={this.handleEnter}>
        <div className={classes.root}>
          <CloseButton small onClick={onClose}/>
          <div className={classes.choices}>
            {createCheckBoxes(variables, selections, this.handleChange)}
          </div>
          <div className={classes.buttons}>
            <Button size="small" onClick={this.handleAll}>All</Button>
            <Button size="small" onClick={this.handleNone}>None</Button>
            <div className={classes.spacer}/>
            <Button size="small" onClick={this.props.onClose}>OK</Button>
          </div>
        </div>
      </Popover>
    );
  }
}


export default withStyles(styles)(ChartVariablePopup);
