import React from 'react'
import PropTypes from 'prop-types'
import prop from "crocks/Maybe/prop"
import isEmpty from "crocks/predicates/isEmpty"
import Checkbox from "@material-ui/core/Checkbox"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import withStyles from '@material-ui/core/styles/withStyles'
import VariableExpansionPanel from "../../../common/components/VariableExpansionPanel"

const variableId = variable =>
  prop('id', variable)
    .option(0)

const variableName = variable =>
  prop('name', variable)
    .option('')

const createCheckBox = (choice, count, checked, onChange, classes) => (
  <FormControlLabel
    key={choice.id}
    classes={{ root: classes.choiceLabel }}
    label={
      <Typography variant="caption" color="primary">{choice.choice} ({count || 0})</Typography>
    }
    control={
      <Checkbox
        classes={{ root: classes.choice }}
        color="primary"
        checked={checked}
        onChange={onChange(choice.id)}
        value={String(choice.id)}
      />
    }
  />
)

function createCheckBoxes(choices, counts, selected, onChange, classes) {
  return isEmpty(choices)
    ? <Typography style={{ fontStyle: 'italic', color: '#AAA' }}>None defined</Typography>
    : [...choices].map(ch => createCheckBox(ch, counts[ch.id], selected.has(ch.id), onChange, classes))
}

const styles = theme => ({
  choice: {
    padding: theme.spacing(0.25),
  },
  choiceLabel: {
    alignItems: 'flex-start'
  }
})

class FilterBarVariable extends React.PureComponent {
  static propTypes = {
    variable: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    onExpansionChange: PropTypes.func,
    counts: PropTypes.object,
    selected: PropTypes.object,
  }

  static defaultProps = {
    counts: {},
    expanded: false,
    selected: new Set(),
  }

  handleChange = id => ev => {
    const { variable, selected, onChange } = this.props
    const newSelections = new Set(selected)

    ev.target.checked ? newSelections.add(id) : newSelections.delete(id)
    onChange(variable.name, newSelections)
  }

  handleExpansionChange = id => (ev, expanded) => this.props.onExpansionChange(id, expanded)

  render() {
    const { variable, counts, selected, expanded, classes } = this.props

    return (
      <VariableExpansionPanel
        title={variableName(variable)}
        expanded={expanded}
        onExpansionChange={this.handleExpansionChange(variableId(variable))}
      >
        {createCheckBoxes(variable.choices, counts, selected, this.handleChange, classes)}
      </VariableExpansionPanel>
    )
  }
}

export default withStyles(styles)(FilterBarVariable)
