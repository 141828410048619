/*
 * Action types recognized by the reducers
 */
export const LOGOUT = 'LOGOUT'
export const SET_APP_STATUS = 'SET_APP_STATUS'
export const SET_REQUEST_STATE = 'SET_REQUEST_STATE'
export const SET_SELECTED_CHART_VARIABLES = 'SET_SELECTED_CHART_VARIABLES'
export const SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS = 'SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS'
export const SET_UI_STATE = 'SET_UI_STATE'
export const SET_SCENARIO_BROWSER_VIEW_STATE = 'SET_SCENARIO_BROWSER_VIEW_STATE'
export const SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS = 'SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS'

export const SET_USERS = 'SET_USERS'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const SET_MEMBERSHIPS = 'SET_MEMBERSHIPS'
export const SET_LOCAL_PORTAL_ADMINS = 'SET_LOCAL_PORTAL_ADMINS'

export const SET_SCENARIO = 'SET_SCENARIO'
export const SET_SCENARIOS = 'SET_SCENARIOS'
export const SET_SCENARIO_INDEX = 'SET_SCENARIO_INDEX'
export const SET_SCENARIO_COUNTS = 'SET_SCENARIO_COUNTS'
export const SET_SCENARIO_PAGES = 'SET_SCENARIO_PAGES'
export const SET_SCENARIO_PAGE_INDEX = 'SET_SCENARIO_PAGE_INDEX'
export const SET_SCENARIO_SORT_COLUMN = 'SET_SCENARIO_SORT_COLUMN'
export const SET_SCENARIO_SORT_DIRECTION = 'SET_SCENARIO_SORT_DIRECTION'
export const SET_ANALYTICS_COUNT = 'SET_ANALYTICS_COUNT'

export const SET_FILTER_VARS = 'SET_FILTER_VARS'
export const SET_TIME_SERIES_VARS = 'SET_TIME_SERIES_VARS'

export const ADD_FILTER = 'ADD_FILTER'
export const SET_FILTERS = 'SET_FILTERS'
export const FILTER_SAVED = 'FILTER_SAVED'
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER'
export const SET_TIME_SERIES = 'SET_TIME_SERIES'
export const SET_IRB_CERTIFICATES = 'SET_IRB_CERTIFICATES'
export const UPDATE_IRB_CERTIFICATE = 'UPDATE_IRB_CERTIFICATE'

export const PUSH_EXPRESSION_TREE = 'PUSH_EXPRESSION_TREE'

export const SET_NEWS = 'SET_NEWS'
export const UPDATE_NEWS_ITEM = 'UPDATE_NEWS_ITEM'
export const DELETE_NEWS_ITEM = 'DELETE_NEWS_ITEM'

export const SET_FAQS = 'SET_FAQS'
export const UPDATE_FAQ = 'UPDATE_FAQ'
export const DELETE_FAQ = 'DELETE_FAQ'

/*
 * Action creators used by the application code
 */

// analytics (in REST API)
export {
  putAnalyticsIds,
  deleteAnalyticsIds,
  fetchAnalyticsAppsFromServer,
  fetchFunctionalScenariosFromServer,
} from './analytics'

// app (in Redux store)
export {
  setRequestState,
  resetRequestState,
  setAppStatus,
  setSelectedChartVariables,
  setDefaultChartVariableSelections,
  setSelectedAnnotationTabVariableIds,
  setUiState,
  setScenarioBrowserViewState,
  setScenarioBrowserExpandedQuickFilters,
} from './app'

// api/cert (in REST API)
export { submitIrbReview, fetchAllIrbCertificates } from "./cert"

// api/doc (in REST API)
export { fetchDocument, fetchSteeringCommitteeDocument } from "./doc"

// api/event (in REST API)
export {
  fetchScenario,
  setScenarioIndex,
  fetchTimeSeries,
  setScenarioCounts,
  setScenarios,
  setTimeSeriesEntry,
  fetchTimeSeriesVars,
  fetchLayerDownloadUrl,
} from "./event"

// api/faq (in REST API)
export {
  fetchFaqsFromServer,
  postFaqToServer,
  putFaqToServer,
  deleteFaqFromServer
} from './faq'

// api/filter (in REST API)
export {
  fetchFilterVars,
  filterImmediate,
  fetchEventIdsInFilter,
  setFilterVariables,
  setScenarioPageIndex,
} from './filter'

// api/member (in REST API)
export { fetchLocalPortalAdmins } from './member'

// api/membership (in REST API)
export { setMemberships, fetchMemberships } from './membership'

// api/news (in REST API)
export {
  fetchNewsFromServer,
  postNewsItemToServer,
  putNewsItemToServer,
  deleteNewsItemFromServer
} from './news'

// api/organization (in REST API)
export {
  addOrganization,
  updateOrganization,
  fetchOrganizations,
  setOrganizations,
  getOrgInfo,
  updateOrgInfo
} from './organization'

// api/profile (in REST API)
export {
  changeProfilePassword,
  fetchUserProfile,
  uploadIrbCertificate,
  updateUserProfile
} from "./profile"

// api/register (in REST API)
export {
  registerUser,
  resetPassword,
  sendRecoveryEmail,
} from "./register"

// api/tag (in REST API)
export {
  fetchTagsFromServer,
  createTagOnServer,
  updateTagOnServer,
  deleteTagFromServer,
  assignTagToScenarios,
  removeTagFromScenarios,
} from './tag'

// api/user (in REST API)
export {
  setUsers,
  fetchUsers,
  inviteUser,
  addRoleToUser,
  removeRoleFromUser,
  updateUserInfo,
  amendUserToIrb,
  sendEmailToUsers,
} from "./user"

// userdata/expressionTree (in Redux store)
export { pushExpressionTree, newExpressionTree } from './expressionTree'

// userdata/scenarios (in Redux store)
export { setScenarioSortColumn, setScenarioSortDirection } from './scenarios'

// login/logout action creators.
export { doLogin } from "./login"
export { doLogout } from "./logout"

// Utility actions: file downloads, etc.
export { downloadFileFromUrl } from "./download"