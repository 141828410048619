import constant from "crocks/combinators/constant"
import prop from "crocks/Maybe/prop"
import safe from "crocks/Maybe/safe"
import { isNotNil } from "../../../common/util/predicates"
import {
  getAnnotatedFeature,
  getCaseType,
  getEventSeverity1,
  getImpactProximity1,
  getPrecrashTypology,
  isCrash,
  isValidImpactProximity
} from "../../../common/models/scenario"

const crashMapper = scenario => {
  const prox1 = getImpactProximity1(scenario).option()
  const severity1 = getEventSeverity1(scenario).option()

  return isCrash(severity1) && isValidImpactProximity(prox1) ? 'impactProximity1' : 'impactProximity2'
}

const nearCrashMapper = scenario => {
  const prox1 = getImpactProximity1(scenario).option()
  return isValidImpactProximity(prox1) ? 'impactProximity1' : 'impactProximity2'
}

const caseTypeToToiPropFn = {
  "Crash": crashMapper,
  "Near-crash": nearCrashMapper,
  "Baseline": constant('conflictBegin'),
  "Epoch": constant('timestampReference'),
}

const caseDescriptionFuncs = {
  "Crash": getPrecrashTypology,
  "Near-crash": getPrecrashTypology,
  "Epoch": getAnnotatedFeature,
}

const apply = param => fn => fn(param)
const lookupDescriptionFunc = caseType => prop(caseType, caseDescriptionFuncs)

/**
 * getCaseDescription :: Scenario -> String
 */
export const getCaseDescription = scenario =>
  getCaseType(scenario)
    .chain(lookupDescriptionFunc)
    .chain(apply(scenario))
    .option('')

/**
 * Returns the Time of Interest property for this scenario based on its case type.
 *
 * toiPropName :: Scenario -> String
 */
export const toiPropName = scenario =>
  prop('caseType', scenario)
    .chain(caseType => prop(caseType, caseTypeToToiPropFn))
    .map(toiLookupFn => toiLookupFn(scenario))
    .chain(safe(isNotNil))

/**
 * getTimeOfInterest :: Scenario -> Number
 */
export const getTimeOfInterest = scenario =>
  toiPropName(scenario)
    .chain(toiProp => prop(toiProp, scenario))
    .option()

