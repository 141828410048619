import React from 'react';
import PropTypes from 'prop-types';
import TimeSeriesState from "./TimeSeriesState";
import LeftIcon from '@material-ui/icons/ArrowLeft';
import RightIcon from '@material-ui/icons/ArrowRight';
import withStyles from "@material-ui/core/styles/withStyles";

const leftIcon = className => <LeftIcon className={className}/>;
const rightIcon = className => <RightIcon className={className}/>;

/**
 * Blinker values: 0=off, 1=left, 2=right, 3=both, 254=invalid data, 255=data not available
 */
const getActiveValues = left => left ? [1, 3] : [2, 3];

const styles = {
  active: {
    width: 50,
    height: 50,
    marginTop: `-10px`,
    color: 'green',
  },
  inactive: {
    width: 50,
    height: 50,
    marginTop: `-10px`,
    color: '#888',
  },
};

class BlinkerStatus extends React.PureComponent {
  static propTypes = {
    time: PropTypes.number,
    data: PropTypes.array,
    left: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    time: 0,
    left: false,
  };

  render() {
    const { time, data, left, className, classes} = this.props;
    const activeValues = getActiveValues(left);
    const activeIcon = left ? leftIcon(classes.active) : rightIcon(classes.active);
    const inactiveIcon = left ? leftIcon(classes.inactive) : rightIcon(classes.inactive);

    return (
      <TimeSeriesState data={data} time={time} activeValues={activeValues}>
        {active => <div className={className}>{active ? activeIcon : inactiveIcon}</div>}
      </TimeSeriesState>
    );
  }
}

export default withStyles(styles)(BlinkerStatus);
