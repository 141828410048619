import { useDispatch, useSelector } from "react-redux"
import {
  fetchEventIdsInFilter as fetchEventIds,
  filterImmediate,
  newExpressionTree,
  setScenarioPageIndex
} from "../actions"
import { getExpressionTree } from ".."
import { applyToTree } from "../../models"
import { getAnalyticsFilterBranch } from "../../models/ampExpressionTree"
import { setGroupEnabled } from "../../models/expressionGroupOp"
import { partial } from "../../util/func"
import { useCallback } from "react"

function useImmediateFilter() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(filterImmediate()), [dispatch])
}

function useEventIdsInFilter() {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(fetchEventIds()), [dispatch])
}

function useAnalyticsBranchEnabler() {
  const dispatch = useDispatch()
  const tree = useSelector(getExpressionTree)

  return () => {
    const analyticsBranch = getAnalyticsFilterBranch(tree)
    const op = partial(setGroupEnabled, true)

    dispatch(setScenarioPageIndex(1))
    dispatch(newExpressionTree(applyToTree(tree, analyticsBranch, op)))
  }
}

export { useImmediateFilter, useEventIdsInFilter, useAnalyticsBranchEnabler }