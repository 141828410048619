import safe from "crocks/Maybe/safe";
import isArray from "crocks/predicates/isArray";
import isDefined from "crocks/predicates/isDefined";
import { safeArray } from "./types";
import { isNotNil } from "./predicates";

const zipAWithLonger = (a, b) => b.map((it, idx) => [a[idx], it]);
const zipAWithShorter = (a, b) => a.map((it, idx) => [it, b[idx]]);

const hasLength = array => isArray(array) && isNotNil(array.length);

/**
 * arrayLength :: [a] -> Number
 */
export const arrayLength = a => hasLength(a) ? a.length : 0;

/**
 * Return the last element of the array.
 */
export const end = array => hasLength(array) ? array[array.length - 1] : undefined;

/**
 * Reverse head. Return the last element of the array. Synonym for end.
 */
export const rhead = end

/**
 * Return the elements of the array before the end element
 */
export const rtail = array => hasLength(array) ? array.slice(0, array.length - 1) : undefined

/**
 * Return the first element of the array.
 */
export const head = array => hasLength(array) ? array[0] : undefined;

/**
 * Return the rest of the array after the head (first) element.
 */
export const tail = array => hasLength(array) ? array.slice(1) : undefined;

/**
 * Zips the contents of a and b, returning an array where each element is an array of the
 * corresponding items in a and b.  The arrays do not have to be the same length.
 *
 * Ex. a = [1, 2]; b = [3, 4, 5]; zip(a, b) = [ [1, 3], [2, 4], [undefined, 5] ];
 *
 * Both arrays must be defined.
 */
export const zip = (a, b) => a.length > b.length ? zipAWithShorter(a, b) : zipAWithLonger(a, b);

/**
 * getAt :: ([a], Integer) -> a | undefined
 */
export const getAt = (array, index) =>
  safeArray(array)
    .chain(arr => safe(isDefined, arr[index]))
    .option(undefined);

/**
 * Return true if two arrays are the same length and have the same content in the same order.
 * This function does a shallow compare.
 */
export const arraysAreEqual = (array1, array2) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index]);

/**
 * Return true if two arrays are the same length and have the same content regardless of order.
 * This function does a shallow compare.
 */
export const arraysAreEqualIgnoreOrder = (array1, array2) => {
  const a1Set = new Set(array1)
  return array1.length === array2.length && (array2.find(it => !a1Set.has(it)) === undefined)
}

/**
 * Returns an array containing the items that are present in updated, but not in original.
 *
 * @param original
 * @param updated
 */
export function findArrayAdditions(original, updated) {
  const originalSet = new Set(original);
  return updated.filter(it => !originalSet.has(it))
}

/**
 * Returns an array containing the items that are present in original, but not in updated.
 *
 * @param original
 * @param updated
 */
export function findArrayRemovals(original, updated) {
  const updatedSet = new Set(updated);
  return original.filter(it => !updatedSet.has(it))
}

/**
 * Groups an Array of items using the supplied grouping function.
 * 
 * @param array An Array of items to group.
 * @param grouper A callback function that receives the item, index, and the array. Should return a String key.
 */
export function groupArrayBy(array, grouper) {
  const result = new Map()
  let index = 0

  for (const it of array) {
    const key = grouper(it, index++, array)
    if (result.has(key)) {
      result.get(key).push(it)
    } else {
      result.set(key, [it])
    }
  }

  return result;
}