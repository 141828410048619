import React from 'react';
import compose from "crocks/helpers/compose";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import { getUserProfile } from "../../common/store";
import { userIsQualifiedResearcher } from "../../common/models";
import ContentContainer from "../../common/components/ContentContainer";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    margin: '0 auto',
    padding: theme.spacing(4),
    maxWidth: '50vw',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridColumnGap: '32px',
    gridRowGap: '16px',
  },
  sign: {
    gridRow: '1 / 3',
    margin: '50px',
    boxShadow: '4px 4px 10px 1px rgba(0, 0, 0, .5);',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: 200,
    backgroundColor: 'darkorange',
    border: '8px solid black',
    borderRadius: 8,
    transform: 'rotate(45deg)',
    '& > div': {
      transform: 'rotate(-45deg)',
      textAlign: 'center',
      '& > p': {
        fontSize: '50px',
        fontWeight: 'bold',
        lineHeight: '55px',
      },
    }
  },
  bodyText: {
    gridColumn: '2',
  }
});

class QualifiedResearcherCheck extends React.PureComponent {
  render() {
    const { profile, classes, children } = this.props;

    if (userIsQualifiedResearcher(profile)) {
      return children;
    }

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <div className={classes.sign}>
            <div>
              <Typography>No</Typography>
              <Typography>Entry</Typography>
            </div>
          </div>

          <Typography variant="h6">Qualified Researcher Area</Typography>

          <div className={classes.bodyText}>
            <Typography variant="subtitle1">
              You must become a Qualified Researcher in order to view this page.  Please submit your
              IRB certification using <Link to="/profile/irb">this link</Link>
            </Typography>
          </div>
        </Paper>
      </ContentContainer>
    )
  }
}

const mapStateToProps = state => ({ profile: getUserProfile(state) });

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(QualifiedResearcherCheck);
