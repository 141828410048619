import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles"
import { useParams } from "../../common/util/reactRouterHooks"
import { getUserProfile } from "../../common/store"
import { connect } from "react-redux"
import { useFunctionalScenarioState } from './FunctionalScenarioStateProvider'

const useStyles = makeStyles({
  root: {},
})

const FunctionalScenarioApp = ({ user }) => {
  const classes = useStyles()

  const iframeRef = useRef(null)
  const appRef = useRef(null)

  const { appId } = useParams()
  const { scenarios: apps } = useFunctionalScenarioState()

  if (apps === undefined) {
    return null
  }

  const app = apps.find(a => a.id === appId)
  if (app === undefined) {
    return null
  }

  appRef.current = app

  return (
    <div className={classes.root}>
      <iframe
        ref={iframeRef}
        id={app.id}
        src={app.url}
        title={app.name}
        style={{ border: 'none', width: '100%', height: '100%' }}
      />
    </div>
  )
}

FunctionalScenarioApp.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  user: getUserProfile(state),
})

export default connect(mapStateToProps)(FunctionalScenarioApp)
