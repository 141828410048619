import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core";
import AmpBanner from "./AmpBanner";
import Footer from "./Footer";

const styles = theme => (
  {
    root: {
      height: '100vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto'
    }
  }
);

const AmpBannerPage = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <AmpBanner />
      {children}
      <Footer />
    </div>
  );
};

AmpBannerPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AmpBannerPage);