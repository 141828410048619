import isNil from "crocks/predicates/isNil";
import identity from "crocks/combinators/identity";

/**
 * Use the HTTP service provided by Redux Thunk to create an anchor tag with the provided URL and
 * programatically click it to begin the download.  This allows you to avoid the use of <a> tags
 * in your JSX in places where a click handler on a <button> or <div> works better for you.
 */
export const downloadFileFromUrl = url => {
  if (isNil(url)) {
    return identity;
  }

  return (dispatch, getState, services) => {
    services.http.downloadFile('', url)
  }
};
