import curry from "crocks/helpers/curry";
import { isNotEmptyString } from "../../../common/util/predicates";

/**
 * Replace all semicolons to commas, inserting a space between each, and clipping off the ending comma.
 */
export const semisToCommas = str => str.replace(/;/g, ', ').slice(0, -2);

/**
 * Returns the string truncated to the indicated length with ellipses concatenated to the end.
 */
export const trim = curry((len, str) => {
  const result = str.slice(0, len);
  return result.length === len
    ? result.trim().concat('...')
    : result;
});

/**
 * Take the string of semicolon-separated features and reformat it as a comma-separated string.
 */
export const formatFeatures = str => isNotEmptyString(str) ? semisToCommas(str) : 'No additional epoch features';
