import React from 'react';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles/index";

const styles = theme => ({
  root: {
    background: `${theme.palette.secondary.dark} 
                  linear-gradient(to right, 
                    rgba(255, 255, 255, 0.4), 
                    rgba(255, 255, 255, 0.2) 20%, 
                    rgba(255, 255, 255, 0.1) 50%, 
                    rgba(255, 255, 255, 0.2) 80%,
                    rgba(255, 255, 255, 0.4) 100%)`,
    padding: theme.spacing(2),
  }
});

const ContentContainer = ({ children, classes, className, ...rest }) =>
  <div className={classNames(classes.root, className)} {...rest}>{children}</div>;

export default withStyles(styles)(ContentContainer);

