import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const ActionButton = ({ icon, label, disabled = false, onClick }) => (
  <Button onClick={onClick} disabled={disabled}>{icon}&nbsp;{label}</Button>
);

ActionButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  icon: <AddIcon />,
};

export default ActionButton;