import React from 'react';
import PropTypes from 'prop-types';
import safe from "crocks/Maybe/safe";
import prop from "crocks/Maybe/prop";
import isArray from "crocks/predicates/isArray";
import { clamp } from "../../../../../common/util/math";

const rate = 10;  // Hz
const toIndex = time => Math.round(time * rate);
const toClampedIndex = (time, len) => clamp(toIndex(time), 0, len - 1);
const performLookup = time => data => prop(toClampedIndex(time, data.length), data);

const lookupValue = (data, time) =>
  safe(isArray, data)
    .chain(performLookup(time))
    .option(null);

class TimeSeriesValue extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    time: PropTypes.number,
    children: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    time: 0,
    children: value => <div>{value}</div>
  };

  render() {
    const { data, time, children } = this.props;
    return children(lookupValue(data, time));
  }
}

export default TimeSeriesValue;
