import map from "crocks/pointfree/map";
import identity from "crocks/combinators/identity";
import { createDeleteThunk, createGetThunk, createPostThunk, createPutThunk } from "./utils";
import { AppRequest } from "../../models";
import { createTag } from "../../models/tag";
import { createFilterBody } from "./filter";

export const fetchTagsFromServer = () =>
  createGetThunk({
    request: AppRequest.GET_TAGS,
    url: '/api/tag',
    responseFnFactory: () => map(createTag),
  });

export const createTagOnServer = tag =>
  createPostThunk({
    request: AppRequest.POST_TAG,
    url: '/api/tag',
    body: { ...tag },
    responseFnFactory: () => createTag,
  });

export const updateTagOnServer = tag =>
  createPutThunk({
    request: AppRequest.PUT_TAG,
    url: `/api/tag/${tag.id}`,
    body: { ...tag },
    responseFnFactory: () => createTag,
  });

export const deleteTagFromServer = id =>
  createDeleteThunk({
    request: AppRequest.DELETE_TAG,
    url: `/api/tag/${id}`,
    responseFnFactory: () => identity,
  });

export const assignTagToScenarios = (tagId, scenarioIds) =>
  createPutThunk({
    request: AppRequest.PUT_TAG_ASSIGNMENT,
    url: `/api/tag/${tagId}/event`,
    body: { eventIds: scenarioIds },
    responseFnFactory: () => identity,
  });

export const removeTagFromScenarios = (tagId, scenarioIds) =>
  createDeleteThunk({
    request: AppRequest.DELETE_TAG_ASSIGNMENT,
    url: `/api/tag/${tagId}/event`,
    body: { eventIds: scenarioIds },
    responseFnFactory: () => identity,
  });

export const assignTagToFilter = tagId =>
  createPutThunk({
    request: AppRequest.PUT_TAG_ASSIGNMENT,
    url: `/api/tag/${tagId}/filter`,
    body: createFilterBody,
    responseFnFactory: () => identity,
  });

export const removeTagFromFilter = tagId =>
  createDeleteThunk({
    request: AppRequest.DELETE_TAG_ASSIGNMENT,
    url: `/api/tag/${tagId}/filter`,
    body: createFilterBody,
    responseFnFactory: () => identity,
  });

export const setSharedOrgsForTag = (tagId, sharedOrgIds) =>
  createPutThunk({
    request: AppRequest.PUT_SHARED_TAGS,
    url: `/api/tag/${tagId}/share`,
    body: { orgIds: sharedOrgIds },
    responseFnFactory: () => createTag,
  });

export const deleteSharedOrgsForTag = (tagId, sharedOrgIds) =>
  createDeleteThunk({
    request: AppRequest.DELETE_SHARED_TAGS,
    url: `/api/tag/${tagId}/share`,
    body: { orgIds: sharedOrgIds },
    responseFnFactory: () => createTag,
  });
