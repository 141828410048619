import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from "@material-ui/core/";
import AddExpressionIcon from '@material-ui/icons/Add';
import AddGroupIcon from '@material-ui/icons/PlaylistAdd';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: 'solid 1px #CCC',
  },
};

const GroupChildControls = ({ classes, onAddGroup, onAddExpression }) => (
  <div className={classes.root}>
    <IconButton title="Add a new subgroup" onClick={onAddGroup}>
      <AddGroupIcon />
    </IconButton>
    <IconButton title="Add a new variable expression" onClick={onAddExpression}>
      <AddExpressionIcon />
    </IconButton>
  </div>
);

GroupChildControls.propTypes = {
  onAddGroup: PropTypes.func.isRequired,
  onAddExpression: PropTypes.func.isRequired,
};

export default withStyles(styles)(GroupChildControls);