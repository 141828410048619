import Maybe from "crocks/Maybe";
import safe from "crocks/Maybe/safe";
import { isNotNil, isNotNull } from "./predicates";

/**
 * Simple identity function, often useful.  Takes any number of parameters and returns the first one.
 * The rest are ignored.
 */
export const identity = a => a;

/**
 * Partially apply the given arguments to the given function.
 */
export const partial = (fn, ...args) => fn.bind(null, ...args);

/**
 * Given two functions f and g, return a function that takes any number of
 * arguments and applies them to f, then passes the result to g.
 *
 * @param f The first function
 * @param g The second function
 * @return The result of g(f(args)) is returned.
 * @private
 */
const _pipe = (f, g) => (...args) => g(f(...args));

/**
 * Given any number of functions, construct a pipeline such that the result of
 * the first function is passed to the second, the second to the third, and so on.
 *
 * @param fns Some number of functions from which the pipeline is created.
 */
export const pipe = (...fns) => fns.reduce(_pipe);

/**
 * ofNilable :: a -> Maybe a
 */
export const ofNilable = a => safe(isNotNil, a);

/**
 * A function that attempts to execute the fn function provided as a parameter.  If fn returns a
 * null, undefined, or NaN, then Nothing will be returned.  Otherwise returns a Just containing
 * the value returned by fn().
 *
 * fromNilable :: (() -> a) -> Maybe a
 */
export const fromNilable = fn => safe(isNotNil, fn());

/**
 * A function that attempts to execute the fn function provided as a parameter.  If fn returns a
 * null value, then Maybe.Nothing will be returned.  Otherwise the result of fn will be returned
 * wrapped in a Maybe.Just.
 *
 * fromNullable :: (() -> a) -> Maybe a
 */
export const fromNullable = fn => safe(isNotNull, fn());

/**
 * A function that attempts to execute the fn function provided as a parameter.  If fn executes
 * successfully, its return value will be hoisted into a Just container.  If fn throws, the
 * exception will be hoisted into a Nothing container.
 *
 * @param fn The function to attempt to execute.
 * @returns Maybe.Just or Maybe.Nothing depending on whether fn completes successfully or throws an exception.
 */
export const tryCatch = fn => {
  try {
    return Maybe.Just(fn());
  } catch (e) {
    return Maybe.Nothing();
  }
};

/**
 * A function that operates on two arrays, xs and ys, and calls one of three functions depending on
 * whether there is a corresponding value in each array.
 *
 * @param xs First array.
 * @param ys Second array.
 * @param xFn Called when there is an item in xs with no corresponding item in ys (e.g. xs is longer than ys).
 *            The one and only parameter is the member of xs.
 * @param yFn Called when there is an item in ys with no corresponding item in xs (e.g. ys is longer than xs).
 *            The one and only parameter is the member of ys.
 * @param bothFn Called when there are items in both arrays at a particular index. The xs item is the first
 *               parameter to the function, the ys item is the second.
 */
export const zipEach = (xs, ys, xFn, yFn, bothFn) => {
  const larger = xs.length > ys.length ? xs : ys;
  for (let i = 0; i < larger.length; ++i) {
    const x = xs[i] === undefined ? null : xs[i];
    const y = ys[i] === undefined ? null : ys[i];

    if (x !== null && y !== null) {
      bothFn(x, y);
    } else if (x === null) {
      yFn(y);
    } else {
      xFn(x);
    }
  }
};

/**
 * Selects a function and invokes it from the options based on the value of choice.  If options contains the
 * special 'else' key, it will be invoked if choice doesn't match any other option keys.
 *
 * @returns {undefined} The result of invoking the function or undefined if there are no options that
 *  match choice and there is no 'else' option.
 */
export const when = (choice, options) =>
  options[choice] ? options[choice]() : (options['else'] ? options['else']() : undefined );


// Removing these until I can figure out the problems that babel-runtime is causing for the
// production build.
// /**
//  * Returns a generator of ints that fall in the range [from, to) incremented by the step size.
//  *
//  * @param from The starting integer
//  * @param to One past the end integer
//  * @param step The step size.
//  */
// export function * range(from, to, step = 1) {
//   for (let i = from; i < to; i += step) {
//     yield i;
//   }
// }
//
// /**
//  * Calls the callback function for each item in the generator.
//  *
//  * @param range The range that controls the iteration.
//  * @param fn The callback function. The range element is the first parameter.
//  */
// export function eachIn(range, fn) {
//   for (const i of range) {
//     fn(i);
//   }
// }

