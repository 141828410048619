import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  swatch: {
    width: '1em',
    height: '1em',
    marginRight: theme.spacing(),
  }
});

class ChartLegendItem extends React.PureComponent {
  static propTypes = {
    variable: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleClick = () => this.props.onClick(this.props.variable);

  render() {
    const { variable, classes } = this.props;
    const textColor = variable.isActive ? '#222' : '#AAA';
    const swatchColor = variable.isActive ? variable.baseColor : variable.inactiveColor;

    return (
      <div className={classes.root} onClick={this.handleClick}>
        <div className={classes.swatch} style={{ backgroundColor: swatchColor }} />
        <Typography style={{ color: textColor }}>{variable.title}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ChartLegendItem);
