import React from 'react';
import PropTypes from 'prop-types';
import Result from "crocks/Result";
import identity from "crocks/combinators/identity";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RoleChips from "./RoleChips";
import { maxLen, minLen } from "../util/form";
import { formatDayMonthYear } from "../util/date";
import { toCamel, toKebab, toTitle } from "../util/string";
import { getUserPhoneNumber } from "../models";
import { isUndefined } from "../../common/util/predicates";

const minMaxValidator = value =>
  Result.Ok(value)
    .chain(minLen(1, 'Cannot be blank'))
    .chain(maxLen(255));

const nameError = value =>
  minMaxValidator(value.trim().length)
    .either(identity, () => undefined);

const validators = {
  firstName: nameError,
  lastName: nameError,
};

const doValidation = (name, value) => validators[name] ? validators[name](value) : undefined;

const ProfileField = ({ value, name, label, onChange, errorMsg, ...rest }) => {
  const id = name ? toKebab(name) : toKebab(label);
  const propName = name || toCamel(label);
  const fieldLabel = label || toTitle(name);
  const hasError = errorMsg !== undefined;
  return (value === undefined ? null :
      <TextField
        id={id}
        fullWidth
        label={fieldLabel}
        value={value}
        error={hasError}
        helperText={errorMsg || ' '}
        onChange={ev => onChange(propName, ev.target.value)}
        {...rest}
      />
  );
};

const ReadOnlyField = ({ value, label }) => (
  <ProfileField disabled fullWidth InputProps={{ disableUnderline: true }} label={label} value={value}/>
);

class ProfileInputFields extends React.Component {
  handleChange = (name, value) => {
    const errorMsg = doValidation(name, value);
    this.props.onProfileChange(name, value, errorMsg);
  };

  handleCheck = ev => {
    this.props.onProfileChange('optOutOfEmail', ev.target.checked)
  };

  render() {
    const { profile, errors } = this.props;
    const phoneNumber = getUserPhoneNumber(profile);

    return (
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={12} sm={6}>
          <ProfileField
            required
            autoFocus
            name="firstName"
            value={profile.firstName}
            errorMsg={errors.firstName}
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileField
            required
            name="lastName"
            value={profile.lastName}
            errorMsg={errors.lastName}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ProfileField name="phoneNumber" value={phoneNumber} onChange={this.handleChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadOnlyField label="Organization" value={profile.orgName}/>
        </Grid>

        <Grid item xs={6} sm={3}>
          <ReadOnlyField label="Invited On" value={formatDayMonthYear(profile.invitedDate)}/>
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyField label="Member Since" value={formatDayMonthYear(profile.registeredDate)}/>
        </Grid>

        <Grid item xs={12} sm={6}>
          {profile.roles === undefined ? null :
            <div>
              <Typography variant="caption">
                Role{profile.roles.length > 1 ? 's' : ''}
              </Typography>
              <RoleChips roles={profile.roles}/>
            </div>
          }
        </Grid>

        <Grid item xs={12}>
          { isUndefined(profile.optOutOfEmail) ? null : (
            <FormControlLabel
              label="Opt out of email notifications from portal administrators"
              control={
                <Checkbox
                  color="primary"
                  checked={profile.optOutOfEmail}
                  onChange={this.handleCheck}
                  value="Opt out"
                />
              }
            />
          )}
        </Grid>

      </Grid>
    );
  }
}

ProfileInputFields.propTypes = {
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onProfileChange: PropTypes.func.isRequired,
};

/**
 * @returns {number} The number of defined (non-falsy) properties in the errors object.
 */
export const errorCount = errors =>
  Object.keys(errors)
    .reduce((sum, key) => errors[key] ? sum + 1 : sum, 0);


export default ProfileInputFields;