import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
  root: {
    display: 'flex',
  },
};

class ActionsColumn extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    item: {}
  };

  handleEdit = () => this.props.onEdit(this.props.item);
  handleDelete = () => this.props.onDelete(this.props.item);

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleEdit}>
          <EditIcon/>
        </IconButton>
        <IconButton onClick={this.handleDelete}>
          <DeleteIcon/>
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(ActionsColumn);
