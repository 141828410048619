import map from "crocks/pointfree/map";
import compose from "crocks/helpers/compose";
import { createDeleteThunk, createGetThunk, createPostThunk, createPutThunk } from "./utils";
import { AppRequest } from "../../models";
import { createNews } from "../../models/news";
import { DELETE_NEWS_ITEM, SET_NEWS, UPDATE_NEWS_ITEM } from "./index";

export const setNews = news => ({ type: SET_NEWS, payload: { news } });
export const updateNewsItem = item => ({ type: UPDATE_NEWS_ITEM, payload: { item } });
export const deleteNewsItem = id => ({ type: DELETE_NEWS_ITEM, payload: { id } });

const processGetNews = dispatch => compose(dispatch, setNews, map(createNews));
const processEditNews = dispatch => compose(dispatch, updateNewsItem, createNews);
const processDeleteNews = id => dispatch => () => dispatch(deleteNewsItem(id));

export const fetchNewsFromServer = () =>
  createGetThunk({
    request: AppRequest.GET_NEWS,
    url: '/api/news',
    responseFnFactory: processGetNews
  });

export const postNewsItemToServer = item =>
  createPostThunk({
    request: AppRequest.PUT_POST_NEWS,
    url: '/api/news',
    body: { ...item },
    responseFnFactory: processEditNews,
  });

export const putNewsItemToServer = item =>
  createPutThunk({
    request: AppRequest.PUT_POST_NEWS,
    url: `/api/news/${item.id}`,
    body: { ...item },
    responseFnFactory: processEditNews,
  });

export const deleteNewsItemFromServer = id =>
  createDeleteThunk({
    request: AppRequest.DELETE_NEWS,
    url: `/api/news/${id}`,
    responseFnFactory: processDeleteNews(id),
  });
