import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  root: {},
  close: {
    float: 'right',
    marginTop: -20,
    marginRight: -20
  }
};

class AmpDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
  };

  render() {
    const { open, onClose, title, text, children, classes } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth={false}
        onClose={onClose}
        className={classes.root}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {title}
          <IconButton className={classes.close} onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#222' }}>{text}</DialogContentText>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AmpDialog);