import React from 'react'
import { connect } from "react-redux"
import compose from "crocks/helpers/compose"
import isNil from "crocks/predicates/isNil"
import isDefined from "crocks/predicates/isDefined"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from '@material-ui/core/styles/withStyles'
import FilterBar from "./FilterBar"
import ResultViewControls from "./ResultsViewControls"
import ResultsView from "./ResultsView/index"
import FilterChoices from "./FilterChoices/FilterChoices"
import ResultsSummary from "./ResultsSummary"
import ContentContainer from "../../../common/components/ContentContainer"
import ResultsSortControls from "./ResultsSortControls"
import { applyToTree, getQuickFilterBranch, replaceChildInGroup, VariableId } from "../../../common/models"
import { partial } from "../../../common/util/func"
import { buildSearchExpressions, createSearchExpressionBuilders, } from "../common/filterExpression"
import {
  getExpressionTree,
  getFilterVarsLookup,
  getScenarioBrowserExpandedQuickFilters,
  getScenarioBrowserViewState,
  getScenarioCounts,
  getScenarios,
  getScenarioSortColumn,
  getScenarioSortDirection,
  getUserProfile
} from "../../../common/store"
import {
  fetchFilterVars,
  filterImmediate,
  newExpressionTree,
  setScenarioBrowserExpandedQuickFilters,
  setScenarioBrowserViewState,
  setScenarioPageIndex,
  setScenarios,
  setScenarioSortColumn,
  setScenarioSortDirection
} from "../../../common/store/actions"
import FilterBuilderPanel from "./FilterBuilderPanel"
import {
  buildFilterParamsFromGalleryBranch,
  clearListVarSelections,
  createVariableListFromIds,
  getListVarSelections,
  setListVarSelections,
  setTextSearch
} from "../common/filterParams"
import { listVariableIds } from "../common/constants"
import AmpDialog from "../../../common/components/AmpDialog"
import ConnectedTagEditor from "../common/components/ConnectedTagEditor"
import { isNotNil } from "../../../common/util/predicates"
import { buildLookupById } from "../../../common/util/object"
import { downloadCasesInFilter } from "../../../common/store/actions/filter"
import { createAmpExpressionTreeLookup, getPortalBranch } from "../../../common/models/ampExpressionTree"

export function recreateTagFilterSelections(filterVarsLookup, filterParams) {
  const tagsVar = filterVarsLookup[VariableId.TAGS]
  const tagsSelected = getListVarSelections(filterParams, tagsVar.name)

  if (isNil(tagsSelected)) {
    return []
  }

  const choiceLookup = buildLookupById(tagsVar.choices)
  return [...tagsSelected].filter(id => isDefined(choiceLookup[id]))
}

function filterScenarios({ newExpressionTree, expressionTree, filterImmediate, setScenarioPageIndex }, node, op) {
  setScenarioPageIndex(1)
  const newTree = applyToTree(expressionTree, node, op)
  newExpressionTree(newTree)
  filterImmediate()
}

function applyQuickFilter(props, state, filterParams) {
  const { expressionBuilders } = state
  const { expressionTree } = props

  const newGalleryBranch = buildSearchExpressions(filterParams, expressionBuilders)
  const replaceChild = partial(replaceChildInGroup, getQuickFilterBranch(expressionTree), newGalleryBranch)

  filterScenarios(props, getPortalBranch(expressionTree), replaceChild)
}

const findScenarioById = (scenarios, id) => scenarios.find(s => s.id === id) || null

const isPopulated = lookup => Object.keys(lookup).length > 0

const isUninitialized = (props, state) =>
  (state.filterBarVariables.length === 0 && isPopulated(props.filterVarsLookup))

const haveFilterVarsChanged = (props, state) =>
  props.filterVarsLookup !== state.currentFilterVars

const createTagEditorTitle = (scenario, counts) => isNotNil(scenario)
  ? `Tag Case #${scenario.fileId}`
  : `Tag ${counts.filtered} Cases`

const styles = theme => ({
  root: {
    padding: theme.spacing(),
    display: 'grid',
    gridTemplateColumns: '[left] 300px [gallery] 1fr auto [right]',
    gridTemplateRows: '[top] auto auto 20px [filterBar] auto [filterBuilder] auto [gallery] 1fr [bottom]',
    gap: `8px`,
  },
  summary: {
    gridColumn: '1',
    gridRow: '2',
  },
  choices: {
    gridColumn: 'gallery / right',
    gridRow: 'filterBar',
    marginLeft: 16,
    marginRight: 16,
  },
  choicesFullWidth: {
    gridColumn: 'left / right',
    gridRow: 'filterBar',
    marginLeft: 16,
    marginRight: 16,
  },
  viewControls: {
    gridColumn: '2',
    gridRow: '1',
    justifySelf: 'left',
  },
  sort: {
    gridRow: '1',
    gridColumn: '3',
    justifySelf: 'end',
  },
  filterBuilder: {
    gridRow: 'filterBuilder / gallery',
    gridColumn: 'gallery / right',
  },
  filterBuilderFullWidth: {
    gridRow: 'filterBuilder / gallery',
    gridColumn: 'left / right',
  },
  filterBar: {
    gridRow: 'filterBar / bottom',
  },
  gallery: {
    gridColumn: 'gallery / right',
    gridRow: 'gallery / bottom',
  },
  galleryFullWidth: {
    gridColumn: 'left / right',
    gridRow: 'gallery / bottom',
  }
})

class ScenarioBrowser extends React.PureComponent {
  state = {
    filterParams: {},
    currentFilterVars: {},
    filterBarVariables: [],
    expressionBuilders: [],
    showTagEditor: false,
    tagEditorScenarioId: undefined,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { filterVarsLookup, expressionTree } = nextProps

    if (isUninitialized(nextProps, prevState) || haveFilterVarsChanged(nextProps, prevState)) {
      const quickFilterBranch = getQuickFilterBranch(expressionTree)

      return {
        currentFilterVars: filterVarsLookup,
        filterParams: buildFilterParamsFromGalleryBranch(quickFilterBranch, filterVarsLookup),
        filterBarVariables: createVariableListFromIds(filterVarsLookup, listVariableIds),
        expressionBuilders: createSearchExpressionBuilders(filterVarsLookup)
      }
    }

    return null
  }

  handleChangeView = viewState => this.props.setScenarioBrowserViewState(viewState)

  handleSortColumnChange = column => {
    this.props.setScenarioSortColumn(column)
    this.props.filterImmediate()
  }

  handleSortDirectionChange = direction => {
    this.props.setScenarioSortDirection(direction)
    this.props.filterImmediate()
  }

  handleGoToPage = pageIndex => {
    window.scroll(0, 0)
    this.props.setScenarios([])
    this.props.setScenarioPageIndex(pageIndex)
    this.props.filterImmediate()
  }

  handleTextSearchChange = textSearch => {
    const filterParams = setTextSearch(this.state.filterParams, textSearch)
    applyQuickFilter(this.props, this.state, filterParams)
    this.setState({ filterParams })
  }

  handleListVariableChange = (name, selected) => {
    const filterParams = setListVarSelections(this.state.filterParams, name, selected)
    applyQuickFilter(this.props, this.state, filterParams)
    this.setState({ filterParams })
  }

  handleExpandAllQuickFilters = () => {
    const expanded = this.state.filterBarVariables.map(v => v.id)
    this.props.setScenarioBrowserExpandedQuickFilters(expanded)
  }

  handleCollapseAllQuickFilters = () => {
    this.props.setScenarioBrowserExpandedQuickFilters([])
  }

  handleExpandedQuickFilterChange = (id, expanded) => {
    let newExpanded
    if (expanded) {
      newExpanded = [...this.props.expandedQuickFilters, id]
    } else {
      newExpanded = this.props.expandedQuickFilters.filter(it => it !== id)
    }
    this.props.setScenarioBrowserExpandedQuickFilters(newExpanded)
  }

  handleFilterBuilderChange = (op, node) => filterScenarios(this.props, node, op)

  handleQuickFilterClear = () => {
    const filterParams = clearListVarSelections(this.state.filterParams)
    applyQuickFilter(this.props, this.state, filterParams)
    this.setState({ filterParams })
  }

  handleShowTagEditor = tagEditorScenarioId => this.setState({
    showTagEditor: true,
    tagEditorScenarioId
  })

  handleTagDialogClose = () => {
    this.setState({ showTagEditor: false, tagEditorScenarioId: undefined })

    const tagVariableName = this.props.filterVarsLookup[VariableId.TAGS].name

    this.props.fetchFilterVars()
      .then(() => recreateTagFilterSelections(this.props.filterVarsLookup, this.state.filterParams))
      .then(selected => this.handleListVariableChange(tagVariableName, new Set(selected)))
  }

  render() {
    const { scenarios, expressionTree, scenarioCounts, sortColumn, sortDirection } = this.props
    const { resultsViewState, downloadCasesInFilter, expandedQuickFilters, user, classes } = this.props

    const { showTagEditor, tagEditorScenarioId, filterParams, filterBarVariables } = this.state

    const tagEditorScenario = showTagEditor ? findScenarioById(scenarios, tagEditorScenarioId) : null
    const tagEditorTitle = showTagEditor ? createTagEditorTitle(tagEditorScenario, scenarioCounts) : undefined

    const treeLookup = createAmpExpressionTreeLookup(expressionTree)
    const quickFilterEnabled = treeLookup.quickFilterBranch.isEnabled
    const advancedFilterEnabled = treeLookup.advancedFilterBranch.isEnabled

    return (
      <ContentContainer>
        <Paper style={{ height: "100%", padding: 8 }}>
          <div className={classes.root}>
            <Typography variant="h5" style={{ alignSelf: 'end' }}>AMP Cases</Typography>
            <ResultViewControls
              view={resultsViewState}
              user={user}
              className={classes.viewControls}
              onChangeView={this.handleChangeView}
              onDownload={downloadCasesInFilter}
            />
            <ResultsSortControls
              className={classes.sort}
              column={sortColumn}
              direction={sortDirection}
              variables={filterBarVariables}
              onColumnChange={this.handleSortColumnChange}
              onDirectionChange={this.handleSortDirectionChange}
            />
            <ResultsSummary
              className={classes.summary}
              scenarioCounts={scenarioCounts}
              onAddTagClick={this.handleShowTagEditor}
            />
            <FilterChoices
              treeLookup={treeLookup}
              className={quickFilterEnabled ? classes.choices : classes.choicesFullWidth}
              onFilterChange={this.handleFilterBuilderChange}
              onQuickFilterChange={this.handleListVariableChange}
              onQuickFilterClear={this.handleQuickFilterClear}
            />
            <FilterBar
              visible={quickFilterEnabled}
              className={classes.filterBar}
              filterParams={filterParams}
              variables={filterBarVariables}
              expandedSet={new Set(expandedQuickFilters)}
              counts={scenarioCounts.variableCounts}
              onChange={this.handleListVariableChange}
              onExpansionChange={this.handleExpandedQuickFilterChange}
              onExpandAll={this.handleExpandAllQuickFilters}
              onCollapseAll={this.handleCollapseAllQuickFilters}
              onTextSearch={this.handleTextSearchChange}
            />
            <FilterBuilderPanel
              visible={advancedFilterEnabled}
              expressionTree={expressionTree}
              onChange={this.handleFilterBuilderChange}
              className={quickFilterEnabled ? classes.filterBuilder : classes.filterBuilderFullWidth}
            />
            <ResultsView
              view={resultsViewState}
              scenarios={scenarios}
              className={quickFilterEnabled ? classes.gallery : classes.galleryFullWidth}
              variables={filterBarVariables}
              column={sortColumn}
              direction={sortDirection}
              onGoToPage={this.handleGoToPage}
              onColumnChange={this.handleSortColumnChange}
              onDirectionChange={this.handleSortDirectionChange}
              onEditTags={this.handleShowTagEditor}
            />
          </div>
        </Paper>

        <AmpDialog
          open={showTagEditor}
          title={tagEditorTitle}
          onClose={this.handleTagDialogClose}
        >
          <ConnectedTagEditor scenario={tagEditorScenario} filterCount={scenarioCounts.filtered}/>
        </AmpDialog>

      </ContentContainer>
    )
  }
}

const mapStateToProps = state => ({
  scenarios: getScenarios(state),
  scenarioCounts: getScenarioCounts(state),
  filterVarsLookup: getFilterVarsLookup(state),
  expressionTree: getExpressionTree(state),
  sortColumn: getScenarioSortColumn(state),
  sortDirection: getScenarioSortDirection(state),
  user: getUserProfile(state),
  resultsViewState: getScenarioBrowserViewState(state),
  expandedQuickFilters: getScenarioBrowserExpandedQuickFilters(state),
})

const mapDispatchToProps = {
  newExpressionTree,
  setScenarios,
  setScenarioSortColumn,
  setScenarioSortDirection,
  setScenarioPageIndex,
  filterImmediate,
  fetchFilterVars,
  downloadCasesInFilter,
  setScenarioBrowserViewState,
  setScenarioBrowserExpandedQuickFilters,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ScenarioBrowser)
