import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const styles = theme => ({
  root: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const CaptionLink = ({ classes, children, onClick }) => {
  return (
    <Link onClick={onClick}>
      <Typography className={classes.root} variant="caption">{children}</Typography>
    </Link>
  );
};

CaptionLink.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default withStyles(styles)(CaptionLink);