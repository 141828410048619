import React from 'react';
import PropTypes from 'prop-types';
import identity from "crocks/combinators/identity";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import AmpTable from "../../../common/components/AmpTable";
import AmpDialog from "../../../common/components/AmpDialog";
import ContentContainer from "../../../common/components/ContentContainer";
import BackWithHeading from "../BackWithHeading";
import { getColumnData, isLoading, joinIrbCertificatesToUsers } from "./certTableHelpers";
import { createIrbCertificate, userIsIrb } from "../../../common/models";
import IrbCertificateViewer from "./IrbCertificateViewer";
import ConfirmAmendToIrbDialog from "./ConfirmAmendToIrbDialog";

const nullCert = createIrbCertificate();

const LoadingMessage = ({ message }) => (
  <div style={{ display: 'grid', height: '100%', justifyItems: 'center' }}>
    <Typography variant="h4">{message}</Typography>
  </div>
);

const styles = theme => ({
  root: {
    height: '100%',
    margin: '0 auto',
    padding: theme.spacing(),
  },
});

class IrbReview extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    requests: PropTypes.object,
    users: PropTypes.array,
    irbCertificates: PropTypes.array,
    onAmendUserToIrb: PropTypes.func,
    onSubmitIrbReview: PropTypes.func,
  };

  static defaultProps = {
    profile: {},
    requests: {},
    users: [],
    irbCertificates: [],
    onAmendUserToIrb: identity,
    onSubmitIrbReview: identity,
  };

  state = {
    cert: nullCert,
    showDialog: false,
    amendCert: null,
    showAmendDialog: false,
  };

  handleReviewClick = cert => this.setState({ cert, showDialog: true });
  handleAmendClick = amendCert => this.setState({ amendCert, showAmendDialog: true });

  handleAmendOk = () => {
    const { userId } = this.state.amendCert;
    this.props.onAmendUserToIrb(userId);
    this.handleAmendCancel();
  };

  handleClose = () => this.setState({ cert: nullCert, showDialog: false });
  handleAmendCancel = () => this.setState({ amendCert: null, showAmendDialog: false });

  render() {
    const { cert, showDialog, amendCert, showAmendDialog } = this.state;
    const { profile, requests, users, irbCertificates, classes, onSubmitIrbReview } = this.props;

    const isUserIrb = userIsIrb(profile);
    const columnData = getColumnData(isUserIrb, this.handleReviewClick, this.handleAmendClick);

    const componentToRender = isLoading(requests)
      ? <LoadingMessage message="Loading Certificates..." />
      : <AmpTable columnData={columnData} items={joinIrbCertificatesToUsers(irbCertificates, users)} />;

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <BackWithHeading heading="IRB Certificates"/>
          {componentToRender}
        </Paper>

        <AmpDialog
          open={showDialog}
          title={"Review IRB Certificate"}
          onClose={this.handleClose}
        >
          <IrbCertificateViewer
            key={cert.id}
            cert={cert}
            profile={profile}
            users={users}
            requests={requests}
            irbCertificates={irbCertificates}
            onSubmitIrbReview={onSubmitIrbReview}
          />
        </AmpDialog>

        <ConfirmAmendToIrbDialog
          open={showAmendDialog}
          name={amendCert ? amendCert.name : ''}
          onOk={this.handleAmendOk}
          onCancel={this.handleAmendCancel}
        />
      </ContentContainer>
    );
  }
}

export default withStyles(styles)(IrbReview);