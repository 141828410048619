import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

class ConfirmAmendToIrbDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const { open, name, onOk, onCancel } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Amend to IRB?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make {name} a Qualified Researcher?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">No</Button>
          <Button onClick={onOk} variant="contained" color="primary" autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmAmendToIrbDialog;
