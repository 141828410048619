import React from 'react';
import PropTypes from 'prop-types';
import compose from "crocks/helpers/compose";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import SubmitButton from "../../../common/components/SubmitButton";
import { connect } from "react-redux";
import AppRequest from "../../../common/models/AppRequest";
import AppRequestState from "../../../common/models/AppRequestState";
import { isEmptyString } from "../../../common/util/string";
import { addOrganization } from "../../../common/store/actions";
import { stringProp } from "../../../common/util/object";
import { getRequestState } from "../../../common/store";
import MembershipSelect from "./MembershipSelect";

const request = AppRequest.POST_ORGANIZATION;
const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: 'Adding...',
  [AppRequestState.SUCCESS]: 'Organization added.',
  [AppRequestState.FAILURE]: 'Failed to add the organization.',
};

const styles = theme => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing(2),
    }
  }
});

class AddOrganizationForm extends React.PureComponent {
  static propTypes = {
    memberships: PropTypes.array.isRequired,
  };

  state = {
    name: "",
    membershipId: 0,
  };

  handleNameChange = ev => this.setState({ name: ev.target.value });
  handleMembershipChange = ev => this.setState({ membershipId: ev.target.value });

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.addOrganization(this.state.name, this.state.membershipId);
  };

  render() {
    const { name, membershipId } = this.state;
    const { memberships, classes, requestState } = this.props;

    const disabled = isEmptyString(name) || membershipId === 0;
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          required
          type="text"
          margin="normal"
          label="Organization Name"
          onChange={this.handleNameChange}
        />
        <MembershipSelect
          value={membershipId}
          memberships={memberships}
          onChange={this.handleMembershipChange}
        />
        <SubmitButton
          label="Add Organization"
          stateMsg={stateMsg}
          requestState={requestState}
          disabled={disabled}/>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  requestState: getRequestState(state, request),
});
const mapDispatchToProps = { addOrganization };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AddOrganizationForm);

export const addOrganizationDialogInfo = {
  dialogTitle: 'Add a New Organization',
  dialogText: "Enter the new organization's name and membership level.",
};

