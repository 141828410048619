import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

const BackToDashboard = () => (
  <IconButton component={Link} to="/admin">
    <ArrowBackIcon/>
  </IconButton>
);

export default BackToDashboard;