/*
 * The functions in this file deal with the `filterParams` object, which is used to save the
 * current settings of the user's filter parameters. The `filterParams` is just a plain object
 * that holds arbitrary keys and values used by the expression builders in filterExperssion.js
 * to create expression tree nodes (group ops and/or value expressions). There is no particular
 * format for the `filterParams`. The expression builders use the accessors in this file to pull
 * data out of and put data into the `filterParams` object.  In other words, all code outside of
 * this file should treat the filterParams as an opaque object.
 *
 * The conventions currently in use for `filterParams` data is that list variables use the variable
 * name as the `filterParams` key and the value is a `Set` containing the user's current selections
 * for that variable (i.e. the values that are currently "checked" in the UI).
 *
 * The text search field, on the other hand, uses a key string set by the `TEXT_SEARCH_LABEL` constant
 * and the corresponding value is an object containing the string that the user has typed into
 * the text search textfield.
 *
 * The `filterParams` object and the expression builders provide a very flexible means to turn
 * user selections into expression tree nodes that can be processed by the server in order to
 * filter the scenarios in the database.
 *
 * In addition to being able to turn `filterParams` into expression tree nodes using builders as
 * explained above, we also need to be able to create a `filterParams` object given an expression
 * tree.  The mechanism for doing this relies on the `label` field of expression tree nodes. First,
 * the branch of the expression tree that holds all the expression tree nodes built from the filter
 * bar is labeled with a special tag to keep it separate from the branch holding expressions built
 * from the advanced filter interface.
 *
 * Further, each expression builder tags the nodes it builds with a special label to identify it
 * when the time comes to rebuild the `filterParams` object from the expression tree (i.e. when
 * the app is first loaded in order to restore the user's previous filter parameters).
 */

import safe from "crocks/Maybe/safe";
import prop from "crocks/Maybe/prop";
import propPath from "crocks/Maybe/propPath";
import isString from "crocks/predicates/isString";
import compose from "crocks/helpers/compose";
import identity from "crocks/combinators/identity";
import { isNotNil } from "../../../common/util/predicates";
import { findNodeByLabel } from "../../../common/models/index";
import { textSearchExpressionExtractor } from "./filterExpression";
import { listVariableIds, TEXT_SEARCH_LABEL } from "./constants";

/**
 * @returns {*} A list of variables that match the provided IDs. Any ID that doesn't correspond
 *    to a variable in filterVarsLookup will be filtered out.
 */
export const createVariableListFromIds = (filterVarsLookup, ids) =>
  ids.map(id => filterVarsLookup[id])
    .filter(isNotNil);

/**
 * Given the current filterParams, look up the current selections for a variable by name.
 */
export const getListVarSelections = (filterParams, name) =>
  safe(isString, name)
    .chain(name => prop(name, filterParams))
    .either(() => new Set(), identity);

/**
 * Create and return a new filterParams object with the given selections for the given name.
 * This function does not mutate the original filterParams object.
 */
export const setListVarSelections = (filterParams, name, selected) => ({ ...filterParams, [name]: selected });

/**
 * Clear all list var selections out of the supplied filterParams while preserving the text search settings.
 */
export const clearListVarSelections = filterParams =>
  prop(TEXT_SEARCH_LABEL, filterParams)
    .map(textSearchObj => ({ [TEXT_SEARCH_LABEL]: textSearchObj }))
    .option({});

/**
 * Given the current filterParams, look up and return the current text search string.
 */
export const getTextSearch = filterParams =>
  safe(isNotNil, filterParams)
    .chain(propPath([TEXT_SEARCH_LABEL, 'search']))
    .map(String)
    .option('');

/**
 * Create and return a new filterParams object using the new text search string.
 */
export const setTextSearch = (filterParams, search) => ({ ...filterParams, [TEXT_SEARCH_LABEL]: { search } });

const findNodeForFilterVar = galleryBranch => filterVar =>
  isNotNil(filterVar) ? findNodeByLabel(galleryBranch, filterVar.name) : undefined;

/**
 * Create a function that, when passed a filterParams object, uses the filterVarsLookup and the
 * galleryBranch to populate the filterParams object with all of the list var selections present
 * in the galleryBranch.
 *
 * @return {function({}): {}} A function that takes filterParams and returns a mutated filterParams.
 */
export const createListVarFilterParamsBuilder = (galleryBranch, filterVarsLookup) =>
  filterParams =>
    listVariableIds.map(id => filterVarsLookup[id])
      .map(findNodeForFilterVar(galleryBranch))
      .filter(isNotNil)
      .reduce((params, exp) => setListVarSelections(params, exp.variable.name, new Set(exp.selected)), filterParams);

/**
 * Create a function that, when passed a filterParams object, uses the galleryBranch to populate
 * the filterParams object with the current text search parameter.
 *
 * @return {function({}): {}} A function that takes filterParams and returns a mutated filterParams.
 */
export const createTextSearchFilterParamsBuilder = galleryBranch => filterParams =>
  setTextSearch(filterParams, textSearchExpressionExtractor(galleryBranch));

/**
 * Create and return the filterParams that correspond to the current expressions in the gallery
 * branch of the expression tree.
 */
export const buildFilterParamsFromGalleryBranch = (galleryBranch, filterVarsLookup) =>
  compose(
    createListVarFilterParamsBuilder(galleryBranch, filterVarsLookup),
    createTextSearchFilterParamsBuilder(galleryBranch)
  )({});

