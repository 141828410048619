import React from 'react';
import PropTypes from 'prop-types';
import isNil from "crocks/predicates/isNil";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { formatDayMonthYear } from "../../../common/util/date";

class AmendedDateTableCell extends React.PureComponent {
  static propTypes = {
    row: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  };

  handleClick = () => this.props.onClick(this.props.row);

  render() {
    const { row } = this.props;

    // Cert must be reviewed before user can be amended
    if (row.approved === false) {
      return null;
    }

    return isNil(row.amendedToIrbDate)
      ? <Button variant="contained" color="primary" onClick={this.handleClick}>Add</Button>
      : <Typography>{formatDayMonthYear(row.amendedToIrbDate)}</Typography>;
  }
}

export default AmendedDateTableCell;
