import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import safe from "crocks/Maybe/safe";
import { isNotEmpty } from "../../../../common/util/predicates";
import TagContainer from "../../common/components/TagContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { stringCompare } from "../../../../common/util/string";

const createTagMapper = (onClick, className) => tag => (
    <TagContainer
      assigned
      key={tag}
      variant={tag.indexOf(':') >= 0 ? 'shared' : 'private'}
      className={className}
      onClick={onClick}
    >
      <Typography color='inherit' variant="subtitle2">{tag}</Typography>
    </TagContainer>
  );

const createTags = (maxCount, onClick, className) => tags =>
  [...tags]
    .sort(stringCompare)
    .map(createTagMapper(onClick, className));

const createAddTag = (onClick, className) => [
  <TagContainer key="add" className={className} onClick={onClick}>
    <Typography color="inherit">+ Add Tags</Typography>
  </TagContainer>
];

const tagsToEls = (tagNames, maxCount, onClick, className, textClass) =>
  safe(isNotEmpty, tagNames)
    .map(createTags(maxCount, onClick, className, textClass))
    .option(createAddTag(onClick, className));

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    marginRight: 8,
    marginBottom: 8,
    cursor: 'pointer',
  }
};

const ScenarioViewerTagList = ({ tagNames, maxCount, onClick, className, classes }) => {
  return (
    <div className={classNames(classes.root, className)}>
      {tagsToEls(tagNames, maxCount, onClick, classes.tag)}
    </div>
  );
};

ScenarioViewerTagList.propTypes = {
  tagNames: PropTypes.array,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

ScenarioViewerTagList.defaultProps = {
  tagNames: [],
};

export default withStyles(styles)(ScenarioViewerTagList);
