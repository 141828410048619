import React from 'react';
import { connect } from "react-redux";
import compose from "crocks/helpers/compose";
import composeK from "crocks/helpers/composeK";
import propPath from "crocks/Maybe/propPath";
import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';
import OptionIcon from "@material-ui/icons/Settings";
import CloudIcon from '@material-ui/icons/CloudDownloadOutlined';

import Report from "./Report";
import ReportHeader from "./ReportHeader";
import MemberContactInfo from "./MemberContactInfo";
import AmpTable from "../../common/components/AmpTable";
import AmpDialog from "../../common/components/AmpDialog";
import OrgInfoDialog from "./OrgInfoDialog";
import ColumnType from "../../common/components/AmpTable/ColumnType";
import InviteUserForm, { inviteUserDialogInfo } from "../../common/components/forms/InviteUserForm";
import UserTableHeader from "../../common/components/UserTableHeader";
import ContentContainer from "../../common/components/ContentContainer";

import { getLocalPortalAdmins, getUsers } from "../../common/store";
import { createOrganization } from "../../common/models";
import { safeAsInteger } from "../../common/util/types";
import { fetchLocalPortalAdmins, fetchUsers, setUsers } from "../../common/store/actions";
import { isArrayEmpty } from "../../common/util/predicates"

const { STRING, INTEGER, BOOLEAN, DATE } = ColumnType;

const columnData = [
  { id: 'id', label: 'ID', type: INTEGER, width: '20px' },
  { id: 'firstName', label: 'First Name', type: STRING },
  { id: 'lastName', label: 'Last Name', type: STRING },
  { id: 'email', label: 'Email', type: STRING },
  { id: 'phoneNumber', label: 'Phone Number', type: STRING },
  { id: 'enabled', label: 'Enabled', type: BOOLEAN },
  { id: 'invitedDate', label: 'Invited', type: DATE },
  { id: 'registeredDate', label: 'Registered', type: DATE },
  { id: 'certUploadDate', label: 'IRB Cert Submitted', type: DATE },
  { id: 'certReviewDate', label: 'IRB Cert Reviewed', type: DATE },
  { id: 'certApproved', label: 'IRB Cert Approved', type: BOOLEAN },
  { id: 'amendedToIrbDate', label: 'Amended to IRB', type: DATE },
  { id: 'lastLoginDate', label: 'Last Login', type: DATE },
];

const buildParamPropPath = name => propPath(['match', 'params', name]);

const getIntegerFromPathProp = name => composeK(safeAsInteger, buildParamPropPath(name));

const getOrgIdFromProps = getIntegerFromPathProp("orgId");

const buildOrgList = (orgId, admin) => [createOrganization({ id: orgId, name: admin.orgName })];

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    height: '100%',
  },
  header: {
    backgroundColor: 'white',
    boxShadow: '0 2px 4px #888',
    marginBottom: 2,
    padding: theme.spacing(),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  table: {
    overflowX: 'auto',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px #888',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  reportSection: {
    marginBottom: 2,
    padding: theme.spacing(),
    backgroundColor: 'white',
    boxShadow: '0 2px 4px #888',
    borderRadius: 0,
  },
  reports: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(),
    display: 'flex',
    flexWrap: 'wrap',
  }
});

class MemberDetails extends React.PureComponent {
  state = {
    showInfoDialog: false,
    showInviteDialog: false,
    showDisabledUsers: false,
  };

  componentDidMount() {
    const orgId = getOrgIdFromProps(this.props).option();
    const { setUsers, fetchUsers, fetchLocalPortalAdmins } = this.props;

    setUsers([]);
    fetchLocalPortalAdmins()
      .then(() => fetchUsers(orgId));
  }

  handleInviteClick = () => this.setState({ showInviteDialog: true });
  handleInviteClose = () => this.setState({ showInviteDialog: false });

  handleInfoClick = () => this.setState({ showInfoDialog: true });
  handleInfoClose = () => this.setState({ showInfoDialog: false });

  handleShowEnabledUserToggle = () => this.setState({ showDisabledUsers: !this.state.showDisabledUsers});

  render() {
    const { showInfoDialog, showInviteDialog, showDisabledUsers } = this.state;
    const { users, localPortalAdmins, classes } = this.props;
    const { dialogText, dialogTitle } = inviteUserDialogInfo;

    const orgId = getOrgIdFromProps(this.props).option();
    const admins = localPortalAdmins.filter(a => a.orgId === orgId);

    if (isArrayEmpty(admins)) {
      return null;
    }

    const userData = showDisabledUsers ? users :users.filter(u => u.enabled === true);

    return (
      <ContentContainer>
        <div className={classes.root}>
          <div className={classes.header}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <MemberContactInfo admins={admins}/>
              <IconButton onClick={this.handleInfoClick} style={{ marginTop: -8 }}><OptionIcon/></IconButton>
            </div>
          </div>

          <div className={classes.reportSection}>
            <ReportHeader/>
            <div className={classes.reports}>
              <Report
                icon={<CloudIcon/>}
                title="User Downloads"
                description="All the downloads initiated by users in your organization"
                url={`/api/event/report?orgID=${orgId}`}
                onClick={this.handleDownloadReportClick}
              />
            </div>
          </div>

          <div className={classes.table}>
            <UserTableHeader
              onInviteUser={this.handleInviteClick}
              showDisabledUsers={showDisabledUsers}
              onToggleEnabledUsers={this.handleShowEnabledUserToggle}
            />
            <AmpTable columnData={columnData} items={userData}/>
          </div>
        </div>

        <AmpDialog open={showInviteDialog} text={dialogText} title={dialogTitle} onClose={this.handleInviteClose}>
          <InviteUserForm orgId={orgId} organizations={buildOrgList(orgId, admins)}/>
        </AmpDialog>

        <OrgInfoDialog
          open={showInfoDialog}
          orgId={admins[0].orgId}
          orgName={admins[0].orgName}
          onClose={this.handleInfoClose}
        />
      </ContentContainer>
    );
  }
}

const mapStateToProps = state => ({
  users: getUsers(state),
  localPortalAdmins: getLocalPortalAdmins(state),
});

const mapDispatchToProps = { fetchLocalPortalAdmins, fetchUsers, setUsers };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MemberDetails);
