import React from 'react';
import { TextField, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { validate } from 'email-validator';
import SubmitButton from "../../common/components/SubmitButton";

export default class FormDialog extends React.Component {
  state = { email: "" };

  handleChange = ev => this.setState({ email: ev.target.value });
  handleSubmit = ev => {
    ev.preventDefault();
    this.props.onSubmit(this.state.email);
  };

  render() {
    const { open, onClose } = this.props;
    const { email } = this.state;
    const validEmail = validate(email);

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Reset Link</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#222' }}>
            Provide the email address you use to sign in to the AMP website. An email containing
            a password reset link will be sent to you.
          </DialogContentText>
          <form onSubmit={this.handleSubmit}>
            <TextField
              fullWidth
              autoFocus
              type="email"
              margin="normal"
              label="Email Address"
              onChange={this.handleChange}
            />
            <SubmitButton label="Send Email" disabled={!validEmail} />
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}