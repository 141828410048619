import map from "crocks/pointfree/map"
import { createDeleteThunk, createGetThunk, createPutThunk } from "./utils"
import { AppRequest, createAnalyticsApp } from "../../models"

const processGetAnalyticsApps = () => map(createAnalyticsApp)

export const fetchAnalyticsAppsFromServer = () =>
  createGetThunk({
    request: AppRequest.GET_ANALYTICS_APPS,
    url: '/api/shiny/apps/analytics',
    responseFnFactory: processGetAnalyticsApps
  })

export const fetchFunctionalScenariosFromServer = () =>
  createGetThunk({
    request: AppRequest.GET_FUNCTIONAL_SCENARIO_APPS,
    url: '/api/shiny/apps/functional',
    responseFnFactory: processGetAnalyticsApps
  })

export const putAnalyticsIds = (appId = '', appName = '', stateId = '', eventIds = []) =>
  createPutThunk({
    request: AppRequest.PUT_ANALYTICS_TAG_EVENT_IDS,
    url: '/api/tag/analytics/event',
    body: { appId, appName, stateId, eventIds },
  })

export const deleteAnalyticsIds = () =>
  createDeleteThunk({
    request: AppRequest.DELETE_ANALYTICS_TAG_EVENT_IDS,
    url: '/api/tag/analytics/event',
  })
