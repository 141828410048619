import React from 'react';
import PropTypes from 'prop-types';

class AsyncImg extends React.PureComponent {
  static propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
  };

  static defaultProps = {
    src: '',
    alt: '',
    className: '',
  };

  state = {
    loaded: false,
  };

  componentDidMount () {
    if (this.img && this.img.complete && !this.state.loaded) {
      this.handleLoad();
    }
  }

  handleLoad = () => {
    this.setState({ loaded: true });
    this.props.onLoad();
  };

  ref = img => this.img = img;

  render() {
    return <img ref={this.ref} alt={this.props.alt} { ...this.props }/>;
  }
}

export default AsyncImg;
