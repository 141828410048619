import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles/index";
import { Grid, Paper, Typography } from "@material-ui/core/";
import ContentContainer from "./ContentContainer";

const styles = theme => ({
  paper: {
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
});

const TitledFormContainer = ({ children, classes, title, email }) => (
  <ContentContainer>
    <Paper elevation={4} className={classes.paper}>
      <Grid container className={classes.header} alignItems="baseline">
        <Grid item style={{ flex: '1 0 auto' }}>
          <Typography variant="h6" color="primary">{title}</Typography>
          <Typography variant="subtitle1">{email}</Typography>
        </Grid>
      </Grid>
      {children}
    </Paper>
  </ContentContainer>
);

TitledFormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TitledFormContainer);

