import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Typography, withStyles } from "@material-ui/core/";
import IrbUploadButton from "../common/components/IrbUploadButton";
import { pipe } from "../common/util/func";
import AppRequestState from "../common/models/AppRequestState";
import { connect } from "react-redux";
import { resetRequestState, uploadIrbCertificate } from "../common/store/actions";
import RequestStatusDisplay from "../common/components/RequestStatusDisplay";
import { withRouter } from "react-router-dom";
import { stringProp } from "../common/util/object";
import AppRequest from "../common/models/AppRequest";

const request = AppRequest.POST_IRB_CERTIFICATE;
const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: "Uploading Certificate...",
  [AppRequestState.FAILURE]: "Could not upload at this time, please try again later.",
  [AppRequestState.SUCCESS]: "Certificate Uploaded",
};

const isUploadButtonHidden = requestState => requestState !== AppRequestState.NONE;
const isSkipButtonHidden = requestState => requestState === AppRequestState.IN_FLIGHT;

const buttonText = requestState =>
  requestState === AppRequestState.NONE ? 'Skip Uploading For Now' : 'Continue to Site';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
    '& > *:first-child': {
      marginBottom: theme.spacing(2),
    },
  },
  hidden: {
    display: 'none',
  }
});

class IrbUploadForm extends React.Component {
  componentDidMount() {
    this.props.resetRequestState(request);
  }

  handleSkip = () => this.props.history.push('/');

  handleUpload = file => {
    const formData = new FormData();
    formData.append("file", file);
    this.props.uploadIrbCertificate(formData);
  };

  render() {
    const { classes, requestState } = this.props;
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    const uploadButtonClass = classNames({ [classes.hidden]: isUploadButtonHidden(requestState) });
    const skipButtonClass = classNames({ [classes.hidden]: isSkipButtonHidden(requestState) });

    return (
      <div className={classes.root}>
        <Typography>
          This website contains human subjects data. You will not be able to access the full site
          until you provide proof of IRB certification. If you have an image file or a .pdf file
          containing your IRB certificate you can upload it now.
        </Typography>
        <IrbUploadButton className={uploadButtonClass} handleUpload={this.handleUpload}/>
        <RequestStatusDisplay requestState={requestState} stateMsg={stateMsg}/>
        <Button className={skipButtonClass} variant="contained" onClick={this.handleSkip}>
          {buttonText(requestState)}
        </Button>
      </div>
    )
  }
}

IrbUploadForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default pipe(
  connect(
    ({ app }) => ({ requestState: app.requests[request] }),
    { resetRequestState, uploadIrbCertificate }
  ),
  withStyles(styles),
  withRouter
)(IrbUploadForm);
