import compose from "crocks/helpers/compose";
import { serializeExpressionTree } from "../../models";
import { PUSH_EXPRESSION_TREE } from "./index";
import { writeExpressionTree } from "../../services/localStorage";

const storeExpressionTree = compose(writeExpressionTree, serializeExpressionTree);

export const pushExpressionTree = expressionTree => ({ type: PUSH_EXPRESSION_TREE, payload: { expressionTree } });

export function newExpressionTree(expressionTree) {
  return (dispatch) => {
    storeExpressionTree(expressionTree);
    dispatch(pushExpressionTree(expressionTree));
  }
}

