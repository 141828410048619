import constant from "crocks/combinators/constant";
import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideNull, provideZero } from "../util/providers";

/**
 * Possible values for News.type
 *
 * @type {Object.<{
 *    MEMBER_NEWS: number,
 *    TOOL_UPDATE: number
 * }>}
 */
export const NewsType = Object.freeze({
  MEMBER_NEWS: 1,
  TOOL_UPDATE: 2,
});

const newsProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: "startTime", defaultProvider: provideNull },
  { name: "endTime", defaultProvider: provideNull },
  { name: 'type', defaultProvider: constant(NewsType.MEMBER_NEWS) },
  { name: 'title', defaultProvider: provideEmptyString },
  { name: 'body', defaultProvider: provideEmptyString },
];

/**
 * Create a canonical News object.
 *
 * createMembership :: Object -> News
 */
export const createNews = createFactoryFunc(newsProps);

/**
 * newsTypeToString :: Number -> String
 */
export const newsTypeToString = type => {
  switch (type) {
    case NewsType.MEMBER_NEWS:
      return "Member News";

    case NewsType.TOOL_UPDATE:
      return "Tool Update";

    default:
      return "Unknown";
  }
};