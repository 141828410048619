import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import safe from "crocks/Maybe/safe"
import map from "crocks/pointfree/map"
import option from "crocks/pointfree/option"
import compose from "crocks/helpers/compose"
import isString from "crocks/predicates/isString"
import withStyles from '@material-ui/core/styles/withStyles'
import DownloadIcon from "./DownloadIcon"
import { countCharReducer, stringReducer } from "../util/string"

const countSemicolons = stringReducer(countCharReducer(';'), 0)

const countVariables = compose(
  option(0),
  map(countSemicolons),
  safe(isString)
)

const layers = [
  { name: 'Algorithm', color: '#F7CBB0' },
  { name: 'Augmented', color: '#C25A23' },
  { name: 'Visualization', color: '#823C16' }
]

const layerToIconMapper = (count, onClick) => (layer, idx) =>
  idx < count
    ? <DownloadIcon key={layer.name} layer={layer.name} color={layer.color} onClick={onClick}/>
    : null

const styles = theme => ({
  root: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(),
    }
  },
})

class AmpDownloadIcons extends React.PureComponent {
  static propTypes = {
    horizontal: PropTypes.bool,
    ampLayer: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    horizontal: false,
  }

  render() {
    const { ampLayer, horizontal, onClick, className, classes } = this.props

    // Minus 1 to account for Portal layer, which is non-downloadable
    const downloadableLayersCount = countVariables(ampLayer) - 1

    const rootClasses = classNames(className, classes.root)
    const style = { flexDirection: horizontal ? 'row' : 'column' }

    return (
      <div className={rootClasses} style={style}>
        {layers.map(layerToIconMapper(downloadableLayersCount, onClick))}
      </div>
    )
  }
}

export default withStyles(styles)(AmpDownloadIcons)
