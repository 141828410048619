import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideZero } from "../util/providers";

const faqProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'sortOrder', defaultProvider: provideZero },
  { name: 'question', defaultProvider: provideEmptyString },
  { name: 'answer', defaultProvider: provideEmptyString },
];

/**
 * Create a canonical Faq (frequently asked question) object.
 *
 * createFaq :: Object -> Faq
 */
export const createFaq = createFactoryFunc(faqProps);

