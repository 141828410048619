import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { end } from "../util/array";

const createRoleChips = (roles, className) =>
  [...roles].sort()
    .map(role => end(role.split('_')))
    .map(str => <span key={str} className={className}>{str}</span>);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    color: '#888',
    margin: `0 ${theme.spacing()}px ${theme.spacing(0.5)}px 0`,
    padding: `2px 8px !important`,
    borderRadius: 20,
  },
});

class RoleChips extends React.PureComponent {
  static propTypes = {
    roles: PropTypes.array.isRequired,
  };

  render() {
    const { roles, classes } = this.props;
    return (
      <div className={classes.root}>
        {createRoleChips(roles, classes.chip)}
      </div>
    );
  }
}

export default withStyles(styles)(RoleChips);
