import React from 'react';
import PropTypes from 'prop-types';
import compose from "crocks/helpers/compose";
import CardIcon from '@material-ui/icons/Beenhere';
import ButtonIcon from '@material-ui/icons/PlaylistAddCheck';
import ActionButton from "../DashboardCard/ActionButton";
import CardStamp from "../DashboardCard/CardStamp";
import CardTitle from "../DashboardCard/CardTitle";
import CardActions from "../DashboardCard/CardActions";
import CardContent from "../DashboardCard/CardContent";
import DashboardCard from "../DashboardCard/index";
import IfUser from "../../../common/components/logic/IfUser";
import { UserRole } from "../../../common/models";
import AppRequest from "../../../common/models/AppRequest";
import { allRequestsSuccessful } from "../../../common/util/request";
import { filterByReviewPending } from "../../../common/models/irbCertificate";

const { GET_USERS, GET_IRB_CERTIFICATES } = AppRequest;
const isLoading = reqState => !allRequestsSuccessful(reqState, GET_USERS, GET_IRB_CERTIFICATES);

const countPending = certs => filterByReviewPending(certs).length;
const createPendingString = pendingCount => `${pendingCount} Review${pendingCount === 1 ? '' : 's'} Pending`;
const createSubtitle = compose(createPendingString, countPending);

const IrbReviewCard = ({ certs, requestState, onReviewSubmissions, onClick }) => (
  <DashboardCard onClick={onClick}>
    <CardStamp backgroundColor="#007E29" color="white">
      <CardIcon />
    </CardStamp>

    <CardContent>
      <CardTitle
        title="IRB Review"
        subtitle={isLoading(requestState) ? 'Loading...' : createSubtitle(certs)}
      />
      <IfUser hasAll={[UserRole.ROLE_IRB]}>
        <CardActions>
          <ActionButton
            icon={<ButtonIcon/>}
            label="Review Submissions"
            onClick={onReviewSubmissions}
            disabled={isLoading(requestState) || countPending(certs) === 0}
          />
        </CardActions>
      </IfUser>
    </CardContent>
  </DashboardCard>
);

IrbReviewCard.propTypes = {
  certs: PropTypes.array,
  requestState: PropTypes.object,
  onClick: PropTypes.func,
  onReviewSubmissions: PropTypes.func,
};

IrbReviewCard.defaultProps = {
  certs: [],
  requestState: {},
};

export default IrbReviewCard;