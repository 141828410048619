import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import UserMenu from "./UserMenu";
import { pipe } from "../util/func";

const menuId = "user-menu";

const styles = theme => ({
  buttonText: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    textTransform: "none"
  },
});

class AccountButton extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClose = () => this.setState({ anchorEl: null });
  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  render() {
    const { anchorEl } = this.state;
    const { classes, name } = this.props;
    const ariaOwns = anchorEl ? menuId : null;

    return (
      <div>
        <Button aria-owns={ariaOwns} onClick={this.handleClick}>
          <AccountCircleIcon />
          <Hidden mdDown>
            <Typography className={classes.buttonText} variant="subtitle1">{name}</Typography>
          </Hidden>
          <KeyboardArrowDownIcon />
        </Button>
        <UserMenu name={name} onClose={this.handleClose} anchor={anchorEl}/>
      </div>
    );
  }
}

AccountButton.propTypes = {
  name: PropTypes.string.isRequired,
};

export default pipe(
  withStyles(styles),
  withRouter
)(AccountButton);

