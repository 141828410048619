import React from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from "@material-ui/core/";

const styles = {
  hiddenInput: {
    display: 'none',
  },
};

const IrbUploadButton = ({ classes, handleUpload, label, className }) => {
  const handleSubmit = ev => {
    ev.preventDefault();
    handleUpload(ev.target.files[0])
  };

  return (
    <form className={className} autoComplete="off" encType="multipart/form-data"
          onSubmit={handleSubmit}>
      <input id="file-btn" type="file" accept="application/pdf,image/*"
             className={classes.hiddenInput} onChange={handleSubmit}/>
      <label htmlFor="file-btn">
        <Button variant="contained" color="primary" component="span">{label}</Button>
      </label>
    </form>
  );
};

IrbUploadButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleUpload: PropTypes.func.isRequired,
};

IrbUploadButton.defaultProps = {
  label: 'Upload IRB Certificate',
};

export default withStyles(styles)(IrbUploadButton);