import map from "crocks/pointfree/map";
import compose from "crocks/helpers/compose";
import { createDeleteThunk, createGetThunk, createPostThunk, createPutThunk } from "./utils";
import { AppRequest } from "../../models";
import { DELETE_FAQ, SET_FAQS, UPDATE_FAQ } from "./index";
import { createFaq } from "../../models/faq";

export const setFaqs = faqs => ({ type: SET_FAQS, payload: { faqs } });
export const updateFaq = faq => ({ type: UPDATE_FAQ, payload: { faq } });
export const deleteFaq = id => ({ type: DELETE_FAQ, payload: { id } });

const processGetFaqs = dispatch => compose(dispatch, setFaqs, map(createFaq));
const processEditFaq = dispatch => compose(dispatch, updateFaq, createFaq);
const processDeleteFaq = id => dispatch => () => dispatch(deleteFaq(id));

export const fetchFaqsFromServer = () =>
  createGetThunk({
    request: AppRequest.GET_FAQS,
    url: '/api/faq',
    responseFnFactory: processGetFaqs
  });

export const postFaqToServer = faq =>
  createPostThunk({
    request: AppRequest.PUT_POST_FAQ,
    url: '/api/faq',
    body: { ...faq },
    responseFnFactory: processEditFaq,
  });

export const putFaqToServer = faq =>
  createPutThunk({
    request: AppRequest.PUT_POST_FAQ,
    url: `/api/faq/${faq.id}`,
    body: { ...faq },
    responseFnFactory: processEditFaq,
  });

export const deleteFaqFromServer = id =>
  createDeleteThunk({
    request: AppRequest.DELETE_FAQ,
    url: `/api/faq/${id}`,
    responseFnFactory: processDeleteFaq(id),
  });
