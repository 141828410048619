import compose from "crocks/helpers/compose"
import constant from "crocks/combinators/constant"
import { withUuid } from "./helpers"
import { createFactoryFunc } from "./factory"
import { isArrayEmpty } from "../util/predicates"
import { createExpressionFromMemento, createExpressionMemento } from "./expression"
import { provideEmptyArray, provideEmptyString, provideFalse, provideTrue } from "../util/providers"

const isDefined = a => a !== undefined

export const GROUP_OPS = Object.freeze({
  AND: "AND",
  OR: "OR",
})

const groupOpProps = [
  { name: 'op', defaultProvider: constant(GROUP_OPS.OR) },
  { name: 'label', defaultProvider: provideEmptyString },
  { name: 'children', defaultProvider: provideEmptyArray },
  { name: 'isCollapsed', defaultProvider: provideFalse },
  { name: 'isEnabled', defaultProvider: provideTrue },
]

/**
 * Create a canonical GroupOp object with defaults where needed.
 *
 * createGroupOp :: Object -> GroupOp
 */
export const createGroupOp = compose(withUuid, createFactoryFunc(groupOpProps))

/**
 * @returns {boolean} True if the supplied node is a group node.  The determination is made based on
 * whether the node object has a "children" property defined.
 */
export const isGroupNode = node => node && node.children !== undefined

/**
 * Turns a string into a canonical GroupOp operation.
 * @returns {string} GROUP_OPS.AND if the string compares successfully, ignoring case, to "and".
 *    Otherwise returns GROUP_OPS.OR.
 */
export const stringToOp = opStr => String(opStr).toUpperCase() === GROUP_OPS.AND ? GROUP_OPS.AND : GROUP_OPS.OR

/**
 * @returns {string} The opposite operation relative to the op used in the groupOp param.  If the op
 *    param is not recognized the default return value is OR.
 */
export const getInvertedOpOf = op => op === GROUP_OPS.OR ? GROUP_OPS.AND : GROUP_OPS.OR

/**
 * @returns A new groupOp with the isEnabled property set inverse of the current value. This
 *    function is non-mutating.
 */
export const toggleGroupEnabled = groupOp => ({ ...groupOp, isEnabled: !groupOp.isEnabled })

/**
 * @returns A new groupOp with the isEnabled property set to the given value. This
 *    function is non-mutating.
 */
export const setGroupEnabled = (isEnabled, groupOp) => ({ ...groupOp, isEnabled })

/**
 * @returns A new groupOp with the isCollapsed property of the original inverted. This
 *    function is non-mutating.
 */
export const toggleCollapse = groupOp => ({ ...groupOp, isCollapsed: !groupOp.isCollapsed })

/**
 * @returns A new groupOp with the op property of the original inverted. This function is
 *    non-mutating.
 */
export const invertGroupOp = groupOp => ({ ...groupOp, op: getInvertedOpOf(groupOp.op) })

/**
 * Adds a new child to a groupOp without mutating the original groupOp.  Child should be an object returned
 * from createGroupOp or createExpression.
 *
 * @param groupOp The groupOp to which the child is added.
 * @param child The new child node: should be a GroupOp or an Expression.
 *
 * @returns A new groupOp that is identical to the original groupOp but with the additional
 *    child node added. This function does not mutate the original groupOp param.
 */
export const addChildToGroup = (child, groupOp) => ({ ...groupOp, children: [...groupOp.children, child] })

/**
 * Remove the specified child from this GroupOp if it's a child.
 *
 * @param child The child to remove.
 * @param groupOp The GroupOp from which the child will be removed.
 *
 * @returns {{children: *[]}} A new groupOp that is identical to the original groupOp but with the child node
 *    removed. If child is not part of this GroupOp, the original is returned unmodified.
 */
export function removeChildFromGroup(child, groupOp) {
  const old = groupOp.children
  const children = old.filter(c => c.uuid !== child.uuid)
  return children.length === old.length ? groupOp : { ...groupOp, children }
}

/**
 * Replace the oldChild with the newChild in this groupOp.
 *
 * @param oldChild The child to remove.
 * @param newChild The child that replaces oldChild
 * @param groupOp The GroupOp from which the child will be removed.
 *
 * @returns {{children: *[]}} A new groupOp that is identical to the original groupOp but with the child node
 *    removed. If child is not part of this GroupOp, the original is returned unmodified.
 */
export function replaceChildInGroup(oldChild, newChild, groupOp) {
  const children = groupOp.children
  const index = children.indexOf(oldChild)

  if (index < 0) {
    return groupOp
  }

  return {
    ...groupOp,
    children: [
      ...children.slice(0, index),
      newChild,
      ...children.slice(index + 1)
    ]
  }
}

/**
 * Remove all children from the provided GroupOp.
 * @returns {{children: Array}} A new groupOp that is identical to the original groupOp but with all children
 *    removed. If the original GroupOp has no children, the original is returned unmodified.
 */
export const clearChildrenFromGroup = groupOp =>
  isArrayEmpty(groupOp.children)
    ? groupOp
    : { ...groupOp, children: [] }

/**
 * Create a group or expression memento from the given child
 */
const createChildMemento = ch =>
  isGroupNode(ch)
    ? createGroupMemento(ch)
    : createExpressionMemento(ch)

/**
 * Create a memento object based on the specified groupOp.  Mementos contain all the information needed
 * to restore the state of the object at a later time.  Expression tree mementos are also used to
 * serialize the expression tree before it is written to disk or sent to the server.
 */
export const createGroupMemento = groupOp =>
  isGroupNode(groupOp) && groupOp.isEnabled !== false
    ? {
      groupOp: groupOp.op,
      label: groupOp.label,
      children: groupOp.children.map(createChildMemento).filter(isDefined)
    }
    : undefined

const isGroupMemento = memento => memento && memento.groupOp

const createChildFromMemento = lookup => memento =>
  isGroupMemento(memento)
    ? createGroupFromMemento(memento, lookup)
    : createExpressionFromMemento(memento, lookup)

/**
 *
 * @param groupOp
 * @param children
 * @param variableLookup
 * @returns {{op: string, children: any[]}}
 */
export function createGroupFromMemento({ groupOp, children = [] } = {}, variableLookup) {
  return createGroupOp({
    op: stringToOp(groupOp),
    label: groupOp.label || '',
    children: children.map(createChildFromMemento(variableLookup))
  })
}