import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from "./VideoPlayer";
import VideoControls from "./VideoPlayer/VideoControls";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";

const doIfScreenFull = fn => screenfull.enabled ? fn() : undefined;
const addFullScreenChangeHandler = fn => doIfScreenFull(() => screenfull.on('change', fn));
const removeFullScreenChangeHandler = fn => doIfScreenFull(() => screenfull.off('change', fn));
const goFullScreen = el => doIfScreenFull(() => screenfull.request(el));
const exitFullScreen = () => doIfScreenFull(() => screenfull.exit());

class AmpVideoPlayer extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    playing: PropTypes.bool,
    fullScreen: PropTypes.bool,
    playerMode: PropTypes.string,
    onSeekTo: PropTypes.func,
    onLoaded: PropTypes.func,
    onProgress: PropTypes.func,
    onDuration: PropTypes.func,
    onModeToggle: PropTypes.func,
    onPlaybackToggle: PropTypes.func,
    onFullScreenToggle: PropTypes.func,
    playbackSeconds: PropTypes.number,
    durationSeconds: PropTypes.number,
  };

  static defaultProps = {
    url: '',
    playing: false,
    playbackSeconds: 0,
    durationSeconds: 0,
  };

  componentDidMount() {
    addFullScreenChangeHandler(this.handleFullScreenChange);
  }

  componentWillUnmount() {
    removeFullScreenChangeHandler(this.handleFullScreenChange);
  }

  handleFullScreenChange = () => this.props.onFullScreenToggle(screenfull.isFullscreen);
  handleFullScreenClick = () => this.props.fullScreen ? exitFullScreen() : goFullScreen(this.playerNode);

  playerRef = player => {
    this.playerNode = findDOMNode(player);
  };

  render() {
    const { url, playing, fullScreen, playbackSeconds, durationSeconds, playerMode, className } = this.props;
    const { onLoaded, onProgress, onDuration, onSeekTo, onPlaybackToggle, onModeToggle } = this.props;

    return (
      <VideoPlayer
        className={className}
        style={{ height: 1000 }}
        url={url}
        playing={playing}
        fullScreen={fullScreen}
        onLoaded={onLoaded}
        onProgress={onProgress}
        onDuration={onDuration}
        onPlaybackToggle={onPlaybackToggle}
        ref={this.playerRef}
      >
        {
          controlsVisible => (
            <VideoControls
              playing={playing}
              fullScreen={fullScreen}
              controlsVisible={controlsVisible}
              playbackSeconds={playbackSeconds}
              durationSeconds={durationSeconds}
              playerMode={playerMode}
              onModeToggle={onModeToggle}
              onPlayPause={onPlaybackToggle}
              onSeekChange={onSeekTo}
              onFullScreen={this.handleFullScreenClick}
            />
          )
        }
      </VideoPlayer>
    );
  }
}

export default AmpVideoPlayer;
