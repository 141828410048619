import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FlipMove from "react-flip-move";
import IrbCertificateForm from "./IrbCertificateForm";
import { slideLeftEnter, slideLeftLeave } from "../../../common/util/flipAnimations";
import { findNextPending } from "./certListHelpers";
import { joinIrbCertificatesToUsers } from "./certTableHelpers";
import { certId, createIrbCertificate } from "../../../common/models/irbCertificate";

const nullCert = createIrbCertificate({ id: 0 });
const isValid = cert => certId(cert) > 0;

const selectCert = (cert, certs) =>
  isValid(cert)
    ? cert
    : findNextPending(certs, nullCert) || nullCert;

const styles = {
  root: {
    width: '90vw',
    height: '80vh',
    padding: 0,
    position: "relative",
  },
};

class IrbCertificateViewer extends React.PureComponent {
  static propTypes = {
    users: PropTypes.array,
    irbCertificates: PropTypes.array,
    cert: PropTypes.object,
    profile: PropTypes.object,
    requests: PropTypes.object,
    onSubmitIrbReview: PropTypes.func,
  };

  static defaultProps = {
    users: [],
    irbCertificates: [],
    cert: {},
    profile: {},
    requests: {},
  };

  static getDerivedStateFromProps(nextProps) {
    const { irbCertificates, users } = nextProps;
    const certs = joinIrbCertificatesToUsers(irbCertificates, users);
    return { certs }
  }

  state = {
    cert: selectCert(this.props.cert, this.props.irbCertificates),
    certs: [],
  };

  handleSubmitReview = review => {
    this.props.onSubmitIrbReview(this.state.cert.id, review);
    const cert = findNextPending(this.state.certs, this.state.cert) || nullCert;
    this.setState({ cert });
  };

  render() {
    const { cert } = this.state;
    const { classes } = this.props;

    return (
      <FlipMove className={classes.root} enterAnimation={slideLeftEnter} leaveAnimation={slideLeftLeave}>
        <IrbCertificateForm key={cert.id} cert={cert} onSubmit={this.handleSubmitReview} />
      </FlipMove>
    );
  }
}

export default withStyles(styles)(IrbCertificateViewer);