import React from 'react'
import ampLogo from './img/amp-logo.png'
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = (theme) => ({
  root: {
    background: 'white',
    padding: '20px 0 10px',
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: '50%',
    maxWidth: '1683px',
    margin: '0 auto',
  },
  subtitle: {
    backgroundColor: '#620C0D',
    marginTop: '1em',
    padding: '10px 0',
    color: 'white',
    fontWeight: 'normal',
    letterSpacing: '.15em',
    fontSize: '150%',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      letterSpacing: '0.5em',
      fontSize: '125%',
    },

    [theme.breakpoints.down('sm')]: {
      letterSpacing: '0.25em',
      fontSize: '100%',
    },
  }
})

const AmpBanner = ({ classes }) => (
  <div className={classes.root}>
    <Link to="/" className={classes.logoContainer}>
      <img className={classes.logo} src={ampLogo} alt="AMP Logo"/>
    </Link>
    <Typography className={classes.subtitle}>Data Access Portal</Typography>
  </div>
)

export default withStyles(styles)(AmpBanner)