import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import compose from "crocks/helpers/compose";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import ViewerControlBar from "./ViewerControlBar";
import PlaybackController from "./PlaybackController";
import ContentContainer from "../../../common/components/ContentContainer";
import { fetchTimeSeries, setScenarioIndex } from "../../../common/store/actions";
import { getScenarioIndex, getScenarios, getTimeSeriesCache } from "../../../common/store";
import { decrCurrentIndex, incrCurrentIndex, processNewScenario, } from "./scenarioViewerFuncs";

const goToPrevScenario = compose(processNewScenario, decrCurrentIndex);
const goToNextScenario = compose(processNewScenario, incrCurrentIndex);

const styles = theme => ({
  fullHeight: {
    height: '100%',
  },
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 80px',
    gridGap: theme.spacing(),
  },
});

class ScenarioViewer extends React.PureComponent {
  static propTypes = {
    scenarios: PropTypes.array,
    currentIndex: PropTypes.number,
    timeSeriesCache: PropTypes.object,
  };

  static defaultProps = {
    scenarios: [],
    currentIndex: 0,
    timeSeriesCache: {},
  };

  static getDerivedStateFromProps(nextProps) {
    return processNewScenario(nextProps)
  }

  state = {
    videoUrl: '',
    currentScenario: {},
  };

  handlePrev = () => this.setState(goToPrevScenario(this.props));
  handleNext = () => this.setState(goToNextScenario(this.props));

  render() {
    const { videoUrl, currentScenario } = this.state;
    const { scenarios, currentIndex, timeSeriesCache, classes } = this.props;

    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <PlaybackController
            videoUrl={videoUrl}
            scenario={currentScenario}
            timeSeriesEntry={timeSeriesCache[currentScenario.id]}
          />

          <ViewerControlBar
            className={classes.controls}
            scenarioCount={scenarios.length}
            currentIndex={currentIndex}
            onPrev={this.handlePrev}
            onNext={this.handleNext}
          />
        </Paper>
      </ContentContainer>
    );
  }
}

const mapStateToProps = state => ({
  scenarios: getScenarios(state),
  currentIndex: getScenarioIndex(state),
  timeSeriesCache: getTimeSeriesCache(state),
});

const mapDispatchToProps = { setScenarioIndex, fetchTimeSeries };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ScenarioViewer);
