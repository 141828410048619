import compose from "crocks/helpers/compose"
import constant from "crocks/combinators/constant"
import { stringProp } from "../util/object"
import { provideEmptyString, provideZero } from "../util/providers"
import { createFactoryFunc } from "./factory"


export const VariableId = Object.freeze({
  TAGS: 1,
  SHARED_TAGS: 2021,
  FINAL_NARRATIVE: 1394,
  CASE_ID: 1432,
  LANES: 2001,
  INTERACTIONS: 2002,
  TRAFFIC_FLOW: 2003,
  INITIAL_SPEED: 2004,
  DRIVER_FACTORS: 2005,
  OTHER_INVOLVED_VEHICLE: 2006,
  EVASIVE_MANEUVER: 2007,
  IN_CAB_ENVIRONMENT: 2008,
  ROADWAY_GEOMETRY: 2009,
  WEATHER_LIGHTING: 2010,
  CASE_TYPE: 2011,
  INITIAL_MANEUVER: 2012,
  OTHER_INVOLVED_OBJECT: 2013,
  ROADWAY_SURROUNDINGS: 2014,
  FEATURES_PRESENT: 2015,
  AMP_LAYER: 2016,
  INTERSECTION: 2017,
  TRAVERSALS: 2018,
  ROADWAY_SURFACE: 2019,
  REFERENCE_SPEED: 2020,
  EPOCH_TYPE: 2022,
  ENHANCED_DATASET: 2023,
  NHTSA_PRE_CRASH_TYPOLOGY_2019: 2024,
})

export const ANALYTICS_TAG_SELECTION_ID = -1

/**
 * The support types of filter variables.
 *
 * @type {Object.<{int: string, float: string, list: string, string: string}>}
 */
export const VariableType = Object.freeze({
  int: 'int',
  float: 'float',
  list: 'list',
  string: 'string',
})

/**
 * The list of comparison operators for int and string variables
 */
export const defaultNumberCompareOps = ["eq", "ne", "lt", "le", "gt", "ge"]
export const defaultStringCompareOps = ["eq", "ne", "cn", "nc"]

/**
 * The default array of choices for list variables.
 */
export const nullChoices = [{
  "id": 1,
  "choice": "Unknown"
}]

const withCmp = cmp => obj => ({ ...obj, cmp })

const intVarProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'columnName', defaultProvider: provideEmptyString },
  { name: 'type', defaultProvider: constant(VariableType.int) },
  { name: 'cmp', defaultProvider: constant(defaultNumberCompareOps) },
]

const floatVarProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'columnName', defaultProvider: provideEmptyString },
  { name: 'type', defaultProvider: constant(VariableType.float) },
  { name: 'cmp', defaultProvider: constant(defaultNumberCompareOps) },
]

const stringVarProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'columnName', defaultProvider: provideEmptyString },
  { name: 'type', defaultProvider: constant(VariableType.string) },
  { name: 'cmp', defaultProvider: constant(defaultStringCompareOps) },
]

const listVarProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'columnName', defaultProvider: provideEmptyString },
  { name: 'type', defaultProvider: constant(VariableType.list) },
  { name: 'choices', defaultProvider: constant(nullChoices) },
]

export const isIntVariable = variable => variable.type === VariableType.int
export const isFloatVariable = variable => variable.type === VariableType.float
export const isListVariable = variable => variable.type === VariableType.list

/**
 * Create a canonical integer filter variable description.
 *
 * createIntVariable :: Object -> IntVariable
 */
export const createIntVariable = compose(withCmp(defaultNumberCompareOps), createFactoryFunc(intVarProps))

/**
 * Create a canonical float filter variable description.
 *
 * createIntVariable :: Object -> FloatVariable
 */
export const createFloatVariable = compose(withCmp(defaultNumberCompareOps), createFactoryFunc(floatVarProps))

/**
 * Create a canonical string filter variable description.
 *
 * createStringVariable :: Object -> StringVariable
 */
export const createStringVariable = compose(withCmp(defaultStringCompareOps), createFactoryFunc(stringVarProps))

/**
 * Create a canonical list filter variable description.
 *
 * createListVariable :: Object -> ListVariable
 */
export const createListVariable = createFactoryFunc(listVarProps)

/**
 * Returns a canonical int, string, or list variable depending on what is passed in.
 */
export const createVariable = variable => {
  switch (variable.type) {
    case VariableType.int:
      return createIntVariable(variable)
    case VariableType.float:
      return createFloatVariable(variable)
    case VariableType.list:
      return createListVariable(variable)
    case VariableType.string:
      return createStringVariable(variable)
    default:
      return undefined
  }
}

const opLookup = {
  "eq": "=",
  "ne": "≠",
  "lt": "<",
  "le": "≤",
  "gt": ">",
  "ge": "≥",
  "cn": "contains",
  "nc": "does not contain"
}

export const displayStringForOp = op => stringProp(op, opLookup).option('')