import React from 'react';
import PropTypes from 'prop-types';
import TimeSeriesValue from "./TimeSeriesValue";

class TimeSeriesState extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    time: PropTypes.number,
    activeValues: PropTypes.array,
    children: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    time: 0,
    activeValues: [1.0],
    children: (active, value) => <div>{value}</div>
  };

  static getDerivedStateFromProps(nextProps) {
    return { activeSet: new Set(nextProps.activeValues) };
  }

  state = {
    activeSet: new Set(),
  };

  render() {
    const { activeSet } = this.state;
    const { data, time, children } = this.props;

    return (
      <TimeSeriesValue data={data} time={time}>
        {val => children(activeSet.has(val), val)}
      </TimeSeriesValue>
    );
  }
}

export default TimeSeriesState;
