import React from 'react';
import PropTypes from 'prop-types';
import identity from "crocks/combinators/identity";
import isFunction from "crocks/predicates/isFunction";
import withStyles from "@material-ui/core/styles/withStyles";
import UsersCard from "./UserManager/UsersCard";
import OrganizationsCard from "./OrganizationManager/OrganizationsCard";
import IrbReviewCard from "./IrbReview/IrbReviewCard";
import AmpDialog from "../../common/components/AmpDialog";
import ContentContainer from "../../common/components/ContentContainer";
import IfUserIsAdmin from "../../common/components/logic/IfUserIsAdmin";
import IrbCertificateViewer from "./IrbReview/IrbCertificateViewer";
import NewsCard from "./NewsManager/NewsCard";
import InviteUserForm, { inviteUserDialogInfo } from "../../common/components/forms/InviteUserForm";
import AddOrganizationForm, { addOrganizationDialogInfo } from "./OrganizationManager/AddOrganizationForm";
import { UserRole } from "../../common/models";
import IfUser from "../../common/components/logic/IfUser";
import NewsItemForm from "./NewsManager/NewsItemForm";
import { createNews, NewsType } from "../../common/models/news";
import FaqForm from "./FaqManager/FaqForm";
import FaqCard from "./FaqManager/FaqCard";
import SendEmailForm from "./UserManager/SendEmailForm";
import { createFaq } from "../../common/models/faq";

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'repeat(auto-fill, 250px)',
    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    gridGap: `${theme.spacing()}px`,
  },
});

class AdminDashboard extends React.Component {
  static propTypes = {
    news: PropTypes.array,
    faqs: PropTypes.array,
    users: PropTypes.array,
    memberships: PropTypes.array,
    organizations: PropTypes.array,
    irbCertificates: PropTypes.array,
    requests: PropTypes.object,
    onCreateNewsItem: PropTypes.func,
    onCreateFaqItem: PropTypes.func,
    onSendEmail: PropTypes.func,
    onSubmitIrbReview: PropTypes.func
  };

  static defaultProps = {
    news: [],
    faqs: [],
    users: [],
    memberships: [],
    organizations: [],
    irbCertificates: [],
    requests: {},
    onCreateNewsItem: identity,
    onCreateFaqItem: identity,
    onSendEmail: identity,
    onSubmitIrbReview: identity,
  };

  state = {
    open: false,
    dialogTitle: '',
    dialogText: '',
    dialogForm: null,
  };

  handleClose = () => this.setState({ open: false, dialogTitle: '', dialogForm: null });

  handleCreateNews = item => {
    this.handleClose();
    this.props.onCreateNewsItem(item);
  };

  handleAddNews = () => this.setState({
    open: true,
    dialogForm: <NewsItemForm item={createNews()} onSubmit={this.handleCreateNews}/>,
    dialogTitle: 'Create Member News',
    dialogText: '',
  });

  handleCreateFaq = faq => {
    this.handleClose();
    this.props.onCreateFaqItem(faq);
  };

  handleAddFaq = () => this.setState({
    open: true,
    dialogForm: <FaqForm key="0" item={createFaq()} onSubmit={this.handleCreateFaq}/>,
    dialogTitle: 'Create FAQ',
    dialogText: '',
  });

  handleAddToolUpdate = () => this.setState({
    open: true,
    dialogForm: <NewsItemForm item={createNews({ type: NewsType.TOOL_UPDATE })} onSubmit={this.handleCreateNews}/>,
    dialogTitle: 'Create Tool Update',
    dialogText: '',
  });

  handleUserInvite = () =>
    this.setState({
      open: true,
      dialogForm: <InviteUserForm organizations={this.props.organizations}/>,
      ...inviteUserDialogInfo
    });

  handleSendEmail = () =>
    this.setState({
      open: true,
      dialogForm: <SendEmailForm onSendEmail={this.props.onSendEmail} />,
      dialogTitle: 'Send Email to Users',
      dialogText: '',
    });

  handleAddOrganization = () =>
    this.setState({
      open: true,
      dialogForm: <AddOrganizationForm memberships={this.props.memberships}/>,
      ...addOrganizationDialogInfo
    });

  handleReviewSubmissions = () => {
    const dialogForm = () => <IrbCertificateViewer
      key={"0"}
      onSubmitIrbReview={this.props.onSubmitIrbReview}
      irbCertificates={this.props.irbCertificates}
    />;

    this.setState({
      open: true,
      dialogForm,
      dialogTitle: "Review IRB Certificate",
      dialogText: '',
    });
  };

  handleFaqClick = () => this.props.history.push('/admin/faq');
  handleNewsClick = () => this.props.history.push('/admin/news');
  handleUsersClick = () => this.props.history.push('/admin/users');
  handleOrganizationsClick = () => this.props.history.push('/admin/organizations');
  handleReviewsClick = () => this.props.history.push('/admin/reviews');

  render() {
    const { open, dialogTitle, dialogText, dialogForm } = this.state;
    const { news, faqs, users, organizations, irbCertificates, requests, classes } = this.props;

    return (
      <ContentContainer className={classes.root}>
        <IfUserIsAdmin>
          <UsersCard
            userCount={users.length}
            requestState={requests}
            onInviteUser={this.handleUserInvite}
            onSendEmail={this.handleSendEmail}
            onClick={this.handleUsersClick}
          />
          <OrganizationsCard
            organizationCount={organizations.length}
            requestState={requests}
            onAddOrg={this.handleAddOrganization}
            onClick={this.handleOrganizationsClick}
          />
        </IfUserIsAdmin>

        <IfUser hasAny={[UserRole.ROLE_IRB, UserRole.ROLE_ADMIN]}>
          <IrbReviewCard
            certs={irbCertificates}
            requestState={requests}
            onReviewSubmissions={this.handleReviewSubmissions}
            onClick={this.handleReviewsClick}
          />
        </IfUser>

        <IfUser hasAny={[UserRole.ROLE_EDITOR]}>
          <NewsCard
            newsCount={news.length}
            requestState={requests}
            onAddNews={this.handleAddNews}
            onAddUpdate={this.handleAddToolUpdate}
            onClick={this.handleNewsClick}
          />

          <FaqCard
            faqCount={faqs.length}
            requestState={requests}
            onAddFaq={this.handleAddFaq}
            onClick={this.handleFaqClick}
          />

        </IfUser>

        <AmpDialog
          open={open}
          text={dialogText}
          title={dialogTitle}
          onClose={this.handleClose}
        >
          {isFunction(dialogForm) ? dialogForm() : dialogForm}
        </AmpDialog>
      </ContentContainer>
    );
  };
}

export default withStyles(styles)(AdminDashboard);