import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

const CloseButton = ({ title, onClick, small, className }) => {
  const size = small ? 'small' : 'medium';

  return (
    <IconButton className={className} size={size} title={title} onClick={onClick}>
      <CloseIcon/>
    </IconButton>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
  title: PropTypes.string,
};

CloseButton.defaultValues = {
  small: false,
  title: 'Close',
};

export default CloseButton;