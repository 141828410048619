import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { drawRadar } from "./radarRenderer"
import RadarEnhancedLegend from "./status/RadarEnhancedLegend"

const borderSize = 4

const styles = {
  root: {
    position: 'relative',
    height: '100%',
    padding: borderSize,
    backgroundColor: '#444',
  },
}

class Radar extends React.PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    currentTime: PropTypes.number,
    timeSeries: PropTypes.object,
    isEnhanced: PropTypes.bool,
  }

  static defaultProps = {
    width: 300,
    height: 300,
    currentTime: 0,
    timeSeries: {},
    isEnhanced: false,
  }

  state = {
    width: 300,
    height: 500,
  }

  renderCanvas() {
    const { currentTime, timeSeries, isEnhanced } = this.props
    drawRadar(this.canvas, this.ctx, currentTime, timeSeries.radar, isEnhanced)
  }

  containerRef = ref => this.container = ref

  canvasRef = ref => {
    this.canvas = ref
    if (ref) {
      this.ctx = this.canvas.getContext("2d")
      this.renderCanvas()
    }
  }

  componentDidMount() {
    const handleResize = () => this.setState({
      width: this.container.offsetWidth - borderSize * 2,
      height: this.container.offsetHeight - borderSize * 2,
    })

    window.addEventListener('resize', handleResize)
    this.removeResizeListener = () => window.removeEventListener('resize', handleResize)

    handleResize()
  }

  componentDidUpdate() {
    this.renderCanvas()
  }

  componentWillUnmount() {
    this.removeResizeListener()
  }

  render() {
    const { isEnhanced, className, classes } = this.props
    const { width, height } = this.state
    return (
      <div className={classNames(className, classes.root)} ref={this.containerRef}>
        <RadarEnhancedLegend visible={isEnhanced}/>
        <canvas
          ref={this.canvasRef}
          width={width}
          height={height}
        />
      </div>
    )
  }
}

export default withStyles(styles)(Radar)
