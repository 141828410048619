import React, { useCallback } from 'react'
import Paper from '@material-ui/core/Paper'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { getInvertedOpOf } from "../../../common/models/expressionGroupOp"

interface AndOrSelectorProps {
  value: string;
  onChange: (op: string) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }
})

const AndOrSelector: React.FC<AndOrSelectorProps> = ({ value, onChange }) => {
  const classes = useStyles()
  const toggleOp = useCallback(() => onChange(getInvertedOpOf(value)), [onChange, value])

  return (
    <Paper className={classes.root} onClick={toggleOp}>
      {value}
    </Paper>
  )
}

export default AndOrSelector