import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    padding: theme.spacing(),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    marginBottom: theme.spacing(),
  },
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(),
  },
  question: {
    fontStyle: 'italic',
  },
  answer: {},
});

class Faq extends React.PureComponent {
  static propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string,
  };

  render() {
    const { question, answer, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography component="span" className={classes.label}>Q:</Typography>
          <Typography component="span" className={classes.question}>{question}</Typography>
        </div>

        <div className={classes.container}>
          <Typography component="span" className={classes.label}>A:</Typography>
          <Typography component="span" className={classes.answer}>{answer}</Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Faq);
