import identity from "crocks/combinators/identity";
import ColumnType from "./ColumnType";
import { formatDayMonthYear } from "../../util/date";

const formatters = {
  [ColumnType.STRING]: identity,
  [ColumnType.NUMBER]: identity,
  [ColumnType.INTEGER]: identity,
  [ColumnType.DATE]: formatDayMonthYear,
  [ColumnType.BOOLEAN]: a => a ? 'Yes' : 'No',
};

export default formatters;