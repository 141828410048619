import React from 'react';
import PropTypes from 'prop-types';
import identity from "crocks/combinators/identity";
import withStyles from '@material-ui/core/styles/withStyles';
import AppRequestState from "../../common/models/AppRequestState";
import TextField from "@material-ui/core/TextField";
import SubmitButton from "../../common/components/SubmitButton";

const styles = theme => ({
  root: {},
  button: {
    marginTop: theme.spacing(4),
  }
});

class EditOrgInfoForm extends React.PureComponent {
  static propTypes = {
    requestState: PropTypes.string,
    requestMessage: PropTypes.string,
    windowsPath: PropTypes.string,
    unixPath: PropTypes.string,
    onSubmit: PropTypes.func,
    onWindowsPathChange: PropTypes.func,
    onUnixPathChange: PropTypes.func,
  };

  static defaultProps = {
    requestState: AppRequestState.NONE,
    requestMessage: '',
    windowsPath: '',
    unixPath: '',
    onSubmit: identity,
    onWindowsPathChange: identity,
    onUnixPathChange: identity,
  };

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const { requestState, requestMessage, classes } = this.props;
    const { windowsPath, onWindowsPathChange, unixPath, onUnixPathChange } = this.props;

    const disabled = requestState === AppRequestState.IN_FLIGHT;

    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          value={windowsPath}
          disabled={disabled}
          margin="normal"
          label="Windows Path"
          helperText="Examples: \\server\sharename\pathname\AMP_Data or E:\AmpSharedFolder\AMP_Data"
          onChange={onWindowsPathChange}
        />
        <TextField
          fullWidth
          value={unixPath}
          disabled={disabled}
          margin="normal"
          label="Mac OS X and Unix Path"
          helperText="Examples: hostname:/pathname/AMP_Data or proto://hostname/pathname/AMP_Data"
          onChange={onUnixPathChange}
        />
        <SubmitButton
          className={classes.button}
          label="Update Info"
          stateMsg={requestMessage}
          requestState={requestState}
          disabled={disabled}/>
      </form>
    );
  }
}

export default withStyles(styles)(EditOrgInfoForm);
