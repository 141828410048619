import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider, Step, StepLabel, Stepper, withWidth } from "@material-ui/core/";
import { pipe } from "../../common/util/func";
import { withStyles } from "@material-ui/core/styles/index";
import primary from "@material-ui/core/colors/grey";

const steps = [
  'Upload IRB Certificate',
  'Certificate Review',
  'Qualified Researcher',
];

const theme = createMuiTheme({
  palette: {
    primary: { main: primary[300] },
    error: { main: '#950' }
  },
  overrides: {
    MuiStepIcon: {
      completed: {
        color: 'green !important'
      },
      root: {
        "&$error": {
          "color": "#C80 !important"
        }
      }
    },
  }
});

const styles = {};

const IrbStepper = ({ step, errorOnStep, width }) => {
  const stepperOrientation = width === 'xs' ? 'vertical' : 'horizontal';

  return (
    <MuiThemeProvider theme={theme}>
      <Stepper activeStep={step} orientation={stepperOrientation}>
        {
          steps.map((label, idx) => {
            return (
              <Step key={label}>
                <StepLabel error={idx+1 === errorOnStep}>{label}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </MuiThemeProvider>
  );
};

IrbStepper.propTypes = {
  step: PropTypes.number.isRequired,
  errorOnStep: PropTypes.number,
  width: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default pipe(
  withWidth(),
  withStyles(styles)
)(IrbStepper);