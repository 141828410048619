import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import BackToDashboard from "./BackToDashboard";

const BackWithHeading = ({ heading }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <BackToDashboard/>
    <Typography variant="h6">{heading}</Typography>
  </div>
);

BackWithHeading.propTypes = {
  heading: PropTypes.string,
};

export default BackWithHeading;