import React from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from "@material-ui/core/";

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const GroupOperation = ({ op, onToggleOp, classes }) => (
  <div className={classes.root}>
    <Button onClick={onToggleOp}>{op}</Button>
  </div>
);

GroupOperation.propTypes = {
  op: PropTypes.string.isRequired,
  onToggleOp: PropTypes.func.isRequired,
};

export default withStyles(styles)(GroupOperation);
