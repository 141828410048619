import intersection from 'lodash/intersection';
import constant from "crocks/combinators/constant";
import and from "crocks/logic/and";
import isEmpty from "crocks/predicates/isEmpty";
import map from "crocks/pointfree/map";
import option from "crocks/pointfree/option";
import head from "crocks/pointfree/head";
import compose from "crocks/helpers/compose";
import curry from "crocks/helpers/curry";
import Maybe from "crocks/Maybe";
import safe from "crocks/Maybe/safe";
import { isNotEmpty } from "../util/predicates";
import { createFactoryFunc } from "./factory";
import { provideEmptyString, provideFalse, provideNull, provideTrue, provideZero } from "../util/providers";
import { arrayProp, stringProp } from "../util/object";
import { safeArray } from "../util/types";
import { stringMatches } from "../util/string";

/**
 * The types of roles that can be assigned to a user.
 *
 * @type {
 *    Object.<{
 *      ROLE_USER: string,
 *      ROLE_QUALIFIED_RESEARCHER: string,
 *      ROLE_LPA: string,
 *      ROLE_IRB: string,
 *      ROLE_ADMIN: string
 *      ROLE_EDITOR: string
 *    }>
 * }
 */
export const UserRole = Object.freeze({
  ROLE_USER: 'ROLE_USER',
  ROLE_LPA: 'ROLE_LPA',
  ROLE_IRB: 'ROLE_IRB',
  ROLE_EDITOR: 'ROLE_EDITOR',
  ROLE_TAGGER: 'ROLE_TAGGER',
  ROLE_DEVELOPER: 'ROLE_DEVELOPER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_QUALIFIED_RESEARCHER: 'ROLE_QUALIFIED_RESEARCHER',
});

// Prop names and default values for the user model
const userProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'email', defaultProvider: provideEmptyString },
  { name: 'firstName', defaultProvider: provideEmptyString },
  { name: 'lastName', defaultProvider: provideEmptyString },
  { name: 'orgName', defaultProvider: provideEmptyString },
  { name: 'orgId', srcName: 'orgID', defaultProvider: provideZero },
  { name: 'orgRank', defaultProvider: provideEmptyString },
  { name: 'orgEnabled', defaultProvider: provideTrue },
  { name: 'phoneNumber', defaultProvider: provideEmptyString },
  { name: 'lpa', defaultProvider: provideEmptyString },
  { name: 'invitedDate', defaultProvider: provideNull },
  { name: 'registeredDate', defaultProvider: provideNull },
  { name: 'lastLoginDate', defaultProvider: provideNull },
  { name: 'certUploadDate', defaultProvider: provideNull },
  { name: 'certReviewDate', defaultProvider: provideNull },
  { name: 'certIssueDate', defaultProvider: provideNull },
  { name: 'certApproved', defaultProvider: provideFalse },
  { name: 'amendedToIrbDate', defaultProvider: provideNull },
  { name: 'enabled', defaultProvider: provideFalse },
  { name: 'optOutOfEmail', defaultProvider: provideFalse },
  { name: 'roles', defaultProvider: constant([UserRole.ROLE_USER]) },
];

const justEmptyString = Maybe.Just('');

const userFirstNameProp = stringProp('firstName');
const userLastNameProp = stringProp('lastName');
const phoneNumberProp = stringProp('phoneNumber');
const orgRankProp = stringProp('orgRank');
const roleProp = arrayProp('roles');

const matchesLength = arr1 => arr2 => arr1.length === arr2.length;
const isNotEmptyAndMatchesLengthOf = arr => and(isNotEmpty, matchesLength(arr));

const intersectionIsNotEmpty = compose(isNotEmpty, intersection);
const intersectionMatchesOriginalLength = (userRoles, roles) => {
  const pred = isNotEmptyAndMatchesLengthOf(roles);
  return pred(intersection(userRoles, roles));
};

const hasAny = (roles, user) =>
  roleProp(user)
    .chain(userRoles =>
      safeArray(roles)
        .map(roles => intersectionIsNotEmpty(userRoles, roles))
    )
    .option(false);

const hasAll = (roles, user) =>
  roleProp(user)
    .chain(userRoles =>
      safeArray(roles)
        .map(roles => intersectionMatchesOriginalLength(userRoles, roles))
    )
    .option(false);

/**
 * Create a canonical user model. Any User props not present in the source object will be
 * provided with default values based on the userProps.
 *
 * createUser :: Object -> User
 */
export const createUser = createFactoryFunc(userProps);

/**
 * Returns the user's phone number replacing it with an empty string if it is null.
 *
 * getUserPhoneNumber :: User -> String
 */
export const getUserPhoneNumber = compose(option(''), phoneNumberProp);

/**
 * getUserInitials :: User -> M String
 */
export const getUserInitials = user =>
  userFirstNameProp(user)
    .chain(head)
    .alt(justEmptyString)
    .concat(
      userLastNameProp(user)
        .chain(head)
        .alt(justEmptyString)
    )
    .chain(safe(isNotEmpty));

/**
 * getUserFullName :: User -> M String
 */
export const getUserFullName = user =>
  userFirstNameProp(user)
    .map(first => first + " ")
    .alt(justEmptyString)
    .concat(userLastNameProp(user).alt(justEmptyString))
    .chain(name => isEmpty(name) ? Maybe.Nothing() : Maybe.Just(name.trim()));

/**
 *  userHasAnyRoles :: [Role] -> User -> Boolean
 */
export const userHasAnyRoles = curry(hasAny);

/**
 *  userHasAllRoles :: [Role] -> User -> Boolean
 */
export const userHasAllRoles = curry(hasAll);

/**
 * userIsLpa :: User -> Boolean
 */
export const userIsLpa = userHasAnyRoles([UserRole.ROLE_LPA]);

/**
 * userIsTagger :: User -> Boolean
 */
export const userIsTagger = userHasAnyRoles([UserRole.ROLE_TAGGER]);

/**
 * userIsAdmin :: User -> Boolean
 */
export const userIsAdmin = userHasAnyRoles([UserRole.ROLE_ADMIN]);

/**
 * userIsQualifiedResearcher :: User -> Boolean
 */
export const userIsQualifiedResearcher = userHasAnyRoles([UserRole.ROLE_QUALIFIED_RESEARCHER]);

/**
 * userIsIrb :: User -> Boolean
 */
export const userIsIrb = userHasAnyRoles([UserRole.ROLE_IRB]);

/**
 * userIsSteeringCommitteeMember :: User -> Boolean
 */
export const userIsSteeringCommitteeMember = compose(option(false), map(stringMatches(/steering/i)), orgRankProp);
