import isDefined from "crocks/predicates/isDefined"
import { createUser } from "../../models"
import AppStatus from "../../models/AppStatus"
import AppRequest from "../../models/AppRequest"
import AppRequestState from "../../models/AppRequestState"
import {
  LOGOUT,
  SET_APP_STATUS,
  SET_REQUEST_STATE,
  SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS,
  SET_SCENARIO_BROWSER_VIEW_STATE,
  SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS,
  SET_SELECTED_CHART_VARIABLES,
  SET_UI_STATE,
} from "../actions"
import ResultsViewTypes from "../../models/ResultsViewTypes"
import { listVariableIds } from "../../../HomePage/ScenariosContent/common/constants"
import { writeUiState } from "../../services/localStorage"

export const createDefaultReqStates = (requests, defaultValue) =>
  Object.keys(requests).reduce((obj, key) => {
    obj[key] = defaultValue
    return obj
  }, {})


const defaultRequestStates = createDefaultReqStates(AppRequest, AppRequestState.NONE)

/**
 * This is the shape of the state contained in the app branch of the Redux store.
 * It is here to provide documentation on the state and to provide initial defaults
 * for the reducers.
 */
export const appInitialState = {
  status: AppStatus.APP_LOADING,
  profile: createUser(),
  requests: defaultRequestStates,
  ui: {
    selectedChartVariables: [],
    selectedAnnotationTabVariableIds: [],
    scenarioBrowser: {
      viewState: ResultsViewTypes.GRID,
      expandedQuickFilters: listVariableIds.slice(0, 4)
    }
  },
}

/**
 * Updates the requests object to reflect the new state of a request.
 *
 * The action's payload shape:
 * {
 *   request: String,      // One of the strings in the requests constant (see constants.js)
 *   requestState: String  // One of the strings in the AppRequestState constant (see constants.js)
 * }
 */
const requestsReducer = (state = defaultRequestStates, action) => {
  const { type, payload } = action

  // noinspection JSRedundantSwitchStatement
  switch (type) {
    case SET_REQUEST_STATE:
      const { request, requestState } = payload

      if (AppRequest[request] === undefined || AppRequestState[requestState] === undefined) {
        console.log(`Attempt to update unknown request state combo: ${request} = ${requestState}`,
          AppRequest, AppRequestState)
        return state
      }

      return { ...state, [request]: requestState }

    default:
      return state
  }
}

const scenarioBrowserReducer = (state = appInitialState.ui.scenarioBrowser, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_SCENARIO_BROWSER_VIEW_STATE:
    case SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS:
      return { ...state, ...payload }

    default:
      return state
  }
}

const uiReducer = (state = appInitialState.ui, action) => {
  const { type, payload } = action
  let uiState

  switch (type) {
    case SET_SELECTED_CHART_VARIABLES:
    case SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS:
      uiState = { ...state, ...payload }
      break

    case SET_SCENARIO_BROWSER_VIEW_STATE:
    case SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS:
      uiState = { ...state, scenarioBrowser: scenarioBrowserReducer(state.scenarioBrowser, action) }
      break

    default:
      return state
  }

  writeUiState(uiState)
  return uiState
}

/**
 * Root reducer for the app state.
 */
export const app = (state = appInitialState, action) => {
  switch (action.type) {
    case SET_UI_STATE:
      return { ...state, ...action.payload }

    case SET_APP_STATUS:
      return { ...state, ...action.payload }

    case SET_REQUEST_STATE:
      return { ...state, requests: requestsReducer(state.requests, action) }

    case SET_SELECTED_CHART_VARIABLES:
    case SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS:
    case SET_SCENARIO_BROWSER_VIEW_STATE:
    case SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS:
      return { ...state, ui: uiReducer(state.ui, action) }

    case LOGOUT:
      return { ...appInitialState, status: AppStatus.APP_LOGGED_OUT }

    default:
      return state
  }
}

/*
 * State accessors decouple clients from the structure of the store's state. These
 * are all re-exported from store/index.js for use by the outside world.
 */
export const getAppStatus = state => state.app.status
export const getUserProfile = state => state.app.profile
export const getSelectedChartVariables = state => state.app.ui.selectedChartVariables
export const getSelectedAnnotationTabVariableIds = state => state.app.ui.selectedAnnotationTabVariableIds
export const getScenarioBrowserViewState = state => state.app.ui.scenarioBrowser.viewState
export const getScenarioBrowserExpandedQuickFilters = state => state.app.ui.scenarioBrowser.expandedQuickFilters
export const getRequestState = (state, request) =>
  isDefined(request) ? state.app.requests[request] : state.app.requests
