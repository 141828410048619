import { useDispatch } from "react-redux"
import { useCallback } from "react"
import { fetchEmailRecipientCounts, sendEmailToUsers } from "../actions/user"

function useEmail() {
  const dispatch = useDispatch()
  const fetchRecipientCounts =  useCallback(() => dispatch(fetchEmailRecipientCounts()), [dispatch])
  const sendUserEmail =  useCallback(
    (subject, body, role) => dispatch(sendEmailToUsers(subject, body, role)),
    [dispatch]
  )

  return { fetchRecipientCounts, sendUserEmail }
}

export default useEmail