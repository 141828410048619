import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import isNil from "crocks/predicates/isNil";
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import WideScreenIcon from "@material-ui/icons/Crop169";
import NormalScreenIcon from "@material-ui/icons/Crop32";
import FullScreenExitIcon from "@material-ui/icons/FullscreenExit";
import Slider from '../../../../common/components/Slider';
import { clamp } from "../../../../common/util/math";
import { isNormal } from "./PlayerMode";

const minDomain = 0;
const maxDomain = 300;

const calcSliderPos = (playSecs, durSecs) =>
  isNil(durSecs) || durSecs === 0
    ? 0
    : clamp(Math.trunc(playSecs / durSecs * maxDomain), minDomain, maxDomain);

const formatSeconds = sec => Number(sec).toFixed(1);
const formatTime = (played, duration) => `${formatSeconds(played)} / ${formatSeconds(duration)}`;

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Color(theme.palette.primary.dark).alpha(0.75).string(),
    position: 'absolute',
    left: 0,
    right: 0,
    // bottom: 0,
    height: 50,
    transition: 'bottom 100ms linear',
    '& > *': {
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    }
  },
});

class VideoControls extends React.Component {
  static propTypes = {
    playerMode: PropTypes.string,
    playing: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    controlsVisible: PropTypes.bool.isRequired,
    playbackSeconds: PropTypes.number.isRequired,
    durationSeconds: PropTypes.number.isRequired,
    onPlayPause: PropTypes.func,
    onFullScreen: PropTypes.func,
    onSeekChange: PropTypes.func,
    onModeToggle: PropTypes.func,
  };

  static defaultProps = {
    playing: false,
    fullScreen: false,
    controlsVisible: true,
    playbackSeconds: 0,
    durationSeconds: 0,
  };

  state = {
    seeking: false,
    sliderVal: 0,
  };

  preventFocus = ev => ev.preventDefault();
  swallowClick = ev => ev.stopPropagation();
  handleChange = values => this.props.onSeekChange(values[0] / maxDomain * this.props.durationSeconds);
  handleSlideStart = values => this.setState({ seeking: true, sliderVal: values[0] });
  handleSlideEnd = values => this.setState({ seeking: false, sliderVal: values[0] });

  render() {
    const { seeking, sliderVal } = this.state;
    const { playing, durationSeconds, playbackSeconds, onPlayPause } = this.props;
    const { controlsVisible, fullScreen, onFullScreen, playerMode, onModeToggle, classes } = this.props;

    const bottom = controlsVisible ? 0 : -50;
    const playingIcon = playing ? <PauseIcon/> : <PlayIcon/>;
    const modeToggleIcon = isNormal(playerMode) ? <WideScreenIcon/> : <NormalScreenIcon/>;
    const fullScreenIcon = fullScreen ? <FullScreenExitIcon/> : <FullScreenIcon/>;
    const sliderPos = seeking ? sliderVal : calcSliderPos(playbackSeconds, durationSeconds);

    const modeTip = isNormal(playerMode) ? "Wide Player" : "Normal Player";

    return (
      <div className={classes.root} style={{ bottom }} onClick={this.swallowClick}>

        <IconButton onClick={onPlayPause} onMouseDown={this.preventFocus}>{playingIcon}</IconButton>

        <Slider
          step={1}
          domain={[minDomain, maxDomain]}
          railSize={8}
          values={[sliderPos]}
          onChange={this.handleChange}
          onSlideStart={this.handleSlideStart}
          onSlideEnd={this.handleSlideEnd}
        />

        <Typography style={{ flexShrink: 0 }}>{formatTime(playbackSeconds, durationSeconds)}</Typography>

        <IconButton
          style={{ width: 16 }}
          title={modeTip}
          onClick={onModeToggle}
          onMouseDown={this.preventFocus}
        >
          {modeToggleIcon}
        </IconButton>

        <IconButton
          style={{ width: 16 }}
          title="Full Screen"
          onClick={onFullScreen}
          onMouseDown={this.preventFocus}
        >
          {fullScreenIcon}
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(VideoControls);
