import React from 'react'
import PropTypes from 'prop-types'
import compose from "crocks/helpers/compose"
import isDefined from "crocks/predicates/isDefined"
import { hotkeys } from 'react-keyboard-shortcuts'
import AmpVideoPlayer from "./AmpVideoPlayer"
import TimeSeriesViewer from "./TimeSeriesViewer/TimeSeriesViewer"
import withStyles from "@material-ui/core/styles/withStyles"
import { isNotNil } from "../../../common/util/predicates"
import { getCueTime, isEnhancedRadarDataset } from "../../../common/models/scenario"
import { getEpochTimeStampBase, offsetSeconds } from "../common/timeSeries"
import AppRequestState from "../../../common/models/AppRequestState"
import StatusPane from "./TabbedSideBar/StatusPane"
import AnalysisPane from "./TabbedSideBar/AnalysisPane"
import Radar from "./TabbedSideBar/Radar"
import EyeGlance from "./components/EyeGlance"
import PlayerMode, { isWideScreen, togglePlayerMode } from "./VideoPlayer/PlayerMode"


const shouldCue = (props, state) =>
  props.timeSeriesEntry.state === AppRequestState.SUCCESS && state.hasCued === false

const getCueOffsetSeconds = (scenario, timeSeries) =>
  getEpochTimeStampBase(timeSeries)
    .chain(baseMillis =>
      getCueTime(scenario)
        .map(offsetSeconds(baseMillis))
    )
    .option(0)

const attemptToCue = (props, seekFn) => {
  const cueOffsetSeconds = getCueOffsetSeconds(props.scenario, props.timeSeriesEntry.timeSeries)

  if (isDefined(seekFn) && cueOffsetSeconds > 0) {
    seekFn(cueOffsetSeconds)
    return true
  }

  return false
}

const styles = theme => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '[top analysis] minmax(480px, 40vh) minmax(300px, 1fr) [bottom]',
    gridTemplateColumns: '[start] minmax(200px, 280px) [videoStart] minmax(400px,2fr) [videoEnd] 340px 350px [end]',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    }
  },
  rootWide: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '[top] minmax(480px, 40vh) [analysis] minmax(300px, 1fr) [end]',
    gridTemplateColumns: '[start] minmax(200px, 280px) [videoStart] minmax(400px,2fr) 340px [videoEnd] 350px',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    }
  },
  video: {
    gridColumn: 'videoStart / videoEnd',
  },
  status: {
    border: 'solid 4px #444',
    backgroundColor: '#222',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden'
  },
  chart: {
    gridRow: '2',
    gridColumn: '1 / 4',
  },

  // If the content's height exceeds the grid's bounds, we don't want to blow out the
  // height of the page.  We'd like to fill the content area completely but then
  // handle any Y overflow with a scroll bar.  To get this behavior we take the
  // analysis pane out of the regular layout flow with a relative position and then
  // absolutely position it's child div combined with a max-height of 100%.
  analysis: {
    gridRow: 'analysis / end',
    gridColumn: '4',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: '4em',
    margin: theme.spacing(),
    '& > div': {
      maxHeight: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    }
  },
})

class PlaybackController extends React.PureComponent {
  static propTypes = {
    videoUrl: PropTypes.string,
    scenario: PropTypes.object,
    timeSeriesEntry: PropTypes.object,
  }

  static defaultProps = {
    videoUrl: '',
    scenario: {},
    timeSeriesEntry: {},
  }

  state = {
    hasCued: false,
    canSeek: false,
    playing: false,
    fullScreen: false,
    playbackSeconds: 0,
    durationSeconds: 0,
    playerMode: PlayerMode.NORMAL,
  }

  componentDidUpdate() {
    if (shouldCue(this.props, this.state)) {
      const hasCued = attemptToCue(this.props, this.seekFn)
      this.setState({ hasCued })
    }
  }

  handleProgress = playbackSeconds => this.setState({ playbackSeconds })
  handleDuration = durationSeconds => this.setState({ durationSeconds })

  handlePlaybackToggle = () => this.setState({ playing: !this.state.playing })
  handleFullScreenToggle = fullScreen => this.setState({ fullScreen })
  handleModeToggle = () => this.setState({ playerMode: togglePlayerMode(this.state.playerMode) })

  handleSeekTo = seekSeconds => this.state.canSeek ? this.seekFn(seekSeconds) : undefined

  handleLoaded = seekFn => {
    this.seekFn = seekFn
    const canSeek = isNotNil(seekFn)
    const hasCued = attemptToCue(this.props, seekFn)
    this.setState({ canSeek, hasCued })
  }

  // noinspection JSUnusedGlobalSymbols
  hot_keys = {
    'space': {
      priority: 1,
      handler: this.handlePlaybackToggle
    },
    'f': {
      priority: 1,
      handler: () => attemptToCue(this.props, this.seekFn),
    },
    'd': {
      priority: 1,
      handler: () => this.handleSeekTo(0),
    },
    'k': {
      priority: 1,
      handler: () => {
        if (this.state.playing === false) {
          this.handleSeekTo(Math.min(this.state.playbackSeconds + 0.1, this.state.durationSeconds))
        }
      }
    },
    'j': {
      priority: 1,
      handler: () => {
        if (this.state.playing === false) {
          this.handleSeekTo(Math.max(this.state.playbackSeconds - 0.1, 0))
        }
      }
    },
  }

  render() {
    const { videoUrl, scenario, timeSeriesEntry, classes } = this.props
    const { fullScreen, playing, playbackSeconds, durationSeconds, playerMode } = this.state
    const rootClassName = isWideScreen(playerMode) ? classes.rootWide : classes.root
    return (
      <div className={rootClassName}>
        <Radar
          isEnhanced={isEnhancedRadarDataset(scenario)}
          timeSeries={timeSeriesEntry.timeSeries}
          currentTime={playbackSeconds}
        />

        <AmpVideoPlayer
          className={classes.video}
          url={videoUrl}
          playing={playing}
          fullScreen={fullScreen}
          playbackSeconds={playbackSeconds}
          durationSeconds={durationSeconds}
          playerMode={playerMode}
          onLoaded={this.handleLoaded}
          onSeekTo={this.handleSeekTo}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          onModeToggle={this.handleModeToggle}
          onPlaybackToggle={this.handlePlaybackToggle}
          onFullScreenToggle={this.handleFullScreenToggle}
        />

        <div className={classes.status}>
          <StatusPane scenario={scenario} timeSeries={timeSeriesEntry.timeSeries} currentTime={playbackSeconds}/>
          <EyeGlance timeSeries={timeSeriesEntry.timeSeries} currentTime={playbackSeconds}/>
        </div>

        <div className={classes.analysis}>
          <AnalysisPane className={classes.sidebar} scenario={scenario}/>
        </div>

        <TimeSeriesViewer
          className={classes.chart}
          scenario={scenario}
          timeSeries={timeSeriesEntry.timeSeries}
          playbackSeconds={playbackSeconds}
          durationSeconds={durationSeconds}
          onSeekChange={this.handleSeekTo}
        />

      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  hotkeys
)(PlaybackController)
