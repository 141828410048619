import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import AmpTable from "../../../common/components/AmpTable";
import ColumnType from "../../../common/components/AmpTable/ColumnType";
import ActionButton from "../DashboardCard/ActionButton";
import ActionsColumn from "../components/ActionsColumn";

const { STRING, INTEGER } = ColumnType;

const buildColumnData = (onEdit, onDelete) => [
  { id: 'id', label: 'ID', type: INTEGER, width: '20px' },
  { id: 'sortOrder', label: 'Sort Order', type: INTEGER, width: '20px' },
  { id: 'question', label: 'Question', type: STRING },
  { id: 'answer', label: 'Answer', type: STRING },
  {
    id: 'actions',
    label: 'Actions',
    render: item => <ActionsColumn item={item} onEdit={onEdit} onDelete={onDelete} /> },

];

const styles = theme => ({
  root: {
    display: 'grid',
    marginBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  table: {
    overflowX: 'auto',
    '& > *': {
      minWidth: 800,
    },
  }
});

class FaqTable extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    onAddFaq: PropTypes.func,
    onEditFaq: PropTypes.func,
    onDeleteFaq: PropTypes.func,
  };

  static defaultProps = {
    items: [],
  };

  static getDerivedStateFromProps(nextProps) {
    const { onEditFaq, onDeleteFaq } = nextProps;
    return { columnData: buildColumnData(onEditFaq, onDeleteFaq) }
  }

  state = {
    columnData: {}
  };

  render() {
    const { columnData } = this.state;
    const { items, onAddFaq,  classes, className } = this.props;

    return (
      <div className={classNames(className, classes.root)}>
        <div className={classes.header}>
          <ActionButton label="Add FAQ" onClick={onAddFaq}/>
        </div>

        <AmpTable className={classes.table} columnData={columnData} items={items}/>
      </div>
    );
  }
}

export default withStyles(styles)(FaqTable);
