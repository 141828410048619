import React from 'react';
import PropTypes from 'prop-types';
import { partial } from "../../../../common/util/func";
import ClickableText from "./ClickableText";

const VariableSelectionList = ({ variables, onSelection }) =>
  variables.map(v =>
    <ClickableText key={v.id} onClick={partial(onSelection, v)}>
      {v.name}
    </ClickableText>
  );

VariableSelectionList.propTypes = {
  variables: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired,
};

export default VariableSelectionList;