import compose from "crocks/helpers/compose"
import { createIntVariable, createListVariable, VariableType } from "./filterVariable"
import { provideEmptyArray, provideEmptyString } from "../util/providers"
import { withUuid } from "./helpers"
import { createFactoryFunc } from "./factory"

export const listOps = ['cn', 'nc']

export const isListExpression = exp => exp && exp.variable && exp.variable.type === VariableType.list
export const isValueExpression = exp => exp && exp.variable && exp.variable.type !== VariableType.list

const valueExpressionProps = [
  { name: 'data', defaultProvider: provideEmptyString },
  { name: 'label', defaultProvider: provideEmptyString },
  { name: 'variable', defaultProvider: () => createIntVariable() },
  { name: 'op', defaultProvider: obj => obj.variable.cmp[0] }
]

const listExpressionProps = [
  { name: 'label', defaultProvider: provideEmptyString },
  { name: 'variable', defaultProvider: () => createListVariable() },
  { name: 'selected', defaultProvider: provideEmptyArray },
  { name: 'op', defaultProvider: () => listOps[0] },
]

const setIntData = (data, expression) =>
  Number.isInteger(Number(data))
    ? setStringData(data, expression)
    : expression

const setFloatData = (data, expression) =>
  Number.isNaN(Number(data))
    ? expression
    : setStringData(data, expression)

const setStringData = (data, expression) => ({ ...expression, data: String(data) })

/**
 * Create a canonical Organization object.
 *
 * createOrganization :: Object -> Organization
 */
export const createValueExpression = compose(withUuid, createFactoryFunc(valueExpressionProps))

/**
 * Sets the operation of the given expression.  This function does not mutate the provided expression.
 *
 * @returns {*} A new expression object with the operation set to the provided op, or the unchanged
 *    original experssion if the op is not valid for this expression type.
 */
export function setValueOperation(op, expression) {
  if (!op || !isValueExpression(expression) || !expression.variable.cmp.includes(op)) {
    return expression
  }
  return { ...expression, op }
}

/**
 * Sets the data field of the specified value expression to the specified data param.
 *
 * @param data If expression is an int expression this must be a valid integer, otherwise a string.
 * @param expression The expression whose data will be modified.
 *
 * @returns {*} A new expression object with the new data.  If expression or the data params are
 *    not valid or if the expression is a list type rather than a value type, the original expression
 *    will be returned unmodified.
 */
export function setValueData(data, expression) {
  if (!data || !isValueExpression(expression)) {
    return expression
  }

  const varType = expression.variable.type
  switch (varType) {
    case VariableType.int:
      return setIntData(data, expression)
    case VariableType.float:
      return setFloatData(data, expression)
    default:
      return setStringData(data, expression)
  }
}

/**
 * Create a list expression for the specified variable and with the specified choices pre-selected.
 */
export const createListExpression = compose(withUuid, createFactoryFunc(listExpressionProps))

const isValidListOp = (op) => op === listOps[0] || op === listOps[1]

/**
 * Sets the operation of the given expression.  This function does not mutate the provided expression.
 *
 * @returns {*} A new expression object with the operation set to the provided op, or the unchanged
 *    original experssion if the op is not valid for this expression type.
 */
export function setListOperation(op, expression) {
  if (!op || !isListExpression(expression) || !isValidListOp(op)) {
    return expression
  }
  return { ...expression, op }
}

/**
 * Make a copy of the expression with the selected array replaced by the selections array.
 */
export function setListSelections(selections, expression) {
  if (!Array.isArray(selections) || !isListExpression(expression)) {
    return expression
  }

  const validIds = expression.variable.choices.map(c => c.id)
  const validChoices = selections.filter(s => validIds.includes(s))

  const allInvalid = selections.length > 0 && validChoices.length === 0
  return allInvalid ? expression : { ...expression, selected: validChoices }
}

/**
 * Make a copy of the expression with the specified choice removed from its selected array.
 */
export function removeListSelection(choiceId, expression) {
  if (!isListExpression(expression)) {
    return expression
  }

  const choiceIdx = expression.selected.findIndex(s => s === choiceId)
  if (choiceIdx === -1) {
    return expression
  }

  return {
    ...expression,
    selected: [
      ...expression.selected.slice(0, choiceIdx),
      ...expression.selected.slice(choiceIdx + 1)
    ]
  }
}

/**
 * Creates and returns an expression depending on the type of variable specified by the options
 * object.
 *
 * @param opts Options that control the kind of expression that will be created.  See the
 *    createValueExpression and createListExpression functions for details on valid options.
 *
 * @returns {*} An int or string value expression, or a list expression depending on the options
 *    provided.
 */
export function createExpression(opts = {}) {
  const variable = opts.variable || createIntVariable()

  switch (variable.type) {
    case VariableType.int:
    case VariableType.float:
    case VariableType.string:
      return createValueExpression({ ...opts, variable })

    case VariableType.list:
      return createListExpression(opts)

    default:
      return null
  }
}

const createValueExpressionMemento = exp =>
  isValueExpression(exp)
    ? { id: exp.variable.id, op: exp.op, data: exp.data }
    : undefined

const createListExpressionMemento = exp =>
  isListExpression(exp)
    ? { id: exp.variable.id, selected: exp.selected, op: exp.op }
    : undefined

/**
 * Create a memento object for the provided expression.  Mementos contain all the information needed
 * to restore the state of the object at a later time.  Expression tree mementos are also used to
 * serialize the expression tree before it is written to disk or sent to the server.
 */
export function createExpressionMemento(expression) {
  return isValueExpression(expression)
    ? createValueExpressionMemento(expression)
    : createListExpressionMemento(expression)
}

/**
 * Create an expression object based on the provided memento.
 *
 * @param memento An expression memento object. It's "id" should correspond to a variable in the
 *    variableLookup map.
 * @param variableLookup An object whose keys are variable IDs and values are variables.
 *
 * @returns {*} An expression based on the data contained within the memento.  If the memento is not
 *    valid then a default integer expression will be returned.
 */
export function createExpressionFromMemento(memento = {}, variableLookup = {}) {
  const variable = variableLookup[memento.id]
  return variable === undefined
    ? createExpression()
    : createExpression({ variable, ...memento })
}
