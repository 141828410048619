/*
 * This module contains utility functions used in the creation of factory functions for data models.
 */
import compose from "crocks/helpers/compose";
import { safePropFromObj, setPropOnObj } from "../util/object";

/**
 * Create a model object using an array of props and the reducer to run on that array to produce
 * the model object.
 *
 * createModel :: [PropDescriptor] -> ((Object, PropDescriptor) -> Object) -> Object
 */
export const createModel = props => propReducer => props.reduce(propReducer, {});

/**
 * Takes an array of property descriptors and creates a factory function using all of the default
 * settings for handling undefined props and default values.
 *
 * createFactoryFunc :: [PropDescriptor] -> Object
 */
export const createFactoryFunc = props => compose(createModel(props), setPropOnObj, safePropFromObj);