import { SortDirection } from "../../models";
import { SET_SCENARIO_SORT_COLUMN, SET_SCENARIO_SORT_DIRECTION } from "./index";

const { ASCENDING, DESCENDING } = SortDirection;

export const setScenarioSortColumn = sortColumn =>
  ({ type: SET_SCENARIO_SORT_COLUMN, payload: { sortColumn } });

export const setScenarioSortDirection = sortDir => {
  const sortDirection = sortDir === DESCENDING ? DESCENDING : ASCENDING;
  return {
    type: SET_SCENARIO_SORT_DIRECTION,
    payload: { sortDirection }
  };
};
