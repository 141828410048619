/*
 * This module contains actions having to do with events (a.k.a. scenarios).
 */
import compose from "crocks/helpers/compose"
import isDefined from "crocks/predicates/isDefined"
import AppRequest from "../../models/AppRequest"
import AppRequestState from "../../models/AppRequestState"
import { createTimeSeriesCacheEntry as tsEntry, timeSeriesVariables } from "../../models"
import { checkResponse, createGetThunk, intercept401, jsonReader } from "./utils"
import { getTimeSeriesCacheEntry } from "../reducers/userData"
import {
  SET_ANALYTICS_COUNT,
  SET_SCENARIO,
  SET_SCENARIO_COUNTS,
  SET_SCENARIO_INDEX,
  SET_SCENARIOS,
  SET_TIME_SERIES,
  SET_TIME_SERIES_VARS
} from "./index"

const { IN_FLIGHT, SUCCESS, FAILURE } = AppRequestState

const createInFlightEntry = scenarioId => tsEntry({ scenarioId, state: IN_FLIGHT })
const createSuccessEntry = scenarioId => ({ data, resp }) => tsEntry({ scenarioId, state: SUCCESS, timeSeries: data })
const createFailureEntry = scenarioId => error => tsEntry({ scenarioId, state: FAILURE, error })

const setEntry = dispatch => compose(dispatch, setTimeSeriesEntry)

const setInFlightEntry = dispatch => compose(setEntry(dispatch), createInFlightEntry)
const setSuccessEntry = (dispatch, scenarioId) => compose(setEntry(dispatch), createSuccessEntry(scenarioId))
const setFailureEntry = (dispatch, scenarioId) => compose(setEntry(dispatch), createFailureEntry(scenarioId))

export const setScenario = scenario => ({ type: SET_SCENARIO, payload: { scenario } })
export const setScenarios = all => ({ type: SET_SCENARIOS, payload: { all } })
export const setScenarioIndex = currentIndex => ({ type: SET_SCENARIO_INDEX, payload: { currentIndex } })
export const setScenarioCounts = counts => ({ type: SET_SCENARIO_COUNTS, payload: { counts } })
export const setAnalyticsCount = analytics => ({ type: SET_ANALYTICS_COUNT, payload: { analytics } })

export const setTimeSeriesEntry = entry => ({ type: SET_TIME_SERIES, payload: { entry } })
export const setTimeSeriesVariables = timeSeriesVars => ({ type: SET_TIME_SERIES_VARS, payload: { timeSeriesVars } })

export const fetchScenario = id =>
  createGetThunk({
    request: AppRequest.GET_EVENT,
    url: `/api/event/${id}`,
    responseFnFactory: dispatch => compose(dispatch, setScenario)
  })

/**
 * Ask the server to build a download URL for a particular event/layer
 * combination, then start the download.
 */
export const fetchLayerDownloadUrl = (eventId, layer) =>
  createGetThunk({
    request: AppRequest.GET_EVENTS,
    url: `/api/event/${eventId}/download`,
    params: { layer },
    responseFnFactory: (dispatch, getState, services) => data =>
      services.http.downloadFile('', data.url)
  })

/**
 * Create a Redux thunk that fetches the time series data if it is not currently in the cache
 */
export const fetchTimeSeries = scenarioId => {
  return (dispatch, getState, services) => {
    const cacheEntry = getTimeSeriesCacheEntry(getState(), scenarioId)

    if (isDefined(cacheEntry)) {
      return Promise.resolve()
    }

    setInFlightEntry(dispatch)(scenarioId)

    return services.http.getData(`/api/event/${scenarioId}/timeseries`)
      .then(intercept401(dispatch))
      .then(checkResponse(jsonReader))
      .then(setSuccessEntry(dispatch, scenarioId))
      .catch(setFailureEntry(dispatch, scenarioId))
  }
}

export const fetchTimeSeriesVars = () => setTimeSeriesVariables(timeSeriesVariables)

// Coming soon: we'll want to get the time series vals from the server - DRI
// createGetThunk({
//   request: AppRequest.GET_TIME_SERIES_VARIABLES,
//   url: '/api/event/variable',
//   params: { type: 'timeseries' },
//   responseFnFactory: processTimeSeriesVariables
// });

