import not from "crocks/logic/not";
import isNil from "crocks/predicates/isNil";
import isEmpty from "crocks/predicates/isEmpty";
import isString from "crocks/predicates/isString";
import isDefined from "crocks/predicates/isDefined";
import or from "crocks/logic/or";
import and from "crocks/logic/and";
import curry from "crocks/helpers/curry";

/**
 * Returns true if a is null.
 *
 * isNull :: a -> Boolean
 */
export const isNull = a => a === null;

/**
 * Returns true if a is not null.
 *
 * isNotNull :: a -> Boolean
 */
export const isNotNull = not(isNull);

/**
 * Returns true if a is not null, undefined, or NaN.
 *
 * isNotNil :: a -> Boolean
 */
export const isNotNil = not(isNil);

/**
 * Returns true if a is not empty.
 *
 * isNotNull :: a -> Boolean
 */
export const isNotEmpty = not(isEmpty);

/**
 * Returns true if a is not a string.
 *
 * isNotString :: a -> Boolean
 */
export const isNotString = not(isString);

/**
 * Returns true if a is a string and is not empty.
 *
 * isNotEmptyString :: a -> Boolean
 */
export const isNotEmptyString = and(isString, isNotEmpty);

/**
 * Returns true if a is undefined.
 *
 * isUndefined :: a -> Boolean
 */
export const isUndefined = not(isDefined);

/**
 * Returns true if a is an instance of a Date object.
 *
 * isDate :: a -> Boolean
 */
export const isDate = a => Object.prototype.toString.call(a) === '[object Date]';

/**
 * isArrayEmpty :: [a] -> Boolean
 */
export const isArrayEmpty = isEmpty;

/**
 * isArrayNotEmpty :: [a] -> Boolean
 */
export const isArrayNotEmpty = not(isArrayEmpty);

/**
 * isGreaterThan :: value -> a -> Boolean
 */
export const isGreaterThan = curry((value, a) => a > value);


/**
 * isBlank :: String -> Boolean
 */
export const isBlank = or(isEmpty, a => a.trim() === '');
