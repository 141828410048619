import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Input, InputAdornment, Popover, withStyles } from "@material-ui/core/";
import SearchIcon from '@material-ui/icons/Search';
import { pipe, tryCatch } from "../../../../common/util/func";
import { getFilterVarsSorted } from "../../../../common/store";
import VariableSelectionList from "./VariableSelectionList";
import CloseButton from "../../../../common/components/CloseButton";

const createRegExp = filterText => new RegExp(filterText, "i");

const matchVariableNames = (variables, filterText) =>
  tryCatch(() => createRegExp(filterText))
    .map(regexp => variables.filter(v => v.name.match(regexp)))
    .option(variables);

const filterAdornment = (
  <InputAdornment position="start">
    <SearchIcon/>
  </InputAdornment>
);

const styles = theme => ({
  variableList: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    overflow: 'auto',
    minHeight: '20vh',
    maxHeight: '50vh',
    clear: 'both',
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(),
  },
});

class VariableSelectionPopup extends React.Component {
  state = {
    filterText: '',
  };

  handleEnter = () => this.setState({ filterText: '' });
  handleChange = ev => this.setState({ filterText: ev.target.value });

  render() {
    const { filterText } = this.state;
    const { anchor, isOpen, onClose, onSelection, variables, classes } = this.props;
    const filteredVars = matchVariableNames(variables, filterText);

    return (
      <Popover anchorEl={anchor} open={isOpen} onClose={onClose} onEnter={this.handleEnter}>
        <div className={classes.filterRow}>
          <CloseButton small onClick={onClose}/>
          <Input
            fullWidth
            autoFocus
            value={filterText}
            placeholder="Filter Variable Names"
            onChange={this.handleChange}
            startAdornment={filterAdornment}
          />
        </div>
        <div className={classes.variableList}>
          <VariableSelectionList variables={filteredVars} onSelection={onSelection}/>
        </div>
      </Popover>
    );
  }
}

VariableSelectionPopup.propTypes = {
  anchor: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ variables: getFilterVarsSorted(state) });

export default pipe(
  withStyles(styles),
  connect(mapStateToProps),
)(VariableSelectionPopup);