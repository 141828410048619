import Maybe from "crocks/Maybe";
import isNil from "crocks/predicates/isNil";
import { isNotNil } from "./predicates";
import { safeDate } from "../util/types";

const { Just } = Maybe;

const dayMonthOpts = { month: 'short', day: 'numeric' };
const dayMonthYearOpts = { month: 'short', day: 'numeric', year: 'numeric' };

const formatDayMonth = date =>
  safeDate(date)
    .alt(Just(new Date(date)))
    .map(d => d.toLocaleDateString("en-US", dayMonthOpts))
    .option('');

const formatDayYear = date =>
  safeDate(date)
    .alt(Just(new Date(date)))
    .map(d => `${d.getDate()}, ${d.getFullYear()}`)
    .option('');

/**
 * Create and return a new Date object using the supplied day, month, and year.  Unlike the Date() constructor,
 * the month parameter uses normal 1-based month numbers, NOT the confusing 0-based numbers that Date() uses.
 *
 * @param day Day of month, 1-based index.
 * @param month Month of year, 1-based index.
 * @param year The year.
 * @returns {Date}
 */
export const date = (day = 1, month = 1, year = 2018) => new Date(year, month-1, day);

/**
 * Format the date into a standard "Mon Day, Year" format.  Note that the month will be in its short form
 * (i.e. Dec rather than December and Jun rather than June).
 *
 * @param date
 * @returns {string}
 */
export const formatDayMonthYear = (date) =>
  isNotNil(date) ? new Date(date).toLocaleDateString("en-US", dayMonthYearOpts) : undefined;


export const formatDateRange = (start, end) => {
  if (isNil(start)) {
    return '';
  }

  if (isNil(end)) {
    return formatDayMonthYear(start);
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  if (startYear === endYear) {
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();

    return startMonth === endMonth
      ? `${formatDayMonth(startDate)} - ${formatDayYear(endDate)}`
      : `${formatDayMonth(startDate)} - ${formatDayMonthYear(endDate)}`;
  }

  return `${formatDayMonthYear(startDate)} - ${formatDayMonthYear(endDate)}`
};

/**
 * Covenience function to add (or subtract with negative years param) a certain number of years to a date.
 *
 * addYears :: (Integer, Date) -> Date
 */
export const addYears = (years, oldDate) =>
  date(oldDate.getDate(), oldDate.getMonth() + 1, oldDate.getFullYear() + years);
