/**
 * @type {
 *  Object.<{
 *    ASCENDING: string,
 *    DESCENDING: string
 *  }>
 * }
 */
const SortDirection = Object.freeze({
  ASCENDING: 'asc',
  DESCENDING: 'desc',
});

const { ASCENDING, DESCENDING } = SortDirection;

export const invertSortDirection = dir => dir === ASCENDING ? DESCENDING : ASCENDING;

export default SortDirection;