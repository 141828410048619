import React from 'react';
import PropTypes from 'prop-types';
import safe from "crocks/Maybe/safe";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import AmpTable from "../../../common/components/AmpTable";
import ColumnType from "../../../common/components/AmpTable/ColumnType";
import RoleColumn from "./RoleColumn";
import AmpDialog from "../../../common/components/AmpDialog";
import UserRoleEditList from "./UserRoleEditList";
import { getUserFullName } from "../../../common/models";
import { isNotNil } from "../../../common/util/predicates";
import UserInfoEditor from "./UserInfoEditor";

const { STRING, INTEGER, BOOLEAN, DATE } = ColumnType;

const columnData = [
  { id: 'id', label: 'ID', type: INTEGER, width: '20px' },
  { id: 'firstName', label: 'First Name', type: STRING },
  { id: 'lastName', label: 'Last Name', type: STRING },
  { id: 'orgName', label: 'Organization', type: STRING },
  { id: 'orgEnabled', label: 'Org Enabled', type: BOOLEAN },
  { id: 'enabled', label: 'User Enabled', type: BOOLEAN, width: '20px' },
  { id: 'email', label: 'Email', type: STRING },
  { id: 'phoneNumber', label: 'Phone Number', type: STRING },
  { id: 'invitedDate', label: 'Invited', type: DATE },
  { id: 'registeredDate', label: 'Registered', type: DATE },
  { id: 'certUploadDate', label: 'IRB Cert Submitted', type: DATE },
  { id: 'certReviewDate', label: 'IRB Cert Reviewed', type: DATE },
  { id: 'certApproved', label: 'IRB Cert Approved', type: BOOLEAN },
  { id: 'amendedToIrbDate', label: 'Amended to IRB', type: DATE },
  { id: 'lastLoginDate', label: 'Last Login', type: DATE },
  { id: 'roles', label: 'Roles', render: user => <RoleColumn user={user}/> },
];

const buildTextForUser = (user, verb) =>
  `Edit ${verb} for ${getUserFullName(user).option('Unknown User')}`;

const styles = theme => ({
  root: {
    height: '100%',
    overflowX: 'auto',
    '& > *': {
      minWidth: 1200,
    },
  },
  roleTitle: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  }
});

class UserTable extends React.PureComponent {
  static propTypes = {
    users: PropTypes.array,
    organizations: PropTypes.array,
  };

  static defaultProps = {
    users: [],
    organizations: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return safe(isNotNil, prevState.user)
      .map(user => nextProps.users.find(u => u.id === user.id))
      .map(user => ({ user }))
      .option(null);
  }

  handleClose = () => this.setState({ open: false, user: null });
  handleEditUser = user => this.setState({ open: true, user });

  state = {
    open: false,
    user: null,
  };

  render() {
    const { open, user } = this.state;
    const { users, organizations, classes } = this.props;

    return (
      <div className={classes.root}>
        <AmpTable columnData={columnData} items={users} onRowSelected={this.handleEditUser}/>

        {
          isNotNil(user) &&
          <AmpDialog
            open={open}
            title={buildTextForUser(user, 'information')}
            onClose={this.handleClose}
          >
            <UserInfoEditor user={user} organizations={organizations}/>

            <Typography className={classes.roleTitle} variant="h6">
              {buildTextForUser(user, 'roles')}
            </Typography>

            <UserRoleEditList user={user}/>
          </AmpDialog>
        }
      </div>
    );
  }
}

export default withStyles(styles)(UserTable);