import materialGrey from "@material-ui/core/colors/grey";

const ampPrimary = '#620C0D';
const viewerDim = '#777';
// const viewerHighlight = '#FEAF5A';
// const viewerHighlight = '#CF7822';
// const viewerHighlight = '#ce8f49';
// const viewerHighlight = '#0BF';
const viewerHighlight = '#D3AC56';
const ampBorderRadius = 4;

const ampTheme = {
  palette: {
    primary: { main: ampPrimary },
    secondary: { main: materialGrey[300] },
    amp: { viewerHighlight, viewerDim },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: ampBorderRadius,
      }
    },
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          borderRadius: ampBorderRadius,
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        borderTopLeftRadius: ampBorderRadius,
        borderTopRightRadius: ampBorderRadius,
      }
    },
    MuiButton: {
      root: {
        borderRadius: ampBorderRadius,
      }
    }
  },
  amp: {
    borderRadius: ampBorderRadius,
  }
};

export default ampTheme;