import partial from "crocks/helpers/partial";
import compose from "crocks/helpers/compose";
import { addYears, formatDayMonthYear } from "../../common/util/date";

const toDate = timestamp => new Date(timestamp);
const add2Years = partial(addYears, 2);
const format2YearsFrom = compose(formatDayMonthYear, add2Years, toDate);

const lookupStatus = (step, approved = false, certIssueDate = null) => {
  switch (step) {
    case 0:
      return 'You have not yet uploaded a certificate.';
    case 1:
      return 'Your new IRB certificate is being reviewed.';
    case 2:
      return approved
        ? 'Your certificate has been approved and you are being added to the list of Qualified Researchers.'
        : 'Your certificate has been rejected, please submit an updated certificate using the Upload button below.';
    case 3:
      if (certIssueDate === null) {
        return '';
      }
      return `Your current IRB certificate expires on ${format2YearsFrom(certIssueDate)}`;
    default:
      return 'Your status is unknown please contact the AMP administrator for your organization.';
  }
};

/**
 * Returns a message stating whether or not the user has approval to view AMP data.
 *
 * @param profile The user's profile
 * @returns {string}
 */
export const getQualifiedResearcherStatus = ({ amendedToIrbDate }) => {
  return amendedToIrbDate === null || amendedToIrbDate === undefined
    ? 'You are not yet approved to view AMP scenario data.'
    : 'You are approved to view AMP scenario data.';
};

/**
 * Returns an object containing an integer IRB step index (see below) and the appropriate status message
 * given the user's IRB dates and the current date.  The steps are as follows:
 *
 *  0 - Not yet uploaded cert
 *  1 - Uploaded cert, under review
 *  2 - Cert reviewed, could be approved or not
 *  3 - Amended to IRB
 *
 * @param profile The user's profile.
 * @returns {{step: number, status: string}}
 */
export const getIrbCertStatus = ({ certUploadDate, certReviewDate, amendedToIrbDate, certApproved, certIssueDate }) => {
  if (certUploadDate === null) {
    return { step: 0, status: lookupStatus(0) };
  } else if (certReviewDate === null) {
    return { step: 1, status: lookupStatus(1) };
  } else if (amendedToIrbDate === null) {
    return { step: 2, status: lookupStatus(2, certApproved) };
  } else {
    return { step: 3, status: lookupStatus(3, certApproved, certIssueDate) }
  }
};