import React from 'react';
import PropTypes from 'prop-types';
import CardStamp from "../DashboardCard/CardStamp";
import CardTitle from "../DashboardCard/CardTitle";
import CardActions from "../DashboardCard/CardActions";
import CardContent from "../DashboardCard/CardContent";
import ActionButton from "../DashboardCard/ActionButton";
import AppRequest from "../../../common/models/AppRequest";
import DashboardCard from "../DashboardCard/index";
import UsersIcon from '@material-ui/icons/SupervisorAccount';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { allRequestsSuccessful } from "../../../common/util/request";

const { GET_ORGANIZATIONS, GET_USERS} = AppRequest;
const isLoading = reqState => !allRequestsSuccessful(reqState, GET_ORGANIZATIONS, GET_USERS);

const createSubtitle = count => count === 0 ? 'Loading...' : `${count} User${count === 1 ? '' : 's'}`;

const UsersCard = ({ onInviteUser, onSendEmail, userCount, requestState, onClick }) => (
  <DashboardCard onClick={onClick}>
    <CardStamp backgroundColor="#AD0380" color="white">
      <UsersIcon/>
    </CardStamp>
    <CardContent>
      <CardTitle title="Users" subtitle={createSubtitle(userCount)}/>
      <CardActions>
        <ActionButton
          icon={<AddIcon/>}
          label="Invite User"
          onClick={onInviteUser}
          disabled={isLoading(requestState)}
        />
        <ActionButton
          icon={<EmailIcon/>}
          label="Send Email"
          onClick={onSendEmail}
          disabled={isLoading(requestState)}
        />
      </CardActions>
    </CardContent>
  </DashboardCard>
);

UsersCard.propTypes = {
  userCount: PropTypes.number,
  onInviteUser: PropTypes.func,
  onSendEmail: PropTypes.func,
  requestState: PropTypes.object,
};

UsersCard.defaultProps = {
  userCount: 0,
  requestState: {},
};

export default UsersCard;