import { VariableId } from "../../../common/models"

/**
 * The label used to identify the text search data in filterParams and in expression
 * tree nodes.
 *
 * @type {string}
 */
export const TEXT_SEARCH_LABEL = "textSearch"

export const textSearchVariableIds = [
  VariableId.CASE_ID,
  VariableId.FINAL_NARRATIVE,
]

/**
 * This list of IDs controls the contents and the order of the list variables in the filter
 * bar along the left side of the scenario browser.
 */
export const listVariableIds = [
  VariableId.TAGS,
  VariableId.SHARED_TAGS,
  VariableId.CASE_TYPE,
  VariableId.AMP_LAYER,
  VariableId.ENHANCED_DATASET,
  VariableId.EPOCH_TYPE,
  VariableId.EVASIVE_MANEUVER,
  VariableId.FEATURES_PRESENT,
  VariableId.INTERACTIONS,
  VariableId.INTERSECTION,
  VariableId.LANES,
  VariableId.INITIAL_MANEUVER,
  VariableId.NHTSA_PRE_CRASH_TYPOLOGY_2019,
  VariableId.DRIVER_FACTORS,
  VariableId.OTHER_INVOLVED_OBJECT,
  VariableId.OTHER_INVOLVED_VEHICLE,
  VariableId.IN_CAB_ENVIRONMENT,
  VariableId.ROADWAY_GEOMETRY,
  VariableId.ROADWAY_SURFACE,
  VariableId.ROADWAY_SURROUNDINGS,
  VariableId.TRAFFIC_FLOW,
  VariableId.TRAVERSALS,
  VariableId.WEATHER_LIGHTING,
]