import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { isNotNil } from "../../../common/util/predicates"

const formatRecipientCount = count =>
  isNotNil(count) ? `${count} Recipient${count === 1 ? '' : 's'}` : 'Recipients'

function RecipientSelect({ value, onChange, count }){
  const handleChange = useCallback(ev => onChange(ev.target.value), [onChange])

  return (
    <FormControl>
      <InputLabel htmlFor="role-select">
        {formatRecipientCount(count)}
      </InputLabel>
      <Select value={value} onChange={handleChange}>
        <MenuItem value="test">
          <em>You (test email)</em>
        </MenuItem>
        <MenuItem value="ROLE_USER">All Users</MenuItem>
        <MenuItem value="ROLE_QUALIFIED_RESEARCHER">Qualified Researchers</MenuItem>
        <MenuItem value="ROLE_LPA">Local Portal Admins</MenuItem>
        <MenuItem value="ROLE_IRB">IRB Reviewers</MenuItem>
      </Select>
    </FormControl>
  )
}

RecipientSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default RecipientSelect
