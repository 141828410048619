import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import AdminIcon from "@material-ui/icons/Apps";
import ProfileIcon from "@material-ui/icons/PermIdentity";
import PasswordIcon from "@material-ui/icons/LockOutlined";
import IrbCertIcon from "@material-ui/icons/Attachment";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import LicenseIcon from "@material-ui/icons/FormatAlignLeft";
import { pipe } from "../util/func";
import { doLogout } from "../store/actions";
import UserMenuItem from "./UserMenuItem";
import IfUser from "./logic/IfUser";
import { UserRole, userHasAnyRoles } from "../models";

const isAdminOrIrbOrEditor = userHasAnyRoles([UserRole.ROLE_ADMIN, UserRole.ROLE_IRB, UserRole.ROLE_EDITOR]);

const menuId = "user-menu";
const anchorOpts = {
  anchorReference: 'anchorEl',
  anchorOrigin: { vertical: 'bottom', horizontal: 'right'},
  getContentAnchorEl: null
};

const redirectFn = history => path => () => history.push(path);

const styles = theme => ({
  buttonText: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    textTransform: "none"
  },
  [theme.breakpoints.up('lg')]: {
    userNameMenuItem: {
      display: 'none',
    }
  }
});

class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    const { onClose } = props;
    const createRedirect = redirectFn(this.props.history);

    this.logoutHandler = pipe(onClose, this.props.doLogout, createRedirect('/'));
    this.profileHandler = pipe(onClose, createRedirect('/profile'));
    this.passwordHandler = pipe(onClose, createRedirect('/profile/changePassword'));
    this.irbHandler = pipe(onClose, createRedirect('/profile/irb'));
    this.dulHandler = pipe(onClose, createRedirect('/profile/dul'));
    this.adminHandler = pipe(onClose, createRedirect('/admin'));
  }

  render() {
    const { classes, name, anchor, onClose } = this.props;
    const isOpen = Boolean(anchor);

    return (
      <Menu id={menuId} anchorEl={anchor} {...anchorOpts} open={isOpen} onClose={onClose}>
        <MenuItem className={classes.userNameMenuItem}>{name}</MenuItem>
        <UserMenuItem text="Profile Information" icon={<ProfileIcon/>} onClick={this.profileHandler} />
        <UserMenuItem text="Change Password" icon={<PasswordIcon/>} onClick={this.passwordHandler} />
        <UserMenuItem text="IRB Certification" icon={<IrbCertIcon/>} onClick={this.irbHandler} />
        <UserMenuItem text="Data Use License" icon={<LicenseIcon/>} onClick={this.dulHandler} divider/>
        <IfUser filter={isAdminOrIrbOrEditor}>
          <UserMenuItem text="Admin Dashboard" icon={<AdminIcon/>} onClick={this.adminHandler} divider/>
        </IfUser>
        <UserMenuItem text="Logout" icon={<LogoutIcon/>} onClick={this.logoutHandler} />
      </Menu>
    );
  }
}

UserMenu.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.object,
};

export default pipe(
  connect(null, { doLogout }),
  withStyles(styles),
  withRouter
)(UserMenu);

