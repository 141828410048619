const privacyStatement = `
  <h1 style="text-align: center;">AMP Portal</h1> 
  <h2 style="text-align: center;">PRIVACY POLICY</h2>
  
  <h3>What information do we collect?</h3>
  <p>We collect information from you when you register on our site.</p>
  <p>
    When ordering or registering on our site, as appropriate, you may be asked to enter your:
    <ul>
      <li>Name</li>
      <li>E-mail address</li>
      <li>Phone number</li>
      <li>Organizational affiliation</li>
    </ul>
  </p>
  
  <h3>What do we use your information for?</h3>
  <p>Any of the information we collect from you may be used in the following ways:
    <ul>
      <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
      <li>
        To improve our website (we continually strive to improve our website offerings based on the information and 
        feedback we receive from you)
      </li>
      <li>
        To improve customer service (your information helps us to more effectively respond to your customer service 
        requests and support needs)
      </li>
      <li>To send periodic emails (that you may opt out of)</li>
    </ul>
    The email address you provide may be used to send you information, respond to inquiries, and/or other requests or 
    questions.
  </p>
  
  <h3>How do we protect your information?</h3>
  <p>
    We implement a variety of security measures to maintain the safety of your personal information when you enter, 
    submit, or access your personal information.
  </p>

  <h3>Do we use cookies?</h3>
  <p>
    Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through 
    your web browser (if you allow) that enables the sites or service providers systems to recognize your browser and 
    capture and remember certain information.
  </p>
  <p>
    We use cookies to understand and save your preferences for future visits and compile aggregate data about site 
    traffic and site interaction so that we can offer better site experiences and tools in the future.
  </p>

  <h3>Do we disclose any information to outside parties?</h3>
  <p>
    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does 
    not include third parties who assist us in operating our website, conducting our business, or servicing you, so 
    long as those parties agree to keep this information confidential. We may also release your information when 
    required by process of law, to enforce our site policies, or protect safety. However, non-personally identifiable 
    visitor information may be provided to other parties for marketing, advertising, or other uses.
  </p>
  <p>
    Virginia Tech complies with all statutory and legal requirements with respect to access to information.
  </p>
  
  <h3>Third party links</h3>
  <p>
    Occasionally, at our discretion, we may include links to third party websites or services from our website. These 
    third party sites have separate and independent privacy policies. We therefore have no responsibility or liability 
    for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and 
    welcome any feedback about these sites.
  </p>
  
  <h3>Your Consent</h3>
  <p>By using our site, you consent to our website privacy policy.</p>


  <h3>Changes to our Privacy Policy</h3>
  <p>If we decide to change our privacy policy, we will post those changes on this page.</p>
  
  <h3>Contacting Us</h3>
  <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>
  
  <h4 style="margin-bottom: 0">Mike Mollenhauer</h4>
  <a href="mailto:mmollenhauer@vtti.vt.edu">mmollenhauer@vtti.vt.edu</a>  

  <h4 style="margin-bottom: 0">Virginia Tech Transportation Institute</h4>
  <p style="margin-top: 0">
    3500 Transportation Research Plaza<br>
    Blacksburg, VA 24060<br>
    Phone: (540) 231-1500<br>
  </p>
`;

export default privacyStatement;