import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core/";
import { createUser, UserRole } from "../../../common/models";
import { compose } from "redux";
import { connect } from "react-redux";
import { addRoleToUser, removeRoleFromUser } from "../../../common/store/actions";

const createCheckBox = (choice, checked, onChange) => (
  <FormControlLabel
    key={choice}
    label={choice}
    control={<Checkbox checked={checked} color="primary" onChange={onChange(choice)} value={String(choice)}/>}
  />
);

const createCheckBoxes = (choices, selected, onChange)  =>
  choices.map(ch => createCheckBox(ch, selected.includes(ch), onChange));

function toggleRole(role, checked) {
  return state => {
    const { roles } = state.user;
    const user = { ...state.user, roles: checked ? [...roles, role] : roles.filter(r => r !== role) };
    return { user };
  };
}

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

class UserRoleEditList extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: createUser(),
  };

  state = { user: {} };

  static getDerivedStateFromProps(nextProps, prevState) {
    return prevState.user.id !== nextProps.user.id ? { user: {...nextProps.user }} : null;
  }

  constructor(props) {
    super(props);
    this.allRoles = Object.values(UserRole);
  }

  handleChange = role => ev => {
    const { checked } = ev.target;
    const { user, addRoleToUser, removeRoleFromUser } = this.props;
    const action = checked ? addRoleToUser : removeRoleFromUser;

    this.setState(toggleRole(role, checked));

    action(user.id, role)
      .catch(() => this.setState(toggleRole(role, !checked)));
  };

  render() {
    const { roles } = this.state.user;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {createCheckBoxes(this.allRoles, roles, this.handleChange)}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(null, { addRoleToUser, removeRoleFromUser })

)(UserRoleEditList);
