import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[500],
  },
});

class Rail extends React.PureComponent {
  static propTypes = {
    railSize: PropTypes.number,
    classes: PropTypes.object.isRequired,
    getRailProps: PropTypes.func.isRequired,
  };

  static defaultProps = {
    railSize: 4,
  };

  render() {
    const { railSize, classes, getRailProps } = this.props;

    return <div
      className={classes.root}
      {...getRailProps()}
      style={{ height: railSize, borderRadius: railSize / 2 }}
    />
  }
}

export default withStyles(styles)(Rail);
