import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';
import EditIcon from '@material-ui/icons/Edit';
import ChartVariablePopup from "./ChartVariablePopup";
import ChartLegendItem from "./ChartLegendItem";

const selectVariableMapper = newSelection => v => ({ ...v, isActive: v.id === newSelection.id });

function selectVariable(selections, newSelection) {
  return newSelection.isActive ? selections : selections.map(selectVariableMapper(newSelection));
}

const styles = theme => ({
  legendTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  legendItems: {
    marginLeft: theme.spacing(),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(10em, 1fr))',
    gridColumnGap: `${theme.spacing(2)}px`,
  },
});

class ChartVariableSelect extends React.PureComponent {
  static propTypes = {
    chartVariables: PropTypes.array.isRequired,
    selectedChartVariables: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    popupOpen: false,
    popupAnchor: undefined,
  };

  handleClick = variable => this.props.onChange(selectVariable(this.props.selectedChartVariables, variable));

  handlePopupOpen = e => this.setState({ popupOpen: true, popupAnchor: e.target });
  handlePopupClose = () => this.setState({ popupOpen: false, popupAnchor: null });

  render() {
    const { popupOpen, popupAnchor } = this.state;
    const { chartVariables, selectedChartVariables, onChange, classes } = this.props;

    return (
      <div>
        <div className={classes.legendTitle}>
          <Typography variant="subtitle1">Legend</Typography>
          <IconButton onClick={this.handlePopupOpen}><EditIcon/></IconButton>
          <ChartVariablePopup
            open={popupOpen}
            anchorEl={popupAnchor}
            variables={chartVariables}
            selections={selectedChartVariables}
            onChange={onChange}
            onClose={this.handlePopupClose}
          />

        </div>
        <div className={classes.legendItems}>
          {selectedChartVariables.map(cv => <ChartLegendItem key={cv.id} variable={cv} onClick={this.handleClick}/>)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ChartVariableSelect);
