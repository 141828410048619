import React from 'react'
import AppBar from "@material-ui/core/AppBar"
import { Tab, Tabs } from "@material-ui/core"
import { Link, Route, Switch } from "react-router-dom"
import ScenarioViewer from "./ScenarioViewer/ScenarioViewer"
import ScenarioBrowser from "./ScenarioBrowser/ScenarioBrowser"
import {
  fetchFilterVars,
  fetchTimeSeriesVars,
  filterImmediate,
  setDefaultChartVariableSelections
} from "../../common/store/actions"
import { connect } from "react-redux"
import QualifiedResearcherCheck from "./QualifiedResearcherCheck"
import { getUserProfile } from "../../common/store"
import { userIsQualifiedResearcher } from "../../common/models"

class ScenariosContent extends React.Component {
  state = {
    value: 0
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.location.pathname.match(/viewer/)) {
      return { value: 1 }
    }
    return null
  }

  componentDidMount() {
    if (userIsQualifiedResearcher(this.props.profile)) {
      this.props.fetchFilterVars()
      this.props.fetchTimeSeriesVars()
      this.props.setDefaultChartVariableSelections()
      this.props.filterScenariosImmediate()
    }
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    return (
      <QualifiedResearcherCheck>
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
          <AppBar position="static">
            <Tabs value={this.state.value} onChange={this.handleChange} centered>
              <Tab label="Browser" component={Link} to="/scenarios"/>
              <Tab label="Viewer" component={Link} to="/scenarios/viewer"/>
            </Tabs>
          </AppBar>

          <Switch>
            <Route path="/scenarios/viewer" component={ScenarioViewer}/>
            <Route path="/scenarios" component={ScenarioBrowser}/>
          </Switch>
        </div>
      </QualifiedResearcherCheck>
    )
  }
}

const mapStateToProps = state => ({ profile: getUserProfile(state) })
const mapDispatchToProps = {
  filterScenariosImmediate: filterImmediate,
  fetchFilterVars,
  fetchTimeSeriesVars,
  setDefaultChartVariableSelections,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosContent)