import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import { certContentType, certId } from "../../../common/models/irbCertificate";


const PdfMessage = ({ cert, className }) => (
  <div className={className} style={{ flexDirection: 'column'}}>
    <Typography variant="h4">This PDF could not be converted to an image automatically.</Typography>
    <Typography variant="h4">
      You can either <a href={`/api/cert/${cert.id}`} target="_blank" rel="noopener noreferrer">view it</a>
      in another window or <a href={`/api/cert/${cert.id}`} download>download it</a>.
    </Typography>
  </div>
);

const EndOfList = ({ className }) => (
  <div className={className}>
    <Typography variant="h4">All certificates have been reviewed</Typography>
  </div>
);

const styles = {
  root: {
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

class IrbCertificateImage extends React.PureComponent {
  static propTypes = {
    cert: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    cert: {},
  };

  render() {
    const { cert, classes, className } = this.props;
    const rootClassName = classNames(className, classes.root);
    const msgClassName = classNames(className, classes.root, classes.message);
    const id = certId(cert);

    if (id === 0) {
      return <EndOfList className={msgClassName} />;
    }

    if (certContentType(cert).match(/pdf/ig) !== null) {
      return <PdfMessage className={msgClassName} cert={cert} />;
    }

    return <img className={rootClassName} src={`/api/cert/${id}`} alt="IRB Certificate"/>;
  }
}

export default withStyles(styles)(IrbCertificateImage);
