import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from "crocks/predicates/isEmpty"
import { isListExpression, treeReduce } from "../../../../common/models/index"
import { buildLookupById } from "../../../../common/util/object"
import QuickFilterChips from "./QuickFilterChips"
import FilterControls from "../FilterControls"
import makeStyles from "@material-ui/core/styles/makeStyles"
import AnalyticsFilterStats from "../AnalyticsFilterStats"
import { partial } from "../../../../common/util/func"
import { setGroupEnabled } from "../../../../common/models/expressionGroupOp"
import { useAnalyticsTag } from "../../../../common/store/hooks/useAnalytics"

export function listChoiceReducer(acc, node) {
  if (!isListExpression(node) || isEmpty(node.selected)) {
    return acc
  }
  const choiceLookup = buildLookupById(node.variable.choices)
  return acc.concat(
    node.selected
      .map(id => choiceLookup[id] ? { id: id, choice: choiceLookup[id].choice, expression: node } : null)
      .filter(it => it !== null)
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    marginTop: theme.spacing(.5),
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '2fr 1fr',
    gridRowGap: theme.spacing(2),
  },
  controls: {
    gridColumn: '1 / -1',
  }
}))

function FilterChoices({
  treeLookup,
  className,
  onFilterChange,
  onQuickFilterChange,
  onQuickFilterClear
}) {
  const classes = useStyles()
  const rootClassNames = classNames(classes.root, className)
  const { clearAnalyticsIds } = useAnalyticsTag()

  const { quickFilterBranch, analyticsFilterBranch } = treeLookup
  const choices = treeReduce(quickFilterBranch, listChoiceReducer, [])

  const clearAnalyticsFilter = () =>
    clearAnalyticsIds()
      .then(() => onFilterChange(partial(setGroupEnabled, false), analyticsFilterBranch))

  return (
    <div className={rootClassNames}>
      <FilterControls
        className={classes.controls}
        treeLookup={treeLookup}
        onFilterChange={onFilterChange}
      />

      <QuickFilterChips
        choices={choices}
        visible={quickFilterBranch.isEnabled}
        onQuickFilterChange={onQuickFilterChange}
        onQuickFilterClear={onQuickFilterClear}
      />

      <AnalyticsFilterStats visible={analyticsFilterBranch.isEnabled} onClear={clearAnalyticsFilter}/>
    </div>
  )
}

FilterChoices.propTypes = {
  tree: PropTypes.object,
  className: PropTypes.string,
  onFilterChange: PropTypes.func,
  onQuickFilterClear: PropTypes.func,
  onQuickFilterChange: PropTypes.func,
  onToggleAdvancedVisible: PropTypes.func,
  onToggleAdvancedApplied: PropTypes.func,
}

FilterChoices.defaultProps = {
  tree: {},
}

export default FilterChoices
