import constant from "crocks/combinators/constant";
import isDefined from "crocks/predicates/isDefined";
import isFunction from "crocks/predicates/isFunction";
import { setRequestState } from "./index";
import AppRequestState from "../../models/AppRequestState";
import { urlWithParams } from "../../services/http";
import { setAppStatus } from "./app";
import { AppStatus, createUser } from "../../models";
import isArray from "crocks/predicates/isArray"

function selectServiceCall(services, method, body) {
  if (method === 'GET') {
    return services.http.getData;
  }

  if (method === 'DELETE') {
    return isDefined(body) ? services.http.deleteJson : services.http.deleteData;
  }

  if (body instanceof FormData) {
    return method === 'POST' ? services.http.postForm : services.http.putForm;
  }

  return method === 'POST' ? services.http.postJson : services.http.putJson;
}

// requestState :: Boolean -> String
const requestState = ok => ok ? AppRequestState.SUCCESS : AppRequestState.FAILURE;

export const handleRequestError = (dispatch, request, err) => {
  console.log(`Error during ${request} request:`, err);
  return dispatch(setRequestState(request, AppRequestState.FAILURE, err.message));

  // Rethrowing triggers uncaught rejection errors that blow up the app unless there
  // is another catch function at the original call site.  Disable for now until we
  // figure out better error handling (issue #237) - DRI
  // throw err;
};

// Useful for debugging promise chains. Just insert 'then(trace)' into the chain.
export const trace = resp => console.log(resp) || resp;

/**
 * Turns all Object props into FormData props.
 *
 * buildFormData :: Object -> FormData
 */
export const buildFormData = (data) =>
  Object.keys(data)
    .reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

export const updateRequestState = (dispatch, request) => resp => {
  dispatch(setRequestState(request, requestState(resp.ok)));
  return resp;
};

export const intercept401 = dispatch => resp => {
  if (resp.status === 401) {
    dispatch(setAppStatus(AppStatus.APP_LOGGED_OUT, createUser()));
  }
  return resp;
};

const readResponse = (reader, resp) =>
  reader(resp)
    .then(data => ({ data, resp }))
    .catch(() => ({ data: {}, resp }));

// checkResponse :: Response -> Promise jsonData | Promise err
export const checkResponse = reader => resp =>
  resp.ok
    ? readResponse(reader, resp)
    : resp.text().then(err => Promise.reject(new Error(err)));

export const jsonReader = resp => resp.json();
export const blobReader = resp => resp.blob();

export const ensureArrayResponse = resp => isArray(resp) ? resp : Promise.reject(resp)

/**
 * Create and return a function that follows the redux-thunk contract and performs the
 * standard steps for fetching data from the server, including error handling.
 **
 * @returns {Function} A function that can be used as a redux-thunk callback.
 * @see createHttpRequestThunk
 */
export const createGetThunk = opts => createHttpRequestThunk('GET', opts);

/**
 * Create and return a function that follows the redux-thunk contract and performs the
 * standard steps for putting data from the server, including error handling.
 *
 * @returns {Function} A function that can be used as a redux-thunk callback.
 * @see createHttpRequestThunk
 */
export const createPutThunk = opts => createHttpRequestThunk('PUT', opts);

/**
 * Create and return a function that follows the redux-thunk contract and performs the
 * standard steps for posting data from the server, including error handling.
 *
 * @returns {Function} A function that can be used as a redux-thunk callback.
 * @see createHttpRequestThunk
 */
export const createPostThunk = opts => createHttpRequestThunk('POST', opts);

/**
 * Create and return a function that follows the redux-thunk contract and performs the
 * standard steps for posting data from the server, including error handling.
 *
 * @returns {Function} A function that can be used as a redux-thunk callback.
 * @see createHttpRequestThunk
 */
export const createDeleteThunk = opts => createHttpRequestThunk('DELETE', opts);

/**
 * Create and return a function that follows the redux-thunk contract and performs the
 * standard steps for putting or posting data from the server, including error handling.
 *
 * @param method The REST method of this request, either 'POST' or 'PUT'.
 *
 * @param request The APP_REQUEST enum value of the request
 * @param requestOpts A set of options passed along to the fetch function
 * @param url The URL to fetch
 * @param params An object containing any query params or a function that receives the current Redux
 *    state and returns that object.
 * @param body An object to be sent as the request body or a function that receives the current Redux
 *    state and returns that object.
 * @param refreshPredicate If this func returns false, the request will not be sent (optional)
 * @param responseReader A function that takes the response and determines how to read the body.  See
 *    jsonReader or blobReader above for examples.
 * @param responseFnFactory A function that takes the dispatch, getState, and services params and returns
 *    a function that will receive the data from the server for processing.
 * @param preprocessor An optional function that will be called before the request is sent to the server.
 *
 * @returns {Function} A function that can be used as a redux-thunk callback.
 */
function createHttpRequestThunk(method, {
  request,
  requestOpts,
  url,
  params,
  body,
  refreshPredicate = constant(true),
  responseReader = jsonReader,
  responseFnFactory = () => () => undefined,
  preprocessor = () => undefined
}) {
  return (dispatch, getState, services) => {
    if (refreshPredicate(getState(), services) === false) {
      return Promise.resolve()
    }

    if (isFunction(preprocessor)) {
      preprocessor(dispatch, getState, services);
    }

    dispatch(setRequestState(request, AppRequestState.IN_FLIGHT));

    const bodyObj = isFunction(body) ? body(getState()) : body;
    const paramsObj = isFunction(params) ? params(getState()) : params;
    const serviceCall = selectServiceCall(services, method, bodyObj);

    const responseFn = responseFnFactory(dispatch, getState, services, bodyObj);
    const callResponseFn = ({ data, resp }) => responseFn(data, resp.headers);

    return serviceCall(urlWithParams(url, paramsObj), bodyObj, requestOpts)
      .then(updateRequestState(dispatch, request))
      .then(intercept401(dispatch))
      .then(checkResponse(responseReader))
      .then(callResponseFn)
      .catch(err => handleRequestError(dispatch, request, err));
  };
}
