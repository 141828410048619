import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import when from "crocks/logic/when"
import prop from "crocks/Maybe/prop"
import compose from "crocks/helpers/compose"
import constant from "crocks/combinators/constant"
import alt from "crocks/pointfree/alt"
import option from "crocks/pointfree/option"
import TimeSeriesValue from "./TimeSeriesValue"
import Typography from "@material-ui/core/Typography"
import { isNull } from "../../../../../common/util/predicates"
import makeStyles from "@material-ui/core/styles/makeStyles"

const provideNullStr = constant("NULL")
const getProp = map => name => prop(name, map)
const getElseVal = map => prop("ELSE", map)

const createLookup = map => compose(
  option(''),
  alt(getElseVal(map)),
  getProp(map),
  when(isNull, provideNullStr)
)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(),
  },
  title: {
    fontSize: '75%',
    textAlign: 'center',
    color: theme.palette.amp ? theme.palette.amp.viewerDim : '#888',
  },
  value: {
    fontSize: '105%',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '-5px',
    color: theme.palette.amp ? theme.palette.amp.viewerHighlight : 'white',
  },
}))

export default function ValueMappedStatus({ data, time, title, map, className }) {
  const classes = useStyles()
  const lookupRef = useRef({ lookup: createLookup(map) })

  useEffect(() => {
    lookupRef.current = { lookup: createLookup(map) }
  }, [map])

  return (
    <div className={classNames(className, classes.root)}>
      <Typography className={classes.title}>{title}</Typography>
      <TimeSeriesValue data={data} time={time}>
        {val => <Typography className={classes.value}>{lookupRef.current.lookup(val)}</Typography>}
      </TimeSeriesValue>
    </div>
  )
}

ValueMappedStatus.propTypes = {
  data: PropTypes.array,
  time: PropTypes.number,
  map: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
}

ValueMappedStatus.defaultProps = {
  data: [],
  time: 0,
  map: { ELSE: '--', NULL: '--' },
}

