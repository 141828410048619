/*
 * This module has functions that act as some common default value providers for factory func
 * object properties.
 */

import constant from "crocks/combinators/constant";

// provideNull :: _ -> Null
export const provideNull = constant(null);

// provideUndefined :: _ -> Undefined
export const provideUndefined = constant(undefined);

// provideZero :: _ -> 0
export const provideZero = constant(0);

// provideFalse :: _ -> false
export const provideFalse = constant(false);

// provideTrue :: _ -> true
export const provideTrue = constant(true);

// provideEmptyString :: _ -> String
export const provideEmptyString = constant("");

// provideEmptyArray :: _ -> []
export const provideEmptyArray = constant([]);

// provideEmptyObject :: _ -> Object
export const provideEmptyObject = constant({});

// provideNow :: _ -> Date
export const provideNow = () => Date.now();
