import compose from "crocks/helpers/compose";
import partial from "crocks/helpers/partial";
import { setAppStatus } from "./index";
import { createGetThunk, createPostThunk, createPutThunk } from "./utils";
import { AppRequest, AppStatus, createUser } from "../../models";

const setAppLoggedIn = partial(setAppStatus, AppStatus.APP_LOGGED_IN);
const createProfileDispatcher = dispatch => compose(dispatch, setAppLoggedIn, createUser);

export const fetchUserProfile = () =>
  createGetThunk({
    url: '/api/profile',
    request: AppRequest.GET_PROFILE,
    responseFnFactory: createProfileDispatcher,
  });

export const updateUserProfile = ({ firstName, lastName, phoneNumber, optOutOfEmail }) =>
  createPutThunk({
    url: '/api/profile',
    body: { firstName, lastName, phoneNumber, optOutOfEmail },
    request: AppRequest.PUT_PROFILE,
    responseFnFactory: createProfileDispatcher,
  });

export const changeProfilePassword = ({ oldPassword, newPassword }) =>
  createPutThunk({
    url: '/api/profile/password',
    body: { oldPassword, newPassword },
    request: AppRequest.PUT_PROFILE_PASSWORD,
  });

export const uploadIrbCertificate = formData =>
  createPostThunk({
    url: '/api/profile/cert',
    body: formData,
    request: AppRequest.POST_IRB_CERTIFICATE,
  });
