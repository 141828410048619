import { app, appInitialState } from "./app";
import { admin, adminInitialState } from "./admin";
import { userData, userDataInitialState } from "./userData";

/**
 * This is the shape of the state contained in the Redux store.  It is here to provide documentation
 * on the state and to provide initial defaults for the reducers.
 */
export const initialState = {
  app: appInitialState,
  userData: userDataInitialState,
  admin: adminInitialState,
};

/**
 * Export the root reducers so they can be used to initialize the store.
 */
export default { app, userData, admin };
