import map from "crocks/pointfree/map"
import compose from "crocks/helpers/compose"
import { SET_USERS, UPDATE_USER } from "./index"
import { AppRequest, createUser } from "../../models"
import { createDeleteThunk, createGetThunk, createPostThunk, createPutThunk } from "./utils"

export const setUsers = users => ({ type: SET_USERS, payload: { users } })
export const updateUser = user => ({ type: UPDATE_USER, payload: { user } })

export const fetchUsers = orgId =>
  createGetThunk({
    request: AppRequest.GET_USERS,
    url: '/api/user',
    params: { orgID: orgId },
    responseFnFactory: dispatch => compose(dispatch, setUsers, map(createUser)),
  })

export const updateUserInfo = (userId, userReadOnlyInfo) =>
  createPutThunk({
    request: AppRequest.PUT_USER,
    url: `/api/user/${userId}`,
    body: userReadOnlyInfo,
    responseFnFactory: dispatch => compose(dispatch, updateUser, createUser),
  })

export const addRoleToUser = (userId, role) =>
  createPutThunk({
    request: AppRequest.PUT_ROLE,
    url: `/api/user/${userId}/role/${role}`,
    responseFnFactory: dispatch => compose(dispatch, updateUser, createUser),
  })

export const removeRoleFromUser = (userId, role) =>
  createDeleteThunk({
    url: `/api/user/${userId}/role/${role}`,
    request: AppRequest.DELETE_ROLE,
    responseFnFactory: dispatch => compose(dispatch, updateUser, createUser)
  })

export const inviteUser = (email, orgId) =>
  createPostThunk({
    request: AppRequest.POST_USER_INVITE,
    url: "/api/user/invite",
    body: { email, organization_id: orgId },
    responseFnFactory: dispatch => () => dispatch(fetchUsers()),
  })

export const amendUserToIrb = userId =>
  createPutThunk({
    request: AppRequest.PUT_ROLE,
    url: `/api/user/${userId}/irb`,
    responseFnFactory: dispatch => compose(dispatch, updateUser, createUser),
  })

export const sendEmailToUsers = (subject, htmlBody, toRole) =>
  createPostThunk({
    request: AppRequest.POST_USER_EMAIL,
    url: "/api/email",
    body: { subject, htmlBody, toRole },
    responseFnFactory: () => a => a,
  })

export const fetchEmailRecipientCounts = () =>
  createGetThunk({
    request: AppRequest.GET_EMAIL_COUNTS,
    url: "/api/email/recipient/count",
    responseFnFactory: () => a => a,
  })
