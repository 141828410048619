/**
 * The states a request can be in during its lifecycle.
 *
 * @type {Object.<{NONE: string, IN_FLIGHT: string, SUCCESS: string, FAILURE: string}>}
 */
const AppRequestState = Object.freeze({
  NONE: 'NONE',
  IN_FLIGHT: 'IN_FLIGHT',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
});

export default AppRequestState;