import curry from "crocks/helpers/curry";
import prop from "crocks/Maybe/prop";
import { end, head } from "../../../common/util/array";

/**
 * getEpochTimeStamps :: TimeSeries -> Maybe [Number]
 */
export const getEpochTimeStamps = prop('epochTimeStamp');

/**
 * getEpochTimeStampBase :: TimeSeries -> Maybe Number
 */
export const getEpochTimeStampBase = timeSeries =>
  getEpochTimeStamps(timeSeries)
    .map(head);

/**
 * getEpochTimeStampEnd :: TimeSeries -> Maybe Number
 */
export const getEpochTimeStampEnd = timeSeries =>
  getEpochTimeStamps(timeSeries)
    .map(end);

/**
 * getEpochTimeStampDuration :: TimeSeries -> Maybe Number
 */
export const getEpochTimeStampDuration = timeSeries =>
  getEpochTimeStampBase(timeSeries)
    .map(base => {
      const end = getEpochTimeStampEnd(timeSeries).option(0);
      return offsetSeconds(base, end);
    });

/**
 * offsetSeconds :: Integer -> Integer -> Number
 */
export const offsetSeconds = curry((baseMillis, offsetMillis) => (offsetMillis - baseMillis) / 1000);

