import React from 'react';
import PropTypes from 'prop-types';
import VariableSelectionPopup from "./VariableSelectionPopup";
import { VariableType } from "../../../../common/models";
import ValueExpression from "./ValueExpression";
import ListExpression from "./ListExpression";
import { createDefaultExpression } from "./expressionHelpers";
import { IconButton, withStyles } from "@material-ui/core/";
import CloseIcon from '@material-ui/icons/Close';

const renderValueExpression = (expression, onVariableClick, onApply, onDelete) => (
  <ValueExpression
    expression={expression}
    onVariableClick={onVariableClick}
    onApply={onApply}
    onDelete={onDelete}
  />
);

const renderListExpression = (expression, onVariableClick, onApply, onDelete) => (
  <ListExpression
    expression={expression}
    onVariableClick={onVariableClick}
    onApply={onApply}
    onDelete={onDelete}
  />
);

function lookupComponent(expression, onVariableClick, onApply, onDelete) {
  const { type } = expression.variable;

  switch (type) {
    case VariableType.int:
    case VariableType.float:
    case VariableType.string:
      return renderValueExpression(expression, onVariableClick, onApply, onDelete);

    case VariableType.list:
      return renderListExpression(expression, onVariableClick, onApply, onDelete);

    default:
      return <div>{expression.variable.name} Not Handled Yet</div>
  }
}

const styles = {
  expression: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
};

class Expression extends React.Component {
  state = {
    popupAnchor: undefined,
    variablePopupOpen: false,
  };

  handleDelete = () => this.props.onDelete(this.props.expression);

  handleVariablePopupOpen = e => this.setState({ variablePopupOpen: true, popupAnchor: e.target });
  handleVariablePopupClose = () => this.setState({ variablePopupOpen: false, popupAnchor: null });

  handleVariableSelection = variable =>
    this.props.onReplace(this.props.expression, createDefaultExpression(variable));

  render() {
    const { variablePopupOpen, popupAnchor } = this.state;
    const { expression, onApply, onDelete, classes } = this.props;

    return (
      <div className="expression-row">
        <div className="expression-box">
          <div className={classes.expression}>
            { lookupComponent(expression, this.handleVariablePopupOpen, onApply, onDelete) }
            <span className={classes.spacer}/>
            <IconButton onClick={this.handleDelete}><CloseIcon/></IconButton>
          </div>
        </div>
        <VariableSelectionPopup
          isOpen={variablePopupOpen}
          anchor={popupAnchor}
          onSelection={this.handleVariableSelection}
          onClose={this.handleVariablePopupClose}
        />
      </div>
    )
  }
}

Expression.propTypes = {
  expression: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(Expression);
