import React from 'react';
import { Route, Switch } from "react-router-dom";
import ResetPasswordPage from "./ResetPasswordPage";
import RegisterUserPage from "./RegisterUserPage";

const RegisterPage = () => (
  <Switch>
    <Route path="/register/resetPassword" component={ResetPasswordPage}/>
    <Route path="/register" component={RegisterUserPage}/>
  </Switch>
);

export default RegisterPage;

