import { partial, pipe } from "../util/func";
import { safeArray, safeString } from "../util/types";
import { isNotEmpty, isNotNil } from "../util/predicates";
import safe from "crocks/Maybe/safe";

const cookieName = 'ampcsrf';
const csrfHeader = 'X-CSRF-TOKEN';
const downloadClassName = "amp-download";

function createDownloadAnchorElement(url, fileName = '') {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.className = downloadClassName;
  return a;
}

function removeOldDownloadLinks() {
  const oldLinks = document.querySelectorAll(`.${downloadClassName}`);
  [...oldLinks].forEach(el => el.remove());
}

// function intercept401(redirect, resp) {
//   if (resp.status === 401) {
//     redirect("/login");
//   }
//   return resp;
// }

function updateCsrfToken(setCookie, resp) {
  const token = resp.headers.get(csrfHeader);
  setCookie(cookieName, { csrf: token, redirectLocation: window.location });
  return resp;
}

function buildOpts(getCookie, opts) {
  opts.headers = opts.headers || {};
  const csrfCookie = getCookie(cookieName);
  opts.headers[csrfHeader] = csrfCookie ? csrfCookie.csrf : "";
  return Object.assign(opts, { credentials: 'same-origin' });
}

/**
 * Adds CSRF processing to the fetch function supplied as a parameter.
 *
 * @param fetch A function that fetches data from the server (same sig as JavaScript's built-in fetch function).
 * @param setCookie A function that takes the name of a cookie and JSON data and writes it as a cookie.
 * @param getCookie A function that takes the name of a cookie and returns the cookie's data as JSON.
 * @returns {function(*=, *=)}
 */
export function withCsrf(fetch = window.fetch, setCookie = () => ({}), getCookie = () => ({})) {
  const processResponse = pipe(
    partial(updateCsrfToken, setCookie),
    // partial(intercept401, redirect)
  );
  return (url, opts = {}) => {
    const allOpts = buildOpts(getCookie, opts);
    return fetch(url, allOpts).then(resp => processResponse(resp));
  }
}

/**
 * Decorate the fetch function by forcing the option's method property to 'POST'.
 *
 * @param fetch The function to decorate.
 * @returns {function(*=, *=, *=): *} A decorated fetch function
 */
export function withPost(fetch) {
  return (url, opts = {}) => fetch(url, { ...opts, method: 'POST' });
}

/**
 * Decorate the fetch function by forcing the option's method property to 'PUT'.
 *
 * @param fetch The function to decorate.
 * @returns {function(*=, *=, *=): *} A decorated fetch function
 */
export function withPut(fetch) {
  return (url, opts = {}) => fetch(url, { ...opts, method: 'PUT' });
}

/**
 * Decorate the fetch function by forcing the option's method property to 'DELETE'.
 *
 * @param fetch The function to decorate.
 * @returns {function(*=, *=, *=): *} A decorated fetch function
 */
export function withDelete(fetch) {
  return (url, opts = {}) => fetch(url, { ...opts, method: 'DELETE' });
}

/**
 * Decorate the fetch function to pass the provided data as the body of the request.
 *
 * @param fetch
 * @returns {function(*=, *=, *=): *}
 */
export function withForm(fetch) {
  return (url, data, opts = {}) => fetch(url, { ...opts, body: data });
}

/**
 * Decorate the fetch function to pass the JSON of the data object as the body of the request.
 *
 * @param fetch
 * @returns {function(*=, *=, *=): *}
 */
export function withJson(fetch) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  return (url, data, opts = {}) => fetch(url, { ...opts, body: JSON.stringify(data), headers });
}

/**
 * Create and return a URL with encoded parameters appended to it.
 * @param url The URL string.
 * @param params An object whose keys and values represent the query params.
 */
export function urlWithParams(url, params) {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }

  const enc = encodeURIComponent;
  const queryStr = Object.keys(params)
    .map(key => params[key] === undefined ? null : `${enc(key)}=${enc(params[key])}`)
    .filter(it => it !== null)
    .join('&');

  return `${url}?${queryStr}`;
}

/**
 * Programmatically starts a download by creating an anchor tag and artificially clicking it.
 */
export function downloadFile(label, url) {
  removeOldDownloadLinks();

  const a = createDownloadAnchorElement(url);
  document.body.appendChild(a);
  a.click();
}

export function parseFilenameFromContentDisposition(headers) {
  return safe(isNotNil, headers)
    .chain(hs => safeString(hs.get('content-disposition')))
    .chain(str => safeArray(str.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)))
    .chain(matches => safe(isNotEmpty, matches[1]))
    .option('text.txt');
}

export function downloadFileFromObject(blob, headers) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = parseFilenameFromContentDisposition(headers);
  a.dispatchEvent(new MouseEvent('click'));
}
