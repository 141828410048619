import { createFactoryFunc } from "./factory";
import { provideFalse, provideNull } from "../util/providers";

const reviewProps = [
  { name: "approve", defaultProvider: provideFalse },
  { name: "issueDate", defaultProvider: provideNull }
];

/**
 * Create and return the canonical representation of an IRB certificate upload.
 *
 * createIrbCertificate :: Object -> IrbCertificateReview
 */
export const createIrbCertificateReview = createFactoryFunc(reviewProps);
