import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import compose from "crocks/helpers/compose";
import isEmpty from "crocks/predicates/isEmpty";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import withStyles from '@material-ui/core/styles/withStyles';
import { userToUserInfoEditorState } from "./userInfoEditorHelpers";
import OrganizationSelect from "../../../common/components/OrganizationSelect";
import SubmitButton from "../../../common/components/SubmitButton";
import AppRequest from "../../../common/models/AppRequest";
import AppRequestState from "../../../common/models/AppRequestState";
import { stringProp } from "../../../common/util/object";
import { getRequestState } from "../../../common/store";
import { resetRequestState, updateUserInfo } from "../../../common/store/actions";

const request = AppRequest.PUT_USER;

const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: 'Updating...',
  [AppRequestState.SUCCESS]: 'User updated.',
  [AppRequestState.FAILURE]: 'Could not update this user.',
};

const formHasChanged = (state, props) => {
  const { orgId, email, enabled } = props.user;
  return state.orgId !== orgId || state.email !== email || state.enabled !== enabled;
};

const styles = theme => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing(2),
    }
  },
});

class UserInfoEditor extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    organizations: PropTypes.array,
  };

  static defaultProps = {
    user: {},
    organizations: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return userToUserInfoEditorState(nextProps, prevState);
  }

  state = {
    id: 0,
    orgId: 0,
    email: '',
    enabled: true,
  };

  componentDidMount() {
    this.props.resetRequestState(request);
  }

  handleOrgChange = ev => this.setState({ orgId: ev.target.value });
  handleEmailChange = ev => this.setState({ email: ev.target.value });
  handleEnabledCheck = ev => this.setState({ enabled: ev.target.checked });

  handleSubmit = ev => {
    ev.preventDefault();
    const { id, orgId, email, enabled } = this.state;
    const result = this.props.updateUserInfo(id, { orgId, email, enabled });
    result
      .then(res => console.log('result', res))
      .catch(err => console.log('err', err));
  };

  render() {
    const { orgId, email, enabled } = this.state;
    const { organizations, requestState, classes } = this.props;

    const disabled = !formHasChanged(this.state, this.props) || isEmpty(email);
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        <TextField
          required
          fullWidth
          value={email}
          label="Email address"
          onChange={this.handleEmailChange}
          InputLabelProps={{ shrink: true }}
        />
        <OrganizationSelect
          style={{ maxWidth: '100%' }}
          value={orgId}
          organizations={organizations}
          onChange={this.handleOrgChange}
        />
        <FormControlLabel
          label="Account enabled"
          control={
            <Checkbox
              value="enabled"
              color="primary"
              checked={enabled}
              onChange={this.handleEnabledCheck}
            />
          }
        />
        <SubmitButton
          label="Update info"
          stateMsg={stateMsg}
          requestState={requestState}
          disabled={disabled}/>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  requestState: getRequestState(state, request)
});

const mapDispatchToProps = { updateUserInfo, resetRequestState };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserInfoEditor);
