import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    boxShadow: 'none',
  },
  summaryRoot: {
    padding: `0 ${theme.spacing()}px`,
    minHeight: '0 !important',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #DDD',
  },
  summaryContent: {
    margin: '2px 0 !important',
  },
  detailsRoot: {
    padding: `0 ${theme.spacing()}px`,
    display: 'block',
    // minHeight: '100px',
    maxHeight: 300,
    overflowY: 'auto',
  },
  detailsContainer: {
    padding: `0 ${theme.spacing(1.5)}px`,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

class VariableExpansionPanel extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    expanded: PropTypes.bool,
    onExpansionChange: PropTypes.func,
  };

  render() {
    const { title, expanded, onExpansionChange, children, classes } = this.props;

    return (
      <ExpansionPanel className={classes.root} expanded={expanded} onChange={onExpansionChange}>
        <ExpansionPanelSummary
          expandIcon={<ArrowDropDownIcon />}
          classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
        >
          <Typography variant="body2">{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.detailsRoot}>
          <div className={classes.detailsContainer}>
            {children}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(VariableExpansionPanel);
