import prop from "crocks/Maybe/prop";
import safe from "crocks/Maybe/safe";
import identity from "crocks/combinators/identity";
import partial from "crocks/helpers/partial";
import curry from "crocks/helpers/curry";
import defaultProps from "crocks/helpers/defaultProps";
import isArray from "crocks/predicates/isArray";
import isDefined from "crocks/predicates/isDefined";
import { safeArray, safeFunction, safeInteger, safeObject, safeString } from "./types";
import { isNotNil } from "./predicates";

const defaultJoin = { key: '', lookup: {} };

const safeLookup = propName => obj =>
  safe(isNotNil, propName)
    .chain(name => prop(name, obj));

const safeProp = safeType => (propName, obj) =>
  safeObject(obj)
    .chain(safeLookup(propName))
    .chain(safeType);

/**
 * Returns a Maybe containing the value of an integer property if propName exists on the
 * passed-in object and is an integer.
 *
 * integerProp :: String -> Object -> M Integer | M Nothing
 */
export const integerProp = curry(safeProp(safeInteger));

/**
 * Returns a Maybe containing the value of a string property if propName exists on the
 * passed-in object and is a string.
 *
 * integerProp :: String -> Object -> M String | M Nothing
 */
export const stringProp = curry(safeProp(safeString));

/**
 * Returns a Maybe containing the value of an array property if propName exists on the
 * passed-in object and is an array.
 *
 * integerProp :: String -> Object -> M Array | M Nothing
 */
export const arrayProp = curry(safeProp(safeArray));

/**
 * Returns a Maybe containing the value of a function property (i.e. a method) if propName
 * exists on the passed-in object and is a function.
 *
 * integerProp :: String -> Object -> M Function | M Nothing
 */
export const functionProp = curry(safeProp(safeFunction));

/**
 * Returns the value of the propName property from the provided object. The default provider func
 * will be called lazily to provide a value to be returned if the propName prop does not exist on
 * the object.
 *
 * safePropFromObj :: Object -> (String, () -> a) -> a
 */
export const safePropFromObj = (obj = {}) => (propName, defaultProvider) =>
  prop(propName, obj).either(defaultProvider, identity);

/**
 * Uses the provided getOrElse func to get a prop from a source obj and set it on the resulting object.
 * The default value provided to getOrElse will be used if the prop doesn't exist on the source object.
 *
 * Note: The source object doesn't appear in the params because it is assumed to be in the scope of the
 * getOrElse function.
 *
 * setPropOnObj :: ((String, a) -> a) -> (Object, String) -> Object
 */
export const setPropOnObj = getOrElse => (obj, prop) => {
  const srcProp = prop.srcName || prop.name;
  obj[prop.name] = getOrElse(srcProp, partial(prop.defaultProvider, obj));
  return obj;
};

const nullsToUndefined = (obj, key) => {
  obj[key] = obj[key] === null ? undefined : obj[key];
  return obj;
};

/**
 * Decorator that wraps a function that takes an options object and sets all null options to
 * undefined so that they will receive a default value when passed to the wrapped function.
 */
export const withoutNullProps = f => opts => f(opts ? Object.keys(opts).reduce(nullsToUndefined, opts) : opts);

/**
 * A reducer that can take an array of objects and build an object whose keys are the values of
 * each object's 'id' prop and whose values are the objects.
 *
 * @see buildLookupById
 */
export const lookupByIdReducer = (lookup, item) => {
  if (isDefined(item.id)) {
    lookup[item.id] = item;
  }
  return lookup;
};

/**
 * Take an array of items each of which have an "id" prop and return an object whose keys are
 * the IDs of the items and whose values are the array's original items.
 */
export const buildLookupById = arr => isArray(arr) ? arr.reduce(lookupByIdReducer, {}) : {};

/**
 * createJoin :: (String, Object) -> Join
 */
export const createJoin = (key, lookup) => defaultProps(defaultJoin, { key, lookup });

/**
 * A joiner is a mapping function (i.e. can be passed to Array.map) that transforms a list of objects
 * into a list of tuples containing the original object and any objects associated with it through a
 * join.
 *
 * createJoiner :: [Join] -> Object -> [Object, ...]
 */
export const createJoiner = joins => src =>
  joins.reduce((acc, j) => {
    acc.push(j.lookup[src[j.key]]);
    return acc;
  }, [src]);

/**
 * A combiner is a mapping function (i.e. can be passed to Array.map) that transforms a list of tuples
 * of associated objects into a new object using a list of property transforms.
 */
export const createCombiner = propTransforms => tuple =>
  propTransforms.reduce((acc, prop) => {
    acc[prop.name] = prop.provider(tuple);
    return acc;
  }, {});