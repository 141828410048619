import React from 'react'
import compose from "crocks/helpers/compose"
import Paper from "@material-ui/core/Paper"
import withStyles from '@material-ui/core/styles/withStyles'
import ContentContainer from "../../common/components/ContentContainer"
import { connect } from "react-redux"
import { fetchDocument, fetchFaqsFromServer, fetchSteeringCommitteeDocument } from "../../common/store/actions"
import { getFaqs, getUserProfile } from "../../common/store"
import FaqSection from "./FaqSection"
import DocsSection from "./DocsSection"
import CommunitySection from "./CommunitySection"
import VideosSection from "./VideosSection"
import UtilitiesSection from "./UtilitiesSection"

const styles = theme => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, 2fr) minmax(400px, 1fr)',
    // gridTemplateRows: '[top] auto auto [bottom]',
    gridGap: theme.spacing(),
  },
  faq: {
    height: '100%',
    borderRight: '1px solid #888',
  },
})

class SupportContent extends React.PureComponent {
  componentDidMount() {
    this.props.fetchFaqsFromServer()
  }

  render() {
    const { faqs, user, fetchDocument, fetchSteeringCommitteeDocument, classes } = this.props
    return (
      <ContentContainer>
        <Paper className={classes.root}>
          <FaqSection className={classes.faq} faqs={faqs} lpaEmail={user.lpa}/>

          <div style={{ paddingTop: 8, paddingBottom: 8, paddingRight: 8 }}>
            <DocsSection onDownload={fetchDocument}/>
            <VideosSection onDownload={fetchDocument}/>
            <CommunitySection
              user={user}
              onDownload={fetchDocument}
              onSteeringDownload={fetchSteeringCommitteeDocument}
            />
            <UtilitiesSection
              user={user}
              onDownload={fetchDocument}
              onSteeringDownload={fetchSteeringCommitteeDocument}
            />
          </div>
        </Paper>
      </ContentContainer>
    )
  }
}

const mapStateToProps = state => ({
  faqs: getFaqs(state),
  user: getUserProfile(state),
})

const mapDispatchToProps = { fetchDocument, fetchSteeringCommitteeDocument, fetchFaqsFromServer }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SupportContent)
