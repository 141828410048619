import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, withStyles } from "@material-ui/core/";
import RequestStatusDisplay from "./RequestStatusDisplay";
import AppRequestState from "../models/AppRequestState";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
});

/**
 * A form submit button that is aware of AppRequestState and uses them along with the
 * state message to display feedback to the user on the state of the form's submission.
 */
const SubmitButton = ({ classes, label, disabled, requestState, stateMsg, style, className }) => {
  const disableBtn = disabled === true || requestState === AppRequestState.IN_FLIGHT;
  return (
    <div className={classNames(classes.root, className)} style={style}>
      <Button type="submit" variant="contained" color="primary" disabled={disableBtn}>{label}</Button>
      <RequestStatusDisplay requestState={requestState} stateMsg={stateMsg}/>
    </div>
  );
};

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  requestState: PropTypes.string,
  stateMsg: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default withStyles(styles)(SubmitButton);