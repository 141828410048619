import constant from "crocks/combinators/constant";
import { provideEmptyObject, provideNow, provideUndefined, provideZero } from "../util/providers";
import AppRequestState from "./AppRequestState";
import { createFactoryFunc } from "./factory";

const cacheEntryProps = [
  { name: 'scenarioId', defaultProvider: provideZero },
  { name: 'state', defaultProvider: constant(AppRequestState.NONE) },
  { name: 'timeStamp', defaultProvider: provideNow },
  { name: 'timeSeries', defaultProvider: provideEmptyObject },
  { name: 'error', defaultProvider: provideUndefined },
];

/**
 * Create a canonical entry for the cache of time series data.
 *
 * Object -> TimeSeriesCacheEntry
 */
export const createTimeSeriesCacheEntry = createFactoryFunc(cacheEntryProps);
