import React from 'react';
import PropTypes from 'prop-types';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import FailureIcon from '@material-ui/icons/AddCircle';
import AppRequestState from "../models/AppRequestState";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *:first-child': {
      marginRight: theme.spacing(),
    }
  },
  busy: {
    width: '1.5em',
    height: '1.5em',
  },
  success: {
    width: '1.5em',
    height: '1.5em',
    color: 'green',
  },
  failure: {
    width: '1.5em',
    height: '1.5em',
    color: '#A00',
    transform: 'rotate(45deg)',
  },
});

const lookupIcon = (requestState, classes) => {
  switch (requestState) {
    case AppRequestState.IN_FLIGHT:
      return <CircularProgress className={classes.busy} variant='indeterminate' color='secondary'/>;
    case AppRequestState.SUCCESS:
      return <SuccessIcon className={classes.success}/>;
    case AppRequestState.FAILURE:
      return <FailureIcon className={classes.failure}/>;
    default:
      return null;
  }
};

const RequestStatusDisplay = ({ classes, requestState, stateMsg }) => (
  <div className={classes.root}>
    {lookupIcon(requestState, classes)}
    <Typography variant='subtitle1' style={{ lineHeight: '2em' }}>{stateMsg}</Typography>
  </div>
);

RequestStatusDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  requestState: PropTypes.string,
  stateMsg: PropTypes.string,
};

export default withStyles(styles)(RequestStatusDisplay);