import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import TimeSeriesValue from "./TimeSeriesValue";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  speed: {
    color: theme.palette.amp ? theme.palette.amp.viewerHighlight : 'white',
  }
});

class SpeedStatus extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    time: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    time: 0,
  };

  render() {
    const { data, time, className, classes } = this.props;

    return (
      <div className={classNames(className, classes.root)}>
        <TimeSeriesValue data={data} time={time}>
          {val => (
            <Typography className={classes.speed} variant="h3">
              {Math.round(val) || 0}
            </Typography>
          )}
        </TimeSeriesValue>
        <Typography className={classes.speed} variant="subtitle1">kph</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SpeedStatus);
