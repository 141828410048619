import React from 'react';
import PropTypes from 'prop-types';
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    padding: theme.spacing(),
  }
});

class ValueDataPopup extends React.Component {
  state = {
    value: this.props.value
  };

  handleChange = ev => {
    this.setState({ value: this.props.validate(ev.target.value) });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    this.props.onChange(this.state.value);
  };

  render() {
    const { value } = this.state;
    const { open, anchorEl, onClose, classes } = this.props;
    return (
      <Popover open={open} anchorEl={anchorEl} onClose={onClose}>
        <form className={classes.root} onSubmit={this.handleSubmit}>
          <Input
            autoFocus
            value={value}
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton type="submit"><CheckIcon /></IconButton>
                <IconButton onClick={onClose}><CloseIcon /></IconButton>
              </InputAdornment>
            }
          />
        </form>
      </Popover>
    )
  }
}

ValueDataPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ValueDataPopup);
