/*
 * As an experiment this component will be written in TypeScript.  If we do decide to move
 * to TS in the future, we need to know that introducing it into a code base won't just
 * break everything or force us into a big-bang scenario of porting the whole project to
 * TS at once.
 */
import React from 'react'
import Button from '@material-ui/core/Button'

interface ToggleButtonProps {
  selected: boolean;
  disabled?: boolean;
  onToggle: (selected: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = props => {
  const color = props.selected ? 'primary' : 'secondary'
  const handleToggle = () => props.onToggle(!props.selected)

  return (
    <Button onClick={handleToggle} variant="contained" color={color} disabled={props.disabled} disableRipple>
      {props.children}
    </Button>
  )
}

export default ToggleButton