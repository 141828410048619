import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import { isNotEmpty } from "../../../../common/util/predicates";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  rootHidden: {
    opacity: 0,
  }
});

class EmptyAnnotationMessage extends React.PureComponent {
  static propTypes = {
    onConfigClick: PropTypes.func,
    selectedVariables: PropTypes.array,
  };

  static defaultProps = {
    selectedVariables: [],
  };

  render() {
    const { selectedVariables, onConfigClick, classes } = this.props;
    const className = isNotEmpty(selectedVariables) ? classes.rootHidden : classes.root;

    return (
      <div className={className}>
        <Typography variant="subtitle1">You have no annotations selected.</Typography>
        <Button variant="contained" onClick={onConfigClick}>Configure Now</Button>
      </div>
    );
  }
}

export default withStyles(styles)(EmptyAnnotationMessage);
