import compose from "crocks/helpers/compose"
import identity from "crocks/combinators/identity"
import { FILTER_SAVED, SET_FILTER_VARS, SET_SCENARIO_PAGE_INDEX, SET_SCENARIO_PAGES } from "./index"
import {
  getExpressionTree,
  getScenarioPageIndex,
  getScenarioPageSize,
  getScenarioSortColumn,
  getScenarioSortDirection
} from "../index"
import { AppRequest, createFilter, createGroupMemento, createScenarioCount, createVariable } from "../../models"
import { writeVariables } from "../../services/localStorage"
import { blobReader, createGetThunk, createPostThunk } from "./utils"
import { setAnalyticsCount, setScenarioCounts, setScenarios } from "./event"
import { buildVariableCountLookup, createAnalyticsCount } from "../../models/scenario"

const filterUnknownTypes = v => v !== undefined

const createVariables = data => data
  .map(createVariable)
  .filter(filterUnknownTypes)

const createFilterConditions = compose(JSON.stringify, createGroupMemento)

export function createFilterBody(state) {
  const tree = getExpressionTree(state)
  const conditions = createFilterConditions(tree.root)
  return createFilter({ conditions })
}

function createDownloadBody(state) {
  return ({ ...createFilterBody(state), variableIds: [] })
}

function createFilterParams(state) {
  const sortField = getScenarioSortColumn(state)
  const sortDir = getScenarioSortDirection(state)
  const page = getScenarioPageIndex(state)
  const size = getScenarioPageSize(state)
  return { sortField, sortDir, page, size }
}

const createFilteredScenariosProcessor = (dispatch, getState, services, filter) => data => {
  dispatch(setScenarios(data.events))

  const counts = createScenarioCount(data.counts)
  const countsWithLookup = { ...counts, variableCounts: buildVariableCountLookup(counts.variableCounts) }
  dispatch(setScenarioCounts(countsWithLookup))

  console.log('filter results', data)
  const analytics = createAnalyticsCount(data.analytics)
  dispatch(setAnalyticsCount(analytics))

  dispatch(setScenarioPages(data.pages))
  dispatch(filterSaved(filter))
}

export const filterSaved = filter => ({ type: FILTER_SAVED, payload: { filter } })
export const setFilterVariables = filterVars => ({ type: SET_FILTER_VARS, payload: { filterVars } })
export const setScenarioPages = pages => ({ type: SET_SCENARIO_PAGES, payload: { pages } })
export const setScenarioPageIndex = index => ({ type: SET_SCENARIO_PAGE_INDEX, payload: { index } })

export const fetchFilterVars = () =>
  createGetThunk({
    request: AppRequest.GET_FILTER_VARIABLES,
    url: "/api/filter/variable",
    responseFnFactory: dispatch => compose(dispatch, setFilterVariables, writeVariables, createVariables)
  })

export const filterImmediate = () =>
  createPostThunk({
    request: AppRequest.POST_FILTER,
    url: '/api/filter/immediate',
    params: createFilterParams,
    body: createFilterBody,
    responseFnFactory: createFilteredScenariosProcessor,
    preprocessor: dispatch => dispatch(setScenarioCounts({}))
  })

export const fetchEventIdsInFilter = () =>
  createPostThunk({
    request: AppRequest.POST_FILTER_EVENT_IDS,
    url: '/api/filter/immediate/eventids',
    params: createFilterParams,
    body: createFilterBody,
    responseFnFactory: () => identity,
  })

export const downloadCasesInFilter = () =>
  createPostThunk({
    request: AppRequest.POST_CASE_DOWNLOAD,
    url: '/api/filter/download',
    body: createDownloadBody,
    responseReader: blobReader,
    responseFnFactory: (dispatch, getState, services) => services.http.downloadFileFromObject
  })