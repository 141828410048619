import safe from "crocks/Maybe/safe";
import safeAfter from "crocks/Maybe/safeAfter";
import isInteger from "crocks/predicates/isInteger";
import isNumber from "crocks/predicates/isNumber";
import isString from "crocks/predicates/isString";
import isArray from "crocks/predicates/isArray";
import isObject from "crocks/predicates/isObject";
import isFunction from "crocks/predicates/isFunction";
import composeK from "crocks/helpers/composeK";
import { isDate, isNotNil } from "./predicates";

/**
 * safeInteger :: a -> M Integer | M Nothing
 */
export const safeInteger = safe(isInteger);

/**
 * safeNumber :: a -> M Number | M Nothing
 */
export const safeNumber = safe(isNumber);

/**
 * safeString :: a -> M String | M Nothing
 */
export const safeString = safe(isString);

/**
 * safeDate :: a -> M Date | M Nothing
 */
export const safeDate = safe(isDate);

/**
 * safeObject :: a -> M Object | M Nothing
 */
export const safeObject = safe(isObject);

/**
 * safeArray :: [a] -> M [a] | M Nothing
 */
export const safeArray = safe(isArray);

/**
 * safeFunction :: a -> M Function | M Nothing
 */
export const safeFunction = safe(isFunction);

/**
 * safeNotNil :: a -> M a | M Nothing
 */
export const safeNotNil = safe(isNotNil);

/**
 * safeNotNil :: a -> M Number | M Nothing
 */
export const safeToNumber = composeK(safeAfter(isNotNil, a => Number(a)), safeNotNil);

/**
 * safeToInteger :: a -> M Integer | M Nothing
 */
export const safeAsInteger = composeK(safeInteger, safeToNumber);