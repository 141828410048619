import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import ApproveIcon from '@material-ui/icons/CheckCircle';
import RejectIcon from '@material-ui/icons/Block';
import { DatePicker } from '@material-ui/pickers';
import { formatDayMonthYear } from "../../../common/util/date";
import { isNull } from "../../../common/util/predicates";
import { createIrbCertificateReview } from "../../../common/models/irbCertificateReview";
import IrbCertificateImage from "./IrbCertificateImage";

const buildUploadString = cert =>
  cert.id === 0 ? '' : `Uploaded on ${formatDayMonthYear(cert.uploadDate)} by ${cert.name} (${cert.email})`;

const styles = theme => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: 'auto 1fr auto',
    gridGap: `${theme.spacing()}px`,
  },
  title: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    borderBottom: '1px solid #DDD',
  },
  certImg: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
  },
  buttonIcon: {
    marginRight: theme.spacing(),
  },
  datePicker: {
    width: '25em',
    justifySelf: 'end',
  },
});

class IrbCertificateForm extends React.PureComponent {
  static propTypes = {
    cert: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  // static getDerivedStateFromProps(nextProps) {
  //   return { date: nextProps.cert.issueDate };
  // }

  state = { issueDate: this.props.cert.issueDate };

  handleDateChange = issueDate => this.setState({ issueDate });

  handleRejectClick = () => this.props.onSubmit(createIrbCertificateReview());

  handleApproveClick = () => {
    const issueDate = this.state.issueDate.valueOf();
    this.props.onSubmit(createIrbCertificateReview({ issueDate, approve: true }));
  };

  render() {
    const { issueDate } = this.state;
    const { classes, cert } = this.props;
    const approveDisabled = isNull(issueDate);
    const showControls = cert.reviewDate === null && cert.id > 0;

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="subtitle1">{buildUploadString(cert)}</Typography>
        <IrbCertificateImage className={classes.certImg} cert={cert} />

        {showControls &&
        <Button variant="contained" onClick={this.handleRejectClick}>
          <RejectIcon className={classes.buttonIcon}/>
          Reject
        </Button>
        }

        {showControls &&
        <DatePicker
          autoOk
          disableFuture
          value={issueDate}
          format="MMM d, yyy"
          className={classes.datePicker}
          emptyLabel="Click here to enter the certificate's issue date"
          onChange={this.handleDateChange}
          leftArrowIcon={<LeftIcon/>}
          rightArrowIcon={<RightIcon/>}
        />
        }

        {showControls &&
        <Button variant="contained" color="primary" disabled={approveDisabled} onClick={this.handleApproveClick}>
          <ApproveIcon className={classes.buttonIcon}/>
          Approve
        </Button>
        }
      </div>
    );
  }
}

export default withStyles(styles)(IrbCertificateForm);
