import React from 'react'
import PropTypes from 'prop-types'
import FilterBarVariable from "./FilterBarVariable"
import { getListVarSelections, getTextSearch } from "../common/filterParams"
import SearchBar from "./SearchBar"
import Button from "@material-ui/core/Button"

class FilterBar extends React.PureComponent {
  static propTypes = {
    variables: PropTypes.array,
    counts: PropTypes.object,
    filterParams: PropTypes.object,
    expandedSet: PropTypes.object,
    onChange: PropTypes.func,
    onExpansionChange: PropTypes.func,
    onExpandAll: PropTypes.func,
    onCollapseAll: PropTypes.func,
    onTextSearch: PropTypes.func,
    visible: PropTypes.bool,
  }

  static defaultProps = {
    variables: [],
    counts: {},
    filterParams: {},
    expandedSet: new Set(),
    visible: false
  }

  render() {
    const {
      variables,
      counts,
      filterParams,
      expandedSet,
      onTextSearch,
      onChange,
      onExpansionChange,
      onExpandAll,
      onCollapseAll,
      visible,
      className
    } = this.props

    if (!visible) {
      return null
    }

    return (
      <div className={className}>
        <SearchBar value={getTextSearch(filterParams)} onSearch={onTextSearch}/>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
          <Button onClick={onExpandAll}>Expand All</Button>
          <Button onClick={onCollapseAll}>Collapse All</Button>
        </div>

        {
          variables.map(v =>
            <FilterBarVariable
              key={v.id}
              variable={v}
              counts={counts[v.id]}
              expanded={expandedSet.has(v.id)}
              onChange={onChange}
              onExpansionChange={onExpansionChange}
              selected={getListVarSelections(filterParams, v.name)}
            />
          )
        }
      </div>
    )
  }
}

export default FilterBar