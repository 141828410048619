import React from 'react'
import PropTypes from 'prop-types'
import isNil from "crocks/predicates/isNil"
import Typography from "@material-ui/core/Typography"
import withStyles from '@material-ui/core/styles/withStyles'
import background from '../../../../common/assets/interior_340.png'
import PassengerIcon from "./PassengerIcon"
import CellularIcon from "./CellularIcon"
import MediaDeviceIcon from "./MediaDeviceIcon"
import prop from "crocks/Maybe/prop"
import HandsOnWheel from "./HandsOnWheel"
import InteriorObjectIcon from "./InteriorObjectIcon"

const NO_DATA = 'No Data'
const EYE_GLANCE_KEY = 'eyeGlance'
const HANDS_ON_WHEEL_KEY = 'handsOnWheel'

const howMap = {
  1: 'Off, Unkn',
  2: 'On, Unkn',
  3: 'On, On',
  4: '--, --',
  5: 'Off, Off',
  6: 'On, Off',
  7: '?, ?',
}

const eyeGlanceMap = {
  1: "Forward",
  2: "Left Window/Mirror",
  3: "Left Windshield",
  4: "Rearview Mirror",
  5: "Right Windshield",
  6: "Right Window/Mirror",
  7: "Instrument Cluster",
  8: "Center Stack",
  9: "Over-the-Shoulder (left or right)",
  10: "Cell Phone",
  11: "Portable Media Device",
  12: "Interior Object",
  13: "Passenger",
  14: "Eyes Closed",
  15: "No Driver",
  16: "No Eyes Visible - Eyes are Off-Road",
  17: "No Eyes Visible - Glance Location Unknown",
  18: "No Video",
  19: "Other",
}

const isOverTheShoulder = eyeGlance => eyeGlance === 9

const transforms = {
  "Forward": { transform: 'translate(70px, 40px) skew(26deg) scale(7, 2)', opacity: 0.5 },
  "Rearview Mirror": { transform: 'translate(135px, 20px) skew(0deg) scale(7, 3)', opacity: 0.5 },
  "Left Windshield": { transform: 'translate(55px, 20px) skew(26deg) scale(8, 5)', opacity: 0.5 },
  "Right Windshield": { transform: 'translate(210px, 20px) skew(-26deg) scale(8, 5)', opacity: 0.5 },
  "Left Window/Mirror": { transform: 'translate(0px, 35px) skew(10deg) scale(5, 6)', opacity: 0.5 },
  "Right Window/Mirror": { transform: 'translate(290px, 35px) skew(-10deg) scale(5, 6)', opacity: 0.5 },
  "Over-the-Shoulder (left or right)": { transform: 'translate(318px, 0px) skew(0deg) scale(1.5, 22)', opacity: 0.5 },
  "Instrument Cluster": { transform: 'translate(90px, 80px) skew(0deg) scale(5, 3)', opacity: 0.5 },
  "Center Stack": { transform: 'translate(150px, 100px) skew(0deg) scale(4.5, 5)', opacity: 0.5 },
  "Portable Media Device": { transform: 'translate(267px, 110px) skew(0deg) scale(6, 9)', opacity: 0.8 },
  "Cell Phone": { transform: 'translate(267px, 110px) skew(0deg) scale(6, 9)', opacity: 0.8 },
  "Passenger": { transform: 'translate(230px, 110px) skew(0deg) scale(9.5, 9)', opacity: 0.8 },
  "Interior Object": { transform: 'translate(205px, 110px) skew(0deg) scale(12, 9)', opacity: 0.8 },
}

const secondaryOverTheShoulderTransform = 'translate(0px, 0px) skew(0deg) scale(1.5, 22)'

const iconLookup = {
  "Passenger": <PassengerIcon/>,
  "Cell Phone": <CellularIcon/>,
  "Portable Media Device": <MediaDeviceIcon/>,
  "Interior Object": <InteriorObjectIcon/>,
}

const secondsToIndex = secs => Math.round(secs * 10)

const dimBackgroundStyle = { opacity: 0.25 }
const hiddenStyle = { transform: 'translate(150px, 80px) skew(0deg) scale(0, 0)' }

const overlayShownStyle = { opacity: 1.0 }
const overlayHiddenStyle = { opacity: 0.0 }

const styles = theme => ({
  root: {
    position: 'relative',
  },
  background: {
    width: '340px',
    height: '211px',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    transition: 'opacity 500ms',
  },
  title: {
    color: theme.palette.amp ? theme.palette.amp.viewerHighlight : 'white',
    textAlign: 'center',
    width: '340px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '80%',
  },
  target: {
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: theme.palette.amp ? theme.palette.amp.viewerHighlight : 'white',
    opacity: 0.5,
    transformOrigin: 'left top',
    transition: 'transform 500ms, opacity 500ms',
  },
  overlay: {
    position: 'absolute',
    top: 140,
    right: 20,
    transition: 'opacity 500ms 250ms',
  },
  handsOnWheel: {
    position: 'absolute',
    top: 190,
    left: 30,
  }
})

class EyeGlance extends React.Component {
  static propTypes = {
    timeSeries: PropTypes.object,
    currentTime: PropTypes.number,
  }

  static defaultProps = {
    timeSeries: {},
    currentTime: 0,
  }

  state = {
    prevGlance: 0,
    currentGlance: 0,
    prevHow: 0,
    currentHow: 0,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { timeSeries, currentTime } = nextProps

    const index = secondsToIndex(currentTime)
    const howData = prop(HANDS_ON_WHEEL_KEY, timeSeries).option([])
    const eyeGlanceData = prop(EYE_GLANCE_KEY, timeSeries).option([]);

    const currentHow = isNil(howData[index]) ? 0 : howData[index]
    const currentGlance = isNil(eyeGlanceData[index]) ? 0 : eyeGlanceData[index]

    return {
      currentHow,
      currentGlance,
      prevHow: prevState.currentHow,
      prevGlance: prevState.currentGlance,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.currentGlance !== nextState.prevGlance || nextState.currentHow !== nextState.prevHow
  }

  render() {
    const { classes } = this.props
    const { currentGlance, currentHow } = this.state

    const eyeGlance = eyeGlanceMap[currentGlance] || NO_DATA
    const targetStyle = transforms[eyeGlance] || hiddenStyle

    const secondaryTargetStyle = {
      ...targetStyle,
      opacity: isOverTheShoulder(currentGlance) ? 0.5 : 0.0,
      transform: isOverTheShoulder(currentGlance) ? secondaryOverTheShoulderTransform : targetStyle.transform,
    };

    const icon = iconLookup[eyeGlance] || null
    const overlayStyle = icon === null ? overlayHiddenStyle : overlayShownStyle
    const backgroundStyle = targetStyle === hiddenStyle ? dimBackgroundStyle : undefined

    return (
      <div className={classes.root}>
        <Typography className={classes.title}>{eyeGlance}</Typography>

        <div className={classes.background} style={backgroundStyle}>
          <div className={classes.target} style={targetStyle}/>
          <div className={classes.target} style={secondaryTargetStyle}/>
        </div>

        <div className={classes.overlay} style={overlayStyle}>
          {icon}
        </div>

        <HandsOnWheel className={classes.handsOnWheel} title="Hands on Wheel" value={howMap[currentHow]}/>
      </div>
    )
  }
}

export default withStyles(styles)(EyeGlance)

