import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { parse } from "query-string";

import IrbUploadForm from "./IrbUplaodForm";
import ProfileCreationForm from "./ProfileCreationForm";
import If from "../common/components/logic/If";
import When from "../common/components/logic/When";
import AmpBannerPage from "../common/components/AmpBannerPage";
import ProfileUIContainer from "../common/components/TitledFormContainer";
import AppStatus from "../common/models/AppStatus";
import { getAppStatus } from "../common/store";
import { doLogout } from "../common/store/actions";

const welcomeToAMP = 'Welcome to AMP';
const noLongerValid = 'Sorry, this registration link is no longer valid.';

const isUrlValid = queryParams => Boolean(queryParams.username && queryParams.token);

/**
 * NOTE: This component requires that the URL contains the query params 'username' and 'token'.
 */
class RegisterUserPage extends React.Component{
  constructor(props) {
    super(props);
    this.queryParams = parse(props.location.search);
    this.isValid = isUrlValid(this.queryParams);
  }

  // componentDidMount() {
  //   this.props.doLogout();
  // }

  formChoices = {
    'upload': () => <IrbUploadForm />,
    'profile': () => <ProfileCreationForm {...this.queryParams} />,
  };

  render() {
    const { loggedIn } = this.props;
    const { username } = this.queryParams;

    const title = this.isValid ? welcomeToAMP : noLongerValid;
    const email = this.isValid ? username : '';
    const choice = loggedIn ? 'upload' : 'profile';

    return  (
      <AmpBannerPage>
        <ProfileUIContainer title={title} email={email}>
          <If expression={this.isValid}>
            <When choice={choice} options={this.formChoices} />
          </If>
        </ProfileUIContainer>
      </AmpBannerPage>
    );
  }
}

RegisterUserPage.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ loggedIn: getAppStatus(state) === AppStatus.APP_LOGGED_IN });

export default connect(mapStateToProps, { doLogout })(RegisterUserPage);
