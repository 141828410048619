import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from "crocks/predicates/isFunction";
import { withStyles } from "@material-ui/core";
import ReactPlayer from "react-player";
import ErrorOverlay from "./ErrorOverlay";
import LoadingOverlay from "./LoadingOverlay";
import { isUndefined } from "../../../../common/util/predicates";

const didStartLoading = (state, prevState) => state.loading === true && prevState.loading === false;

const createSeekFn = player => seconds => {
  player.getInternalPlayer().currentTime = seconds;
  return seconds;
};

const styles = {
  root: {
    backgroundColor: '#222',
    position: 'relative',
    paddingRight: "56.25%",
    height: "100%",
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  player: {
    backgroundColor: '#222',
    position: 'absolute',
    top: 0,
    left: 0,
  },
};

class VideoPlayer extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    playing: PropTypes.bool,
    fullScreen: PropTypes.bool,
    onLoaded: PropTypes.func,
    onProgress: PropTypes.func,
    onDuration: PropTypes.func,
    onPlaybackToggle: PropTypes.func,

  };

  static defaultProps = {
    url: '',
    playing: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.url !== prevState.url
      ? { loading: true, url: nextProps.url, error: false }
      : null;
  }

  state = {
    url: undefined,
    error: false,
    loading: false,
    controlsVisible: false,
    seekTime: -1,
  };

  componentDidUpdate(prevProps, prevState) {
    if (didStartLoading(this.state, prevState)) {
      this.props.onLoaded(undefined);
    }
  }

  /**
   * Probably a bug in ReactPlayer, but video duration can change during
   * playback without us getting notified.
   */
  checkDurationChange() {
    if (isUndefined(this.player)) {
      return;
    }

    const duration = this.player.getDuration();
    if (duration !== this.duration) {
      this.handleDuration(duration);
    }
  }

  handleReady = () => {
    if (this.state.loading) {
      this.props.onLoaded(createSeekFn(this.player));
    }
    this.setState({ error: false, loading: false });
  };

  handleProgress = progress => {
    this.checkDurationChange();
    this.props.onProgress(progress.playedSeconds);
  };

  handleDuration = duration => {
    this.duration = duration;
    this.props.onDuration(duration);
  };

  handleEnded = () => this.props.onPlaybackToggle();
  handleError = () => this.setState({ error: true, loading: false });

  handleMouseEnter = () => this.setState({ controlsVisible: !this.state.error });
  handleMouseLeave = () => this.setState({ controlsVisible: false });

  swallowEvent = ev => ev.preventDefault();

  playerRef = player => this.player = player;
  containerRef = container => this.container = container;

  render() {
    const { error, loading, controlsVisible } = this.state;
    const { url, playing, onPlaybackToggle, className, classes, children } = this.props;

    return (
      <div
        ref={this.containerRef}
        className={classNames(className, classes.root)}
        onClick={onPlaybackToggle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onContextMenu={this.swallowEvent}
      >
        <ReactPlayer
          width="100%"
          height="100%"
          ref={this.playerRef}
          className={classes.player}
          url={url}
          progressInterval={100}
          playing={playing}
          onReady={this.handleReady}
          onEnded={this.handleEnded}
          onError={this.handleError}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />

        { isFunction(children) ? children(controlsVisible) : null }

        <ErrorOverlay show={error} />
        <LoadingOverlay show={loading} />
      </div>
    );
  }
}

export default withStyles(styles)(VideoPlayer);
