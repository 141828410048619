import React from 'react'
import PropTypes from 'prop-types'
import ListSelectionPopup from "./ListSelectionPopup"
import { setListSelections } from "../../../../common/models"
import { partial } from "../../../../common/util/func"
import { listOps, setListOperation } from "../../../../common/models/expression"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { arraysAreEqual } from "../../../../common/util/array"
import Button from "@material-ui/core/Button"
import withStyles from "@material-ui/core/styles/withStyles"

function nameLookupBuilder(obj, ch) {
  obj[ch.id] = ch.choice
  return obj
}

function buildSelectedNameString(expression) {
  const nameLookup = expression.variable.choices.reduce(nameLookupBuilder, {})
  return expression.selected
    .map(id => nameLookup[id])
    .join(", ")
}

function buildSelectedString(expression) {
  const { selected, variable } = expression
  return selected.length === variable.choices.length
    ? `${expression.op === 'cn' ? 'ANY' : 'ALL'} VALUES`
    : buildSelectedNameString(expression)
}

const getListOpDescription = op => op !== 'nc' ? 'includes' : 'excludes'

const createOpsMenuItems = onClick =>
  listOps.map(op => (
    <MenuItem key={op} button={false} onClick={() => onClick(op)}>{getListOpDescription(op)}</MenuItem>)
  )

const styles = theme => ({
  expControls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  narrow: {
    padding: theme.spacing(),
    minWidth: theme.spacing(4),
  },
  selected: {
    maxWidth: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

class ListExpression extends React.Component {
  state = {
    popupOpen: false,
    opMenuOpen: false,
    popupAnchor: undefined,
  }

  handleChoicePopupOpen = e => this.setState({ popupOpen: true, popupAnchor: e.target })
  handleChoicePopupClose = () => this.setState({ popupOpen: false, popupAnchor: null })

  handleChoiceChange = selected => {
    this.handleChoicePopupClose()
    if (!arraysAreEqual(this.props.expression.selected.sort(), selected.sort())) {
      this.props.onApply(partial(setListSelections, selected), this.props.expression)
    }
  }

  handleOpMenuOpen = e => this.setState({ opMenuOpen: true, popupAnchor: e.target })
  handleOpMenuClose = () => this.setState({ opMenuOpen: false, popupAnchor: null })

  handleOpChange = op => {
    this.handleOpMenuClose()
    this.props.onApply(partial(setListOperation, op), this.props.expression)
  }

  render() {
    const { popupOpen, opMenuOpen, popupAnchor } = this.state
    const { expression, onVariableClick, classes } = this.props
    const selectedStr = buildSelectedString(expression)

    return (
      <div>
        <div className={classes.expControls}>
          <Button className={classes.narrow} onClick={onVariableClick}>{expression.variable.name}</Button>

          <Button style={{ textTransform: "none" }} className={classes.narrow} onClick={this.handleOpMenuOpen}>
            {getListOpDescription(expression.op)}
          </Button>

          <Button className={classes.narrow} onClick={this.handleChoicePopupOpen}>
            <span className={classes.selected}>{selectedStr}</span>
          </Button>
        </div>

        <Menu open={opMenuOpen} anchorEl={popupAnchor} onClose={this.handleOpMenuClose}>
          {createOpsMenuItems(this.handleOpChange)}
        </Menu>

        <ListSelectionPopup
          key={expression.uuid}
          open={popupOpen}
          anchorEl={popupAnchor}
          expression={expression}
          onChange={this.handleChoiceChange}
          onOpChange={this.handleOpChange}
          onClose={this.handleChoicePopupClose}
        />
      </div>
    )
  }
}

ListExpression.propTypes = {
  expression: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVariableClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(ListExpression)
