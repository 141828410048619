/**
 * The requests whose states are tracked and potentially displayed to the user.
 *
 * @type {Object.<{
 *    POST_LOGIN: string,
 *    POST_PASSWORD_RESET: string,
 *    GET_PROFILE: string,
 *    PUT_PROFILE: string,
 *    GET_FORGOT_PASSWORD: string,
 *    PUT_PROFILE_PASSWORD: string,
 *    POST_IRB_CERTIFICATE: string,
 *    POST_REGISTRATION: string,
 *    GET_USERS: string,
 *    POST_USER_INVITE: string,
 *    POST_USER_EMAIL: string,
 *    PUT_ROLE: string,
 *    DELETE_ROLE: string,
 *    PUT_USER_AMEND_TO_IRB: string,
 *    GET_ORGANIZATIONS: string,
 *    POST_ORGANIZATION: string,
 *    PUT_ORG_INFO: string,
 *    GET_ORG_INFO: string,
 *    GET_MEMBERSHIPS: string,
 *    GET_LOCAL_PORTAL_ADMINS: string,
 *    GET_FILTERS: string,
 *    POST_FILTER: string,
 *    GET_FILTER_VARIABLES: string,
 *    GET_EVENTS: string,
 *    GET_TIME_SERIES_VARIABLES: string,
 *    GET_FILTER_VARIABLES: string,
 *    POST_CASE_DOWNLOAD: string,
 *    GET_EVENT_COUNTS: string,
 *    GET_TIME_SERIES: string,
 *    GET_IRB_CERTIFICATES: string,
 *    GET_IRB_CERTIFICATE: string,
 *    POST_IRB_CERTIFICATE_REVIEW: string,
 *    GET_DOCUMENT: string,
 *    GET_NEWS: string,
 *    PUT_POST_NEWS: string,
 *    DELETE_NEWS: string
 *    GET_FAQ: string,
 *    PUT_POST_FAQ: string,
 *    DELETE_FAQ: string,
 *    GET_ANALYTICS_APPS: string
 * }>}
 */
const AppRequest = Object.freeze({
  POST_LOGIN: 'POST_LOGIN',
  POST_PASSWORD_RESET: 'POST_PASSWORD_RESET',
  GET_PROFILE: 'GET_PROFILE',
  PUT_PROFILE: 'PUT_PROFILE',
  GET_FORGOT_PASSWORD: 'GET_FORGOT_PASSWORD',
  PUT_PROFILE_PASSWORD: 'PUT_PROFILE_PASSWORD',
  POST_IRB_CERTIFICATE: 'POST_IRB_CERTIFICATE',
  POST_REGISTRATION: 'POST_REGISTRATION',
  GET_USERS: 'GET_USERS',
  PUT_USER: 'PUT_USER',
  POST_USER_INVITE: 'POST_USER_INVITE',
  POST_USER_EMAIL: 'POST_USER_EMAIL',
  GET_EMAIL_COUNTS: 'GET_EMAIL_COUNTS',
  PUT_ROLE: 'PUT_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  PUT_USER_AMEND_TO_IRB: 'PUT_USER_AMEND_TO_IRB',
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  POST_ORGANIZATION: 'POST_ORGANIZATION',
  PUT_ORGANIZATION: 'PUT_ORGANIZATION',
  GET_ORG_INFO: 'GET_ORG_INFO',
  PUT_ORG_INFO: 'PUT_ORG_INFO',
  GET_MEMBERSHIPS: 'GET_MEMBERSHIPS',
  GET_LOCAL_PORTAL_ADMINS: 'GET_LOCAL_PORTAL_ADMINS',
  GET_FILTERS: 'GET_FILTERS',
  POST_FILTER: 'POST_FILTER',
  POST_FILTER_EVENT_IDS: 'POST_FILTER_EVENT_IDS',
  GET_EVENT: 'GET_EVENT',
  GET_EVENTS: 'GET_EVENTS',
  GET_TIME_SERIES_VARIABLES: 'GET_TIME_SERIES_VARIABLES',
  GET_FILTER_VARIABLES: 'GET_FILTER_VARIABLES',
  POST_CASE_DOWNLOAD: 'POST_CASE_DOWNLOAD',
  GET_EVENT_COUNTS: 'GET_EVENT_COUNTS',
  GET_TIME_SERIES: 'GET_TIME_SERIES',
  GET_IRB_CERTIFICATES: 'GET_IRB_CERTIFICATES',
  GET_IRB_CERTIFICATE: 'GET_IRB_CERTIFICATE',
  POST_IRB_CERTIFICATE_REVIEW: 'POST_IRB_CERTIFICATE_REVIEW',
  GET_DOCUMENT: 'GET_DOCUMENT',
  GET_NEWS: 'GET_NEWS',
  PUT_POST_NEWS: 'PUT_POST_NEWS',
  DELETE_NEWS: 'DELETE_NEWS',
  GET_FAQS: 'GET_FAQS',
  PUT_POST_FAQ: 'PUT_POST_FAQ',
  DELETE_FAQ: 'DELETE_FAQ',
  GET_TAGS: 'GET_TAGS',
  POST_TAG: 'POST_TAG',
  PUT_TAG: 'PUT_TAG',
  DELETE_TAG: 'DELETE_TAG',
  PUT_SHARED_TAGS: 'PUT_SHARED_TAGS',
  DELETE_SHARED_TAGS: 'DELETE_SHARED_TAGS',
  PUT_TAG_ASSIGNMENT: 'PUT_TAG_ASSIGNMENT',
  DELETE_TAG_ASSIGNMENT: 'DELETE_TAG_ASSIGNMENT',
  GET_ANALYTICS_APPS: 'GET_ANALYTICS_APPS',
  PUT_ANALYTICS_TAG_EVENT_IDS: 'PUT_ANALYTICS_TAG_EVENT_IDS',
  DELETE_ANALYTICS_TAG_EVENT_IDS: 'DELETE_ANALYTICS_TAG_EVENT_IDS',
  GET_FUNCTIONAL_SCENARIO_APPS: 'GET_FUNCTIONAL_SCENARIO_APPS',
});

export default AppRequest;