import React from 'react';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import ReportsIcon from '@material-ui/icons/Description';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(),
  },
});

class ReportHeader extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ReportsIcon color="primary" className={classes.icon}/>
        <Typography color="primary" variant="h4">Reports</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ReportHeader);
