import Case from 'case';
import pluralize from 'pluralize';
import compose from "crocks/helpers/compose";
import map from "crocks/pointfree/map";
import option from "crocks/pointfree/option";
import safe from "crocks/Maybe/safe";
import or from "crocks/logic/or";
import isNil from "crocks/predicates/isNil";
import isString from "crocks/predicates/isString";
import isEmpty from "crocks/predicates/isEmpty";
import curry from "crocks/helpers/curry";
import { isNotEmpty, isNotNil, isNotString } from "./predicates";
import { safeString } from "./types";

export const toKebab = (value) => Case.kebab(value);
export const toTitle = (value) => Case.title(value);
export const toCamel = (value) => Case.camel(value);

/**
 * Returns false iff Object is a String and is not empty. In all other cases (i.e. Object is not
 * a string, is undefined or null, or is an empty string) it returns true.
 *
 * isEmptyString :: Object -> Boolean
 */
export const isEmptyString = or(or(isNil, isNotString), isEmpty);

export const stringCompare = (a, b) => a === b ? 0 : (a > b ? 1 : -1);

/**
 * Run a reducer on a string.
 *
 * @param reducer Func that will be called for each character in the string
 * @param init The initial value for the accumulator
 * @param str The string.
 * @returns The final value of the accumulator
 */
function reduce(reducer, init, str) {
  if (isEmptyString(str)) {
    return init;
  }

  let result = init;
  for (let i = 0; i < str.length; ++i) {
    result = reducer(result, str[i]);
  }
  return result;
}

export const stringReducer = curry(reduce);

/**
 * A reducer for stringReducer that counts occurrences of a character
 * @param char The char we are counting
 * @returns {function(*, *): *}
 */
export const countCharReducer = char => (sum, ch) => ch === char ? sum + 1 : sum;

/**
 * Appends b to a.
 *
 * stringAppender :: String -> String -> String
 */
export const stringAppender = b => a => a.concat(b);

export const pluralOf = compose(option(''), map(pluralize.plural), safe(isString));
export const singularOf = compose(option(''), map(pluralize.singular), safe(isString));

/**
 * stringSplitter :: Char -> String -> [String]
 */
export const stringSplitter = (char = ' ') =>
  compose(
    option([]),
    map(str => str.split(char).filter(isNotEmpty)),
    safe(isNotEmpty)
  );

/**
 * stringMatcher :: RegEx -> String -> Boolean
 */
export const stringMatches = curry(
  (regex, str) =>
    safeString(str)
      .chain(str => safe(isNotNil, regex).map(regex => str.match(regex) !== null))
      .option(false)
);