import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from "@material-ui/core/styles/makeStyles";

const privateTagBackgroundColor = '#2282c2';
const sharedTagBackgroundColor = '#22a262';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    minWidth: 30,
    height: '1em',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '1em',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    '&:before': {
      position: 'absolute',
      content: '""',
      display: 'block',
      top: 0,
      left: '-0.73em',
      width: 0,
      height: 0,
      borderTop: '0.5em solid transparent',
      borderBottom: '0.5em solid transparent',
      borderLeft: '0.5em solid transparent',
    },
  },
  private: {
    color: '#FFF',
    backgroundColor: privateTagBackgroundColor,
    '&:before': {
      borderRight: `0.25em solid ${privateTagBackgroundColor}`,
    }
  },
  shared: {
    color: '#FFF',
    backgroundColor: sharedTagBackgroundColor,
    '&:before': {
      borderRight: `0.25em solid ${sharedTagBackgroundColor}`,
    }
  },
  unassigned: {
    opacity: 0.4,
  },
  outlined: {
    color: '#222',
    backgroundColor: '#FFF',
    border: '1px solid #2282c2',
    '&:before': {
      borderRight: '0.25em solid #2282c2',
    }
  },
});

export default function TagContainer({ children, variant, assigned, className, onClick }) {
  const classes = useStyles();
  const tagClasses = classNames(
    classes.root,
    classes[variant],
    assigned ? null : classes.unassigned,
    className
  );

  return (
    <div className={tagClasses} onClick={onClick} data-testid="tag-container">
      {children}
    </div>
  );
}

TagContainer.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  assigned: PropTypes.bool,
  onClick: PropTypes.func,
};

TagContainer.defaultProps = {
  variant: 'private',
  assigned: false,
};

// export default withStyles(styles)(TagContainer);
