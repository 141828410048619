import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from "crocks/predicates/isEmpty";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import Faq from "../../../common/components/Faq";
import { createFaq } from "../../../common/models/faq";

const isFaqInvalid = faq => isEmpty(faq.question) || isEmpty(faq.answer);

const styles = theme => ({
  root: {
    display: 'grid',
    gridRowGap: '8px',
  },
  faqInfo: {
    marginTop: theme.spacing(2),
  },
});

class FaqForm extends React.PureComponent {
  static propTypes = {
    faq: PropTypes.object,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    faq: createFaq(),
  };

  state = { faq: this.props.faq };

  handleQuestionChange = ev => this.setState({ faq: { ...this.state.faq, question: ev.target.value } });
  handleAnswerChange = ev => this.setState({ faq: { ...this.state.faq, answer: ev.target.value } });
  handleSortOrderChange = ev => this.setState({ faq: { ...this.state.faq, sortOrder: ev.target.value } });

  handleSubmit = () => this.props.onSubmit(this.state.faq);

  render() {
    const { faq } = this.state;
    const { className, classes } = this.props;
    const submitDisabled = isFaqInvalid(faq);

    return (
      <div className={classNames(classes.root, className)}>
        <Typography variant="subtitle1" color="primary">Preview</Typography>
        <Faq {...faq} />

        <Typography className={classes.faqInfo} variant="subtitle1" color="primary">FAQ Information</Typography>

        <TextField
          fullWidth
          required
          label="Question"
          value={faq.question}
          onChange={this.handleQuestionChange}
        />

        <TextField
          fullWidth
          multiline
          label="Answer"
          value={faq.answer}
          onChange={this.handleAnswerChange}
        />

        <TextField
          required
          type="number"
          label="Sort Order"
          value={faq.sortOrder}
          onChange={this.handleSortOrderChange}
        />
        <Typography variant="caption">
          FAQs are displayed in ascending sort order on the support page.
        </Typography>

        <div>
          <Button color="primary" variant="contained" disabled={submitDisabled} onClick={this.handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FaqForm);
