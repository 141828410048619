import React from 'react';
import PropTypes from 'prop-types';
import compose from "crocks/helpers/compose";
import isEmpty from "crocks/predicates/isEmpty";
import identity from "crocks/combinators/identity";
import Chip from "@material-ui/core/Chip/Chip";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from '@material-ui/core/styles/withStyles';

const removeChoice = (id, ids) => ids.filter(i => i !== id);
const arrayToSet = arr => new Set(arr);
const createChoiceSet = compose(arrayToSet, removeChoice);

const chipMapper = (className, onDelete) => c =>
  <Chip key={c.id} label={c.choice} title={`Remove ${c.choice}`} onDelete={onDelete(c)} className={className}/>;

const createQuickFilterChips = (choices, className, onDelete) =>
  <div>{choices.map(chipMapper(className, onDelete))}</div>;

const styles = {
  root: {
    marginRight: '24px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    // gridTemplateRows: '1.1em 2em',
    gridRowGap: '8px',
    alignItems: 'center',
    justifyItems: 'start',
    border: '#ddd 1px solid',
    borderRadius: 6,
    padding: 8,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  chips: {
    gridColumn: ' 1 / -1',
    gridRow: '2',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginLeft: 8,
  },
  chip: {
    marginRight: 4,
    marginBottom: 4,
  },
  clearButton: {
    backgroundColor: 'transparent',
    margin: '0 8px',
    width: 24,
    '& > span': {
      padding: 0,
    },
    '& > svg': {
      margin: 0,
    },
  },
  noSelections: {
    marginLeft: 8,
    fontStyle: 'italic',
    color: '#AAA'
  },
};

class QuickFilterChips extends React.PureComponent {
  static propTypes = {
    choices: PropTypes.array,
    visible: PropTypes.bool,
    onQuickFilterClear: PropTypes.func,
    onQuickFilterChange: PropTypes.func,
  };

  static defaultProps = {
    choices: [],
    onQuickFilterChange: identity,
  };

  handleDelete = c => () =>
    this.props.onQuickFilterChange(c.expression.variable.name, createChoiceSet(c.id, c.expression.selected));


  render() {
    const { choices, onQuickFilterClear, visible, classes } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.label}>
          <Typography>Quick Filters</Typography>

          {
            isEmpty(choices)
              ? <Typography className={classes.noSelections}>(No Selections)</Typography>
              : <Chip className={classes.clearButton} title="Clear Quick Filter" onDelete={onQuickFilterClear}/>
          }
        </div>

        <div className={classes.chips}>
          {createQuickFilterChips(choices, classes.chip, this.handleDelete)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(QuickFilterChips);
