import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore from "./common/store";
import services from "./common/services";

ReactDOM.render(
  <App store={configureStore(services)}/>,
  document.getElementById('root')
);

// Taking out the service worker for now until we figure out caching issues.
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
