import React from 'react';
import PropTypes from 'prop-types';
import ifElse from "crocks/logic/ifElse";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import { buildPageNumberList } from "./pagingHelpers";

const isPageNum = a => a >= 0;

const pageButton = ({ index, classes, onGoToPage }) => num => (
  <Typography
    key={num}
    variant="body2"
    color="primary"
    component="span"
    onClick={() => onGoToPage(num)}
    className={index === num ? classes.currentPage : classes.pageButton}
  >
    {num}
  </Typography>
);

const nextPageButton = ({ index, max, classes, onGoToPage }) => (
  index < max &&
  <Typography
    key="next"
    variant="body2"
    color="primary"
    component="span"
    className={classes.pageButton}
    onClick={() => onGoToPage(index + 1)}
  >
    Next Page
  </Typography>
);

const prevPageButton = ({ index, classes, onGoToPage }) => (
  index > 1 &&
  <Typography
    key="prev"
    variant="body2"
    color="primary"
    component="span"
    className={classes.pageButton}
    onClick={() => onGoToPage(index - 1)}
  >
    Previous Page
  </Typography>
);

const pageEllipsis = num => <span key={num}>&hellip;</span>;

const pageNumberMapper = props => ifElse(isPageNum, pageButton(props), pageEllipsis);

const buildPagingControls = (props) =>
  buildPageNumberList(props.index, props.max)
    .map(pageNumberMapper(props));

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2em',
    '& > *': {
      margin: '0 4px',
    }
  },
  pageButton: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  currentPage: {
    color: '#CC8888',
  },
};

class PagingControls extends React.PureComponent {
  static propTypes = {
    max: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onGoToPage: PropTypes.func.isRequired,
  };

  render() {
    const { max, classes } = this.props;

    if (max < 2) {
      return null;
    }

    return (
      <div className={classes.root}>
        {prevPageButton(this.props)}
        {buildPagingControls(this.props)}
        {nextPageButton(this.props)}
      </div>
    );
  }
}

export default withStyles(styles)(PagingControls);
