import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import TimeSeriesState from "./TimeSeriesState";

const styles = theme => ({
  active: {
    fontSize: '105%',
    fontWeight: '500',
    color: theme.palette.amp ? theme.palette.amp.viewerHighlight : 'white',
  },
  inactive: {
    fontSize: '105%',
    color: theme.palette.amp ? theme.palette.amp.viewerDim : '#888',
  },
});

class TextStatus extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    time: PropTypes.number,
    children: PropTypes.string,
    className: PropTypes.string,
    activeValues: PropTypes.array,
  };

  static defaultProps = {
    data: [],
    time: 0,
    children: 'STATUS',
    activeValues: [1],
  };

  render() {
    const { data, time, activeValues, children, classes } = this.props;

    return (
      <TimeSeriesState data={data} time={time} activeValues={activeValues}>
        {active =>
          <Typography className={active ? classes.active : classes.inactive}>{children}</Typography>
        }
      </TimeSeriesState>
    );
  }
}

export default withStyles(styles)(TextStatus);
