import React from 'react';
import PropTypes from 'prop-types';
import isNil from "crocks/predicates/isNil";
import CircleIndicator from "./CircleIndicator";

const stringValue = value => String(isNil(value) ? 'NA' : value);

const SpeedIndicator = ({ size, value }) => (
  <CircleIndicator
    size={size}
    fill="#F8F8F8"
    stroke="#979797"
    cornerRadius="6px"
    value={stringValue(value)}
    tooltip={`Reference speed: ${value} KPH`}
  />
);

SpeedIndicator.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number,
};

SpeedIndicator.defaultProps = {
  size: 36,
};

export default SpeedIndicator;
