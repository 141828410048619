import { createFactoryFunc } from "./factory";
import constant from "crocks/combinators/constant";
import { provideEmptyString, provideZero, provideTrue } from "../util/providers";

const organizationProps = [
  { name: 'id', defaultProvider: provideZero },
  { name: 'name', defaultProvider: provideEmptyString },
  { name: 'membershipId', defaultProvider: constant(4) },
  { name: 'enabled', defaultProvider: provideTrue }
];

/**
 * Create a canonical Organization object.
 *
 * createOrganization :: Object -> Organization
 */
export const createOrganization = createFactoryFunc(organizationProps);
