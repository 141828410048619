import prop from "crocks/Maybe/prop";
import pick from "crocks/helpers/pick";
import ifElse from "crocks/logic/ifElse";
import constant from "crocks/combinators/constant";

const userStateProps = ['id', 'email', 'orgId', 'enabled'];
const userHasChanged = id => user => user.id !== id;

export const userToUserInfoEditorState = (props, state) =>
  prop('user', props)
    .chain(user =>
      prop('id', state)
        .map(id => ifElse(userHasChanged(id), pick(userStateProps), constant(null), user))
    )
    .option(null);

