import prop from "crocks/Maybe/prop"
import map from "crocks/pointfree/map"
import option from "crocks/pointfree/option"
import compose from "crocks/helpers/compose"
import { head } from "../util/array"
import { safeArray } from "../util/types"
import { stringSplitter } from "../util/string"
import { createFactoryFunc } from "./factory"
import { provideEmptyArray, provideEmptyString, provideZero } from "../util/providers"
import { isNotNil } from "../util/predicates"

function countArrayReducer(lookup, variable) {
  lookup[variable.id] = variable.counts
  return lookup
}

const scenarioCountProps = [
  { name: 'total', defaultProvider: provideZero },
  { name: 'filtered', defaultProvider: provideZero },
  { name: 'variableCounts', defaultProvider: provideEmptyArray },
]

const analyticsCountProps = [
  { name: 'appId', defaultProvider: provideEmptyString },
  { name: 'appName', defaultProvider: provideEmptyString },
  { name: 'stateId', defaultProvider: provideEmptyString },
  { name: 'eventCount', defaultProvider: provideZero },
]

/**
 * Create a canonical EventCount object.
 */
export const createScenarioCount = createFactoryFunc(scenarioCountProps)

/**
 * Create a canonical AnalyticsCount object.
 */
export const createAnalyticsCount = createFactoryFunc(analyticsCountProps)

/**
 * Reduce an array of count objects into a map for easier lookup.
 *
 * buildVariableCountLookup :: [Counts] -> Object
 */
export const buildVariableCountLookup = countArray =>
  safeArray(countArray)
    .map(arr => arr.reduce(countArrayReducer, {}))
    .option({})

/**
 * getAnnotationStart :: Scenario -> Maybe Number
 */
export const getAnnotationStart = prop('timestampAnnotationStart')

/**
 * getAnnotationEnd :: Scenario -> Maybe Number
 */
export const getAnnotationEnd = prop('timestampAnnotationEnd')

/**
 * getReferenceTime :: Scenario -> Maybe Number
 */
export const getReferenceTime = prop('timestampReference')

/**
 * getCueTime :: Scenario -> Maybe Number
 */
export const getCueTime = prop('timestampCue')

/**
 * getCaseType :: Scenario -> Maybe String
 */
export const getCaseType = prop('caseType')

/**
 * getPrecrashTypology :: Scenario -> Maybe String
 */
export const getPrecrashTypology = prop('precrashTypology')

/**
 * getFeaturesPresent :: Scenario -> Maybe String
 */
export const getFeaturesPresent = prop('featuresPresent')

/**
 * getImpactProximity1 :: Scenario -> Maybe Number
 */
export const getImpactProximity1 = prop('impactProximity1')

/**
 * getImpactProximity2 :: Scenario -> Maybe Number
 */
export const getImpactProximity2 = prop('impactProximity2')

/**
 * getEventSeverity1 :: Scenario -> Maybe String
 */
export const getEventSeverity1 = prop('eventSeverity1')

/**
 * getEventSeverity2 :: Scenario -> Maybe String
 */
export const getEventSeverity2 = prop('eventSeverity2')

/**
 * getAnnotatedFeature :: Scenario -> Maybe String
 */
export const getAnnotatedFeature = compose(
  map(head),
  map(stringSplitter(';')),
  getFeaturesPresent
)

/**
 * getEnhancedDatasets :: Scenario -> Maybe String
 */
export const getEnhancedDatasets = prop('enhancedDatasets')

/** */
export const isEnhancedRadarDataset = compose(
  option(false),
  map(s => isNotNil(s) && s.match(/WP1/i) !== null),
  getEnhancedDatasets
)

export const isEnhancedL2Dataset = compose(
  option(false),
  map(s => isNotNil(s) && s.match(/L2/i) !== null),
  getEnhancedDatasets
)

const crashRegExp = /^crash$/i
const nearCrashRegExp = /near.crash/i

/**
 * isCrash :: String -> Boolean
 */
export const isCrash = str => crashRegExp.test(str)

/**
 * isNearCrash :: String -> Boolean
 */
export const isNearCrash = str => nearCrashRegExp.test(str)

/**
 * isValidImpactProximity :: Number? -> Boolean
 */
export const isValidImpactProximity = impactProx => isNotNil(impactProx) && impactProx >= 0
