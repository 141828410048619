/*
 * This module contains actions having to do with members (a.k.a. Local Portal Admins).
 */

import map from "crocks/pointfree/map";
import compose from "crocks/helpers/compose";
import { createGetThunk } from "./utils";
import { SET_LOCAL_PORTAL_ADMINS } from "./index";
import { createLocalPortalAdmin, AppRequest } from "../../models";

export const setLocalPortalAdmins = localPortalAdmins =>
  ({ type: SET_LOCAL_PORTAL_ADMINS, payload: { localPortalAdmins } });

const processLPAs = dispatch => compose(dispatch, setLocalPortalAdmins, map(createLocalPortalAdmin));

export const fetchLocalPortalAdmins = () =>
  createGetThunk({
    request: AppRequest.GET_LOCAL_PORTAL_ADMINS,
    url: '/api/member',
    responseFnFactory: processLPAs,
  });
