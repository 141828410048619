import React from 'react'
import { Route, Switch } from "react-router-dom"
import AnalyticsAppGrid from "./AnalyticsAppGrid"
import AnalyticsApp from "./AnalyticsApp"
import { ShinyAppStateProvider } from "./shinyAppContext"

export default function AnalyticsContent() {
  return (
    <ShinyAppStateProvider>
      <Switch>
        <Route path="/analytics/:appId" component={AnalyticsApp}/>
        <Route path="/analytics" component={AnalyticsAppGrid}/>
      </Switch>
    </ShinyAppStateProvider>
  )
}
