import PropTypes from "prop-types";
import { connect } from "react-redux";
import isDefined from "crocks/predicates/isDefined";
import isFunction from "crocks/predicates/isFunction";
import { userHasAllRoles, userHasAnyRoles } from "../../models";
import { isArrayNotEmpty } from "../../util/predicates";
import { getUserProfile } from "../../store";

/**
 * IfUser controls the visibility of it's children components using one of three properties. In
 * the order of precedence, these props are:
 *
 * filter: A predicate that receives the user's profile and should return true if the children
 *    should be rendered.
 *
 * userHasAllRoles: An array of UserRole strings. The children are visible if the current user has all
 *    of the roles.
 *
 * userHasAnyRoles: An array of UserRole strings. The children are visible if the current user has any
 *    of the roles.
 *
 * If none of these props are provided, the children will not be visible.
 */
const IfUser = ({ filter, hasAny: anyRoles, hasAll: allRoles, profile, children }) => {
  if (isDefined(filter) && isFunction(filter)) {
    return filter(profile) ? children : null;
  } else if (isArrayNotEmpty(allRoles)) {
    return userHasAllRoles(allRoles, profile) ? children : null;
  } else if(isArrayNotEmpty(anyRoles)) {
    return userHasAnyRoles(anyRoles, profile) ? children : null;
  }
  return null;
};

IfUser.propTypes = {
  filter: PropTypes.func,
  hasAny: PropTypes.array,
  hasAll: PropTypes.array,
};

const mapStateToProps = state => ({ profile: getUserProfile(state) });

export default connect(mapStateToProps)(IfUser);
