import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import compose from "crocks/helpers/compose";
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import ResultsViewTypes from "../../../../common/models/ResultsViewTypes";
import { fetchLayerDownloadUrl, setScenarioIndex } from "../../../../common/store/actions/index";
import { withRouter } from "react-router-dom";
import TableResultsView from "./TableResultsView";
import ListResultsView from "./ListResultsView";
import GridResultsView from "./GridResultsView";
import PagingControls from "../PagingControls";
import { getScenarioPageIndex, getScenarioPageMax, getScenarios } from "../../../../common/store/index";

const { LIST, TABLE } = ResultsViewTypes;

const selectLoadingClass = (view, scenarios, classes) =>
  scenarios.length === 0 ? classes.loading : classes.loaded;

const createPagingControls = ({ pageIndex, pageMax, onGoToPage }) =>
  <PagingControls index={pageIndex} max={pageMax} onGoToPage={onGoToPage}/>;

const selectViewComponent = (props, onClick, onLayerClick, onEditTags) => {
  const { view, scenarios, variables, className } = props;
  switch (view) {
    case LIST:
      return (
        <ListResultsView
          scenarios={scenarios}
          className={className}
          onClick={onClick}
          onLayerClick={onLayerClick}
        />
      );

    case TABLE:
      const { column, direction, onColumnChange, onDirectionChange } = props;
      return (
        <TableResultsView
          scenarios={scenarios}
          variables={variables}
          className={className}
          onClick={onClick}
          column={column}
          direction={direction}
          onColumnChange={onColumnChange}
          onDirectionChange={onDirectionChange}
        />
      );

    default:
      return (
        <GridResultsView
          scenarios={scenarios}
          className={className}
          onClick={onClick}
          onLayerClick={onLayerClick}
          onTagClick ={onEditTags}
        />
      );
  }
};

const styles = theme => ({
  root: {
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
  },
  loading: {
    opacity: '0',
  },
  loaded: {
    opacity: '1.0',
  }
});

class ResultsView extends React.PureComponent {
  static propTypes = {
    view: PropTypes.string.isRequired,
    scenarios: PropTypes.array.isRequired,
    variables: PropTypes.array.isRequired,
    column: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    onGoToPage: PropTypes.func.isRequired,
    onColumnChange: PropTypes.func.isRequired,
    onDirectionChange: PropTypes.func.isRequired,
    onEditTags: PropTypes.func,
  };

  handleLayerClick = (id, layer) => this.props.fetchLayerDownloadUrl(id, layer);

  handleClick = id => {
    const { setScenarioIndex, scenarios, history } = this.props;
    const idx = scenarios.findIndex(s => s.id === id);
    if (idx >= 0) {
      setScenarioIndex(idx);
      history.push('/scenarios/viewer');
    }
  };

  render() {
    const { view, scenarios, onEditTags, className, classes } = this.props;
    const rootClasses = classNames(className, classes.root, selectLoadingClass(view, scenarios, classes));

    return (
      <div className={rootClasses}>
        {selectViewComponent(this.props, this.handleClick, this.handleLayerClick, onEditTags)}
        {createPagingControls(this.props)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  scenarios: getScenarios(state),
  pageIndex: getScenarioPageIndex(state),
  pageMax: getScenarioPageMax(state),
});

const mapDispatchToProps = { setScenarioIndex, fetchLayerDownloadUrl };

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ResultsView);
