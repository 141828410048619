import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
import BackWithHeading from "../BackWithHeading";
import ActionButton from "../DashboardCard/ActionButton";

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
  }
};

class UserTableHeader extends React.PureComponent {
  static propTypes = {
    onAddUser: PropTypes.func,
  };

  render() {
    const { onAddUser, classes } = this.props;
    return (
        <div className={classes.root}>
          <BackWithHeading heading="Users"/>
          <ActionButton
            icon={<AddIcon/>}
            label="Invite User"
            onClick={onAddUser}
          />
        </div>

    );
  }
}

export default withStyles(styles)(UserTableHeader);
