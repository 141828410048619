import React from 'react';
import PropTypes from 'prop-types';
import and from "crocks/logic/and";
import isNumber from "crocks/predicates/isNumber";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import TimeSeriesValue from "./TimeSeriesValue";
import { clamp } from "../../../../../common/util/math";
import { isNotNil } from "../../../../../common/util/predicates";

const prndl = "PRNDL";
const prndlLen = prndl.length;
const prndlNoData = 254;

const buildSpan = (str, className) => <Typography className={className} component="span">{str}</Typography>;
const isValid = and(isNumber, val => val < prndlNoData);
const isValidPrndl = and(isNotNil, isValid);

function createPrndl(className, classes, value) {
  const index = isValidPrndl(value) ? clamp(value, 0, prndlLen - 1) : prndlLen;
  return (
    <div className={className}>
      { index > 0 ? buildSpan(prndl.slice(0, index), classes.inactive) : null}
      { index < prndlLen ? buildSpan(prndl[index], classes.active) : null}
      { index < prndlLen - 1 ? buildSpan(prndl.slice(index + 1), classes.inactive) : null}
    </div>
  )
}

const styles = theme => ({
  inactive: {
    display: 'inline',
    color: theme.palette.amp ? theme.palette.amp.viewerDim : '#888',
    letterSpacing: '0.25em',
  },
  active: {
    display: 'inline',
    color: theme.palette.amp ? theme.palette.amp.viewerHighlight : 'white',
    fontWeight: 'bolder',
    letterSpacing: '0.25em',
  },
});

/**
 * This component renders the PRNDL gear status.  The possible values are:
 *
 * PRNDL: 0 = P, 1 = R, 2 = N, 3 = D, 4 = L, 254=no data, 255=invalid data
 *
 * We also need to handle valid values between L (4) and "no data" (254). For example, there
 * are occasional values of 7 in the data (presumably for manual transmissions?).
 */
class PrndlStatus extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array,
    time: PropTypes.number,
    className: PropTypes.string,
  };

  render() {
    const { data, time, className, classes } = this.props;

    return (
      <TimeSeriesValue data={data} time={time}>
        {value => createPrndl(className, classes, value)}
      </TimeSeriesValue>
    );
  }
}

export default withStyles(styles)(PrndlStatus);
