import React from 'react'
import { withStyles } from "@material-ui/core/"
import background from '../../common/assets/grey-background.png'

const styles = {
  root: {
    gridArea: '1 / span 2',
    position: 'relative',
  },
  img: {
    width: '100%',
    height: '100%',
  },
  welcome: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    maxWidth: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#eee',
    borderRadius: '.5rem',
    padding: '1rem',
  },
  welcomeTitle: {
    margin: 0,
  }
}

const LandingBanner = ({ classes }) => (
  <div className={classes.root}>
    <img className={classes.img} src={background} alt="Landing page banner"/>
    <div className={classes.welcome}>
      <h1 className={classes.welcomeTitle}>Welcome to the AMP Portal</h1>
      <p>The Automated Mobility Partnership (AMP) Portal provides members with access to a variety of real-world driving
        data and a suite of support tools focused on the development and evaluation of automated driving
        technologies.</p>
      <p>An overview tool describing the AMP collection is located on the analytics page.</p>
    </div>
  </div>
)

export default withStyles(styles)(LandingBanner)