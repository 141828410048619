import React from 'react';
import PropTypes from 'prop-types';
import CardStamp from "../DashboardCard/CardStamp";
import CardTitle from "../DashboardCard/CardTitle";
import CardActions from "../DashboardCard/CardActions";
import CardContent from "../DashboardCard/CardContent";
import ActionButton from "../DashboardCard/ActionButton";
import DashboardCard from "../DashboardCard/index";
import OrgsIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';
import AppRequest from "../../../common/models/AppRequest";
import { allRequestsSuccessful } from "../../../common/util/request";

const { GET_ORGANIZATIONS, GET_MEMBERSHIPS} = AppRequest;
const isLoading = reqState => !allRequestsSuccessful(reqState, GET_ORGANIZATIONS, GET_MEMBERSHIPS);

const createSubtitle = (isLoading, count) =>
  isLoading ? 'Loading...' : `${count} Organization${count === 1 ? '' : 's'}`;

const OrganizationsCard = ({ onAddOrg, organizationCount, requestState, onClick }) => (
  <DashboardCard onClick={onClick}>
    <CardStamp backgroundColor="#06098A" color="white">
      <OrgsIcon />
    </CardStamp>
    <CardContent>
      <CardTitle
        title="Orgs"
        subtitle={createSubtitle(isLoading(requestState), organizationCount)}
      />
      <CardActions>
        <ActionButton
          icon={<AddIcon/>}
          label="Add Organization"
          onClick={onAddOrg}
          disabled={isLoading(requestState)}
        />
      </CardActions>
    </CardContent>
  </DashboardCard>
);

OrganizationsCard.propTypes = {
  onAddOrg: PropTypes.func,
  organizationCount: PropTypes.number,
  requestState: PropTypes.object,
};

OrganizationsCard.defaultProps = {
  organizationCount: 0,
  requestState: {}
};

export default OrganizationsCard;