export const slideLeftEnter = {
  from: { transform: "translateX(100%)" },
  to: { transform: "" }
};

export const slideLeftLeave = {
  from: { transform: "" },
  to: { transform: "translateX(-100%)" }
};

export const slideRightEnter = {
  from: { transform: "translateX(-100%)" },
  to: { transform: "" }
};

export const slideRightLeave = {
  from: { transform: "" },
  to: { transform: "translateX(100%)" }
};
