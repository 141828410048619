import map from "crocks/pointfree/map";
import compose from "crocks/helpers/compose";
import AppRequest from "../../models/AppRequest";
import { createGetThunk, createPostThunk } from "./utils";
import { createIrbCertificate } from "../../models/irbCertificate";
import { SET_IRB_CERTIFICATES, UPDATE_IRB_CERTIFICATE } from "./index";

const setIrbCertificates = irbCertificates => ({ type: SET_IRB_CERTIFICATES, payload: { irbCertificates } });
const updateIrbCertificate = irbCertificate => ({ type: UPDATE_IRB_CERTIFICATE, payload: { irbCertificate } });

/**
 * Create a thunk action that GETs all IRB certificate uploads from the server.
 *
 * @param status If present and has a value of "new", then will fetch only those certs for which
 *  a review is still pending.
 */
export const fetchAllIrbCertificates = status =>
  createGetThunk({
    url: '/api/cert',
    params: { status },
    request: AppRequest.GET_IRB_CERTIFICATES,
    responseFnFactory: dispatch => compose(dispatch, setIrbCertificates, map(createIrbCertificate)),
  });

/**
 * Create a thunk action that POSTs an IrbCertificateReview to the server.
 */
export const submitIrbReview = (id, irbCertificateReview) =>
  createPostThunk({
    url: `/api/cert/${id}`,
    body: irbCertificateReview,
    request: AppRequest.POST_IRB_CERTIFICATE_REVIEW,
    responseFnFactory: dispatch => compose(dispatch, updateIrbCertificate, createIrbCertificate),
  });
