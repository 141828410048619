import * as React from 'react'
import { useAnalyticsApps } from "../../common/store/hooks/useAnalytics"
import { ensureArrayResponse } from "../../common/store/actions/utils"

interface ShinyApp {
  id: string;
  url: string;
  name: string;
  description: string;
  namespace: string;
}

interface ShinyAppState {
  apps: ShinyApp[] | undefined;
  setApps: (apps: ShinyApp[]) => void;
}

type ShinyAppProviderProps = { children: React.ReactNode }

const ShinyAppStateContext = React.createContext<ShinyAppState | undefined>(undefined)

function ShinyAppStateProvider({ children }: ShinyAppProviderProps) {
  const [apps, setApps] = React.useState<ShinyApp[] | undefined>(undefined)
  const fetchAnalyticsApps = useAnalyticsApps()

  React.useEffect(() => {
    fetchAnalyticsApps()
      // @ts-ignore
      .then(ensureArrayResponse)
      .then(setApps)
  }, [setApps, fetchAnalyticsApps])

  return (
    <ShinyAppStateContext.Provider value={{ apps, setApps }}>
      {children}
    </ShinyAppStateContext.Provider>
  )
}

function useShinyAppState() {
  const context = React.useContext(ShinyAppStateContext)

  if (context === undefined) {
    throw new Error('useShinyAppState must be used within a ShinyAppStateProvider')
  }

  return context
}

export { ShinyAppStateProvider, useShinyAppState }