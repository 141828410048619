import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import MovieIcon from '@material-ui/icons/LocalMovies';
import AsyncImg from "../../../../common/components/AsyncImg";

const playVideoVelocityThreshold = 0.1;

function calculatePointerVelocity(mouseInfo, x, y) {
  const t = Date.now();
  const { lastX, lastY, lastT } = mouseInfo;

  const deltaX = x - lastX;
  const deltaY = y - lastY;
  const deltaT = t - lastT;

  if (deltaT === 0) {
    return Number.MAX_SAFE_INTEGER;
  }

  mouseInfo.lastX = x;
  mouseInfo.lastY = y;
  mouseInfo.lastT = t;

  return Math.sqrt(deltaX * deltaX + deltaY * deltaY) / deltaT;
}

const PlaceholderThumbnail = ({ className }) => (
  <div className={className}>
    <MovieIcon style={{ width: 100, height: 100, color: '#666', }}/>
  </div>
);

const buildVideoElement = id =>
  <video src={`/api/event/${id}/preview`} poster={`/api/event/${id}/image`} autoPlay width="100%"/>;

const buildImageElement = (id, imgError, onError, onLoad) =>
  imgError || id <= 0
    ? null
    : <AsyncImg src={`/api/event/${id}/image`} width='240' height='180' alt="Thumbnail" onError={onError} onLoad={onLoad}/>;

const styles = {
  root: {
    height: 180,
    position: 'relative',
    '& > *': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#222',
    borderRadius: 4,
  },
};

class Thumbnail extends React.PureComponent {
  static propTypes = {
    scenarioId: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    scenarioId: 0,
  };

  state = {
    imgLoadError: false,
    showPlaceholder: true,
    mouseHover: false,
  };

  constructor(props) {
    super(props);
    this.mouseInfo = { lastX: 0, lastY: 0, lastT: 0 }
  }

  handleImageLoad = () => this.setState({ showPlaceholder: false });
  handleImageError = () => this.setState({ imgLoadError: true });

  handleMouseEnter = ev => {
    const { mouseInfo } = this;
    mouseInfo.lastX = ev.clientX;
    mouseInfo.lastY = ev.clientY;
    mouseInfo.lastT = Date.now();
  };

  handleMouseLeave = () => this.setState({ mouseHover: false });

  handleMouseMove = ev => {
    if (this.state.mouseHover === true) {
      return;
    }

    const v = calculatePointerVelocity(this.mouseInfo, ev.clientX, ev.clientY);
    if (v < playVideoVelocityThreshold) {
      this.setState({ mouseHover: true });
    }
  };

  swallowEvent = ev => ev.preventDefault();

  render() {
    const { scenarioId, className, classes } = this.props;
    const { showPlaceholder, imgLoadError, mouseHover } = this.state;

    return (
      <div
        className={classNames(className, classes.root)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
        onContextMenu={this.swallowEvent}
      >
        {
          mouseHover
            ? buildVideoElement(scenarioId)
            : buildImageElement(scenarioId, imgLoadError, this.handleImageError, this.handleImageLoad)
        }
        {showPlaceholder && <PlaceholderThumbnail className={classes.placeholder}/>}
      </div>
    );
  }
}

export default withStyles(styles)(Thumbnail);
