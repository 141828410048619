import React from 'react';
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  stamp: {
    position: 'absolute',
    left: theme.spacing(),
    top: -20,
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > *:first-child': {
      width: 50,
      height: 50,
    }
  },
});

class CardStamp extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
  };

  static defaultProps = {
    color: '#FFF',
    backgroundColor: '#888',
  };

  render() {
    const { color, backgroundColor, classes, children } = this.props;

    return (
      <Paper className={classes.stamp} style={{ backgroundColor, color }} elevation={6}>
        {children}
      </Paper>
    );
  }
}

export default withStyles(styles)(CardStamp);
