import React from 'react';
import PropTypes from 'prop-types';
import prop from "crocks/Maybe/prop";
import compose from "crocks/helpers/compose";
import identity from "crocks/combinators/identity";
import option from "crocks/pointfree/option";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import Indicators from "./Indicators";
import Features from "./Features";
import Thumbnail from "./Thumbnail";
import AmpDownloadIcons from "../../../../common/components/AmpDownloadIcons";
import ScenarioTagList from "./ScenarioTagList";
import { getAllTagNames } from "../../common/tagUtils";

const ampLayer = compose(option(''), prop('ampLayer'));

const styles = {
  root: {
    position: 'relative',
    width: 320,
    height: 320,
    display: 'grid',
    gridTemplateColumns: '36px 240px 36px',
    gridTemplateRows: 'auto 180px 36px 1fr',
    gridGap: '4px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  scenarioId: {
    gridColumn: '1 / 4',
    textAlign: 'center',
  },
  thumbnail: {
    gridColumn: '2',
  },
  downloads: {
    gridColumn: '3',
    gridRow: '2',
  },
  caseTypes: {
    gridColumn: '2',
  },
  features: {
    gridColumn: '1 / 4',
    textAlign: 'center',
  },
  tag: {
    position: 'absolute',
    top: 40,
    left: 20,
  }
};

class GridResult extends React.PureComponent {
  static propTypes = {
    scenario: PropTypes.object,
    onClick: PropTypes.func,
    onTagClick: PropTypes.func,
    onLayerClick: PropTypes.func,
  };

  static defaultProps = {
    scenario: {},
    onTagClick: identity,
  };

  handleClick = () => this.props.onClick(this.props.scenario.id);
  handleLayerClick = layer => this.props.onLayerClick(this.props.scenario.id, layer);
  handleTagClick = ev => {
    ev.stopPropagation();
    this.props.onTagClick(this.props.scenario.id);
  };

  render() {
    const { scenario, classes } = this.props;
    const { id, fileId, caseType, referenceSpeed, featuresPresent } = scenario;

    return (
      <div className={classes.root} onClick={this.handleClick}>
        <div className={classes.scenarioId} title={`Case #${fileId}`}>
          <Typography variant="subtitle1">{fileId}</Typography>
        </div>

        <Thumbnail className={classes.thumbnail} scenarioId={id}/>

        <ScenarioTagList
          className={classes.tag}
          tagNames={getAllTagNames(scenario)}
          onClick={this.handleTagClick}
        />

        <AmpDownloadIcons
          className={classes.downloads}
          ampLayer={ampLayer(scenario)}
          onClick={this.handleLayerClick}
        />

        <Indicators className={classes.caseTypes} caseType={caseType} speed={referenceSpeed}/>
        <Features className={classes.features} featuresPresent={featuresPresent} maxLen={120} small/>
      </div>
    );
  }
}

export default withStyles(styles)(GridResult);
