import {
  DELETE_FAQ,
  DELETE_NEWS_ITEM,
  SET_FAQS,
  SET_IRB_CERTIFICATES,
  SET_LOCAL_PORTAL_ADMINS,
  SET_MEMBERSHIPS,
  SET_NEWS,
  SET_ORGANIZATIONS,
  SET_USERS,
  UPDATE_FAQ,
  UPDATE_IRB_CERTIFICATE,
  UPDATE_NEWS_ITEM, UPDATE_ORGANIZATION,
  UPDATE_USER,
} from "../actions";
import { provideFalse } from "../../util/providers";

/**
 * Creates a new array containing all of the old items, with the new item in place of the old one.
 */
function updateItemInArray(array, item, itemFinder = provideFalse) {
  const itemIdx = array.findIndex(itemFinder);
  return itemIdx === -1
    ? [...array, item]
    : [...array.slice(0, itemIdx), item, ...array.slice(itemIdx + 1)];
}

/**
 * This is the shape of the state contained in the admin branch of the Redux store.
 * It is here to provide documentation on the state and to provide initial defaults
 * for the reducers.
 */
export const adminInitialState = {
  news: [],
  faqs: [],
  users: [],
  organizations: [],
  memberships: [],
  localPortalAdmins: [],
  irbCertificates: [],
};

/**
 * Reducer that handles changes to the news items array
 */
export const news = (state = adminInitialState.news, action) => {
  switch (action.type) {
    case SET_NEWS:
      return action.payload.news;

    case UPDATE_NEWS_ITEM:
      return updateItemInArray(state, action.payload.item, n => n.id === action.payload.item.id);

    case DELETE_NEWS_ITEM:
      return state.filter(n => n.id !== action.payload.id);

    default:
      return state;
  }
};

/**
 * Reducer that handles changes to the faqs array
 */
export const faqs = (state = adminInitialState.faqs, action) => {
  switch (action.type) {
    case SET_FAQS:
      return action.payload.faqs;

    case UPDATE_FAQ:
      return updateItemInArray(state, action.payload.faq, f => f.id === action.payload.faq.id);

    case DELETE_FAQ:
      return state.filter(f => f.id !== action.payload.id);

    default:
      return state;
  }
};

/**
 * Root reducer for admin data.
 */
export const admin = (state = adminInitialState, action) => {
  switch (action.type) {
    case SET_USERS:
    case SET_ORGANIZATIONS:
    case SET_MEMBERSHIPS:
    case SET_LOCAL_PORTAL_ADMINS:
    case SET_IRB_CERTIFICATES:
      return { ...state, ...action.payload };

    case UPDATE_IRB_CERTIFICATE:
      const irbCertificates = updateItemInArray(
        state.irbCertificates,
        action.payload.irbCertificate,
        c => c.id === action.payload.irbCertificate.id
      );
      return { ...state, irbCertificates };

    case UPDATE_USER:
      const users = updateItemInArray(
        state.users,
        action.payload.user,
        u => u.id === action.payload.user.id
      );
      return { ...state, users };

    case UPDATE_ORGANIZATION:
      const organizations = updateItemInArray(
        state.organizations,
        action.payload.organization,
        o => o.id === action.payload.organization.id
      );
      return { ...state, organizations };

    case SET_NEWS:
    case UPDATE_NEWS_ITEM:
    case DELETE_NEWS_ITEM:
      return { ...state, news: news(state.news, action) };

    case SET_FAQS:
    case UPDATE_FAQ:
    case DELETE_FAQ:
      return { ...state, faqs: faqs(state.faqs, action) };

    default:
      return state;
  }
};

/*
 * State accessors decouple clients from the structure of the store's state. These
 * are all re-exported from store/index.js for use by the outside world.
 */
export const getNews = state => state.admin.news;
export const getFaqs = state => state.admin.faqs;
export const getUsers = state => state.admin.users;
export const getMemberships = state => state.admin.memberships;
export const getOrganizations = state => state.admin.organizations;
export const getIrbCertificates = state => state.admin.irbCertificates;
export const getLocalPortalAdmins = state => state.admin.localPortalAdmins;

