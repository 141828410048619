import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from "@material-ui/core/styles"
import { Route, Switch } from "react-router-dom"

import NavBar from "../common/components/NavBar"
import Footer from "../common/components/Footer"

import AnalyticsContent from "./AnalyticsContent/AnalyticsContent"
import FunctionalScenarioContent from "./FunctionalScenarioContent/FunctionalScenarioContent"
import MembersContent from "./MembersContent"
import LandingContent from "./LandingContent"
import ProfileContent from "./ProfileContent"
import ScenariosContent from "./ScenariosContent/ScenariosContent"
import SupportContent from "./SupportContent/SupportContent"
import AdminContent from "./AdminContent"

const styles = () => ({
  root: {
    height: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto'
  }
})

const HomePageContainer = props => (
  <div className={props.classes.root}>
    <NavBar profile={props.profile} pathname={props.pathname} />
    <Switch>
      <Route path="/support" component={SupportContent} />
      <Route path="/analytics" component={AnalyticsContent} />
      <Route path="/functionalscenario" component={FunctionalScenarioContent} />
      <Route path="/members" component={MembersContent} />
      <Route path="/profile" component={ProfileContent} />
      <Route path="/scenarios" component={ScenariosContent} />
      <Route path="/admin" component={AdminContent} />
      <Route path="/" component={LandingContent} />
    </Switch>
    <Footer />
  </div>
)

HomePageContainer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HomePageContainer)
