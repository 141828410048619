import React, { useCallback, useEffect, useState } from 'react'
import compose from "crocks/helpers/compose"
import prop from "crocks/Maybe/prop"
import option from "crocks/pointfree/option"
import map from "crocks/pointfree/map"
import chain from "crocks/pointfree/chain"
import isEmpty from "crocks/predicates/isEmpty"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import AppRequestState from "../../../common/models/AppRequestState"
import RequestStatusDisplay from "../../../common/components/RequestStatusDisplay"
import RecipientSelect from "./RecipientSelect"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useEmail from "../../../common/store/hooks/useEmail"
import { isNotNil } from "../../../common/util/predicates"

const testEmail = 'test'
const isTestEmail = toRole => toRole === testEmail

const { SUCCESS, IN_FLIGHT, FAILURE } = AppRequestState

const createSuccessMessage = compose(
  option('Success! Your email will be sent to the specified users'),
  map(count => `Success! Your email will be sent to ${count} user${count === 1 ? '' : 's'}.`),
  prop('numRecipients')
)

const createErrorMessage = compose(
  option('Error while processing request.'),
  map(msg => `Error: ${msg}`),
  chain(prop('message')),
  prop('payload')
)

const createStateMsg = (isSuccess, result) =>
  isSuccess ? createSuccessMessage(result) : createErrorMessage(result)

const recipientCountReducer = (counts, it) => ({ ...counts, [it.rolename]: it.count })
const reduceCounts = counts => counts.reduce(recipientCountReducer, { [testEmail]: 1 })

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    width: 600,
    height: 600,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto auto',
    gridRowGap: '16px'
  },
  submit: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > button': {
      marginRight: 16,
    }
  },
  body: {
    font: '18px Roboto',
  }
})

function SendEmailForm() {
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [recipientRole, setRecipientRole] = useState(testEmail)
  const [stateMsg, setStateMsg] = useState('')
  const [requestState, setRequestState] = useState(null)
  const [recipientCounts, setRecipientCounts] = useState({})

  const classes = useStyles()
  const { sendUserEmail, fetchRecipientCounts } = useEmail()

  useEffect(
    () => {
      fetchRecipientCounts()
        .then(reduceCounts)
        .then(setRecipientCounts)
        .catch(err => console.log('Could not get recipient counts from the server:', err))
    },
    [fetchRecipientCounts]
  )

  const handleSend = useCallback(
    () => {
      setStateMsg('Processing request...')
      setRequestState(IN_FLIGHT)

      sendUserEmail(subject, body, isTestEmail(recipientRole) ? null : recipientRole)
        .then(result => {
          const isSuccess = isNotNil(result.numRecipients)
          setStateMsg(createStateMsg(isSuccess, result))
          setRequestState(isSuccess ? SUCCESS : FAILURE)
        })
    },
    [sendUserEmail, subject, body, recipientRole]
  )

  const recipientCount = recipientCounts[recipientRole]
  const submitDisabled = isEmpty(subject) || isEmpty(body)

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        autoFocus
        label="Subject"
        value={subject}
        onChange={ev => setSubject(ev.target.value)}
      />

      <textarea
        value={body}
        onChange={ev => setBody(ev.target.value)}
        className={classes.body}
        placeholder="Body text or HTML of your email..."
      />

      <RecipientSelect value={recipientRole} onChange={setRecipientRole} count={recipientCount}/>

      <div className={classes.submit}>
        <Button color="primary" variant="contained" disabled={submitDisabled} onClick={handleSend}>
          Send
        </Button>
        <RequestStatusDisplay requestState={requestState} stateMsg={stateMsg}/>
      </div>
    </div>
  )
}

export default SendEmailForm
