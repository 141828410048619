import React from 'react';
import { compose } from "redux";
import { connect } from "react-redux";

import withStyles from '@material-ui/core/styles/withStyles';

import { getMemberships } from "../../common/store";
import { fetchLocalPortalAdmins, fetchMemberships } from "../../common/store/actions";
import ContentContainer from "../../common/components/ContentContainer";
import MembershipExpansionPanel from "./MembershipExpansionPanel";
import { getLocalPortalAdmins } from "../../common/store";

const isMembershipLevel = membership => admin => admin.rank === membership.rank;

function buildMembershipPanels(memberships, admins) {
  return memberships.map(m =>
    <MembershipExpansionPanel
      key={m.rank}
      membership={m}
      localPortalAdmins={admins.filter(isMembershipLevel(m))}
    />
  )
}

const styles = {
  root: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 1280,
  },
};

class MembersList extends React.PureComponent {
  state = {
    expanded: {}
  };

  componentDidMount() {
    this.props.fetchMemberships();
    this.props.fetchLocalPortalAdmins();
  }

  render() {
    const { localPortalAdmins, memberships, classes } = this.props;

    return (
      <ContentContainer>
        <div className={classes.root}>
          {buildMembershipPanels(memberships, localPortalAdmins)}
        </div>
      </ContentContainer>
    );
  }
}

const mapStateToProps = state => ({
  memberships: getMemberships(state),
  localPortalAdmins: getLocalPortalAdmins(state)
});
const mapDispatchToProps = { fetchMemberships, fetchLocalPortalAdmins };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MembersList);
