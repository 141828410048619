import curry from "crocks/helpers/curry";
import { stringProp } from "./object";
import AppRequestState from "../models/AppRequestState";
import { isArrayNotEmpty } from "./predicates";

const stateComparison = state => (states, request) => stringProp(request, states).option() === state;

/**
 * isRequestInFlight :: (Object, String) -> Boolean
 */
export const isRequestStateInFlight = curry(stateComparison(AppRequestState.IN_FLIGHT));

/**
 * isRequestInFlight :: (Object, String) -> Boolean
 */
export const isRequestStateSuccess = curry(stateComparison(AppRequestState.SUCCESS));

/**
 * isFailure :: (Object, String) -> Boolean
 */
export const isRequestStateFailure = curry(stateComparison(AppRequestState.FAILURE));

/**
 * allRequestsInFlight :: (Object, [String]) -> Boolean
 */
export const allRequestsInFlight = (states, ...requests) =>
  isArrayNotEmpty(requests) && requests.every(isRequestStateInFlight(states));

/**
 * allRequestsInFlight :: (Object, [String]) -> Boolean
 */
export const anyRequestsInFlight = (states, ...requests) =>
  isArrayNotEmpty(requests) && requests.some(isRequestStateInFlight(states));

/**
 * allRequestsSuccessful :: (Object, [String]) -> Boolean
 */
export const allRequestsSuccessful = (states, ...requests) =>
  isArrayNotEmpty(requests) && requests.every(isRequestStateSuccess(states));