const takeSize = 5;

const arrayOfInts = (start, count) => new Array(count).fill(start).map((val, index) => val + index);

export function buildPageNumberList(index, max) {
  let prefix = [];
  let postfix = [];
  let takeStart = 1;

  if (max <= takeSize) {
    return arrayOfInts(takeStart, Math.min(max, takeSize));
  }

  if (index >= takeSize - 1) {
    takeStart = index - Math.floor(takeSize / 2);
    prefix = [1, -1];
  }

  if (index <= max - Math.ceil(takeSize / 2)) {
    postfix = [-2, max];
  } else {
    takeStart = max - (takeSize - 1);
  }

  return prefix.concat(arrayOfInts(takeStart, takeSize)).concat(postfix);
}
