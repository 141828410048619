import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import identity from "crocks/combinators/identity";
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from "@material-ui/core/IconButton";
import GridViewIcon from '@material-ui/icons/ViewModule';
import ListViewIcon from '@material-ui/icons/ViewList';
import TableViewIcon from '@material-ui/icons/BorderAll';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ResultsViewTypes from "../../../common/models/ResultsViewTypes";
import { userIsSteeringCommitteeMember } from "../../../common/models/user";
import DownloadInProgressSnackbar from "./ResultsView/DownloadInProgressSnackbar";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  control: {
    borderRadius: 4,
    color: '#CCC',
    cursor: 'pointer',
    width: 36,
    height: 36,
    padding: theme.spacing(0.25),
    marginLeft: 1,
    marginRight: 1,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  selected: {
    color: '#888',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  download: {
    marginLeft: theme.spacing(2),
    width: 48,
    height: 48,
    fontSize: 48,
  }
});

class ResultsViewControls extends React.PureComponent {
  static propTypes = {
    view: PropTypes.string,
    user: PropTypes.object,
    onChangeView: PropTypes.func,
    onDownload: PropTypes.func,
  };

  static defaultProps = {
    view: ResultsViewTypes.GRID,
    onDownload: identity,
  };

  state = {
    showDownloadMessage: false
  };

  handleGridClick = () => this.props.onChangeView(ResultsViewTypes.GRID);
  handleListClick = () => this.props.onChangeView(ResultsViewTypes.LIST);
  handleTableClick = () => this.props.onChangeView(ResultsViewTypes.TABLE);

  handleDownloadClick = () => {
    this.props.onDownload();
    this.setState({ showDownloadMessage: true });
  };
  handleSnackbarClose = () => this.setState({ showDownloadMessage: false });

  render() {
    const { view, user, classes, className } = this.props;
    const { showDownloadMessage } = this.state;
    const rootClasses = classNames(classes.root, className);

    const gridClass = classNames(classes.control, view === ResultsViewTypes.GRID ? classes.selected : '');
    const listClass = classNames(classes.control, view === ResultsViewTypes.LIST ? classes.selected : '');
    const tableClass = classNames(classes.control, view === ResultsViewTypes.TABLE ? classes.selected : '');
    const downloadClass = classNames(classes.control, classes.download);

    const downloadComponent = (
      <IconButton
        className={downloadClass}
        disabled={showDownloadMessage}
        onClick={this.handleDownloadClick}
        title="Download Cases as CSV"
      >
        <CloudDownloadIcon color="primary" style={{ fontSize: 32 }} />
      </IconButton>
    );

    return (
      <div className={rootClasses}>
        <IconButton className={gridClass} onClick={this.handleGridClick} title="Grid View">
          <GridViewIcon/>
        </IconButton>

        <IconButton className={listClass} onClick={this.handleListClick} title="List View">
          <ListViewIcon/>
        </IconButton>

        <IconButton className={tableClass} onClick={this.handleTableClick} title="Table View">
          <TableViewIcon/>
        </IconButton>

        { userIsSteeringCommitteeMember(user) ? downloadComponent : null }
        <DownloadInProgressSnackbar open={showDownloadMessage} onClose={this.handleSnackbarClose}/>
      </div>
    );
  }
}

export default withStyles(styles)(ResultsViewControls);
