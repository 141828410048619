import React from 'react';
import PropTypes from 'prop-types';
import AppRequestState from "../../common/models/AppRequestState";
import { withStyles } from "@material-ui/core/styles/index";
import { connect } from "react-redux";
import { Divider, Typography } from "@material-ui/core/";
import { fetchUserProfile, resetRequestState, uploadIrbCertificate } from "../../common/store/actions";
import { pipe } from "../../common/util/func";
import { getIrbCertStatus, getQualifiedResearcherStatus } from "./irb-funcs";
import IrbStepper from "./IrbStepper";
import TitledFormContainer from "../../common/components/TitledFormContainer";
import RequestStatusDisplay from "../../common/components/RequestStatusDisplay";
import IrbUploadButton from "../../common/components/IrbUploadButton";
import { stringProp } from "../../common/util/object";
import AppRequest from "../../common/models/AppRequest";

const requestName = AppRequest.POST_IRB_CERTIFICATE;

const stateMsgs = {
  [AppRequestState.IN_FLIGHT]: "Uploading Certificate...",
  [AppRequestState.FAILURE]: "Could not upload at this time, please try again later.",
  [AppRequestState.SUCCESS]: "Certificate Uploaded",
};

const styles = theme => ({
  stepperDivider: {
    marginTop: 24,
    marginBottom: 8
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  irbUploadBtn: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  approvalStatus: {
    marginTop: theme.spacing(3),
  },
  certStatus: {
    maxWidth: 550,
  },
  hiddenInput: {
    display: 'none',
  },
});

class ProfileIrbContent extends React.Component {
  handleSubmit = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    this.props.uploadIrbCertificate(formData)
      .then(() => this.props.fetchUserProfile());
  };

  render() {
    const { classes, profile, requestState } = this.props;
    const stateMsg = stringProp(requestState, stateMsgs).option('');

    const approvalStatusMsg = getQualifiedResearcherStatus(profile);
    const { step, status: certStatusMsg } = getIrbCertStatus(profile);
    const errorOnStep = step === 2 && !profile.certApproved ? step : undefined;

    return (
      <TitledFormContainer title="IRB Certification" email={profile.email}>
        <Typography className={classes.approvalStatus} variant="subtitle1">{approvalStatusMsg}</Typography>
        <Divider className={classes.stepperDivider} />
        <IrbStepper step={step} errorOnStep={errorOnStep} />

        <div className={classes.formRoot}>
          <Typography className={classes.certStatus} variant="subtitle1">{certStatusMsg}</Typography>
          <IrbUploadButton
            className={classes.irbUploadBtn}
            label="Upload New IRB Certificate"
            handleUpload={this.handleSubmit}
          />
          <RequestStatusDisplay requestState={requestState} stateMsg={stateMsg} />
        </div>
      </TitledFormContainer>
    );
  }
}

ProfileIrbContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({
  profile: app.profile,
  requestState: app.requests[requestName]
});

export default pipe(
  connect(mapStateToProps, { uploadIrbCertificate, fetchUserProfile, resetRequestState }),
  withStyles(styles)
)(ProfileIrbContent);