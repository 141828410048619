import Cookie from "js-cookie";
import {
  downloadFile,
  downloadFileFromObject,
  withCsrf,
  withDelete,
  withForm,
  withJson,
  withPost,
  withPut
} from "./http";

/*
 * Set up the cookie service
 */
const clearSession = () => Cookie.remove('JSESSIONID');
const cookie = { clearSession };

/*
 * Set up the http service
 */
const getData = withCsrf(fetch, Cookie.set, Cookie.getJSON);

const post = withPost(getData);
const postForm = withForm(post);
const postJson = withJson(post);

const put = withPut(getData);
const putForm = withForm(put);
const putJson = withJson(put);

const deleteData = withDelete(getData);
const deleteJson = withJson(deleteData);

const http = {
  getData,
  postForm,
  postJson,
  putForm,
  putJson,
  deleteData,
  deleteJson,
  downloadFile,
  downloadFileFromObject
};

/*
 * Export the services object with the preconfigured services
 */
const services = { http, cookie };
export default services;