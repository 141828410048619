import { end } from "../../../../common/util/array"
import { getEpochTimeStampBase, offsetSeconds } from "../../common/timeSeries"
import { provideZero } from "../../../../common/util/providers"
import { isUndefined } from "../../../../common/util/predicates"
import { buildChartData, buildChartOptions, createXAxisLabels } from "./timeSeriesChartFuncs"
import { getTimeOfInterest } from "../../common/scenario"
import {
  getAnnotationEnd,
  getAnnotationStart,
  getCaseType,
  getCueTime,
  getReferenceTime
} from "../../../../common/models/scenario"

/**
 * @returns { * } The next state for the TimeSeriesViewer based on the prevState and the nextProps.
 */
export function createNextState(nextProps, prevState) {
  const { scenario, selectedChartVars, timeSeries } = nextProps
  const { lastScenario, lastSelections, lastTimeSeries } = prevState

  if (scenario === lastScenario &&
    selectedChartVars === lastSelections &&
    timeSeries === lastTimeSeries) {
    return null
  }

  if (isUndefined(timeSeries)) {
    return null
  }

  const secondsOffsetFn = getEpochTimeStampBase(timeSeries)
    .map(offsetSeconds)
    .option(provideZero)

  const cueTimeSecs = secondsOffsetFn(getCueTime(scenario).option())
  const timeOfInterestSecs = secondsOffsetFn(getTimeOfInterest(scenario))

  // Don't show reference time for Epochs or baselines
  const caseType = getCaseType(scenario).option()
  const refTimeSecs = caseType === 'Epoch' || caseType === 'Baseline'
    ? null
    : secondsOffsetFn(getReferenceTime(scenario).option())

  const annotationStartSecs = secondsOffsetFn(getAnnotationStart(scenario).option())
  const annotationEndSecs = secondsOffsetFn(getAnnotationEnd(scenario).option())

  const labels = createXAxisLabels(timeSeries)
  const data = buildChartData(selectedChartVars, timeSeries, labels)

  const maxTicks = Math.ceil(end(labels))
  const options = buildChartOptions(maxTicks, selectedChartVars)

  return {
    data,
    options,
    secondsOffsetFn,
    refTimeSecs,
    cueTimeSecs,
    timeOfInterestSecs,
    annotationStartSecs,
    annotationEndSecs,
    lastSelections: selectedChartVars,
    lastScenarioId: scenario.id,
    lastTimeSeriesId: timeSeries.epochId,
  }
}

