import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import AmpTable from "../../../../common/components/AmpTable/index";
import ColumnType from "../../../../common/components/AmpTable/ColumnType";
import { stringCompare } from "../../../../common/util/string";

const { STRING, NUMBER, INTEGER } = ColumnType;

const buildFilterVarColumnData = variables =>
  variables.map(v => ({ id: v.columnName, label: v.name, type: STRING }));

const buildColumnData = variables =>
  [
    { id: 'fileId', label: 'Case ID', type: INTEGER },
    { id: 'initialSpeed', label: 'Initial Speed', type: INTEGER },
    { id: 'urgency', label: 'Urgency', type: NUMBER }
  ]
    .concat(buildFilterVarColumnData(variables))
    .sort((a, b) => stringCompare(a.label, b.label));

const styles = {
  table: {
    border: '1px solid #DDD',
    borderRadius: 6,
  }
};

class TableResultsView extends React.PureComponent {
  static propTypes = {
    scenarios: PropTypes.array,
    variables: PropTypes.array,
    className: PropTypes.string,
    column: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    onColumnChange: PropTypes.func.isRequired,
    onDirectionChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    scenarios: [],
    variables: {},
  };

  render() {
    const { scenarios, variables, className, classes } = this.props;
    const { column, direction, onColumnChange, onDirectionChange } = this.props;
    const columnData = buildColumnData(variables);

    return (
      <div className={className}>
        <AmpTable
          items={scenarios}
          orderBy={column}
          order={direction}
          onOrderByChange={onColumnChange}
          onOrderChange={onDirectionChange}
          columnData={columnData}
          className={classes.table}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TableResultsView);
