/*
 * This module contains actions that deal with the /app object in the Redux store
 */
import {
  SET_APP_STATUS,
  SET_REQUEST_STATE,
  SET_SCENARIO_BROWSER_VIEW_STATE,
  SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS,
  SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS,
  SET_SELECTED_CHART_VARIABLES,
  SET_UI_STATE
} from "./index"
import { getChartVars, getRequestState } from "../index"
import AppRequestState from "../../models/AppRequestState"
import { createSelectedChartVariable } from "../../models"

export const setAppStatus = (status, profile) => ({ type: SET_APP_STATUS, payload: { status, profile } })
export const setRequestState = (request, requestState, message) =>
  ({ type: SET_REQUEST_STATE, payload: { request, requestState, message } })

export const setSelectedChartVariables = selectedChartVariables =>
  ({ type: SET_SELECTED_CHART_VARIABLES, payload: { selectedChartVariables } })

export const setDefaultChartVariableSelections = () => (dispatch, getState) => {
  const chartVars = getChartVars(getState())
  const accelX = createSelectedChartVariable({ ...chartVars[0], isActive: true })
  const accelY = createSelectedChartVariable({ ...chartVars[1] })
  const brake = createSelectedChartVariable({ ...chartVars[7] })
  const speed = createSelectedChartVariable({ ...chartVars[11] })
  dispatch(setSelectedChartVariables([accelX, accelY, brake, speed]))
}

export const setSelectedAnnotationTabVariableIds = selectedAnnotationTabVariableIds =>
  ({ type: SET_SELECTED_ANNOTATION_TAB_VARIABLE_IDS, payload: { selectedAnnotationTabVariableIds } })

export const setUiState = ui =>
  ({ type: SET_UI_STATE, payload: { ui } })

export const setScenarioBrowserViewState = viewState =>
  ({ type: SET_SCENARIO_BROWSER_VIEW_STATE, payload: { viewState } })

export const setScenarioBrowserExpandedQuickFilters = expandedQuickFilters =>
  ({ type: SET_SCENARIO_BROWSER_EXPANDED_QUICK_FILTERS, payload: { expandedQuickFilters } })

export const resetRequestState = (request) => {
  return (dispatch, getState) => {
    const reqState = getRequestState(getState(), request)
    if (reqState !== AppRequestState.NONE) {
      dispatch(setRequestState(request, AppRequestState.NONE))
    }
  }
}
