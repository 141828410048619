import prop from "crocks/Maybe/prop"
import compose from "crocks/helpers/compose"
import chain from "crocks/pointfree/chain"
import option from "crocks/pointfree/option"
import { ofNilable } from "../util/func"
import { createGroupOp, GROUP_OPS } from "./expressionGroupOp"
import { createExpressionTree, findNodeByLabel } from "./expressionTree"
import { createListExpression } from "./expression"
import { ANALYTICS_TAG_SELECTION_ID, createListVariable, VariableId } from "./filterVariable"

/**
 * @type {Object.<{GRID: string, FILTER: string}>}
 */
export const BranchLabel = Object.freeze({
  QUICK_FILTER: "quick",
  ADVANCED_FILTER: "advanced",
  ANALYTICS_FILTER: 'analytics',
  PORTAL_GROUP: 'portal'
})

const createPortalGroup = (src = {}) =>
  createGroupOp({ ...src, op: GROUP_OPS.AND, label: BranchLabel.PORTAL_GROUP })

export const createQuickFilterBranch = (src = {}) =>
  createGroupOp({ ...src, op: GROUP_OPS.AND, label: BranchLabel.QUICK_FILTER })

export const createAdvancedFilterBranch = (src = {}) =>
  createGroupOp({ ...src, op: GROUP_OPS.OR, label: BranchLabel.ADVANCED_FILTER, isEnabled: false })

export const createAnalyticsFilterBranch = (src = {}) =>
  createGroupOp({ ...src, op: GROUP_OPS.OR, label: BranchLabel.ANALYTICS_FILTER, isEnabled: false })

/**
 * The canonical AMP expression tree has a root group with two children.  The child at index 0
 * is the "portal" group whose children are the quick filter and advanced filter branches. The
 * portal group's operator controls how those two branches are combined (either AND'ed or OR'ed
 * together).
 *
 * The other child of the root is the analytics filter branch.  This allows the root group's
 * operator to control how the analytics branch is combined with the portal's quick and advanced
 * filters.
 *
 * This topology allows us to combine the user's quick filter settings with the output of the
 * advanced filter builder and the analytics filter. This gives the user complete control of
 * their filter settings.
 */
export function createAmpExpressionTree() {
  const portalGroup = createPortalGroup({
    children: [
      createQuickFilterBranch(),
      createAdvancedFilterBranch(),
    ]
  })

  const tagsVar = createListVariable({ id: VariableId.TAGS })
  const analyticsTagExpression = createListExpression({ variable: tagsVar, selected: [ANALYTICS_TAG_SELECTION_ID]})

  const root = createGroupOp({
    op: GROUP_OPS.AND,
    children: [
      portalGroup,
      createAnalyticsFilterBranch({ children: [analyticsTagExpression] })
    ]
  })

  return createExpressionTree({ root })
}

export function createAmpExpressionTreeLookup(tree) {
  const root = safeRoot(tree).option(undefined)

  const portalBranch = getPortalBranch(tree)
  const quickFilterBranch = findNodeByLabel(portalBranch, BranchLabel.QUICK_FILTER)
  const advancedFilterBranch = findNodeByLabel(portalBranch, BranchLabel.ADVANCED_FILTER)
  const analyticsFilterBranch = getAnalyticsFilterBranch(tree)

  return { root, portalBranch, quickFilterBranch, advancedFilterBranch, analyticsFilterBranch }
}

const safeRoot = prop("root")

// safeFindChild :: String -> (Tree -> GroupOp | undefined)
const safeFindChild = label => compose(
  option(undefined),
  chain(root => ofNilable(findNodeByLabel(root, label))),
  safeRoot
)

// getPortalBranch :: Tree -> GroupOp | undefined
export const getPortalBranch = safeFindChild(BranchLabel.PORTAL_GROUP)

// getQuickFilterBranch :: Tree -> GroupOp | undefined
export const getQuickFilterBranch = safeFindChild(BranchLabel.QUICK_FILTER)

// getAdvancedFilterBranch :: Tree -> GroupOp | undefined
export const getAdvancedFilterBranch = safeFindChild(BranchLabel.ADVANCED_FILTER)

// getAnalyticsFilterBranch :: Tree -> GroupOp | undefined
export const getAnalyticsFilterBranch = safeFindChild(BranchLabel.ANALYTICS_FILTER)
