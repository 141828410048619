import { isArrayEmpty } from "../../common/util/predicates"

/**
 * This reducer takes an array of local portal admins and returns an array containing
 * the local portal admins grouped into arrays by organization.
 */
export function groupLocalAdminsByOrg(lpas) {
  if (isArrayEmpty(lpas)) {
    return []
  }

  const orgMap = lpas.reduce((orgs, lpa) => {
    if (orgs[lpa.orgId] === undefined) {
      orgs[lpa.orgId] = [lpa]
    } else {
      orgs[lpa.orgId].push(lpa)
    }
    return orgs
  }, {})

  const result = []
  for (const orgId in orgMap) {
    if (orgMap.hasOwnProperty(orgId)) {
      result.push(orgMap[orgId])
    }
  }

  return result
}