import React from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 500,
    height: 200,
    margin: '20px',
    position: 'relative',
    cursor: 'pointer',
    transition: 'transform 150ms ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    '&:active': {
      transform: 'scale(0.98)',
    },
  }
});

class DashboardCard extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
  };

  render() {
    const { classes, children, onClick } = this.props;

    return (
      <Paper className={classes.root} elevation={6} onClick={onClick}>
        {children}
      </Paper>
    );
  }
}

export default withStyles(styles)(DashboardCard);
