import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import identity from "crocks/combinators/identity";
import AmpDialog from "../../common/components/AmpDialog";
import EditOrgInfoForm from "./EditOrgInfoForm";
import AppRequestState from "../../common/models/AppRequestState";
import { getOrgInfo } from "../../common/store/actions/organization";
import { updateOrgInfo } from "../../common/store/actions";

const { NONE, IN_FLIGHT, SUCCESS, FAILURE } = AppRequestState;
const errorMessage = 'The server returned an error. Try reloading the page and trying again.';
const submitMessage = 'Updating organization info...';

const errorState = { requestState: FAILURE, requestMessage: errorMessage };
const dialogText = `
  Enter the file paths to your organization's local AMP_Data directory. The file paths you enter
  should end with AMP_Data. These paths may be different for your Windows and Mac (or Unix) users. 
  If they are the same, enter the same path twice.
`;

const isFailure = resp => resp.payload && resp.payload.requestState === FAILURE;

const ensurePathsNotNull = ({ windowsPath, unixPath }) => ({
  windowsPath: windowsPath === null ? '' : windowsPath,
  unixPath: unixPath === null ? '' : unixPath,
});

const handleGetResponse = resp =>
  isFailure(resp) ? errorState : { ...ensurePathsNotNull(resp), requestState: NONE, requestMessage: '' };

const handleUpdateResponse = resp =>
  isFailure(resp) ? errorState : { requestState: SUCCESS, requestMessage: 'Success' };

class OrgInfoDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    orgId: PropTypes.number,
    orgName: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    orgId: 0,
    orgName: '',
    onClose: identity,
  };

  state = {
    windowsPath: '',
    unixPath: '',
    requestState: IN_FLIGHT,
    requestMessage: 'Retrieving organization info...',
  };

  componentDidMount() {
    this.props.getOrgInfo(this.props.orgId)
      .then(resp => this.setState(handleGetResponse(resp)));
  }

  handleSubmit = () => {
    this.setState({ requestState: IN_FLIGHT, requestMessage: submitMessage });
    this.props.updateOrgInfo(this.props.orgId, this.state.windowsPath, this.state.unixPath)
      .then((resp) => {
        this.setState(handleUpdateResponse(resp));
        if (!isFailure(resp)) {
          setTimeout(this.props.onClose, 500);
        }
      });
  };

  handleUnixPathChange = ev => this.setState({ unixPath: ev.target.value });
  handleWindowsPathChange = ev => this.setState({ windowsPath: ev.target.value });

  render() {
    const { open, orgName, onClose } = this.props;

    return (
      <AmpDialog
        open={open}
        text={dialogText}
        title={orgName}
        onClose={onClose}
      >
        <EditOrgInfoForm
          {...this.state}
          onUnixPathChange={this.handleUnixPathChange}
          onWindowsPathChange={this.handleWindowsPathChange}
          onSubmit={this.handleSubmit}
        />
      </AmpDialog>
    );
  }
}

const mapDispatchToProps = { getOrgInfo, updateOrgInfo };

export default connect(null, mapDispatchToProps)(OrgInfoDialog);
