import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import withStyles from '@material-ui/core/styles/withStyles';
import SortDirectionButton from "./SortDirectionButton";
import { invertSortDirection } from "../../../common/models";
import { stringCompare } from "../../../common/util/string";

const variableMapper = v => ({ value: v.columnName, name: v.name });

const buildSelections = variables =>
  [
    { value: 'fileId', name: 'Case ID' },
    { value: 'initialSpeed', name: 'Initial Speed' },
    { value: 'urgency', name: 'Urgency' }
  ]
    .concat(variables.map(variableMapper))
    .sort((a, b) => stringCompare(a.name, b.name));

const styles = theme => ({
  sortRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  sortLabel: {
    marginRight: theme.spacing(),
  },
  sort: {
    width: '10em',
    margin: '0 8px',
    border: '1px solid #888',
    borderRadius: '6px',
  },
  sortMenu: {
    fontSize: '90%',
    padding: theme.spacing(),
  }
});

class ResultsSortControls extends React.PureComponent {
  static propTypes = {
    variables: PropTypes.array.isRequired,
    column: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    onColumnChange: PropTypes.func.isRequired,
    onDirectionChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  handleSortChange = ev => this.props.onColumnChange(ev.target.value);
  handleDirectionChange = () => this.props.onDirectionChange(invertSortDirection(this.props.direction));

  render() {
    const { variables, column, direction, className, classes } = this.props;
    const selections = buildSelections(variables);

    return (
      <div className={classNames(classes.sortRoot, className)}>
        <Typography className={classes.sortLabel} component="span">Sort By</Typography>
        <FormControl className={classes.sort}>
          <Select
            value={column}
            onChange={this.handleSortChange}
            classes={{ selectMenu: classes.sortMenu }}
            input={<Input disableUnderline />}
            inputProps={{
              name: 'sort',
              id: 'sort-by',
            }}
          >
            {selections.map(s => <MenuItem key={s.value} value={s.value}>{s.name}</MenuItem>)}
          </Select>
        </FormControl>
        <SortDirectionButton
          direction={direction}
          onClick={this.handleDirectionChange}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ResultsSortControls);
