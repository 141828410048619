import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import ExpressionBuilder from "./ExpressionBuilder";
import { createAmpExpressionTree } from "../../../../common/models";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    height: '100%',
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gridRowGap: '8px',
  },
  docNote: {
    paddingLeft: 16,
    paddingRight: 16
  }
});

class FilterBuilder extends React.PureComponent {
  static propTypes = {
    expressionTree: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    expressionTree: createAmpExpressionTree(),
  };

  handleSubmitFilter = () => this.props.filterScenariosImmediate();

  render() {
    const { expressionTree, onChange, className, classes } = this.props;

    return (
      <div className={classNames(className, classes.root)}>
        <ExpressionBuilder expressionTree={expressionTree} onChange={onChange}/>
        <Typography className={classes.docNote}>
          Note:  The Portal variable definitions are detailed in the Video and Supplemental Dictionaries
          and can be found on the <Link to="/support">Portal Support page</Link>.  The AMP Portal variable
          filter mapping can be found in the Supplemental Dictionary.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(FilterBuilder);
