import React from 'react';

import AmpBanner from "../../common/components/AmpBanner";
import Footer from "../../common/components/Footer";
import LoginDialog from "./LoginDialog";

const LoginPage = () => (
  <div style={{ height: '100vh', display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
    <AmpBanner />
    <LoginDialog />
    <Footer />
  </div>
);

export default LoginPage;