import { useDispatch, useSelector } from "react-redux"
import { fetchOrganizations as fetchOrgs } from "../actions"
import { getOrganizations } from ".."

export default function useOrganization() {
  const dispatch = useDispatch()
  const organizations = useSelector(getOrganizations)

  const fetchOrganizations = () => dispatch(fetchOrgs())

  return { organizations, fetchOrganizations }
}
