import React from 'react';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import withStyles from '@material-ui/core/styles/withStyles';
import { getUserFullName } from "../../common/models";

const getAdminContactInfo = admin =>
  getUserFullName(admin)
    .map(fullName => `${fullName} (${admin.email})`)
    .option("No Contact Info Provided");

const getOrgInitials = admin =>
  admin.orgName
    .split(" ")
    .map(s => s[0])
    .reduce((str, letter) => str.length < 2 ? str.concat(letter) : str);

const styles = {
  root: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  placeholderLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#AAA',
    borderRadius: 6,
    width: 150,
    height: 150,
    marginRight: 16
  }
};

class MemberContactInfo extends React.PureComponent {
  static propTypes = {
    admins: PropTypes.array.isRequired,
  };

  render() {
    const { admins, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.placeholderLogo}>
          <Typography variant="h3" style={{ color: '#444' }}>
            {getOrgInitials(admins[0])}
          </Typography>
        </div>
        <div>
          <Typography variant="h6" component="div">{admins[0].orgName}</Typography>
          {
            admins.map(admin => (
              <Typography
                key={admin.email}
                variant="subtitle1"
                component="div"
              >
                {getAdminContactInfo(admin)}
              </Typography>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MemberContactInfo);
