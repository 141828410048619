import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import isArray from "crocks/predicates/isArray"
import { makeStyles } from "@material-ui/core/styles"
import { useShinyAppState } from "./shinyAppContext"
import { useLocation, useParams } from "../../common/util/reactRouterHooks"
import { useAnalyticsBranchEnabler, useEventIdsInFilter } from "../../common/store/hooks/useFilter"
import { ensureArrayResponse } from "../../common/store/actions/utils"
import { isNotNil } from "../../common/util/predicates"
import { useAnalyticsTag } from "../../common/store/hooks/useAnalytics"
import { getUserProfile } from "../../common/store"
import { connect } from "react-redux"

const useStyles = makeStyles({
  root: {},
})

// const fakeIds = [
//   70571, 51673, 38687, 2804, 8710, 1625, 2213, 47347, 26025, 8, 26011, 8852, 3558, 25869, 41401, 10558, 25993, 2119,
//   24919, 66615, 1517, 25947, 466, 2656, 10646, 2049, 26043
// ]
//
// const fakeStateId = '1234567890abcdef'

const createAnalyticsUrl = (url, searchString) => console.log(`Analytics URL: ${url}${searchString || ''}`) ||
  `${url}${searchString || ''}`

const postInitialIdsToIframe = (iframeRef, ids, user) =>
  iframeRef.current.contentWindow.postMessage({ msg: 'initialIds', data: ids, userId: user.id, membership: user.orgRank }, '*')

const handleMessage = (iframeRef, idHandlerRef, setConnected) => event => {
  const { msg, data, stateid } = event.data
  const logMsg = `got msg ${msg} with data ${data} and stateId ${stateid}`

  if (msg === 'connected') {
    console.log('got "connected" event from Shiny')
    setConnected(true)
  } else if (msg === 'scenarioIds') {
    console.log('got "scenarioIds" event from Shiny')
    idHandlerRef.current(data, stateid)
  }

  console.log(logMsg)
}

const registerAppEventListenerEffect = (iframeRef, idHandlerRef, setConnected) => {
  const handler = handleMessage(iframeRef, idHandlerRef, setConnected)
  window.addEventListener('message', handler)
  return () => window.removeEventListener('message', handler, false)
}

const getEventIdsFromResponse = resp => resp.eventIds ? resp.eventIds : Promise.reject(null)

const fetchInitialIds = (fetchEventIds, setEventIds) => {
  fetchEventIds()
    .then(getEventIdsFromResponse)
    .then(ensureArrayResponse)
    .then(setEventIds)
    .catch(e => isNotNil(e) ? console.error('Error getting event IDs from server', e) : null)
}

function maybePostEventsToIframe(iframeRef, initializedRef, connected, eventIds, user) {
  console.log('maybe send events', initializedRef.current, connected, eventIds ? eventIds.length : eventIds, user.id, user.orgRank)
  if (initializedRef.current === false && connected && eventIds !== undefined) {
    initializedRef.current = true
    postInitialIdsToIframe(iframeRef, eventIds, user)
  }
}

function useAnalyticsScenarioIdHandler(app) {
  const handlerRef = useRef()
  const { setAnalyticsIds } = useAnalyticsTag()
  const { navigate } = useLocation()
  const analyticsBranchEnabler = useAnalyticsBranchEnabler()

  handlerRef.current = (eventIds, stateId) => {
    setAnalyticsIds(app.id, app.name, stateId, isArray(eventIds) ? eventIds : [eventIds])
      .then(analyticsBranchEnabler)
      .then(() => navigate('/scenarios/browser'))
  }

  return handlerRef
}

const AnalyticsApp = ({ user }) => {
  const classes = useStyles()
  const fetchEventIds = useEventIdsInFilter()

  const [eventIds, setEventIds] = useState(undefined)
  const [connected, setConnected] = useState(false)

  const iframeRef = useRef(null)
  const appRef = useRef(null)
  const initializedRef = useRef(false)

  const idHandlerRef = useAnalyticsScenarioIdHandler(appRef.current)
  const { location } = useLocation()

  const { appId } = useParams()
  const { apps } = useShinyAppState()

  useEffect(() => fetchInitialIds(fetchEventIds, setEventIds), [fetchEventIds])
  useEffect(() => registerAppEventListenerEffect(iframeRef, idHandlerRef, setConnected), [idHandlerRef])

  maybePostEventsToIframe(iframeRef, initializedRef, connected, eventIds, user)

  if (apps === undefined) {
    return null
  }

  const app = apps.find(a => a.id === appId)
  if (app === undefined) {
    return null
  }

  appRef.current = app

  return (
    <div className={classes.root}>
      <iframe
        ref={iframeRef}
        id={app.id}
        src={createAnalyticsUrl(app.url, location.search)}
        title={app.name}
        style={{ border: 'none', width: '100%', height: '100%' }}
      />
    </div>
  )
}

AnalyticsApp.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  user: getUserProfile(state),
})

export default connect(mapStateToProps)(AnalyticsApp)
