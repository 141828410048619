import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    zIndex: 2,
    position: 'absolute',
    cursor: 'pointer',
    borderRadius: '50%',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
    border: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
});

class Handle extends React.PureComponent {
  static propTypes = {
    railSize: PropTypes.number,
    handleSize: PropTypes.number,
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
    getHandleProps: PropTypes.func.isRequired,
  };

  static defaultProps = {
    railSize: 14,
    handleSize: 21,
  };

  render() {
    const {
      railSize,
      handleSize,
      domain: [min, max],
      handle: { id, value, percent },
      classes,
      getHandleProps,
    } = this.props;

    return (
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        className={classes.root}
        {...getHandleProps(id)}
        style={{
          left: `${percent}%`,
          width: handleSize,
          height: handleSize,
          marginTop: `${(railSize - handleSize)/2}px`,
          marginLeft: `${-handleSize/2}px`,
        }}
      />
    )
  }
}

export default withStyles(styles)(Handle);
