import React, { useState } from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import safe from "crocks/Maybe/safe"
import chain from "crocks/pointfree/chain"
import option from "crocks/pointfree/option"
import compose from "crocks/helpers/compose"
import identity from "crocks/combinators/identity"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import Popover from "@material-ui/core/Popover"
import makeStyles from "@material-ui/core/styles/makeStyles"
import TagEditForm from "./TagEditForm"
import TagContainer from "./TagContainer"
import { isNotEmpty, isNotNil } from "../../../../common/util/predicates"
import {
  getTagDisplayName,
  getTagEventCount,
  getTagId,
  getTagOwnerEmail,
  getTagOwnerOrgId,
  isTagInOrg,
  isTagOwnedBy,
  isTagShared,
  isTagValid
} from "../../../../common/models/tag"
import useUserProfile from "../../../../common/store/hooks/useUserProfile"
import { userIsLpa } from "../../../../common/models/user"

const safeTagDisplayName = compose(option('Unnamed'), chain(safe(isNotEmpty)), getTagDisplayName)
const safeTagDisplayCount = compose(option(0), chain(safe(isNotNil)), getTagEventCount)

const useStyles = makeStyles({
  root: {
    height: '1em',
    marginBottom: 8,
  },
  readRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    height: '100%',
    cursor: 'pointer',
  },
  editRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    height: '1em',
    fontSize: '80%',
  },
  textField: {
    padding: '6px 0 0 0',
    color: 'white',
  }
})

function useEditButtonRenderer(user, tag) {
  const canEditTag = isTagOwnedBy(user.email, tag) || (userIsLpa(user) && isTagInOrg(user.orgId, tag))

  return canEditTag === false
    ? () => null
    : onClick => (
      <IconButton size="small" onClick={onClick} color="inherit" data-testid="tag-entry-edit-btn">
        <EditIcon/>
      </IconButton>
    )
}

function renderDeleteOrLock(isMine, isLocked, handleDelete) {
  if (isLocked) {
    return <LockIcon/>
  }

  if (isMine) {
    return (
      <IconButton size="small" onClick={handleDelete} color="inherit" data-testid="tag-entry-delete-btn">
        <DeleteIcon color="inherit"/>
      </IconButton>
    )
  }

  return null
}

export default function TagEditorEntry({ tag, assigned, onUpdateTag, onToggleAssignment, onShowDeleteConfirmation }) {
  const [editMode, setEditMode] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const classes = useStyles()
  const user = useUserProfile()
  const renderEditButton = useEditButtonRenderer(user, tag)

  const isMine = user.email === getTagOwnerEmail(tag).option('')
  const isForeign = user.orgId !== getTagOwnerOrgId(tag).option(0)
  const handleTagClick = () => isForeign ? null : onToggleAssignment(tag)
  const handleCancelEdit = () => setEditMode(false)

  const handleEditClick = ev => {
    ev.stopPropagation()
    setEditMode(true)
    setAnchorEl(ev.target)
  }

  const handleUpdateTag = (id, newName, newDescription, oldDisplayName, sharedOrgAdditions, sharedOrgRemovals) => {
    const tagUpdate = newName === tag.name && newDescription === tag.description
      ? { id }
      : { id, name: newName, description: newDescription }

    onUpdateTag(tagUpdate, oldDisplayName, sharedOrgAdditions, sharedOrgRemovals)
    setEditMode(false)
  }

  const handleDeleteClick = ev => {
    ev.stopPropagation()
    if (isTagValid(tag)) {
      const tagId = getTagId(tag).option(0)
      onShowDeleteConfirmation(tagId)
    }
  }

  const tagName = safeTagDisplayName(tag)
  const tagCount = safeTagDisplayCount(tag)
  const variant = isTagShared(tag) ? 'shared' : 'private'

  return (
    <div>
      <TagContainer
        className={classes.root}
        variant={variant}
        assigned={assigned}
        onClick={handleTagClick}
      >
        <div className={classes.readRoot}>
          <Typography color="inherit">{tagName}</Typography>
          <Typography color="inherit" style={{ marginLeft: 8 }}>{`(${tagCount})`}</Typography>
          {renderEditButton(handleEditClick)}
          <div style={{ flexGrow: 1 }}/>
          {renderDeleteOrLock(isMine, isForeign, handleDeleteClick)}
        </div>
      </TagContainer>

      <Popover open={editMode} anchorEl={anchorEl} onClose={handleCancelEdit}>
        <TagEditForm tag={tag} onCancel={handleCancelEdit} onSubmit={handleUpdateTag}/>
      </Popover>
    </div>
  )
}

TagEditorEntry.propTypes = {
  tag: PropTypes.object,
  assigned: PropTypes.bool,
  onUpdateTag: PropTypes.func,
  onToggleAssignment: PropTypes.func,
  onShowDeleteConfirmation: PropTypes.func,
}

TagEditorEntry.defaultProps = {
  tag: {},
  assigned: true,
  onUpdateTag: identity,
  onToggleAssignment: identity,
  onShowDeleteConfirmation: identity,
}
