import privacyStatement from "./privacy";
import contactUs from "./contactUs";

export default [
  {
    id: 'contact',
    content: contactUs
  },
  {
    id: 'privacy',
    content: privacyStatement,
  }
]